import { getStarInvoiceLink } from "./url"
import { getShopPayCount } from '@/api/shop.js'
export const starPayHandle = async (params) => {
    try {
        const { code, data } = await getStarInvoiceLink(params)
        if (code == "0") {
            return data
        } else {
            return "-1"
        }
    } catch (e) {

    }
}

export const getShopPayCountHandle = async (type) => {
    try {
        const { code, data } = await getShopPayCount(type)
        if (code == 0 && data) {
            return data
        }else{
            return 0
        }
    } catch (e) {
        console.log(e)
    }

}