import { useCallback, useEffect, useRef, useState } from "react"


import ranks_bg from '@/assets/images/ranks/ranks_bg.png'

import top_bg_2 from '@/assets/images/ranks/top_bg_2.png'
import top_bg_1 from '@/assets/images/ranks/top_bg_1.png'
import top_bg_3 from '@/assets/images/ranks/top_bg_3.png'
import top_1 from '@/assets/images/ranks/top_1.png'
import top_2 from '@/assets/images/ranks/top_2.png'
import top_3 from '@/assets/images/ranks/top_3.png'

import ClipPathBorderRadius from "../../components/ClipPathBorderRadius"
import { useNavigate } from "react-router-dom"
import { formatIdString, formatNameString, FormattingNumber } from "../../utils"
import { getBattleRanks } from '@/api/pk_url.js'
import BScroll from 'better-scroll'
import { useDispatch, useSelector } from "react-redux"
import { Popup, Popover } from "react-vant"
import BackComp from "../../components/common/BackComp"
import { changeHomePage } from "../../store/module/tabbar"
import HomePage from "../../components/HomePage"

new BScroll('.role-box', {
    pullUpLoad: true,
    scrollbar: true,
    pullDownRefresh: true
    // and so on
})
const ranks = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const homePageType = useSelector((state: any) => state.tabbar.homePage)
    const [buttonActive, setButtonActive] = useState(1)
    const [ranksList, setRanksList] = useState<Array<any>>([])
    const flag = useSelector((state: any) => state.tabbar.value);
    const [myRanks, setMyRanks] = useState<any>({
        name: '',
        id: '',
        level: '',
        points: '',
        rank: '',
        badge: [],
    })
    const getData = useCallback(async () => {
        try {
            const { code, data } = await getBattleRanks(buttonActive)
            if (code == '0') {
                if (data && Object.prototype.toString.call(data.ranks) === '[object Array]') {
                    setRanksList(data.ranks)
                } else {
                    setRanksList([])
                }

                setMyRanks(data.my)
            } else {
                setRanksList([])
            }
        }
        catch (error) {
            setMyRanks({
                name: '',
                id: '',
                level: '',
                points: '',
                rank: '',
                badge: [],
            })
            setRanksList([])
        }
    }, [buttonActive])
    useEffect(() => {
        getData()

    }, [buttonActive])

    useEffect(() => {
        document.addEventListener('touchstart', touchStartHandle);
        return () => {
            document.removeEventListener('touchstart', touchStartHandle);
            window.scrollTo(0, 0);
        }
    })

    const touchStartHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 1px)",
                "important"
            );
        }
    }

    const openHomePage = (id: any) => {
        if (id) {
            dispatch(changeHomePage(
                {
                    showHomePage: true,
                    id: id,
                }
            ))
        }
    }

    const ranksDom = useRef<any>(null)
    return (
        <div ref={ranksDom} className="w-[100vw] h-[100vh] ranksPopover  overflow-hidden relative px-4 pt-4">
            <div className="h-[100vh] w-[100vw] fixed top-0 left-0 -z-10" style={{ background: 'linear-gradient( 179deg, rgba(87,255,61,0.4) 0%, rgba(84,173,255,0) 100%)' }}></div>
            <img className="absolute top-[-20px] z-[-1] left-[-32px]" src={ranks_bg} alt="" />

            <BackComp callback={() => {
                navigate(-1)
            }}>
                <div className="w-full grid grid-cols-3">
                    <ClipPathBorderRadius setButtonActive={() => setButtonActive(1)} buttonActive={buttonActive} borderRadius={15} height={31} width={115} flag={1} text={'Daily Wins'}></ClipPathBorderRadius>
                    <ClipPathBorderRadius setButtonActive={() => setButtonActive(2)} buttonActive={buttonActive} borderRadius={15} height={31} width={115} flag={2} text={'Weekly Wins'}></ClipPathBorderRadius>
                    <ClipPathBorderRadius setButtonActive={() => setButtonActive(3)} buttonActive={buttonActive} borderRadius={15} height={31} width={115} flag={3} text={`History Wins`} ></ClipPathBorderRadius>
                </div>
            </BackComp>


            <div className=" flex mt-[16px] justify-between z-10 px-[10px]">
                <div onClick={() => { openHomePage(ranksList[1].id) }} className=" flex items-center flex-col">
                    <img className="mt-10 w-[40px]" src={top_2} alt="" />
                    <p className=" text-[#FFFFFF] text-[14px] font-semibold max-w-[90px] ellipsis leading-[18px]">{ranksList?.length > 1 ? ranksList[1]?.name.trim() ? formatNameString(ranksList[1]?.name) : formatIdString(ranksList[1]?.id.toString()) : 'None'}</p>
                    <p className=" text-[#A6A6A6] text-[14px] font-semibold leading-[18px]">Wins%: {ranksList[1]?.win_rate ? ranksList[1]?.win_rate : 0}%</p>
                    <p className="flex items-center justify-center mt-[10px] gap-1 relative text-style-3 textStroke text-[16px] leading-[19px] font-semibold italic px-1" >{ranksList[1]?.victory_count ? ranksList[1]?.victory_count : 0} <span className="text-[12px]"></span> </p>
                    <img className="w-[89px] -z-[9] -mt-[23px]" src={top_bg_2} alt="" />
                </div>
                <div onClick={() => { openHomePage(ranksList[0].id) }} className=" flex items-center relative flex-col gap-[3px]">
                    <img className=" w-[30px]" src={top_1} alt="" />
                    <p className=" text-[#FFFFFF] text-[14px] font-semibold max-w-[90px] ellipsis leading-[18px]">{ranksList?.length > 0 ? ranksList[0]?.name.trim() ? formatNameString(ranksList[0]?.name) : formatIdString(ranksList[0]?.id.toString()) : 'None'}</p>
                    <p className=" text-[#A6A6A6] text-[14px] font-semibold leading-[18px]">Wins%: {ranksList[0]?.win_rate ? ranksList[0]?.win_rate : 0}%</p>
                    <p className="flex items-center justify-center mt-[10px] gap-1 relative text-style-3 textStroke text-[16px] leading-[19px] font-semibold italic px-1">{ranksList[0]?.victory_count ? ranksList[0]?.victory_count : 0}</p>
                    <img className="w-[123px] -z-[9] -mt-[29px]" src={top_bg_1} alt="" />
                </div>
                <div onClick={() => { openHomePage(ranksList[2].id) }} className=" flex  items-center flex-col">
                    <img className=" mt-11 w-[41px]" src={top_3} alt="" />
                    <p className=" text-[#FFFFFF] text-[14px] font-semibold max-w-[90px] ellipsis leading-[18px]">{ranksList?.length > 2 ? ranksList[2]?.name.trim() ? formatNameString(ranksList[2]?.name) : formatIdString(ranksList[2]?.id.toString()) : 'None'}</p>

                    <p className=" text-[#A6A6A6] text-[14px] font-semibold leading-[18px]">Wins%: {ranksList[2]?.win_rate ? ranksList[2]?.win_rate : 0}%</p>
                    <p className="flex items-center justify-center gap-1 mt-[10px] relative text-style-3 textStroke text-[16px] leading-[19px] font-semibold italic px-1" >{ranksList[2]?.victory_count ? ranksList[2]?.victory_count : 0}</p>
                    <img className="w-[89px] -z-[9] -mt-[23px]" src={top_bg_3} alt="" />
                </div>
            </div>
            <div style={{ background: 'rgba(222,245,224,0.3)', border: ' 1px solid rgba(87,255,61,0.5)' }} className="!backdrop-blur-[10px] h-[calc(100vh_-_200px)] -mt-[94px] rounded-[16px] bg-white/10  w-full">
                <div className="w-full rounded-[16px]  h-[calc(100vh_-_310px)] overflow-auto">
                    <div className="w-full rounded-[16px] flex gap-5 items-center flex-col  p-5 py-3 ">
                        {ranksList.length > 3 && ranksList.slice(3).map((el: any, index: any) =>
                            <div onClick={() => { openHomePage(el.id) }} key={`ranks ${index}`} className="w-full flex justify-between items-center">
                                <div className="w-[40px] text-[14px] text-left leading-[18px] font-semibold text-white">{index + 4}</div>
                                <div className="flex-1 grid grid-cols-3 gap-4">
                                    <div className="text-[14px] text-center leading-[18px] font-semibold text-white">{el.name.trim() ? formatNameString(el.name) : formatIdString(el?.id.toString())}</div>
                                    <div className="text-[14px] flex justify-start leading-[18px] font-semibold text-white">Wins%: <span className="text-style-6 ml-1">{el?.win_rate ? el?.win_rate : 0}%</span></div>
                                    <div className="text-[16px] text-center flex justify-start leading-[19px] items-center text-white font-semibold">Wins: <span className="text-style-6 ml-1">{el?.victory_count ? el?.victory_count : 0}</span></div>
                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={`fixed flex justify-around ${flag ? 'bottom-[20px] w-[calc(100vw_-_32px)] left-[16px] rounded-[10px] h-[40px] p-[11px] px-[10px]' : 'bottom-0 rounded-[30px_30px_0_0] w-[100vw] h-[70px] p-[26px] px-[16px] left-[0%]'}  z-40 left-0 flex transition-all items-center`}
                style={{ background: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)' }}
            >
                <p className="text-[#01825D] text-[14px] leading-[18px] font-semibold">{myRanks?.name.trim() ? formatNameString(myRanks?.name) : formatIdString(myRanks?.id.toString())}</p>


                {/* <Popover
                    placement={'top-start'}
                    theme='dark'
                    teleport={ranksDom.current}
                    reference={<p className="text-[#fff] text-[14px] leading-[18px] font-semibold flex items-center text-nowrap whitespace-nowrap">Power:{myRanks?.power > 999 ? '999+' : myRanks?.power}</p>}
                >
                    <div style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className='text-[12px] p-[10px] leading-[18px] text-[#ffffff]'>Power:{myRanks?.power}</div>
                </Popover> */}
                <Popover
                    placement={'top-start'}
                    theme='dark'
                    teleport={ranksDom.current}
                    reference={<p className="text-[#fff] text-[14px] leading-[18px] font-semibold flex items-center text-nowrap whitespace-nowrap text-style-3">Wins: ({myRanks?.win_rate ? myRanks?.win_rate : 0}%)</p>}
                >
                    <div style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className='text-[12px] p-[10px] leading-[18px] overflow-auto text-[#ffffff]'>({myRanks?.win_rate ? myRanks?.win_rate : 0}%)</div>
                </Popover>
                <Popover
                    placement={'top-start'}
                    theme='dark'
                    teleport={ranksDom.current}
                    reference={<p className="text-[#fff] text-[14px] leading-[18px] font-semibold flex items-center text-nowrap whitespace-nowrap text-style-3">Wins: {myRanks?.victory_count ? myRanks?.victory_count : 0}</p>}
                >
                    <div style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className='text-[12px] p-[10px] leading-[18px] overflow-auto text-[#ffffff]'>{myRanks?.victory_count ? myRanks?.victory_count : 0}</div>
                </Popover>
                <Popover
                    placement={'top-start'}
                    theme='dark'
                    teleport={ranksDom.current}
                    reference={<p className="text-[#fff] text-[14px] leading-[18px] font-semibold flex items-center text-nowrap whitespace-nowrap">No. {myRanks?.rank > 999 ? '999+' : myRanks?.rank}</p>}
                >
                    <div style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className='text-[12px] p-[10px] leading-[18px] text-[#ffffff]'>No. {myRanks?.rank}</div>
                </Popover>

            </div>
            {homePageType.showHomePage && <HomePage userInfo={userInfo} dialog={homePageType.showHomePage}></HomePage>}

        </div>
    )
}
export default ranks
