import { 
    Cell,
    Slice, 
    Address, 
    Builder, 
    beginCell, 
    ComputeError, 
    TupleItem, 
    TupleReader, 
    Dictionary, 
    contractAddress, 
    ContractProvider, 
    Sender, 
    Contract, 
    ContractABI, 
    ABIType,
    ABIGetter,
    ABIReceiver,
    TupleBuilder,
    DictionaryValue
} from '@ton/core';

export type StateInit = {
    $$type: 'StateInit';
    code: Cell;
    data: Cell;
}

export function storeStateInit(src: StateInit) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeRef(src.code);
        b_0.storeRef(src.data);
    };
}

export function loadStateInit(slice: Slice) {
    let sc_0 = slice;
    let _code = sc_0.loadRef();
    let _data = sc_0.loadRef();
    return { $$type: 'StateInit' as const, code: _code, data: _data };
}

function loadTupleStateInit(source: TupleReader) {
    let _code = source.readCell();
    let _data = source.readCell();
    return { $$type: 'StateInit' as const, code: _code, data: _data };
}

function storeTupleStateInit(source: StateInit) {
    let builder = new TupleBuilder();
    builder.writeCell(source.code);
    builder.writeCell(source.data);
    return builder.build();
}

function dictValueParserStateInit(): DictionaryValue<StateInit> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeStateInit(src)).endCell());
        },
        parse: (src) => {
            return loadStateInit(src.loadRef().beginParse());
        }
    }
}

export type Context = {
    $$type: 'Context';
    bounced: boolean;
    sender: Address;
    value: bigint;
    raw: Cell;
}

export function storeContext(src: Context) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeBit(src.bounced);
        b_0.storeAddress(src.sender);
        b_0.storeInt(src.value, 257);
        b_0.storeRef(src.raw);
    };
}

export function loadContext(slice: Slice) {
    let sc_0 = slice;
    let _bounced = sc_0.loadBit();
    let _sender = sc_0.loadAddress();
    let _value = sc_0.loadIntBig(257);
    let _raw = sc_0.loadRef();
    return { $$type: 'Context' as const, bounced: _bounced, sender: _sender, value: _value, raw: _raw };
}

function loadTupleContext(source: TupleReader) {
    let _bounced = source.readBoolean();
    let _sender = source.readAddress();
    let _value = source.readBigNumber();
    let _raw = source.readCell();
    return { $$type: 'Context' as const, bounced: _bounced, sender: _sender, value: _value, raw: _raw };
}

function storeTupleContext(source: Context) {
    let builder = new TupleBuilder();
    builder.writeBoolean(source.bounced);
    builder.writeAddress(source.sender);
    builder.writeNumber(source.value);
    builder.writeSlice(source.raw);
    return builder.build();
}

function dictValueParserContext(): DictionaryValue<Context> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeContext(src)).endCell());
        },
        parse: (src) => {
            return loadContext(src.loadRef().beginParse());
        }
    }
}

export type SendParameters = {
    $$type: 'SendParameters';
    bounce: boolean;
    to: Address;
    value: bigint;
    mode: bigint;
    body: Cell | null;
    code: Cell | null;
    data: Cell | null;
}

export function storeSendParameters(src: SendParameters) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeBit(src.bounce);
        b_0.storeAddress(src.to);
        b_0.storeInt(src.value, 257);
        b_0.storeInt(src.mode, 257);
        if (src.body !== null && src.body !== undefined) { b_0.storeBit(true).storeRef(src.body); } else { b_0.storeBit(false); }
        if (src.code !== null && src.code !== undefined) { b_0.storeBit(true).storeRef(src.code); } else { b_0.storeBit(false); }
        if (src.data !== null && src.data !== undefined) { b_0.storeBit(true).storeRef(src.data); } else { b_0.storeBit(false); }
    };
}

export function loadSendParameters(slice: Slice) {
    let sc_0 = slice;
    let _bounce = sc_0.loadBit();
    let _to = sc_0.loadAddress();
    let _value = sc_0.loadIntBig(257);
    let _mode = sc_0.loadIntBig(257);
    let _body = sc_0.loadBit() ? sc_0.loadRef() : null;
    let _code = sc_0.loadBit() ? sc_0.loadRef() : null;
    let _data = sc_0.loadBit() ? sc_0.loadRef() : null;
    return { $$type: 'SendParameters' as const, bounce: _bounce, to: _to, value: _value, mode: _mode, body: _body, code: _code, data: _data };
}

function loadTupleSendParameters(source: TupleReader) {
    let _bounce = source.readBoolean();
    let _to = source.readAddress();
    let _value = source.readBigNumber();
    let _mode = source.readBigNumber();
    let _body = source.readCellOpt();
    let _code = source.readCellOpt();
    let _data = source.readCellOpt();
    return { $$type: 'SendParameters' as const, bounce: _bounce, to: _to, value: _value, mode: _mode, body: _body, code: _code, data: _data };
}

function storeTupleSendParameters(source: SendParameters) {
    let builder = new TupleBuilder();
    builder.writeBoolean(source.bounce);
    builder.writeAddress(source.to);
    builder.writeNumber(source.value);
    builder.writeNumber(source.mode);
    builder.writeCell(source.body);
    builder.writeCell(source.code);
    builder.writeCell(source.data);
    return builder.build();
}

function dictValueParserSendParameters(): DictionaryValue<SendParameters> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeSendParameters(src)).endCell());
        },
        parse: (src) => {
            return loadSendParameters(src.loadRef().beginParse());
        }
    }
}

export type Deploy = {
    $$type: 'Deploy';
    queryId: bigint;
}

export function storeDeploy(src: Deploy) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(2490013878, 32);
        b_0.storeUint(src.queryId, 64);
    };
}

export function loadDeploy(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 2490013878) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    return { $$type: 'Deploy' as const, queryId: _queryId };
}

function loadTupleDeploy(source: TupleReader) {
    let _queryId = source.readBigNumber();
    return { $$type: 'Deploy' as const, queryId: _queryId };
}

function storeTupleDeploy(source: Deploy) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    return builder.build();
}

function dictValueParserDeploy(): DictionaryValue<Deploy> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeDeploy(src)).endCell());
        },
        parse: (src) => {
            return loadDeploy(src.loadRef().beginParse());
        }
    }
}

export type DeployOk = {
    $$type: 'DeployOk';
    queryId: bigint;
}

export function storeDeployOk(src: DeployOk) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(2952335191, 32);
        b_0.storeUint(src.queryId, 64);
    };
}

export function loadDeployOk(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 2952335191) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    return { $$type: 'DeployOk' as const, queryId: _queryId };
}

function loadTupleDeployOk(source: TupleReader) {
    let _queryId = source.readBigNumber();
    return { $$type: 'DeployOk' as const, queryId: _queryId };
}

function storeTupleDeployOk(source: DeployOk) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    return builder.build();
}

function dictValueParserDeployOk(): DictionaryValue<DeployOk> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeDeployOk(src)).endCell());
        },
        parse: (src) => {
            return loadDeployOk(src.loadRef().beginParse());
        }
    }
}

export type FactoryDeploy = {
    $$type: 'FactoryDeploy';
    queryId: bigint;
    cashback: Address;
}

export function storeFactoryDeploy(src: FactoryDeploy) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(1829761339, 32);
        b_0.storeUint(src.queryId, 64);
        b_0.storeAddress(src.cashback);
    };
}

export function loadFactoryDeploy(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 1829761339) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    let _cashback = sc_0.loadAddress();
    return { $$type: 'FactoryDeploy' as const, queryId: _queryId, cashback: _cashback };
}

function loadTupleFactoryDeploy(source: TupleReader) {
    let _queryId = source.readBigNumber();
    let _cashback = source.readAddress();
    return { $$type: 'FactoryDeploy' as const, queryId: _queryId, cashback: _cashback };
}

function storeTupleFactoryDeploy(source: FactoryDeploy) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    builder.writeAddress(source.cashback);
    return builder.build();
}

function dictValueParserFactoryDeploy(): DictionaryValue<FactoryDeploy> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeFactoryDeploy(src)).endCell());
        },
        parse: (src) => {
            return loadFactoryDeploy(src.loadRef().beginParse());
        }
    }
}

export type ChangeOwner = {
    $$type: 'ChangeOwner';
    queryId: bigint;
    newOwner: Address;
}

export function storeChangeOwner(src: ChangeOwner) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(2174598809, 32);
        b_0.storeUint(src.queryId, 64);
        b_0.storeAddress(src.newOwner);
    };
}

export function loadChangeOwner(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 2174598809) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    let _newOwner = sc_0.loadAddress();
    return { $$type: 'ChangeOwner' as const, queryId: _queryId, newOwner: _newOwner };
}

function loadTupleChangeOwner(source: TupleReader) {
    let _queryId = source.readBigNumber();
    let _newOwner = source.readAddress();
    return { $$type: 'ChangeOwner' as const, queryId: _queryId, newOwner: _newOwner };
}

function storeTupleChangeOwner(source: ChangeOwner) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    builder.writeAddress(source.newOwner);
    return builder.build();
}

function dictValueParserChangeOwner(): DictionaryValue<ChangeOwner> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeChangeOwner(src)).endCell());
        },
        parse: (src) => {
            return loadChangeOwner(src.loadRef().beginParse());
        }
    }
}

export type ChangeOwnerOk = {
    $$type: 'ChangeOwnerOk';
    queryId: bigint;
    newOwner: Address;
}

export function storeChangeOwnerOk(src: ChangeOwnerOk) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(846932810, 32);
        b_0.storeUint(src.queryId, 64);
        b_0.storeAddress(src.newOwner);
    };
}

export function loadChangeOwnerOk(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 846932810) { throw Error('Invalid prefix'); }
    let _queryId = sc_0.loadUintBig(64);
    let _newOwner = sc_0.loadAddress();
    return { $$type: 'ChangeOwnerOk' as const, queryId: _queryId, newOwner: _newOwner };
}

function loadTupleChangeOwnerOk(source: TupleReader) {
    let _queryId = source.readBigNumber();
    let _newOwner = source.readAddress();
    return { $$type: 'ChangeOwnerOk' as const, queryId: _queryId, newOwner: _newOwner };
}

function storeTupleChangeOwnerOk(source: ChangeOwnerOk) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.queryId);
    builder.writeAddress(source.newOwner);
    return builder.build();
}

function dictValueParserChangeOwnerOk(): DictionaryValue<ChangeOwnerOk> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeChangeOwnerOk(src)).endCell());
        },
        parse: (src) => {
            return loadChangeOwnerOk(src.loadRef().beginParse());
        }
    }
}

export type CheckInItem = {
    $$type: 'CheckInItem';
    user: Address;
    checkInAt: bigint;
}

export function storeCheckInItem(src: CheckInItem) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeAddress(src.user);
        b_0.storeUint(src.checkInAt, 32);
    };
}

export function loadCheckInItem(slice: Slice) {
    let sc_0 = slice;
    let _user = sc_0.loadAddress();
    let _checkInAt = sc_0.loadUintBig(32);
    return { $$type: 'CheckInItem' as const, user: _user, checkInAt: _checkInAt };
}

function loadTupleCheckInItem(source: TupleReader) {
    let _user = source.readAddress();
    let _checkInAt = source.readBigNumber();
    return { $$type: 'CheckInItem' as const, user: _user, checkInAt: _checkInAt };
}

function storeTupleCheckInItem(source: CheckInItem) {
    let builder = new TupleBuilder();
    builder.writeAddress(source.user);
    builder.writeNumber(source.checkInAt);
    return builder.build();
}

function dictValueParserCheckInItem(): DictionaryValue<CheckInItem> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeCheckInItem(src)).endCell());
        },
        parse: (src) => {
            return loadCheckInItem(src.loadRef().beginParse());
        }
    }
}

export type CircularBufferCheckInItems = {
    $$type: 'CircularBufferCheckInItems';
    data: Dictionary<number, CheckInItem>;
    length: bigint;
    start: bigint;
}

export function storeCircularBufferCheckInItems(src: CircularBufferCheckInItems) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeDict(src.data, Dictionary.Keys.Uint(16), dictValueParserCheckInItem());
        b_0.storeUint(src.length, 16);
        b_0.storeUint(src.start, 16);
    };
}

export function loadCircularBufferCheckInItems(slice: Slice) {
    let sc_0 = slice;
    let _data = Dictionary.load(Dictionary.Keys.Uint(16), dictValueParserCheckInItem(), sc_0);
    let _length = sc_0.loadUintBig(16);
    let _start = sc_0.loadUintBig(16);
    return { $$type: 'CircularBufferCheckInItems' as const, data: _data, length: _length, start: _start };
}

function loadTupleCircularBufferCheckInItems(source: TupleReader) {
    let _data = Dictionary.loadDirect(Dictionary.Keys.Uint(16), dictValueParserCheckInItem(), source.readCellOpt());
    let _length = source.readBigNumber();
    let _start = source.readBigNumber();
    return { $$type: 'CircularBufferCheckInItems' as const, data: _data, length: _length, start: _start };
}

function storeTupleCircularBufferCheckInItems(source: CircularBufferCheckInItems) {
    let builder = new TupleBuilder();
    builder.writeCell(source.data.size > 0 ? beginCell().storeDictDirect(source.data, Dictionary.Keys.Uint(16), dictValueParserCheckInItem()).endCell() : null);
    builder.writeNumber(source.length);
    builder.writeNumber(source.start);
    return builder.build();
}

function dictValueParserCircularBufferCheckInItems(): DictionaryValue<CircularBufferCheckInItems> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeCircularBufferCheckInItems(src)).endCell());
        },
        parse: (src) => {
            return loadCircularBufferCheckInItems(src.loadRef().beginParse());
        }
    }
}

export type UpdateMaxCheckInListSizeMsg = {
    $$type: 'UpdateMaxCheckInListSizeMsg';
    size: bigint;
}

export function storeUpdateMaxCheckInListSizeMsg(src: UpdateMaxCheckInListSizeMsg) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(3072845823, 32);
        b_0.storeUint(src.size, 16);
    };
}

export function loadUpdateMaxCheckInListSizeMsg(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 3072845823) { throw Error('Invalid prefix'); }
    let _size = sc_0.loadUintBig(16);
    return { $$type: 'UpdateMaxCheckInListSizeMsg' as const, size: _size };
}

function loadTupleUpdateMaxCheckInListSizeMsg(source: TupleReader) {
    let _size = source.readBigNumber();
    return { $$type: 'UpdateMaxCheckInListSizeMsg' as const, size: _size };
}

function storeTupleUpdateMaxCheckInListSizeMsg(source: UpdateMaxCheckInListSizeMsg) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.size);
    return builder.build();
}

function dictValueParserUpdateMaxCheckInListSizeMsg(): DictionaryValue<UpdateMaxCheckInListSizeMsg> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeUpdateMaxCheckInListSizeMsg(src)).endCell());
        },
        parse: (src) => {
            return loadUpdateMaxCheckInListSizeMsg(src.loadRef().beginParse());
        }
    }
}

export type Withdraw = {
    $$type: 'Withdraw';
    amount: bigint;
}

export function storeWithdraw(src: Withdraw) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeUint(195467089, 32);
        b_0.storeCoins(src.amount);
    };
}

export function loadWithdraw(slice: Slice) {
    let sc_0 = slice;
    if (sc_0.loadUint(32) !== 195467089) { throw Error('Invalid prefix'); }
    let _amount = sc_0.loadCoins();
    return { $$type: 'Withdraw' as const, amount: _amount };
}

function loadTupleWithdraw(source: TupleReader) {
    let _amount = source.readBigNumber();
    return { $$type: 'Withdraw' as const, amount: _amount };
}

function storeTupleWithdraw(source: Withdraw) {
    let builder = new TupleBuilder();
    builder.writeNumber(source.amount);
    return builder.build();
}

function dictValueParserWithdraw(): DictionaryValue<Withdraw> {
    return {
        serialize: (src, builder) => {
            builder.storeRef(beginCell().store(storeWithdraw(src)).endCell());
        },
        parse: (src) => {
            return loadWithdraw(src.loadRef().beginParse());
        }
    }
}

 type CheckIn_init_args = {
    $$type: 'CheckIn_init_args';
    size: bigint;
}

function initCheckIn_init_args(src: CheckIn_init_args) {
    return (builder: Builder) => {
        let b_0 = builder;
        b_0.storeInt(src.size, 257);
    };
}

async function CheckIn_init(size: bigint) {
    const __code = Cell.fromBase64('te6ccgECHwEABHAAART/APSkE/S88sgLAQIBYgIDAvTQAdDTAwFxsKMB+kABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiFRQUwNvBPhhAvhi2zxVFds88uCCyPhDAcx/AcoAVVBQZSDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IjPFhPLP8sPQTNQI/QAyw/LD8ntVBsEAgEgDA0D9u2i7fsBkjB/4HAh10nCH5UwINcLH94gghC3J+f/uo4eMNMfAYIQtyfn/7ry4IHTDwExNIFp+fhCJ8cF8vR/4CCCEAuml1G64wIgghCUapi2uo6oMNMfAYIQlGqYtrry4IHTPwExyAGCEK/5D1dYyx/LP8n4QgFwbds8fwUGBwGSMNMfAYIQC6aXUbry4IH6AAExgW0R+EJSgMcF8vT4J28Q+EFvJBNfA6GCEAX14QChtgiCANVXIcIA8vT4Qn9YgEIQI21tbds8fwgBOm1tIm6zmVsgbvLQgG8iAZEy4hAkcAMEgEJQI9s8CAF+4MAAjrb5AYLwEswe6g2UCwnq9wbvKFxicJ9JeVm7Ut5X/25pvbwbkbO6jo4EpPhC+CMlEDfbPH/bMeCRMOJwCgHKyHEBygFQBwHKAHABygJQBSDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IjPFlAD+gJwAcpoI26zkX+TJG6z4pczMwFwAcoA4w0hbrOcfwHKAAEgbvLQgAHMlTFwAcoA4skB+wAJAJh/AcoAyHABygBwAcoAJG6znX8BygAEIG7y0IBQBMyWNANwAcoA4iRus51/AcoABCBu8tCAUATMljQDcAHKAOJwAcoAAn8BygACyVjMAZJTQLmOQQKAEALIWVkg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIzxbLH8kjEDYBIG6VMFn0WzCUQTP0F+IBpFADqQhZ4w1YCwB6MAGAEALIWVkg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIzxbLH8kjEDUBIG6VMFn0WzCUQTP0F+IBpAIBIA4PAgEgEhMCEbltPbPNs8bGOBsQAhG4Ud2zzbPGxhgbEQAGVHIQAAIlALm7vRgnBc7D1dLK57HoTsOdZKhRtmgnCd1jUtK2R8syLTry398WI5gnAgVcAbgGdjlM5YOq5HJbLDgnAb1J3vlUWW8cdT094FWcMmgnCdl05as07LczoOlm2UZuikgCASAUFQIBIBYXAgEgGBkAEbCvu1E0NIAAYAB1sm7jQ1aXBmczovL1FtVUdGQ0pNb2c3c3ZadU02dmhiUUZ5Sk5zcThRZjhhVk56M1ZXM0RaNnhqaUKCACEbPUts82zxsYYBsaAhGzozbPNs8bGGAbHAACJAGs7UTQ1AH4Y9IAAY40+kABINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiAHTP9MP9ATTD9MPVSAQNhA1EDRsFuD4KNcLCoMJuvLgiYEBAdcAAQHR2zwdAAIjARL4QnDbPBBFEDQeAAZtcCA=');
    const __system = Cell.fromBase64('te6cckECIQEABHoAAQHAAQEFofwvAgEU/wD0pBP0vPLICwMCAWIEDQL00AHQ0wMBcbCjAfpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IhUUFMDbwT4YQL4Yts8VRXbPPLggsj4QwHMfwHKAFVQUGUg10mBAQu68uCIINcLCiCBBP+68tCJgwm68uCIzxYTyz/LD0EzUCP0AMsPyw/J7VQdBQP27aLt+wGSMH/gcCHXScIflTAg1wsf3iCCELcn5/+6jh4w0x8BghC3J+f/uvLggdMPATE0gWn5+EInxwXy9H/gIIIQC6aXUbrjAiCCEJRqmLa6jqgw0x8BghCUapi2uvLggdM/ATHIAYIQr/kPV1jLH8s/yfhCAXBt2zx/BgcKAZIw0x8BghALppdRuvLggfoAATGBbRH4QlKAxwXy9PgnbxD4QW8kE18DoYIQBfXhAKG2CIIA1VchwgDy9PhCf1iAQhAjbW1t2zx/CAE6bW0ibrOZWyBu8tCAbyIBkTLiECRwAwSAQlAj2zwIAcrIcQHKAVAHAcoAcAHKAlAFINdJgQELuvLgiCDXCwoggQT/uvLQiYMJuvLgiM8WUAP6AnABymgjbrORf5MkbrPilzMzAXABygDjDSFus5x/AcoAASBu8tCAAcyVMXABygDiyQH7AAkAmH8BygDIcAHKAHABygAkbrOdfwHKAAQgbvLQgFAEzJY0A3ABygDiJG6znX8BygAEIG7y0IBQBMyWNANwAcoA4nABygACfwHKAALJWMwBfuDAAI62+QGC8BLMHuoNlAsJ6vcG7yhcYnCfSXlZu1LeV/9uab28G5Gzuo6OBKT4QvgjJRA32zx/2zHgkTDicAsBklNAuY5BAoAQAshZWSDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IjPFssfySMQNgEgbpUwWfRbMJRBM/QX4gGkUAOpCFnjDVgMAHowAYAQAshZWSDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IjPFssfySMQNQEgbpUwWfRbMJRBM/QX4gGkAgEgDhMCASAPEQIRuW09s82zxsY4HRAABlRyEAIRuFHds82zxsYYHRIAAiUCASAUFQC5u70YJwXOw9XSyuex6E7DnWSoUbZoJwndY1LStkfLMi068t/fFiOYJwIFXAG4BnY5TOWDquRyWyw4JwG9Sd75VFlvHHU9PeBVnDJoJwnZdOWrNOy3M6DpZtlGbopIAgEgFhkCASAXGAARsK+7UTQ0gABgAHWybuNDVpcGZzOi8vUW1VR0ZDSk1vZzdzdlp1TTZ2aGJRRnlKTnNxOFFmOGFWTnozVlczRFo2eGppQoIAIBIBocAhGz1LbPNs8bGGAdGwACJAIRs6M2zzbPGxhgHSABrO1E0NQB+GPSAAGONPpAASDXSYEBC7ry4Igg1wsKIIEE/7ry0ImDCbry4IgB0z/TD/QE0w/TD1UgEDYQNRA0bBbg+CjXCwqDCbry4ImBAQHXAAEB0ds8HgES+EJw2zwQRRA0HwAGbXAgAAIjzCrzbA==');
    let builder = beginCell();
    builder.storeRef(__system);
    builder.storeUint(0, 1);
    initCheckIn_init_args({ $$type: 'CheckIn_init_args', size })(builder);
    const __data = builder.endCell();
    return { code: __code, data: __data };
}

const CheckIn_errors: { [key: number]: { message: string } } = {
    2: { message: `Stack underflow` },
    3: { message: `Stack overflow` },
    4: { message: `Integer overflow` },
    5: { message: `Integer out of expected range` },
    6: { message: `Invalid opcode` },
    7: { message: `Type check error` },
    8: { message: `Cell overflow` },
    9: { message: `Cell underflow` },
    10: { message: `Dictionary error` },
    13: { message: `Out of gas error` },
    32: { message: `Method ID not found` },
    34: { message: `Action is invalid or not supported` },
    37: { message: `Not enough TON` },
    38: { message: `Not enough extra-currencies` },
    128: { message: `Null reference exception` },
    129: { message: `Invalid serialization prefix` },
    130: { message: `Invalid incoming message` },
    131: { message: `Constraints error` },
    132: { message: `Access denied` },
    133: { message: `Contract stopped` },
    134: { message: `Invalid argument` },
    135: { message: `Code of a contract was not found` },
    136: { message: `Invalid address` },
    137: { message: `Masterchain support is not enabled for this contract` },
    19333: { message: `Index of the item cannot be negative!` },
    22661: { message: `No items in the circular buffer!` },
    27129: { message: `Only the owner can update the maxCheckInListSize!` },
    27921: { message: `Only owner is allowed to withdraw` },
    54615: { message: `Insufficient balance` },
}

const CheckIn_types: ABIType[] = [
    {"name":"StateInit","header":null,"fields":[{"name":"code","type":{"kind":"simple","type":"cell","optional":false}},{"name":"data","type":{"kind":"simple","type":"cell","optional":false}}]},
    {"name":"Context","header":null,"fields":[{"name":"bounced","type":{"kind":"simple","type":"bool","optional":false}},{"name":"sender","type":{"kind":"simple","type":"address","optional":false}},{"name":"value","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"raw","type":{"kind":"simple","type":"slice","optional":false}}]},
    {"name":"SendParameters","header":null,"fields":[{"name":"bounce","type":{"kind":"simple","type":"bool","optional":false}},{"name":"to","type":{"kind":"simple","type":"address","optional":false}},{"name":"value","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"mode","type":{"kind":"simple","type":"int","optional":false,"format":257}},{"name":"body","type":{"kind":"simple","type":"cell","optional":true}},{"name":"code","type":{"kind":"simple","type":"cell","optional":true}},{"name":"data","type":{"kind":"simple","type":"cell","optional":true}}]},
    {"name":"Deploy","header":2490013878,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}}]},
    {"name":"DeployOk","header":2952335191,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}}]},
    {"name":"FactoryDeploy","header":1829761339,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}},{"name":"cashback","type":{"kind":"simple","type":"address","optional":false}}]},
    {"name":"ChangeOwner","header":2174598809,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}},{"name":"newOwner","type":{"kind":"simple","type":"address","optional":false}}]},
    {"name":"ChangeOwnerOk","header":846932810,"fields":[{"name":"queryId","type":{"kind":"simple","type":"uint","optional":false,"format":64}},{"name":"newOwner","type":{"kind":"simple","type":"address","optional":false}}]},
    {"name":"CheckInItem","header":null,"fields":[{"name":"user","type":{"kind":"simple","type":"address","optional":false}},{"name":"checkInAt","type":{"kind":"simple","type":"uint","optional":false,"format":32}}]},
    {"name":"CircularBufferCheckInItems","header":null,"fields":[{"name":"data","type":{"kind":"dict","key":"uint","keyFormat":16,"value":"CheckInItem","valueFormat":"ref"}},{"name":"length","type":{"kind":"simple","type":"uint","optional":false,"format":16}},{"name":"start","type":{"kind":"simple","type":"uint","optional":false,"format":16}}]},
    {"name":"UpdateMaxCheckInListSizeMsg","header":3072845823,"fields":[{"name":"size","type":{"kind":"simple","type":"uint","optional":false,"format":16}}]},
    {"name":"Withdraw","header":195467089,"fields":[{"name":"amount","type":{"kind":"simple","type":"uint","optional":false,"format":"coins"}}]},
]

const CheckIn_getters: ABIGetter[] = [
    {"name":"totalCheckIn","arguments":[],"returnType":{"kind":"simple","type":"int","optional":false,"format":257}},
    {"name":"owner","arguments":[],"returnType":{"kind":"simple","type":"address","optional":false}},
    {"name":"maxCheckInListSize","arguments":[],"returnType":{"kind":"simple","type":"int","optional":false,"format":257}},
    {"name":"checkInList","arguments":[],"returnType":{"kind":"simple","type":"CircularBufferCheckInItems","optional":false}},
]

const CheckIn_receivers: ABIReceiver[] = [
    {"receiver":"internal","message":{"kind":"text","text":"check-in"}},
    {"receiver":"internal","message":{"kind":"typed","type":"UpdateMaxCheckInListSizeMsg"}},
    {"receiver":"internal","message":{"kind":"typed","type":"Withdraw"}},
    {"receiver":"internal","message":{"kind":"typed","type":"Deploy"}},
]

export class CheckIn implements Contract {
    
    static async init(size: bigint) {
        return await CheckIn_init(size);
    }
    
    static async fromInit(size: bigint) {
        const init = await CheckIn_init(size);
        const address = contractAddress(0, init);
        return new CheckIn(address, init);
    }
    
    static fromAddress(address: Address) {
        return new CheckIn(address);
    }
    
    readonly address: Address; 
    readonly init?: { code: Cell, data: Cell };
    readonly abi: ContractABI = {
        types:  CheckIn_types,
        getters: CheckIn_getters,
        receivers: CheckIn_receivers,
        errors: CheckIn_errors,
    };
    
    private constructor(address: Address, init?: { code: Cell, data: Cell }) {
        this.address = address;
        this.init = init;
    }
    
    async send(provider: ContractProvider, via: Sender, args: { value: bigint, bounce?: boolean| null | undefined }, message: 'check-in' | UpdateMaxCheckInListSizeMsg | Withdraw | Deploy) {
        
        let body: Cell | null = null;
        if (message === 'check-in') {
            body = beginCell().storeUint(0, 32).storeStringTail(message).endCell();
        }
        if (message && typeof message === 'object' && !(message instanceof Slice) && message.$$type === 'UpdateMaxCheckInListSizeMsg') {
            body = beginCell().store(storeUpdateMaxCheckInListSizeMsg(message)).endCell();
        }
        if (message && typeof message === 'object' && !(message instanceof Slice) && message.$$type === 'Withdraw') {
            body = beginCell().store(storeWithdraw(message)).endCell();
        }
        if (message && typeof message === 'object' && !(message instanceof Slice) && message.$$type === 'Deploy') {
            body = beginCell().store(storeDeploy(message)).endCell();
        }
        if (body === null) { throw new Error('Invalid message type'); }
        
        await provider.internal(via, { ...args, body: body });
        
    }
    
    async getTotalCheckIn(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('totalCheckIn', builder.build())).stack;
        let result = source.readBigNumber();
        return result;
    }
    
    async getOwner(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('owner', builder.build())).stack;
        let result = source.readAddress();
        return result;
    }
    
    async getMaxCheckInListSize(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('maxCheckInListSize', builder.build())).stack;
        let result = source.readBigNumber();
        return result;
    }
    
    async getCheckInList(provider: ContractProvider) {
        let builder = new TupleBuilder();
        let source = (await provider.get('checkInList', builder.build())).stack;
        const result = loadTupleCircularBufferCheckInItems(source);
        return result;
    }
    
}
