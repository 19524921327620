import stack from '@/assets/images/cards/stack.png'
import StarAI from '@/assets/images/cards/StarAI.png'
import show from '@/assets/images/cards/show.svg'
import selectActive from "@/assets/images/selectActive.png"
import soldOutImg from '@/assets/images/cards/soldOut.png'
import selling from '@/assets/images/cards/selling.png'
import coin from '@/assets/images/coin.png'
import close from "@/assets/images/task/close.png"
import report from '@/assets/images/cards/report.svg'
import { Popup } from 'react-vant'
import WebApp from '@twa-dev/sdk'
import '@/styles/index.less'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getCardSaleCards, postBuy, getUserInfo, getCards, checkCardStatus, postSetting, getSetting, getRefiningTips, reportCard, getThumbup } from '@/api/url.js'
import { useDispatch, useSelector } from 'react-redux'
import CardItem from '../../components/CardItem'
import { convertNumber, formatIdString, formatNumber } from '../../utils'
import { changeHomePage, changeUserInfo } from '../../store/module/tabbar'
import { changeCardData, changeSaleCardData } from '../../store/module/tasks'
import { addRefiningCardList, removeRefiningCardList, changeIsSelect } from '../../store/module/search'
import PromptDialog from '../../components/PromptDialog'
import usePlaySound from "@/hook/usePlaySound.tsx"
import activeImg from "../../assets/images/selectActive.png"
import { changeSettings } from '../../store/module/settins'
import Back from '../../components/common/BackComp'
import ButtonComp from '../../components/common/ButtonComp'
import PublicDialog from '../../components/PublicDialog'
import { changeCardReport } from '../../store/module/card'
import NsfwPay from '../../components/nsfw/nsfwPay'
import CloseComp from '../../components/common/CloseComp'

interface SaleProps {
    setCardsData?: Function
    setSaleCardsData?: Function
    scrollToTop?: Function
    setPage?: Function
    saleCardsData: Array<any>
    isPadding?: Boolean
    type?: string
    callback?: Function
    sort?: Number
}
const Sale = ({ setCardsData, saleCardsData, setSaleCardsData, isPadding = true, type = "", setPage, scrollToTop, callback, sort }: SaleProps) => {
    const closeAudioRef = useRef<any>(null)
    const [seq, setSeq] = useState('')
    const [buttonActive, setVuttonActive] = useState(2);
    const dispatch = useDispatch()
    const closePlaySound = usePlaySound('close')
    // const [cardPurchaseFaileddialog, setCardPurchaseFailed] = useState(false)
    const [buyCard, setBuyCard] = useState({})
    // const [insufficientCoinsDialog, setInsufficientCoins] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [soldOutDialog, setSoldOut] = useState(false)
    const [buyPopup, setBuyPopup] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [confirmBuyPopup, setConfirmBuyPopup] = useState(false)
    const popupRef = useRef<any>(null)
    const dialogRef = useRef<any>(null)
    const [payDialog, setPayDialog] = useState<any>(false)
    const [promptText, setPromptText] = useState<any>('')
    const [active, setActive] = useState<number>(-1)
    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const refiningCardList = useSelector((state: any) => state.search.refiningCardList)
    const address_params = localStorage.getItem('address_params')
    const [text, setText] = useState<any>('')
    const [coin_NSFW, setCoin_NSFW] = useState(false)
    const [is_report, setReport] = useState(false)
    const settings = useSelector((state: any) => state.settings.settings)
    const card_report = useSelector((state: any) => state.card.card_report)

    const cardType = useRef<any>(null)
    const isNft = useRef<any>(false)
    const actionArr = useMemo(() => {
        return saleCardsData[active]
    }, [buttonActive, active, saleCardsData])

    const setUserInfo = useCallback(async () => {
        const UserInfo = await getUserInfo(address_params)
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))

        }
    }, [])

    const getCardsData = useCallback(async () => {
        const ID = window.localStorage.getItem("userId") || "";
        const resSale = await getCardSaleCards(1, 20, sort)
        const res = await getCards(ID, 1, 20, -1)
        if (res.code == 0) {
            dispatch(changeCardData(res.data.data))
            setCardsData && setCardsData(res.data.data)
        }
        if (resSale.code == 0) {
            setSaleCardsData && setSaleCardsData(resSale.data.data)
            dispatch(changeSaleCardData(resSale.data.data))
        }
    }, [sort])

    async function Buy(id: string, points: string) {
        setBuyPopup(false)
        setConfirmBuyPopup(false)
        // closePlaySound()
        setText('Attention')
        if (userInfo.points > points) {
            try {
                const res = await postBuy(Number(id))
                if (res.code == 0) {
                    setSeq(res.data.seq)
                    setText('')
                    setUserInfo()
                    setPromptText("Purchase successful!🎉")
                    setDialog(true)
                } else if (res.code == 65541) {

                    setPromptText('Someone else has bought this card.')
                    setDialog(true)
                }
                else if (res.code == 65547) {
                    setPromptText('Insufficient $STAR')
                    setDialog(true)
                } else if (res.code == 65550) {
                    setPromptText("You can't buy your own cards.")
                    setDialog(true)
                } else {
                    setPromptText('Card purchase failed, please try again.')
                    setDialog(true)
                }
                setPage && setPage(2)
                scrollToTop && scrollToTop()
                // getCardsData()
                callback ? '' : getCardsData()
            } catch (err) {
                setPromptText('Card purchase failed, please try again.')
                setDialog(true)
            }
        } else {
            setPromptText('Insufficient $STAR')
            setDialog(true)
        }
    }

    const promptCard = useMemo(() => {
        return promptText.includes('Purchase successful') ? buyCard : { seq: '', description: "", image: '', is_official: "", level: '', }

    }, [promptText, active, dialog])
    //音频播放
    const audioPlayHandle = () => {
        if (closeAudioRef.current) {
            closeAudioRef.current.currentTime = 0;
            closeAudioRef.current?.play()
        }
    }

    const [reportIndex, setReportIndex] = useState<any>([])
    async function reportPost() {
        try {
            let [code] = await reportCard({ id: actionArr?.id, type: reportIndex })
            if (code != "0") {
                await reportCard()
            }
            setPage && setPage(2)
            scrollToTop && scrollToTop()
            callback ? '' : getCardsData()
            setReport(false)
            setBuyPopup(false)
        } catch {
            setPage && setPage(2)
            scrollToTop && scrollToTop()
            callback ? '' : getCardsData()
            setReport(false)
            setBuyPopup(false)
        }
        setReportIndex([])
    }
    async function Thumbup(id: any) {
        if (!loading) {
            setLoading(true)
            WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
            try {
                const { data } = await getThumbup(id)
                if (!saleCardsData) return
                const newSaleCardsData: any = saleCardsData.map((el: any) => {
                    let newLikeCount = JSON.parse(JSON.stringify(el))
                    if (el.id == id) {
                        newLikeCount.like_count = data.like_count
                    }
                    return newLikeCount
                })
                setSaleCardsData(newSaleCardsData)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
    }

    // const getTips = useCallback(async () => {
    //     try {
    //         const { code, data } = await getRefiningTips()
    //         if (code == 0 && Array.isArray(data.card_report)) {

    //             dispatch(changeCardReport(data))
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }, [])
    // useEffect(() => {
    //     if (card_report.card_report) {
    //         getTips()
    //     }
    // }, [])

    function reportText() {
        return (
            <div className=' pt-10 px-[14px] relative'>
                <CloseComp position="absolute" top={14} right={16} callBack={() => { setReport(false) }}></CloseComp>
                <p className=' text-center text-[20px] mb-5 leading-6 font-bold text-[#333333]'>Report Inappropriate Image</p>
                {Array.isArray(card_report.card_report) && card_report.card_report.map((el: any, index: number) =>
                    <div key={`${index}-Inappropriate`} className='flex mt-[10px] gap-[10px]' onClick={() => {
                        if (reportIndex.includes(index + 1)) {
                            setReportIndex(reportIndex.filter((el: any) => el != index + 1))
                        } else {
                            setReportIndex([...reportIndex, index + 1])
                        }
                    }}>
                        <div style={{ border: `${reportIndex.includes(index + 1) ? '' : '1px solid #333333'}` }}
                            className='w-5 flex-shrink-0 rounded-full h-5'>
                            {reportIndex.includes(index + 1) && < img className=' h-5 w-5' src={selectActive} alt="" />}
                        </div>
                        <p className='text-[14px] leading-[18px] text-[#333] font-semibold'>{el}</p>
                    </div>
                )}
                <div className='h-[30px]'></div>
                <ButtonComp title='OK' callBack={() => { reportPost() }}></ButtonComp>
            </div>
        )

    }

    const teleportRef = useRef(null)
    return (
        <>
            <div className={`${isPadding ? 'pt-12' : ''} flex px-[.1875rem] pl-[10px] pr-[.75rem] relative flex-wrap justify-between`}>
                {saleCardsData && saleCardsData.length > 0 && saleCardsData?.map((el: any, index: number) =>
                    <div style={{ width: 'calc(50%)' }} key={`saleCardsData${index}`} className={`pt-[8px] pl-[5px] pr-[.3125rem] pb-[.1875rem] relative `}>
                        <div onClick={async () => {
                            // if (el.count != 0) {
                            // audioPlayHandle()
                            closePlaySound()
                            if (type) {
                                if (!el.is_official && el.state == 2) { return false }
                                if (refiningCardList.find((item: any) => item.id == el.id)) {
                                    dispatch(removeRefiningCardList(el.id))
                                    return false;
                                }
                                if (refiningCardList.length < 2) {
                                    dispatch(addRefiningCardList(el))
                                } else {
                                    dispatch(changeIsSelect(true))
                                    setTimeout(() => {
                                        dispatch(changeIsSelect(false))
                                    }, 1200)
                                }

                            } else {
                                if (el.count == 0) return false
                                try {
                                    const res = await checkCardStatus(el.id)
                                    if (res.code == 0) {
                                        cardType.current = res.data.card_type
                                        isNft.current = res.data.is_nft
                                        setBuyPopup(true);
                                        setActive(index);
                                    } else {
                                        getCardsData()
                                        setPromptText('Someone else has bought this card.')
                                        setDialog(true)
                                    }
                                } catch {
                                    getCardsData()
                                    setPromptText('Someone else has bought this card.')
                                    setDialog(true)
                                }
                            }
                            // }
                        }} className="overflow-hidden w-full z-[2] flex items-center justify-center p-[.125rem] h-fit rounded-[.625rem] relative">
                            {type != 'refining' && el.is_nsfw && !settings.star_nsfw && <div className=' w-[100%] h-[100%] absolute top-0 left-0 flex items-center justify-center !backdrop-blur-[1.25rem] z-10' onClick={(e: any) => {
                                e.stopPropagation()
                                if (settings.nsfw_pay_type != 0 || settings.nsfw) {
                                    console.log(settings)
                                    if (setSaleCardsData) {
                                        const newDataList = JSON.parse(JSON.stringify(saleCardsData))
                                        newDataList[index].is_nsfw = false
                                        setSaleCardsData(newDataList)
                                    }
                                } else {
                                    setCoin_NSFW(true)
                                }
                            }}>
                                <img src={show} alt="" />
                            </div>}
                            <CardItem cardType={el.card_type} power={el.power - el.enhance_power} enhanceLevel={type == 'refining' || (el.card_type == 2 && el.is_nft) ? el.enhance_level : ""} property={el.property} mint_state={el.mint_state} state={type == "refining" ? el.state : 0} price={el.price} saleCardsData={saleCardsData} setSaleCardsData={setSaleCardsData} pageName={'sale'} no={el.seq ? `No. ${el.seq}` : 'No. ?'} id={el.id} like={el.like_count} view={el.view_count} image={el.image} level={el.is_official ? 6 : el.level + 1} text={el.description}></CardItem>

                            {
                                type && refiningCardList.find((item: any) => item.id == el.id) &&
                                <div className="isActive absolute top-[.75rem] right-[.75rem]">
                                    <img className="w-5 h-5" src={activeImg} alt="" />
                                </div>

                            }

                            {(el.count == 0 && el.is_official && !type) && <div className='absolute top-0 left-0 w-full h-full bg-[#000000] opacity-[0.5] z-[2]'></div>}

                            {el.count == 0 && el.is_official && !type && <img className='absolute w-[7.1875rem] top-[50%] right-[50%] translate-x-[50%] z-[3] translate-y-[-50%]' src={soldOutImg} alt="" />}
                            {
                                type && el.state == 2 && <div className='absolute top-0 left-0 w-full h-full bg-[#000000] opacity-[0.5] z-[2]'></div>
                            }
                            {
                                type && el.state == 2 && <img className='absolute w-[7.1875rem] top-[50%] right-[50%] translate-x-[50%] z-[3] translate-y-[-50%]' src={selling} alt="" />
                            }
                        </div>
                        {el.count > 1 && <img style={{ width: 'calc(100% - 1.125rem)', height: 'calc(100% - 1.125rem)' }} className='absolute bottom-[-0.1875rem] z-[1] right-[-0.0625rem]' src={stack} alt="" />}
                    </div>
                )}
            </div>
            <Popup
                style={{ width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: 'rgba(0,0,0,0)' }}
                position='right'
                overlay={false}
                ref={teleportRef}
                visible={buyPopup}>
                <div ref={popupRef} className='w-full h-full relative bg-no-repeat bg-cover' style={{ backgroundImage: `url(${actionArr?.image})`, }}>
                    {!actionArr?.is_official && <img className='absolute w-[18px] h-[18px] z-[400] right-4 top-3' onClick={() => setReport(true)} src={report} alt="" />}
                    {/* <img className='absolute h-6 top-[.625rem] left-4 z-10' onClick={() => { closePlaySound(), setBuyPopup(false), setConfirmBuyPopup(false) }} src={left_arrow} alt="" /> */}
                    <Back position={true} callback={() => { setBuyPopup(false), setConfirmBuyPopup(false) }}></Back>
                    <div className='w-full !backdrop-blur-[1.25rem] flex justify-center items-center flex-col relative z-1 h-full !py-10 px-10' onClick={() => setConfirmBuyPopup(false)}>
                        <div ref={dialogRef} className="w-[100%] items-center flex justify-center p-2 overflow-hidden relative">
                            <CardItem info={actionArr} cardType={2} noLike={true} enhanceLevel={type == 'refining' || (actionArr?.card_type == 2 && actionArr?.is_nft) ? actionArr?.enhance_level : ""} power={actionArr?.power - actionArr?.enhance_power} property={actionArr?.property} mint_state={actionArr?.mint_state} saleCardsData={saleCardsData} setSaleCardsData={setSaleCardsData} id={actionArr?.id} like={actionArr?.like_count} view={actionArr?.view_count} pageName={'sale'} no={buttonActive == 2 ? 'No. ?' : `No. ${convertNumber(actionArr?.seq)}`} image={actionArr?.image} big={true} text={actionArr?.description} level={actionArr?.is_official ? 6 : actionArr?.level + 1}></CardItem>
                        </div>
                        {!(actionArr?.is_official && buttonActive != 2) && <>
                            <div className='w-full'>
                                <div className=' flex justify-between mt-10 items-center'>
                                    <div className={`${actionArr?.card_type == 2 ? 'hidden' : ''} flex w-full  items-center`}>
                                        <img src={coin} className='h-[1.625rem] mr-[.625rem]' alt="" />
                                        <p className='text-style-coin'>{formatNumber(+ actionArr?.price)}</p>
                                    </div>
                                    <div>
                                        <div className={`flex justify-center z-[10] font-medium text-[#E0E0E0] text-[12px] leading-3 gap-[26px] items-center`}>
                                            <div onClick={(e) => {
                                                e.stopPropagation()
                                                Thumbup(actionArr?.id)
                                            }} className=' bg-[rgba(0,0,0,0.4)] font-medium text-[#E0E0E0] text-[12px] leading-3 h-4 scale-[1.5] p-1 rounded-[25px] flex items-center gap-1'>
                                                <div className=' relative'>
                                                    <svg width="9px" height="8px" viewBox="0 0 9 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <g id="炼化mine页面" transform="translate(-35.000000, -266.000000)" fill="#FFFFFF" fillRule="nonzero">
                                                                <g id="编组-13" transform="translate(30.000000, 262.000000)">
                                                                    <g id="编组备份-3" transform="translate(5.000000, 4.000000)">
                                                                        <path d="M6.16401541,0.0287077182 C6.91957638,-0.0868417951 7.62901168,0.148257022 8.20322002,0.700894807 C8.78665349,1.26264323 9.06633256,2.00282677 8.9866814,2.77923064 C8.90793026,3.54630167 8.49572428,4.30381763 7.8020392,4.97156051 C7.60853639,5.15799522 7.08832884,5.66130225 6.341768,6.38437556 C6.00606313,6.70969189 5.64875793,7.05567381 5.28875271,7.40498889 L4.93864762,7.74408228 L4.7995956,7.87896412 C4.6332039,8.04034529 4.3665786,8.04034529 4.2001869,7.87896412 L2.99146934,6.7056921 L2.73609064,6.4585939 C2.22338378,5.96283984 1.71060133,5.46716204 1.1977433,4.97156051 C0.50405822,4.30381763 0.0920772411,3.54652387 0.013326094,2.77923064 C-0.0663250627,2.00282677 0.213128995,1.26264323 0.796787475,0.700894807 C1.37099582,0.148257022 2.08043112,-0.086619588 2.83599209,0.0287077182 C3.39670023,0.114258799 3.96550849,0.390022169 4.50011625,0.830221382 C5.03494901,0.390244376 5.60353228,0.114258799 6.16424042,0.0287077182 L6.16401541,0.0287077182 Z" id="路径"></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <svg className={`svg-celebrate ${loading ? 'svg-celebrate_active' : ''}`} width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                                                        <polygon points="10,10 20,20"></polygon>
                                                        <polygon points="10,50 20,50"></polygon>
                                                        <polygon points="20,80 30,70"></polygon>
                                                        <polygon points="90,10 80,20"></polygon>
                                                        <polygon points="90,50 80,50"></polygon>
                                                        <polygon points="80,80 70,70"></polygon>
                                                    </svg>
                                                </div>
                                                {actionArr?.like_count}
                                            </div>
                                            <div className=' bg-[rgba(0,0,0,0.4)] font-medium text-[#E0E0E0] text-[12px] scale-[1.5] leading-3 h-4 p-1 rounded-[25px] flex items-center gap-1'>
                                                <svg width="11px" height="8px" viewBox="0 0 11 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <g id="炼化mine页面" transform="translate(-73.000000, -266.000000)" fill="#FFFFFF" fillRule="nonzero">
                                                            <g id="编组-15" transform="translate(69.000000, 262.000000)">
                                                                <g id="编组" transform="translate(4.000000, 4.000000)">
                                                                    <path d="M5.50013508,0 C1.86589644,0 0,3.99974599 0,3.99974599 C0,3.99974599 1.4080277,8 5.50013508,8 C9.20593068,8 11,4.01534775 11,4.01534775 C11,4.01534775 9.19162421,0 5.50013508,0 L5.50013508,0 L5.50013508,0 Z M5.50839968,6.49981117 C4.17957953,6.49981117 3.21901919,5.4059633 3.21901919,3.99970588 C3.21901919,2.59332814 4.17965321,1.49976103 5.50839968,1.49976103 C6.83714616,1.49976103 7.79766965,2.59323456 7.79766965,3.99970588 C7.79766965,5.4059633 6.83715845,6.49981117 5.50839968,6.49981117 L5.50839968,6.49981117 L5.50839968,6.49981117 Z M5.50839968,2.4995412 C4.74892827,2.50144219 4.13480577,3.19616841 4.13480577,3.99971925 C4.13480577,4.80284227 4.74894055,5.4998145 5.50839968,5.4998145 C6.26782198,5.4998145 6.88190763,4.8027888 6.88190763,3.99971925 C6.88195676,3.19588766 6.26783426,2.49799296 5.50839968,2.4995412 L5.50839968,2.4995412 L5.50839968,2.4995412 Z" id="形状"></path>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                {actionArr?.view_count}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div onClick={() => {
                                    dispatch(changeHomePage(
                                        {
                                            showHomePage: true,
                                            id: actionArr?.creator,
                                        }
                                    ))
                                }} className='flex w-full mt-[.625rem] items-center'>
                                    <p className=' text-[.875rem] font-semibold leading-[1rem] text-[#a6a6a6]'>Creator: {actionArr?.creator.length > 7 ? formatIdString(actionArr?.creator) : actionArr?.creator}</p>
                                    <img src={actionArr?.is_official ? StarAI : actionArr?.avatar} className='h-[1.25rem] ml-2' alt="" />
                                </div>
                            </div>
                            {
                                cardType.current != 2 && <ButtonComp mt={40} title='Buy' callBack={() => { setConfirmBuyPopup(true) }}></ButtonComp>
                            }
                            {
                                (cardType.current == 2 && isNft.current) && <ButtonComp mt={40} title='Buy' callBack={() => {
                                    const url = import.meta.env.VITE_TRADE_URL + import.meta.env.VITE_MINT_CONTRACT_ADDRESS
                                    WebApp.openLink(url)
                                }}></ButtonComp>
                            }



                        </>}
                    </div>
                </div>
                <PublicDialog width={'290'} teleport={popupRef.current} dialog={is_report} setDialog={setReport} content={reportText()}></PublicDialog>
            </Popup >
            <Popup
                style={{ width: '100%', height: '12.8125rem', borderRadius: '1.875rem 1.875rem 0 0' }}
                position='bottom'
                teleport={popupRef.current}
                onClose={() => { setConfirmBuyPopup(false) }}
                visible={confirmBuyPopup && buyPopup}>
                <div className='w-full rounded-[1.875rem_1.875rem_0_0] px-[2.875rem] py-10 h-full absolute bottom-0' style={{ backgroundImage: `linear-gradient( 135deg, #CFFFAF 0%, #FFFFFF 100%)`, }}>
                    <p className='text-[1rem] text-[#333] leading-[1.1875rem] font-bold'>Creator:  {actionArr?.creator.length > 7 ? formatIdString(actionArr?.creator) : actionArr?.creator}</p>
                    <p className='text-[1rem] text-[#333] leading-[1.1875rem] mt-[1.125rem] mb-[1.875rem] flex items-center font-bold'>Price: <img className='w-[1.375rem] mx-[.375rem]' src={coin} alt="" /> <span className='text-[1rem] text-style-6 leading-[1.1875rem] '>{formatNumber(+ actionArr?.price)}</span></p>
                    {/* <div className='bg-gradient-to-r button_shadow text-[#fff] from-[#00E49F] to-[#54ADFF] w-[100%] h-[2.25rem] rounded-[.625rem]'
                        onClick={
                            () => {
                                Buy(actionArr?.id, actionArr?.price)
                                setBuyCard(actionArr)
                            }
                        }
                    >
                        {loading ? <img className='w-5 h-5 animate-spin' src={loadingImg} /> : 'Buy'}
                    </div> */}
                    <ButtonComp isLoading={loading} width={"100%"} title='Buy' callBack={() => {
                        Buy(actionArr?.id, actionArr?.price)
                        setBuyCard(actionArr)
                    }}></ButtonComp>

                </div>
            </Popup >

            <PromptDialog title={text} dialog={dialog} teleport={document.querySelector("body")} power={promptCard?.power - promptCard?.enhance_power} property={promptCard?.property} no={`No: ${convertNumber(seq)}`} name={promptCard.description} image={promptCard.image} level={promptCard.is_official ? 6 : promptCard?.level + 1} setDialog={setDialog} callback={callback} text={promptText}></PromptDialog>

            {
                coin_NSFW && <NsfwPay type={14} closeHandle={async (val) => {
                    setCoin_NSFW(false)
                    if (!val) {
                        return;
                    }
                    try {
                        const result = await postSetting(localStorage.getItem('userId'), { ...settings, nsfw: true, order_id: val })
                        if (result.code == 0) {
                            dispatch(changeSettings({ ...settings, nsfw: true }))
                            setUserInfo()
                        } else {
                            setText('Attention')
                            setPromptText('Network error')
                            setDialog(true)
                        }

                        const setting = await getSetting(localStorage.getItem("userId") || '')
                        if (setting.code == 0) {
                            dispatch(changeSettings(setting.data))
                        } else {
                            setText('Attention')
                            setPromptText('Network error')
                            setDialog(true)
                        }
                    } catch {
                        setText('Attention')
                        setPromptText('Network error')
                        setDialog(true)

                    }
                }


                }></NsfwPay>
            }
            <PromptDialog title={'Attention'} dialog={payDialog} setDialog={setPayDialog} callback={() => { setDialog(false) }} text={promptText}></PromptDialog>

            {/* <audio ref={closeAudioRef} src={closeMusic} id="audio"></audio> */}
        </ >
    )
}


export default Sale;
