import request from '@/utils/axios-req'



//获取商城配置

export const getShopConfig = (id, type) => {
    return request({
        url: `/api/v1/miniapp/shop/configs`,
        method: 'get',
    })
}



//获取商城支付链接
export const getShopPayLink = (params) => {
    const { title, description, total, type, relevance = "", id } = params
    return request({
        url: `/api/v1/miniapp/shop/pay_link?config_id=${id}&title=${title}&description=${description}&total=${total}&type=${type}&relevance=${relevance}`,
        method: 'get',
    })
}


//商城支付校验

export const getShopPayCheck = (data) => {
    return request({
        url: `/api/v1/miniapp/shop/pay_check`,
        method: 'post',
        data: data
    })
}


//商城支付校验

export const getShopPayCount = (type) => {
    return request({
        url: `/api/v1/miniapp/shop/count?order_type=${type}`,
        method: 'get',
    })
}

//根据活动查找支付配置

export const getShopPayConfig = (type) => {
    return request({
        url: `/api/v1/miniapp/shop/configs/${type}`,
        method: 'get',
    })
}