import { Dialog } from "react-vant"
import { useEffect, useRef } from "react"
import usePlaySound from "@/hook/usePlaySound.tsx"
import CardItem from "./CardItem"
import left_arrow from '@/assets/images/inviting/left-arrow.svg'
import NFT_bg from '@/assets/images/NFT_bg.png'
import "@/styles/NFT.less"
import { useDispatch, useSelector } from "react-redux"
import { changeUserInfo } from "../store/module/tabbar"
import { useNavigate } from "react-router-dom"
import ButtonComp from "./common/ButtonComp"
interface NFTDrawCardsDialogProps {
}

const NFTDrawCardsDialog = ({ }: NFTDrawCardsDialogProps) => {
  const closePlaySound = usePlaySound('close')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  var mo = function (e: any) { e.preventDefault(); };
  const userInfo = useSelector((state: any) => state.tabbar.userInfo)
  const dialogRef = useRef<any>(null)
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      visible={userInfo.card_rewards.length > 0}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={true}
      onClose={() => {
        dispatch(changeUserInfo({ ...userInfo, card_rewards: [] }))
      }}
      width={'100%'}
    >
      <div ref={dialogRef} className={`flex px-10 flex-col items-center justify-center w-[100vw] h-[100vh]`}>
        <img className=' fixed h-6 top-[50px] left-4 z-10' onClick={() => { dispatch(changeUserInfo({ ...userInfo, card_rewards: [] })) }} src={left_arrow} alt="" />
        <div
          data-aos="flip-left"
          data-aos-duration="1000"
          className={`mb-10 items-center flex w-[100%] flex-col justify-center relative`}>
          <div className="relative w-[100%] flex items-center justify-center overflow-hidden">
            <div className="NFT_card">
              <div className="NFT_content">
                <div className="NFT_back rounded-[20px]">
                  <div className="NFT_back-content">
                    <img className=" w-[99%] h-[99.5%] rounded-[20px] " src={NFT_bg} alt="" />
                  </div>
                </div>
                <div className="NFT_front">
                  <div className="NFT_front-content">
                    <CardItem property={userInfo.card_rewards[0]?.property} power={userInfo.card_rewards[0]?.power} rounded={32} no={""} image={userInfo.card_rewards[0]?.card} text={userInfo.card_rewards[0]?.description} big={true} level={userInfo.card_rewards[0]?.level + 1}></CardItem>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ButtonComp title="Waiting for mint" callBack={() => {
            navigate(`/cards?id=1`)
            dispatch(changeUserInfo({ ...userInfo, card_rewards: [] }))
          }} ></ButtonComp>
        </div>
      </div>
    </Dialog>
  )
}
export default NFTDrawCardsDialog
