import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface check_in_loadingType {
    loading: boolean,
    msg: string,
    point: string
}
interface tasksState {
    tasksData: any;
    cardData: any;
    resSale: any;
    cardType: number
    charBackground: string;
    check_in_loading: check_in_loadingType;
    isGateAddress: string
}

const initialState: tasksState = {
    tasksData: [],
    cardData: [],
    resSale: [],
    charBackground: '',
    check_in_loading: {
        loading: false,
        msg: '',
        point: ''
    },
    cardType: 0,
    isGateAddress: ""
}
const tasksStore = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        changeTasks(state, action: PayloadAction<any>) {
            state.tasksData = action.payload
        },
        changeCardData(state, action: PayloadAction<any>) {
            state.cardData = action.payload
        },
        changeSaleCardData(state, action: PayloadAction<any>) {
            state.resSale = action.payload
        },
        changeCharBackground(state, action: PayloadAction<string>) {
            state.charBackground = action.payload
        },
        changeCheck_in_loading(state, action: PayloadAction<check_in_loadingType>) {
            state.check_in_loading = action.payload
        },
        changeCardType(state, action: PayloadAction<number>) {
            state.cardType = action.payload
        },
        changeIsGate(state, action) {
            state.isGateAddress = action.payload
        }
    }
})

export const { changeIsGate, changeTasks, changeCardData, changeSaleCardData, changeCharBackground, changeCheck_in_loading, changeCardType } = tasksStore.actions

export default tasksStore.reducer