
import { Dialog, Toast } from "react-vant"
import { useEffect, useMemo, useRef, useState } from "react"
import coin from '@/assets/images/task/coin.png'
import tonImg from "../../assets/images/shop/ton.png"
import usdtImg from "../../assets/images/shop/usdt.png"
import starImg from "../../assets/images/shop/star.png"
import progressRight from "@/assets/images/refining/progressRight.png"
import close from "@/assets/images/task/close.png"
import CardItem from "../../components/CardItem"
import ButtonComp from "../../components/common/ButtonComp"
import { formatNumber } from "../../utils"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { changeEnhanceSaveOrder, changeIsSaveEnhance } from "../../store/module/search"
import activeImg from "@/assets/images/selectActive.png"
import PayDialog from "../../components/common/PayDialog"
import { getShopPayConfig } from "@/api/shop.js"
import CloseComp from "../../components/common/CloseComp"

interface EnhancedCardDialogProps {
  teleport?: any
  dialog: boolean
  setDialog: Function
  data: any
  cardInfo: any
  setReinforcementResult: any
}

const saveEnhanceCoins = ["10000", "20000","0.6","1.2"]
const EnhancedCardDialog = ({ teleport, dialog, setDialog, data, cardInfo, setReinforcementResult }: EnhancedCardDialogProps) => {
  const dialogRef = useRef(null)
  const navigate = useNavigate();
  const isSaveEnhance = useSelector((state: any) => state.search.isSaveEnhance)
  const { shopCount } = useSelector((state: any) => state.lottery)
  const dispatch = useDispatch()
  const [payList, setPay] = useState<any>([])
  const [showPayDialog, setShowPayDialog] = useState(false)
  const isShowPayDialog = useMemo(() => {
    if (cardInfo.enhance_level < 2) {
      return false
    } else {
      if (cardInfo.enhance_level == 3) {
        if (shopCount[2] > 0) {
          return false
        } else {
          return true
        }
      }
      if (cardInfo.enhance_level == 4) {
        if (shopCount[2] > 1) {
          return false
        } else {
          return true
        }
      }
    }


  }, [shopCount, cardInfo.level,cardInfo.enhance_level])

  const getPayConfigHandle = async () => {
    const toast = Toast.loading({
      message: "Loading...",
      forbidClick: true,
      duration: 0
    })
    try {
      const { code, data } = await getShopPayConfig(11)
      if (code == 0) {
        let list = data.filter((item: any) => item.count == (cardInfo?.enhance_level - 2))
        list = list.map((item: any) => {
            return {
                ...item,
                title: "Enhance Protection"
            }
        });
        setPay(list)
      }
      toast.clear()
    } catch (e) {
      toast.clear()
    }

  }

  useEffect(() => {
    
  }, [])
  return (
    <Dialog
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      onClose={() => { setDialog(false) }}
      width={'92%'}
      teleport={teleport}
    >

      <div ref={dialogRef} style={{ backgroundSize: "100% 100% !important" }} className={`w-full !bg-[length:100%_100%] overflow-visible pb-[42px] relative pt-[32px] bg-[url(@/assets/images/dialog_bg.png)]  `}>
        <CloseComp position="absolute" top={14} right={16} callBack={() => { setDialog(false) }}></CloseComp>
        <div className="flex justify-between items-center px-[50px]">
          <div className="text-[14px] leading-[18px] text-[#333]">Power : {cardInfo.power}</div>
          <div className="text-[14px] leading-[18px] text-[#333]"> Power : <span className="text-style-1 text-[14px] leading-[18px] ">{cardInfo.power + data.next_enhance_level_power}</span></div>
        </div>
        <div className="flex justify-between mt-[14px] items-center px-[50px]">
          <div className=" w-[60px] h-[90px] relative">
            <CardItem property={cardInfo.property} is_enhance={true} height={90} image={cardInfo.image} enhanceLevel={cardInfo.enhance_level} level={cardInfo.level + 1} rounded={10}></CardItem>
          </div>
          <div className=" flex flex-col justify-center items-center gap-[6px]">
            <p className=" text-[14px] leading-[18px] text-[#333]">Level : {cardInfo.enhance_level} - {cardInfo.enhance_level + 1}</p>
            <div className="w-[94px] mt-1 overflow-hidden flex">
              <img className=" w-[94px] translate-x-[-94px] progressRight_1 " src={progressRight} alt="" />
              <img className=" w-[94px] translate-x-[-94px] progressRight_2 " src={progressRight} alt="" />
            </div>
            <div className=" flex justify-start items-center gap-[2px] text-[14px] leading-[18px] text-[#333] ">
              Cost :
              <img className="w-[10px] h-[10px]" src={coin} alt="" />
              <span className=" text-[14px] leading-[18px]  text-style-6">{formatNumber(data.cost)}</span>
            </div>
          </div>
          <div className=" w-[60px] h-[90px] relative">
            <CardItem property={cardInfo.property} is_enhance={true} height={90} image={cardInfo.image} enhanceLevel={cardInfo.enhance_level + 1} level={cardInfo.level + 1} rounded={10}></CardItem>
          </div>
        </div>
        <p className=" text-[14px] leading-[18px] text-[#333] text-center mt-4 ">Success rate : {data.rate / 10}%</p>
        {/* 是否开启强化保护 */}
        <div className={`${cardInfo.enhance_level > 0 ? '' : 'hidden'} radio flex items-center justify-center mt-4`} onClick={() => { dispatch(changeIsSaveEnhance()) }}>
          {
            !isSaveEnhance ? <div className="select-box w-4 h-4 border border-[#000] rounded-full mt-[2px]"></div> : <img className="w-4 h-4" src={activeImg} alt="" />
          }
          <div className="text-sm ml-2 flex">
            <p>Enhance Protection</p>
            {
              !isShowPayDialog ?
                <div className="flex items-center ml-1">
                  <img className="w-[10px] h-[10px]" src={coin} alt="" />
                  <span className="ml-1 text-[14px] leading-[18px]  text-style-6">{formatNumber(Number(saveEnhanceCoins[cardInfo.enhance_level - 1]))}</span>
                </div>
                :

                <div className="flex items-center">
                  {shopCount[2] > 0 ? '(' + shopCount[2] + ')' : ''}

                  <div className='text-style-6 text-[16px] ml-2 font-semibold leading-[18px]'>${saveEnhanceCoins[cardInfo.enhance_level - 1]}</div>
                  <div className='flex justify-around px-2'>
                    <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={starImg} alt="" />
                    <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px] mx-2' src={usdtImg} alt="" />
                    <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={tonImg} alt="" />
                  </div>
                </div>
            }
          </div>
        </div>
        <ButtonComp mt={26} title='OK' callBack={async () => {
          dispatch(changeEnhanceSaveOrder(""))
          if (isSaveEnhance && isShowPayDialog) {
            await getPayConfigHandle()
            setShowPayDialog(true)
            return false
          } else {
            setDialog(false);
            setReinforcementResult(true)
          }

          // navigate(`/strengthenCardResult?id=${cardInfo.id}`)

        }}></ButtonComp>
        <p className=" text-[12px] leading-[15px] text-center text-[#a6a6a6] mt-[10px] px-4">After a failed enhance, the card will not disappear, but its enhancement level will reset!</p>
      </div>
      {
        showPayDialog && <PayDialog isNeedDialogSuccess={false} dataList={payList} title={`Enhance Bundle`} callBack={(val) => {
          setShowPayDialog(false)
          if (val != 'close') {
            dispatch(changeEnhanceSaveOrder(val))
            setDialog(false);
            setReinforcementResult(true)
          }



        }} />
      }
    </Dialog>
  )
}
export default EnhancedCardDialog