import request from '@/utils/axios-req'
import { data } from 'autoprefixer'
import axios from "axios"


// 获取 英雄卡牌
export const getHeroCard = (data) => {
    return request({
        url: `/api/v1/miniapp/battle/character_cards`,
        method: 'get',
        data
    })
}

