import { useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { Address, Cell, Sender, SenderArguments } from '@ton/core';
import { changeTransactionStatus, changeMintTransactionStatus } from '../../store/module/tontransaction'
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'react-vant';
import { waitForTransaction } from '../WaitForTransaction';
import { useTonClient } from './useTonClient';
import { useEffect, useRef } from 'react';
import { changeTransactionBoc } from '../../store/module/lottery';

export function useTonConnect(): { usdtSender: Sender, sender: Sender; connected: boolean, mintSender: Sender, tonClient: any, walletAddress: Address | null } {
  const [tonConnectUI] = useTonConnectUI();
  const dispatch = useDispatch()

  const wallet = useTonWallet();
  const walletAddress = wallet?.account?.address ? Address.parse(wallet.account.address) : undefined;
  const client: any = useTonClient();



  return {
    sender: {
      send: async (args: SenderArguments) => {
        tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc().toString('base64'),
            },
          ],
          validUntil: Date.now() + 1 * 60 * 1000, // 5 minutes for user to approve
        }).then(res => {
          console.log("🚀 ~ send: ~ res transaction success:", res)
          dispatch(changeTransactionStatus("1"))
        }).catch(e => {
          console.log("🚀 ~ send~~~~~ton: ~ e :", e)

          Toast.fail({
            message: 'Payment failed! The TON wallet connection is abnormal, please try again later.',
            forbidClick: true,
            className: 'custom-toast'
          })
          dispatch(changeTransactionStatus("-1"))
        }

        )

      },
    },
    connected: tonConnectUI.connected,
    mintSender: {
      send: async (args: SenderArguments) => {
        tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc().toString('base64'),
            },
          ],
          validUntil: Date.now() + 1 * 60 * 1000, // 5 minutes for user to approve
        }).then(res => {
          dispatch(changeMintTransactionStatus("mintTransactionSuccess_@_" + res.boc))

        }).catch(e => {
          // console.log("🚀 ~ send transaction reject: ~ e :", e)
          Toast.fail({
            message: 'Payment failed! The TON wallet connection is abnormal, please try again later.',
            forbidClick: true,
            className: 'custom-toast'
          })
          dispatch(changeMintTransactionStatus("-1"))
        }

        )

      },
    },
    usdtSender: {
      send: async (args: SenderArguments) => {
        return tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc()?.toString('base64'),
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
        }).then(async (res) => {
          console.log(res)
          dispatch(changeTransactionBoc(res?.boc))
        }).catch((e) => {
          console.log("🚀 ~ usdt send: ~ e:", e)
          Toast.fail({
            message: 'Payment encountered an issue. Please check your wallet balance or network connection and try again.',
            forbidClick: true,
            className: 'custom-toast'
          })
        })
      },
    },
    walletAddress: walletAddress ?? null,
    tonClient: client
  };
}