import { Dialog } from "react-vant"
import { useEffect, useRef } from "react"
import CardItem from "./CardItem"
import close from "@/assets/images/task/close.png"
import icon from "@/assets/component/starspay/icon.png"
import coin from '@/assets/images/coin.png'
import usePlaySound from "@/hook/usePlaySound.tsx"
import ButtonComp from "./common/ButtonComp"
import CloseComp from "./common/CloseComp"


interface DialogProps {
  className?: string,
  dialog: boolean
  setDialog: Function
  callback?: Function
  text: string
  name?: string
  image?: string
  title?: string,
  level?: number
  text_mt?: number
  no?: string
  text_1?: string
  is_Stars?: string
  price?: string
  usdts?: any
  teleport?: any
  lineBreak?: any
  isClose?: boolean
  is?: boolean
  children?: any
  power?: any
  property?: string
}
const PromptDialog = ({ className = 'overflow-visible', dialog, power = 0, property = '', setDialog, text, name, image = '', level = 0, no = '', title = '', text_1, callback, is_Stars, text_mt, teleport, price, usdts, lineBreak = false, children, isClose = false, is = true }: DialogProps) => {
  const dialogRef = useRef<any>(null)
  const closePlaySound = usePlaySound('close')
  var mo = function (e: any) { e.preventDefault(); };
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      className={className}
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={false}
      onClose={() => {
        setDialog(false)
      }}
      teleport={teleport}
      width={image ? '92%' : 290}
    >
      <div ref={dialogRef} style={{ backgroundSize: "100% 100% !important" }} className={`w-full ${image ? '' : 'px-[24px]'} !bg-[length:100%_100%] overflow-visible pt-[12px] relative bg-[url(@/assets/images/dialog_bg.png)] ${!is_Stars ? 'pb-[50px]' : 'pb-[50px]'} `}>

        {(!!is_Stars || isClose) && <CloseComp position="absolute" top={14} right={16} callBack={() => { setDialog(false) }}></CloseComp>}
        <div className=' flex justify-center flex-col items-center pt-[42px]'>
          {title && <p className={`font-bold text-center !text-[#333] text-[20px] ${is_Stars ? 'mb-[36px]' : "mb-5"} leading-[24px] text-[#333]`}>{title}</p>
          }
          {usdts && <img src={usdts} className=" w-[74px] pb-1" alt="" />}

          {!lineBreak ?
            <p className={`text-[#333333] font-bold text-center leading-6 ${image ? 'text-[20px]' : 'text-[14px] leading-[18px]'}`}>{text}</p> :
            <>
              {text.split('.').map((el: string, index: number, arr: any) =>
                <p key={el} className={`text-[#333333] font-bold text-center leading-[24px] ${image ? 'text-[20px]' : 'text-[14px] leading-[18px]'}`}>{el}{arr.length - 1 == index ? '' : '.'}</p>
              )}
            </>
          }


          {is && <p className={`text-[#333333] font-bold text-center ${text_1 ? '' : 'hidden'} my-2.5 mt-[${text_mt}px] text-[14px] leading-[18px]}`}>{text_1}</p>}

          {image && <div className='w-[174px] mt-[15px] relative'>
            <CardItem power={power} property={property} no={no} image={image} text={name} level={level}></CardItem>
          </div>}
          {/* <div className={`bg-gradient-to-r button_shadow ${is_Stars ? "" : 'mt-8'} text-[#fff] from-[#00E49F] to-[#54ADFF] w-[174px] h-[36px] rounded-[10px]`} onClick={() => {
            closePlaySound()
            setDialog(false)
            callback && callback()
          }}>
            OK
          </div> */}


          {
            !children && <ButtonComp mt={is_Stars ? 0 : 32} title='OK' callBack={() => {
              setDialog(false)
              callback && callback()
            }}></ButtonComp>

          }

          {!children && is_Stars && <div className="flex items-center mt-1 justify-center">
            <img className="w-[14px] h-[14px] mr-[2px]" src={is_Stars == 'Stars' ? icon : coin} alt="" />
            <p className={`${is_Stars == 'Stars' ? 'text-style-6' : 'text-style-6'} text-[12px] leading-[15px] font-bold`}>{price}</p>
          </div>}

          {
            children && children
          }


        </div>
      </div>
    </Dialog >
  )
}
export default PromptDialog
