import { Dialog } from "react-vant"
import close from "@/assets/images/task/close.png"
import { useEffect, useRef } from "react"
import CloseComp from "./common/CloseComp"

interface DialogProps {
  width?: string,
  dialog: boolean
  setDialog: (val: boolean) => void
  content: React.ReactNode
  closeOnClickOverlay?: boolean,
  isPadding?: boolean
  RefinedLoading?: boolean
  teleport?: any
  pb?: number,
  customClassName?: string
}
const PublicDialog = ({ width = "100%", dialog, setDialog, content, closeOnClickOverlay = true, isPadding = true, RefinedLoading = true, teleport, pb = 50, customClassName = "" }: DialogProps) => {
  const dialogRef = useRef<any>(null)
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      className={`${width === "100%" ? 'PublicDialog' : ''} ${customClassName ? customClassName : ''} overflow-visible`}
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={closeOnClickOverlay}
      onClose={() => { setDialog(false) }}
      width={width}
      teleport={teleport}
    >

      <div ref={dialogRef} style={{ backgroundSize: "100% 100% !important" }} className={`w-full !bg-[length:100%_100%] overflow-visible relative bg-[url(@/assets/images/dialog_bg.png)] ${isPadding ? `pb-[${pb}px]` : ''} `}>
        {content}
        {!closeOnClickOverlay && RefinedLoading && <CloseComp position="absolute" top={14} right={16} callBack={() => { setDialog(false) }}></CloseComp>}
      </div>
    </Dialog>
  )
}
export default PublicDialog
