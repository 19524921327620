import { Dialog, Toast } from "react-vant"
import close from "@/assets/images/task/close.png"
import ButtonComp from "../../components/common/ButtonComp"
import usdts from '@/assets/images/cards/usdts.png'
import wallet from "@/hook/wallet/contentWallet"
import { useCallback, useEffect, useRef, useState } from "react"
import { Address } from '@ton/core';
import { getUserInfo, getWithdrawInfo, postWithdraw } from "@/api/url.js";
import PromptDialog from "../../components/PromptDialog"
import CloseComp from "../../components/common/CloseComp"


interface withdrawDialogProps {
  dialog: boolean
  setDialog: Function
  setUserPddInfo: Function
  userInfo: any
}
const WithdrawDialog = ({ dialog, setDialog, userInfo, setUserPddInfo }: withdrawDialogProps) => {
  const [active, setActive] = useState<any>(-1)
  const { useConnectWallet, useGetWalletAddress, useDisconnectWallet } = wallet();
  const address = useGetWalletAddress()
  const [withdrawInfo, setWithdrawInfo] = useState([])
  const [promptText, setPromptText] = useState('')
  const [promptDialog, setPromptDialog] = useState(false)
  const [successfulDialog, setSuccessfulDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const teleportRef = useRef<any>(null)


  const init = useCallback(async () => {
    try {
      const { data, code } = await getWithdrawInfo()
      if (code === "0") {
        setWithdrawInfo(data.amount)
      }
    } catch (e) {
      console.log("🚀 ~ innt ~ e:", e)
    }
  }, [])

  // try {
  //   await getUserInfo(Address.parse(address))
  //   const { message, voice } = await postWithdraw({ usdt: withdrawInfo[active] })
  //   if (message) {
  //   }
  // } catch {
  // }

  const [title, setTitle] = useState('Attention')

  const withdrawCallBack = async () => {
    setPromptDialog(false)
    if (active != -1) {
      if (address) {
        const toast = Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          duration: 0
        });
        try {
          setIsLoading(true)
          await getUserInfo(Address.parse(address))
          const { msg, code } = await postWithdraw({ usdt: withdrawInfo[active], addr: Address.parse(address).toString() })
          if (code == '0') {
            setTitle('Withdrawal successful! 🎉')
            setPromptText('Please check your wallet in a few minutes. ⌛')
            setSuccessfulDialog(true)

            toast.clear()
            await setUserPddInfo()
          } else {
            toast.clear()
            setTitle('Attention')
            setPromptText(msg)
            setSuccessfulDialog(true)
          }
        } catch (e) {
          toast.clear()
          setTitle('Attention')
          setPromptText('Blockchain network busy, withdrawal failed. Please try again in 5 minutes.')
          setSuccessfulDialog(true)
        }
      } else {
        useConnectWallet();
      }
      setIsLoading(false)
    }
  }

  const PromptDCallBack = async () => {
    if (active != -1) {
      if (userInfo.withdrawn_total > 2) {
        if (address) {
          setTitle('Attention')
          setPromptText('Confirm proceeding with a $0.20 transaction fee?')
          setPromptDialog(true)

        } else {
          useConnectWallet();
        }
      } else {
        setTitle('Attention')
        setPromptText('Blockchain is congested. Please proceed after the reward exceeds 2 USDT 💰.')
        setSuccessfulDialog(true)
      }
    }
  }
  useEffect(() => {
    init()
  }, [])
  return (
    <Dialog
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={true}
      onClose={() => {
        setActive(-1)
        setDialog(false)
      }}
      width={'100%'}
    >

      <div ref={teleportRef} style={{ backgroundSize: "100% 100% !important" }} className={`w-[calc(100%_-_24px)] !bg-[length:100%_100%] overflow-visible px-[19px] relative bg-[url(@/assets/images/dialog_bg.png)] pt-[31px] mx-[12px] pb-[50px]`}>

        <CloseComp position="absolute" top={14} right={16} callBack={() => { setActive(-1)
          setDialog(false) }}></CloseComp>
        <p className="font-bold text-[20px] leading-6 mb-[20px] text-center text-[#333]">Withdraw</p>
        <div className="flex items-center flex-wrap gap-[10px]">
          {Array.isArray(withdrawInfo) && withdrawInfo.map(((el: any, index: number) =>
            <div
              onClick={() => { setActive(index) }}
              key={`${index} Withdraw`}
              style={{ background: `${index === active ? "linear-gradient( 135deg, rgb(87, 255, 61) 0%, rgb(84, 173, 255) 100%)" : "linear-gradient( 135deg, rgb(87, 255, 61,0.2) 0%, rgb(84, 173, 255,0.2) 100%)"}` }}
              className="w-[calc(50%_-_5px)] h-10 flex flex-shrink-0 justify-center items-center rounded-[6px]">
              <p className="text-style-3 text-[14px] font-semibold leading-[18px]">${el}</p>
            </div>
          ))}
        </div>
        <div style={{ borderBottom: '1px solid #FFFFFF' }} className="flex pt-[20px] justify-between pb-1 items-center">
          <p className="text-[14px] leading-[18px] text-[#333] font-semibold">Transaction Fee</p>
          <p className="text-style-3 text-[14px] leading-[18px] font-semibold">$0.2</p>
        </div>
        <div style={{ borderBottom: '1px solid #FFFFFF' }} className="flex pt-[10px] justify-between pb-1 items-center">
          <p className="text-[14px] leading-[18px] text-[#333] font-semibold">Amount Withdrawable</p>
          <p className="text-style-3 text-[14px] leading-[18px] font-semibold">${userInfo.withdrawn_total}</p>
        </div>
        {
          Array.isArray(withdrawInfo) && <ButtonComp disabled={userInfo.withdrawn_total < withdrawInfo[active] || active == -1 ? true : false} title="Withdraw" callBack={() => { PromptDCallBack() }} mt={38}></ButtonComp>
        }
        <PromptDialog callback={() => { withdrawCallBack() }} teleport={teleportRef.current} title={title} dialog={promptDialog} setDialog={setPromptDialog} text={promptText}></PromptDialog>
        <PromptDialog callback={() => {
          setDialog(false)
          setActive(-1)
          setSuccessfulDialog(false)
        }} teleport={teleportRef.current} title={title} lineBreak={true} usdts={usdts} dialog={successfulDialog} setDialog={setSuccessfulDialog} text={promptText}></PromptDialog>
      </div>
    </Dialog>
  )
}
export default WithdrawDialog
