import { useFakeProgress } from "react-use-fakeprogress";
import logo from '@/assets/images/start/logo.png'
import logo_new from '@/assets/images/start/logo-new.png'
import left from '@/assets/images/start/left.png'
import rules_bg from '@/assets/images/inviting/rules_bg.png'
// import starai from '@/assets/images/start/starai.png'
import ton from '@/assets/images/start/ton.png'
import telegram from '@/assets/images/start/telegram.png'
import logo_icon from '@/assets/images/start/logo-icon.png'
// import progress_line from '@/assets/images/start/progress.png'
// import progressImg from '@/assets/images/talk/progress-line.png'
import inviting_bg from '@/assets/images/inviting/inviting_bg.png'
import rules_bg_4 from '@/assets/images/inviting/rules_bg_4.png'
import rules_bg_2 from '@/assets/images/inviting/rules_bg_2.png'
import rules_bg_3 from '@/assets/images/inviting/rules_bg_3.png'
import talk_bg from '@/assets/images/talk/bg.png'
import "./Start.less"
import { getTask, getCards, getSaleCards, getAnnouncement, getIntroductory } from '@/api/url.js'
import { getMiningMiningUnread } from '@/api/mining.js'

import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TypeIt from "typeit-react";
import { useDispatch } from 'react-redux';
import { changeLoading } from '@/store/module/progress';
import { changeCardData, changeTasks } from '../../store/module/tasks'
import { getAnnouncementList } from "../../store/module/search"
import { changeShowIntroductory, changeMiningPoolConfig } from "../../store/module/settins";


import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
    type Container,
    type ISourceOptions,
    MoveDirection,
    OutMode,
} from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";

const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));


const Start = () => {
    const navigate = useNavigate()
    const [progress, start, done, stop] = useFakeProgress(
        7000 /* timeconstant value */
    );
    const list = [

        {
            id: 2,
            url: telegram,
            name: 'telegram',
        },
        {
            id: 3,
            url: logo_icon,
            name: 'logo',
        },
        {
            id: 1,
            url: ton,
            name: 'TON',
        }
    ]
    const options: any = {
        cursor: false,
        speed: 100
    }
    const progressRef = useRef<HTMLImageElement>(null);

    const dispatch = useDispatch()
    const [cardsData, setCardsData] = useState([])
    const [Sale, setSaleData] = useState([])
    const getTaskData = useCallback(async () => {
        try {
            const { code, data } = await getTask(0, 0)
            if (code === '0') {
                dispatch(changeTasks(data.task))
            }
        }
        catch (e) {
            console.log(e)
        }
        const ID = window.localStorage.getItem("userId") || "";
        try {
            const { code, data } = await getCards(ID, 1, 20)
            if (code === "0") {
                dispatch(changeCardData(data.data))
            }
        } catch (e) {
            console.log(e)
        }

        try {
            const { code, data } = await getSaleCards(1, 20)
            if (code === "0") {
                setSaleData(data.data)
            }
        } catch (e) {
            console.log(e)
        }



        // const charBackground = await getCharBackground(ID)

        // dispatch(changeCharBackground(charBackground.data.image))

    }, [])


    const getAnnouncements = useCallback(async () => {
        const { code, data: { announcement } } = await getAnnouncement()
        if (code == 0) {
            if (announcement.length > 0) {
                dispatch(getAnnouncementList(announcement))
            }

        }
    }, [])

    useLayoutEffect(() => {
        startLoading()
        getIntroductoryHandle()
    }, [])

    const isShowIntroductory = useRef(false)
    const getIntroductoryHandle = async () => {
        try {
            const { code, data } = await getIntroductory("")
            if (code == '0') {
                if (data.result) {
                    isShowIntroductory.current = false
                } else {
                    isShowIntroductory.current = true
                }
            } else {
                isShowIntroductory.current = false
            }
        } catch (e) {
            isShowIntroductory.current = false
        }

    }

    const getMiningMiningUnreadHandle = async () => {
        try {
            const { code, data } = await getMiningMiningUnread()
            if (code == '0') {
                dispatch(changeMiningPoolConfig(data))
            }
        } catch (e) {
            console.log("star page", e)
        }
    }

    const startLoading = async () => {
        start()
        await sleep(6010)
        done()
        dispatch(changeLoading(true))
        if (isShowIntroductory.current) {
            dispatch(changeShowIntroductory(true))
        }
        sessionStorage.setItem('avatarListId', "1")
        navigate("/character");
    }

    useEffect(() => {
        getAnnouncements()
        getTaskData()
        getMiningMiningUnreadHandle()
    }, [])



    const optionsl: any = useMemo(
        () => ({
            background: {
                color: {}
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push',
                    },
                    onHover: {
                        enable: false,
                        mode: 'repulse',
                    },
                    resize: true,
                },
                modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 100,
                    },
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: ["#00E49F",
                        "#54ADFF", "#00E49F"],
                },
                links: {
                    color: '#ffffff',
                    distance: 150,
                    enable: true,
                    opacity: 0,
                    width: 1,
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: false,
                    speed: 0.8,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                        area: 2500,
                    },
                    value: 200,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: 'circle',
                },
                size: {
                    random: false,
                    value: 1.8,
                },
            },
            detectRetina: true,
        }),
        [],
    );

    const [init, setInit] = useState(false);
    // useEffect(() => {
    //     initParticlesEngine(async (engine) => {
    //         // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    //         // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    //         // starting from v2 you can add only the features you need reducing the bundle size
    //         //await loadAll(engine);
    //         //await loadFull(engine);
    //         await loadSlim(engine);

    //     }).then(() => {
    //         setInit(true);
    //     });
    // }, []);

    const particlesLoaded = async (container?: Container): Promise<void> => {
    };

    return (
        <div className="start-box w-full h-full flex flex-col justify-between items-center py-20 relative">
            {/* {init &&
                <div className=" absolute z-50">
                    <Particles
                        id="tsparticles"
                        particlesLoaded={particlesLoaded}
                        options={optionsl}
                    />
                </div>
            } */}
            <div className="text-box text-white h-[160px] w-full z-20">
                <div className="img w-full h-[60px] flex items-center justify-center">
                    <img data-aos="fade-right" data-aos-delay="100" data-aos-duration="800" className='w-[109px] h-[77px]' src={logo_new} alt="" />
                    {/* <img data-aos="fade-left" data-aos-delay="100" data-aos-duration="800" className='w-21 h-7 ml-3' src={starai} alt="" /> */}
                </div>
                <div data-aos="fade-up" data-aos-delay="400" className='text-box flex justify-center items-center gap-[2px] text-white text-xl text-center mt-9'>
                    <img className=" rotate-180 w-[70px] h-[3px]" src={left} alt="" /> Play with AI to Win <img className=" w-[70px] h-[3px]" src={left} alt="" />
                    {/* <TypeIt options={options}>Talk with AI Avatars to win</TypeIt> */}
                </div>
                <div className=" relative w-fit mx-auto">
                    <div className=' w-[308px] mt-3 text-center flex items-center justify-start'>
                        <TypeIt options={options} className="flex items-center">
                            <p data-aos="fade-right" data-aos-duration="800" data-aos-delay="300" data-content="$STAR" className='whitespace-nowrap declaration  flex-shrink-0'>
                                $STAR
                            </p>
                            <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="500" data-content="+" className='whitespace-nowrap mx-1 declaration  flex-shrink-0'>
                                +
                            </p>
                            <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="500" data-content="NFT Cards" className='whitespace-nowrap declaration  flex-shrink-0'>
                                NFT Cards
                            </p>
                            <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="500" data-content='+' className='whitespace-nowrap mx-1 declaration  flex-shrink-0'>
                                +
                            </p>
                            <p data-aos="fade-left" data-aos-duration="800" data-aos-delay="300" data-content='$USDT' className='whitespace-nowrap declaration  flex-shrink-0'>
                                $USDT
                            </p>
                        </TypeIt>
                    </div>
                    <div className='w-[308px] absolute top-[1px] left-[1px] z-0 text-center flex items-center justify-start'>
                        <TypeIt options={options} className="flex items-center">
                            <p data-aos="fade-right" data-aos-duration="800" data-aos-delay="300" data-content="$STAR" className='whitespace-nowrap shadow  flex-shrink-0'>
                                $STAR
                            </p>
                            <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="500" data-content="+" className='whitespace-nowrap mx-1 shadow  flex-shrink-0'>
                                +
                            </p>
                            <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="500" data-content="NFT Cards" className='whitespace-nowrap shadow  flex-shrink-0'>
                                NFT Cards
                            </p>
                            <p data-aos="fade-up" data-aos-duration="800" data-aos-delay="500" data-content='+' className='whitespace-nowrap mx-1 shadow  flex-shrink-0'>
                                +
                            </p>
                            <p data-aos="fade-left" data-aos-duration="800" data-aos-delay="300" data-content='$USDT' className='whitespace-nowrap shadow  flex-shrink-0'>
                                $USDT
                            </p>
                        </TypeIt>
                    </div>
                </div>
            </div>
            <div className="progress-box h-[160px] w-full flex flex-col z-20 text-white px-10 relative">

                {/* <div className="progress h-[18px] w-full mb-10 relative overflow-hidden rounded-full">
                    <img className='w-full h-[18px]' src={progressImg} alt="" />
                    <img ref={progressRef} style={{ left: `${-100 + Math.floor(progress * 100)}%` }} className='w-full h-[12px] absolute top-[50%] -translate-y-[50%] rounded-full' src={progress_line} alt="" />
                </div> */}
                <div className="h-[18px] w-full mb-10 relative overflow-hidden flex justify-center items-center rounded-full bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">
                    <div className="rounded-full h-[14px] w-[calc(100%_-_4px)] bg-white">
                        <div style={{ width: `${Math.floor(progress * 100)}%` }} className={`rounded-full h-[14px]  bg-gradient-to-r from-[#00E49F] to-[#54ADFF]`}>

                        </div>
                    </div>
                </div>

                <div className='progress-len w-ful flex items-center absolute top-7  text-sm font-semibold'>
                    <p className="w-[30px] text-white">{Math.floor(progress * 1000) / 10}%</p>
                    <img className='h-[22px] w-[22px] ml-3' src={logo} alt="" />
                </div>
                {/* {progresslen}% */}
                <div className="list w-full h-[60px] flex justify-evenly items-center mt-5">
                    {
                        list.map(item => {
                            return (
                                <div data-aos="fade-up" data-aos-duration="800" data-aos-delay={100 * item.id} key={item.id} className="item h-full flex items-center justify-center">
                                    <img className='w-14 h-14' src={item.url} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="bg-box h-[calc(100%_-_300px)] w-full  bg-[url(@/assets/images/start/bg.png)] absolute top-[50%] -translate-y-[50%] left-0"></div>
            <div className='absolute left-[10000px]  opacity-0'>
                <img src={rules_bg} alt="" />
                <img src={inviting_bg} alt="" />
                <img src={rules_bg_4} alt="" />
                <img src={rules_bg_2} alt="" />
                <img src={rules_bg_3} alt="" />
                <img src={talk_bg} alt="" />
                {cardsData && cardsData.map((el: any, i: number) =>
                    <img key={`i-10-01-${i}`} src={el.image} alt="" />
                )}
                {Sale && Sale.map((el: any, i: number) =>
                    <img key={`i-1001-${i}`} src={el.image} alt="" />
                )}
            </div>
        </div>
    )
}


export default Start;

