import { configureStore } from '@reduxjs/toolkit';
import tabbarReducer from './module/tabbar';
import progressReducer from './module/progress';
import tasksReducer from './module/tasks';
import searchReducer from "./module/search"
import settinsReducer from "./module/settins"
import tonReducer from "./module/tontransaction"
import lotteryReducer from "./module/lottery"
import createReducer from "./module/createAvatar"
import battleReducer from "./module/battle"
import cardReducer from "./module/card"

export default configureStore<any>({
    reducer: {
        tabbar: tabbarReducer,
        progress: progressReducer,
        tasks: tasksReducer,
        search: searchReducer,
        settings: settinsReducer,
        ton: tonReducer,
        lottery: lotteryReducer,
        createAvatar: createReducer,
        battle: battleReducer,
        card: cardReducer
    },
});