import bg from "../../assets/component/starspay/bg.png"
import lottery from "../../assets/component/starspay/lottery.png"
import lottertbg from "../../assets/component/starspay/lottert_bg.png"
import icon from "../../assets/component/starspay/icon.png"
import { useCallback, useEffect, useRef, useState } from "react"
import WebApp from "@twa-dev/sdk"
import { starPayHandle } from "../../api/starpay.js"
import { lotteryHandleApi, lotteryCount, getUserInfo } from "../../api/url"
import LotteryCardDialog from "../LotteryCardDialog.js"
import { Toast } from "react-vant"
import ButtonComp from "../common/ButtonComp.js"
import { changeUserInfo } from "../../store/module/tabbar.js"
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
import { useDispatch, useSelector } from "react-redux";
import { PAYTYPE } from "../../utils/common-helpers.js"
import TonTransfer from "@/components/connectOkWallet/transform"
import UsdtTransfer from "@/components/connectOkWallet/USDT_transform"
import { changeTransactionBoc, changeUsdtTransferType } from "../../store/module/lottery.js"
import { waitForTransaction } from "../../hook/WaitForTransaction.js"
import ConnectWallet from "../common/ConnectWallet.js"
import { useTonAddress } from "@tonconnect/ui-react"
import PromptDialog from "../PromptDialog.js"


const Pay = () => {
    const [count, setCount] = useState(10)
    const [dialog, setDialog] = useState(false)
    const [cardInfo, setCardInfo] = useState<any>({})
    const [points, setPoints] = useState<string>("0")
    const dispatch = useDispatch()

    const { transformHandle } = TonTransfer()
    const { UsdtTransformHandle } = UsdtTransfer()
    const address = useTonAddress()
    const [isShowConnectWallet, setIsShowConnectWallet] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const errorText = useRef("")

    useEffect(() => {
        if (address) {
            setIsShowConnectWallet(false)
        }
    }, [address])


    useEffect(() => {
        getLotteryCount()
    }, [])

    const getLotteryCount = async () => {
        try {
            const { code, data } = await lotteryCount()
            if (code == "0") {
                setCount(data)
            }
        } catch (e) {
            console.log(e)
        }

    }


    const lotteryHandle = async () => {

        const toast = Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            duration: 0
        });
        const params = {
            title: "Bonus Pack",
            description: "Do you want to buy buy Bonus Pack in StarAI for 9 star?",
            total: 9,
            type: PAYTYPE.star
        }
        const res = await starPayHandle(params)
        toast.clear()
        if (res && res.link) {
            WebApp.openInvoice(res.link, async (status) => {
                if (status === "paid") {
                    await loopOrder(res.order_id)
                }
            })
        }
        if (res && !res.link) {
            await loopOrder(res.order_id)
        }

    }

    const loopOrder = async (order_id: string) => {
        let timeCount = 0;
        let code_ = null;
        let data_ = null;

        while (code_ != "0" && timeCount < 60) {
            await sleep(2000)
            const { code, data } = await lotteryHandleApi({ order_id, transaction_id: "" })
            if (code == "0") {
                code_ = code
                data_ = data
            }
            timeCount++
        }
        refreshCount(data_)
    }
    const setUserInfo = async () => {
        const UserInfo = await getUserInfo()
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))
        }
    }

    // ton pay for

    const tonTransferHandle = async () => {
        if (!address) {
            setIsShowConnectWallet(true)
            return false
        }
        try {
            const data = await transformHandle("0.036")
            if (data?.code == "0") {
                refreshCount(data.data)
            } else if (data?.code == "65551") {
                errorText.current = "Transaction failed, please try again."
                setNetworkError(true)
            } else if (data?.code == "65539") {
                errorText.current = "Blockchain network congested.Please wait for 5 minutes."
                setNetworkError(true)
            }
        } catch (err) {
            errorText.current = "Network Error."
            setNetworkError(true)
        }


    }

    // usdt pay for

    const { UsdtTransferOrderId, transactionBoc, usdtTransferType } = useSelector((state: any) => state.lottery)
    const orderId = useRef("")
    useEffect(() => {
        orderId.current = UsdtTransferOrderId
        if (transactionBoc && usdtTransferType === 'lottery') {
            transferHandle(orderId.current, transactionBoc)
        }
    }, [UsdtTransferOrderId, transactionBoc])

    const transferHandle = async (orderId: string, boc: string) => {
        try {
            const data = await waitForTransaction(orderId, boc);
            dispatch(changeTransactionBoc(""))
            if (data?.code == "0") {
                refreshCount(data.data)
            } else if (data?.code == "65551") {
                errorText.current = "Transaction failed, please try again."
                setNetworkError(true)
            } else if (data?.code == "65539") {
                errorText.current = "Blockchain network congested.Please wait for 5 minutes."
                setNetworkError(true)
            }
        } catch (err) {
            errorText.current = "Network Error."
            setNetworkError(true)
        }

    }

    const usdtTransferHandle = async () => {
        if (!address) {
            setIsShowConnectWallet(true)
            return false
        }
        dispatch(changeUsdtTransferType("lottery"))
        dispatch(changeTransactionBoc(""))
        UsdtTransformHandle(0.18, "Task Lottery")
    }

    //callback  刷新次数
    const refreshCount = async (data: any) => {
        setDialog(true)
        setCardInfo(data.card)
        setPoints(data.points)
        setUserInfo()
        await getLotteryCount()
    }
    return (
        <div className={`w-full mt-3 rounded-[25px] relative m-auto pb-[55%]`} style={{ background: `url(${bg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
            <div className="w-full h-full absolute p-3 text-white flex flex-col justify-center items-center">
                <img className="w-[84px] h-[74px] absolute top-6 animate-pulsate" src={lottery} alt="" />
                <img className="absolute" src={lottertbg} alt="" />
                <div className="flex-1 flex flex-col justify-end items-center text-center text-white">
                    <div className="w-full justify-center z-[1] items-end flex">

                        <ButtonComp title='0.036 TON' width={90} height={30} callBack={() => {
                            tonTransferHandle()
                        }}>

                        </ButtonComp>
                        <ButtonComp title='0.18 USDT' width={90} height={30} callBack={() => {
                            usdtTransferHandle()
                        }}>

                        </ButtonComp>
                        <ButtonComp title='9 STARS' width={90} height={30} callBack={() => {
                            lotteryHandle()
                        }}>

                        </ButtonComp>

                    </div>
                    {/* <span>Daily remaining times: {count}</span> */}
                    <p className="text-center text-white">A large amount of $STAR plus either a Diamond, Gold, or Silver Card. </p>
                </div>
            </div>
            {
                dialog && <LotteryCardDialog title="Lottery" power={cardInfo?.power} property={cardInfo?.property} img={cardInfo?.url} name={cardInfo?.name} level={cardInfo?.level} points={points} close={() => { setDialog(false) }}></LotteryCardDialog>
            }

            {
                isShowConnectWallet && <ConnectWallet callback={() => { setIsShowConnectWallet(false) }}></ConnectWallet>
            }
            {
                <PromptDialog isClose={true} title={'Attention'} dialog={networkError} setDialog={setNetworkError} text={errorText.current}></PromptDialog>
            }

        </div >
    )
}

export default Pay;