import request from '@/utils/axios-req'
import { data } from 'autoprefixer'
import axios from "axios"


//获取擂台详情

export const getBattleArenasDetail = (id, type) => {
    return request({
        url: `/api/v1/miniapp/battle/arena/${id}?type=${type}`,
        method: 'get',
    })
}

//获取pk列表
export const getBattleArenasList = (params) => {
    const { page = 1, limit = 20, type } = params
    return request({
        url: `/api/v1/miniapp/battle/arenas?page=${page}&limit=${limit}&type=${type}`,
        method: 'get',
    })
}

//创建擂台

export const createBattleArenas = (data) => {
    return request({
        url: `/api/v1/miniapp/battle/arena`,
        method: 'post',
        data: data
    })
}


//下架擂台
export const delBattleArenas = (id) => {
    return request({
        url: `/api/v1/miniapp/battle/arena/${id}`,
        method: 'delete',
    })
}

//检查卡组

export const checkBattleDeck = (type) => {
    return request({
        url: `/api/v1/miniapp/battle/deck_check?type=${type}`,
        method: 'get',
    })
}

//获取属性卡牌

export const getPropertyCardsList = (page, limit, level = -1) => {
    return request({
        url: `/api/v1/miniapp/battle/property_cards/?page=${page}&limit=${limit}&level=${level}&type=${1}`,
        method: 'get',
    })
}

// 创建卡组
export const createBattleDeck = (data) => {
    return request({
        url: `/api/v1/miniapp/battle/deck`,
        method: 'post',
        data: data
    })
}

// 获取卡组
export const getBattleDeck = (type) => {
    return request({
        url: `/api/v1/miniapp/battle/deck?type=${type}`,
        method: 'get',
    })
}

//获取pk记录
export const getBattleRecords = (params) => {
    const { page = 1, limit = 20 } = params
    return request({
        url: `/api/v1/miniapp/battle/records?page=${page}&limit=${limit}`,
        method: 'get',
    })
}
//获取PK记录详情
export const getBattleRecordsDetails = (id) => {
    return request({
        url: `/api/v1/miniapp/battle/records/${id}`,
        method: 'get',
    })
}

//获取pk排行榜


export const getBattleRanks = (type) => {
    return request({
        url: `/api/v1/miniapp/battle/rank?type=${type}`,
        method: 'get',
    })
}
//pk
export const postCardBattle = (id, pledge) => {
    return request({
        url: `/api/v1/miniapp/battle/arena/${id}/pk`,
        method: 'post',
        data: {
            pledge: Number(pledge)
        }
    })
}

//pk检查
export const pkCheck = (id) => {
    return request({
        url: `/api/v1/miniapp/battle/arena/${id}/pk_check`,
        method: 'post',
        data: {
            pledge: 0
        }
    })
}

// 获取登录卡组奖励

export const getDeckReward = (id) => {
    return request({
        url: `/api/v1/miniapp/battle/login_reward`,
        method: 'get'
    })
}