import maintenanceImg from "../../assets/images/maintenance.png"
const Maintenance = () => {
    return (
        <div className="w-full h-full px-4 pb-[180px] flex flex-col items-center justify-center">
            <div className="flex justify-center items-center">
                <img className="w-[320px]" src={maintenanceImg} alt="" />
            </div>
            <div className="text-white w-full mt-10 text-xl flex flex-col items-center justify-center">
                <p>StarAI is under maintenance. </p>
                <p>Please try again later. </p>
            </div>

        </div>
    )
}


export default Maintenance