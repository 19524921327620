import { useRef, useState } from "react"

interface SelectCompProps {
  value: any
  setValue: Function
  selectItem: Array<any>
}
const SelectComp = ({ value, setValue, selectItem }: SelectCompProps) => {
  const [isSelect, setIsSelect] = useState(false)
  const inputDom = useRef<any>(null)

  function handleFocus() {
    setIsSelect(true)
  }
  function handleBlur() {
    setTimeout(() => {
      setIsSelect(false)
    }, 100)
  }
  return (
    <>
      <input readOnly onFocus={handleFocus} onBlur={handleBlur} value={selectItem.find(el => el.value == value)?.text||''} ref={inputDom} className=" rounded-[10px] p-[10px] text-[14px] bg-[#333333] text-[#fff] w-full h-10" type="text" />
      <svg onClick={() => { setIsSelect(!isSelect) }} className={` absolute ${isSelect ? 'rotate-180' : ''}  right-[10px] top-2`} width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-327.000000, -229.000000)" fillRule="nonzero">
            <g
              transform="translate(338.000000, 240.000000) rotate(-270.000000) translate(-338.000000, -240.000000) translate(327.000000, 229.000000)">
              <rect fill="#000000" opacity="0" x="-2.27373675e-13" y="-2.09414603e-13" width="22" height="22"></rect>
              <polygon fill="#FFFFFF"
                points="13.2643067 11.0029906 7.5 5.55511998 8.61860304 4.5 15.5 11.0034805 8.61808437 17.5 7.50105896 16.4438798">
              </polygon>
            </g>
          </g>
        </g>
      </svg>
      {isSelect &&
        <div style={{ boxShadow: ' 0px 2px 4px 0px rgba(0,0,0,0.5)' }} className=" z-10 rounded-[10px] p-[10px] flex bg-[#333333] flex-col left-0 top-[43px] gap-[10px] absolute w-full">
          {selectItem.map(el => <p key={el.text} onClick={() => {
            setValue(el.value)
          }} className=" text-[14px] leading-[18px] font-semibold text-[#fff]">{el.text}</p>)}

        </div>}
    </>
  )
}
export default SelectComp 