import ButtonComp from "../common/ButtonComp"

const Award = ({ children, callback }: { children: any, callback: () => void }) => {
    return (
        <div className="flex flex-col items-center justify-center m-auto relative">
            {
                children && children
            }

            <div className="btn absolute -bottom-[250px]">
                <ButtonComp title="Happy to Accept" callBack={() => { callback && callback() }}></ButtonComp>
            </div>
        </div>
    )
}


export default Award