import { useNavigate, useSearchParams } from 'react-router-dom'
import tipImg from "@/assets/images/attribute/tip.svg"
import Back from '../../components/common/BackComp'
import mining_icon from '@/assets/images/mining/mining_icon.png'
import speedImg from '@/assets/images/mining/speed.png'
import accelerate from '@/assets/images/mining/accelerate.png'
import { getProfits, getMiningRanks, getAcceleratorConfig, getAcceleratorPayLink, postAcceleratorPaycheck, getMiningMiningUnread } from '@/api/mining'
import { useCallback, useEffect, useState } from 'react'
import { miningRules } from '../../text/rulesText'
import RulesDialog from '../../components/RulesDialog'
import ButtonComp from '../../components/common/ButtonComp'
import { Popup, Toast } from 'react-vant'
import PromptDialog from '../../components/PromptDialog'
import { FormattingTwoNumber } from '../../utils'
import { calculateUsdtAmountSmall, PAYTYPE } from '../../utils/common-helpers'
import { fromNano } from '@ton/core'
import Pay from '../shop/pay'
import { changeMiningPoolConfig } from "../../store/module/settins";
import { useDispatch } from 'react-redux'
const Pool = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const type: any = Number(searchParams.get("type") as string)
  const [visible, setVisible] = useState(false)
  const [poolData, setPoolData] = useState<any>({})
  const [rulesDialog, setRulesDialog] = useState(false)
  const [promptDialog, setPromptDialog] = useState(false)
  const [currentTime, setCurrentTime] = useState<any>({})
  const [indexActive, setIndexActive] = useState<any>('')
  const [promptText, setPromptText] = useState('')
  const [ranksList, setRanksList] = useState<any>([])
  const [title, setTitle] = useState<any>('Attention')
  const [acceleratorData, setAcceleratorConfig] = useState<any>([])

  const dispatch = useDispatch()
  const [data, setData] = useState<any>({})
  const payHandle = async (item: any) => {
    setData(item)

  }


  const getData = async () => {
    const { data, msg, code } = await getProfits(type)
    if (code == '0') {
      setPoolData(data)
    } else {
      setTitle('Attention')
      setPromptDialog(true)
      setPromptText("Network Error.")
    }
  }

  const acceleratorConfig = async () => {
    const { data, msg, code } = await getAcceleratorConfig(type)

    if (code == '0') {
      const _data = data.filter((item: any) => item.pay_type != '1')
      setAcceleratorConfig(Array.from({ length: Math.ceil(_data.length / 3) }, (_, i) => _data.slice(i * 3, i * 3 + 3)))
    } else {
      setTitle('Attention')
      setPromptDialog(true)
      setPromptText("Network Error.")
    }
  }

  const getRanks = async () => {
    const res = await getMiningRanks(type)
    if (res.code == '0') {
      setRanksList(res.data)
    }
  }
  const init = useCallback(() => {
    const date = new Date();
    const timestamp = date.getTime();
    setCurrentTime(Math.floor(timestamp / 1000))
    try {
      getData()
      getRanks()
      acceleratorConfig()
    } catch {

    }


  }, [])
  useEffect(() => {
    init()
  }, [])

  const payLink = async () => {
    const toast = Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0,
    })
    try {
      const params = {
        title: "",
        description: "",
        total: 0,
        type: PAYTYPE.coins,
        relevance: '',
        id: type == 0 ? "20" : "21"
      }

      const { code, data } = await getAcceleratorPayLink(params)

      if (code == 0) {
        toast.clear()
        return data.order_id
      }
      toast.clear()

    } catch (e) {
      toast.clear()
    }

  }

  const checkInHandle = async () => {
    const id = await payLink()
    const { code } = await postAcceleratorPaycheck({ order_id: id, transaction_id: "", config_id: type == 0 ? 20 : 21 })
    if (code == 0) {
      setPromptDialog(true)
      setTitle('Congratulations!🎉')
      setPromptText(`You have successfully obtained a 1% Acceleration Card through daily Check-in.`)
      await getData()
      await getMiningMiningUnreadHandle()
    }
  }
  const getMiningMiningUnreadHandle = async () => {
    try {
      const { code, data } = await getMiningMiningUnread()
      if (code == '0') {
        dispatch(changeMiningPoolConfig(data))
      }
    } catch (e) {
      console.log("star page", e)
    }
  }


  return (
    <div className='px-4 relative pt-[53px] h-[100vh] overflow-auto pb-10'>
      <Back background={'#000'} position={true} title={type == 0 ? 'Standard Pool' : 'VIP Pool'} callback={() => { navigate('/mining') }}></Back>
      <img src={tipImg} onClick={() => {
        setRulesDialog(true)
      }} className=' fixed top-3 z-[1001] right-4' />
      {/* <div className=' text-[20px] leading-6 text-[#fff] font-bold flex justify-between items-center'>
        <p>Total Profits: &nbsp;</p>
        <p className='text-style-6 text-[14px] font-bold leading-[18px] flex items-center gap-1 pr-[10px]'><img className=' w-[14px] h-[14px]' src={mining_icon} alt="" /> +{FormattingTwoNumber(poolData?.pool?.total_profits || 0)} </p>
      </div>
      <div className=' bg-[rgba(166,166,166,0.48)] my-[10px] rounded-[8px] w-full h-4'>
        <div className={`h-4 rounded-[8px]`} style={{ background: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)', width: `${Math.floor(((currentTime - poolData?.pool?.start_time_uinx) / (poolData?.pool?.end_time_uinx - poolData?.pool?.start_time_uinx)) * 100)}%` }}>
        </div>
      </div> */}
      <div className="h-[100px] w-full bg-[#333333] my-[10px] rounded-[20px] px-[12px] py-[10px]">
        <div className='w-full flex items-start'>
          <img className=' w-[18px] h-[18px]' src={mining_icon} alt="" />
          <span className='text-white text-base ml-1'>My Estimated Profit:</span>
        </div>
        <div className='text-[20px] leading-6 text-[#fff] font-bold flex  justify-center  my-[15px] items-end'>
          <p className='text-style-6 !text-[30px] font-bold !leading-[28px] flex items-center gap-1 pr-[10px]'> {FormattingTwoNumber(poolData?.profits?.profits || 0)} </p>
          <p className='text-style-6 !text-[14px] !leading-[18px]'> $ </p>
          <p className='text-style-6 !text-[20px] font-bold !leading-[22px]  pr-[10px]'> SAI </p>
        </div>
      </div>
      <div className="h-[70px] w-full bg-[#333333] my-[10px] rounded-[20px] px-[12px] py-[10px] flex items-center">
        <div className='w-full flex items-start'>
          <img className=' mr-1 w-[18px] h-[18px]' src={speedImg} alt="" />
          <span className='text-white text-base ml-1'>My Total Efficiency:</span>
        </div>
        <span className='text-[30px] leading-[37px] font-bold text-style-1'>{(poolData?.profits?.card_avatar || 0) + (poolData?.profits?.accelerator || 0)}
          <span className='!text-[22px] leading-[18px] ml-[2px]'>%</span>
        </span>

      </div>
      <div className='mt-[10px] bg-[#333333] px-[10px] pt-2 pb-[20px] rounded-[20px] relative' >
        <div style={{ background: 'linear-gradient( 135deg, #FB5445 0%, #FBB11B 100%)', borderRadius: '13px 0px 20px 0px' }}
          className=' text-[14px] w-fit absolute top-0 text-[#ffffff] left-0 leading-[18px] p-[10px] font-bold'>
          Cards & Avatars
        </div>
        <div className=' flex text-[14px] leading-[18px] text-[#fff] font-bold justify-end items-center '>
          <img className=' mr-1 w-[18px] h-[18px]' src={speedImg} alt="" />
          Efficiency: &nbsp; <span className='text-[18px] leading-[22px] font-bold text-style-1'>{poolData?.profits?.card_avatar || 0}

            <span className='!text-[12px] leading-[18px] ml-[2px]'>%</span>
          </span>
        </div>
        <p className='mt-[20px] text-center text-[#fff] text-[14px] leading-[18px] font-semibold'> Total Power:&nbsp;<span className='text-[30px] leading-[37px] font-bold text-style-1'>{poolData?.profits?.power}</span></p>
        <p className='mt-[20px] text-center text-[#fff] text-[14px] leading-[18px] font-semibold'> Each 150 points of power, mining efficiency can be accelerated by 1%.</p>
        <ButtonComp width={174} mt={20} title='Start Mining' callBack={() => {
          navigate(`/myMining?type=${type}`)
        }}>
        </ButtonComp>
      </div>

      <div className='mt-[10px] bg-[#333333] px-[10px] pt-2 pb-[20px] rounded-[20px] relative' >
        <div style={{ background: 'linear-gradient( 135deg, #FB5445 0%, #FBB11B 100%)', borderRadius: '13px 0px 20px 0px' }}
          className=' text-[14px] w-fit absolute top-0 text-[#ffffff] left-0 leading-[18px] p-[10px] font-bold'>
          Accelerator
        </div>
        <div className=' flex text-[14px] leading-[18px] text-[#fff] font-bold justify-end items-center '>
          <img className=' mr-1 w-[18px] h-[18px]' src={speedImg} alt="" />
          Efficiency: &nbsp; <span className='text-[18px] leading-[22px] font-bold text-style-1'>{poolData?.profits?.accelerator || 0}

            <span className='!text-[12px] leading-[18px] ml-[2px]'>%</span>
          </span>
        </div>
        <p className='mt-[20px] text-center text-[#fff] text-[14px] leading-[18px] font-semibold'> Purchasing acceleration cards can enhance mining efficiency.</p>
        <ButtonComp width={174} mt={20} title='Accelerate Mining' callBack={() => {
          setVisible(true)
        }}>

          {((type == 0 && !!poolData?.pool?.free_accelerator_config_remaining) || (type != 0 && !!poolData?.pool?.free_accelerator_config_remaining)) && <div className='w-3 h-3 bg-[orangered] rounded-full absolute right-0 -top-1'></div>}
        </ButtonComp>
      </div>

      <div className='mt-[20px] bg-[#333333]  pt-2 rounded-[20px] relative' >
        <div className={`bg-[url(@/assets/images/mining/list_top.png)] mx-auto font-semibold text-center pt-[11px] text-[#fff] text-[16px] leading-[19px] bg-cover w-[182px] h-11`}>
          Profit Ranks
        </div>
        <div className='mt-[4px] px-[10px] w-full overflow-auto max-h-[290px] flex flex-col gap-[10px]'>
          {ranksList?.ranks?.map((el: any) =>
            <div key={`Profit Ranks ${el.id}`} className=' flex justify-between rounded-[10px] bg-[#444444] items-center p-[10px]'>
              <p className='text-[14px] text-[#fff] leading-[18px] font-semibold'>No. {el.rank}</p>
              <p className='text-[14px] text-[#fff] leading-[18px] font-semibold'>{el.id}</p>
              <p className='text-[14px] leading-[18px] flex items-center font-bold text-style-6'> <img className='w-5 mr-1' src={mining_icon} alt="" />{FormattingTwoNumber(el.profits || "0")} $SAI</p>
            </div>
          )}
        </div>
        <div className='mt-[10px] bg-gradient-to-r from-[#00E49F] to-[#54ADFF] flex justify-between rounded-[10px] items-center p-[10px] px-5'>
          <p className='text-[14px] text-[#01825D] leading-[18px] font-semibold'>{ranksList?.my?.id}</p>
          <p className='text-[14px] leading-[18px] flex items-center font-bold text-style-3'> <img className='w-5 mr-1' src={mining_icon} alt="" />{FormattingTwoNumber(ranksList?.my?.profits || "0")} $SAI</p>
          <p className='text-[14px] text-[#fff] leading-[18px] font-semibold'>No. {ranksList?.my?.rank}</p>
        </div>
      </div>
      {visible &&
        <Popup
          visible={visible}
          position="right"
          style={{ width: '100%', height: '100vh', overflow: 'auto', paddingBottom: '20px', backgroundColor: 'rgba(0,0,0)' }}
        >
          <div className=' pt-[54px] px-4'>
            <Back position={true} title='Accelerator Cards' background='#101010' callback={() => { setVisible(false) }}></Back>
            <div key={`${1}-acceleratorData`} className='p-[14px] mt-[18px] special-class w-full relative  rounded-[20px]'>
              <div className='flex absolute top-0 left-0'>
                <div className=' py-1 px-2 w-fit text-[14px] leading-[18px] text-[#fff] font-semibold' style={{ borderRadius: '13px 0px 13px 0px', background: 'linear-gradient( 135deg, #FB5445 0%, #FBB11B 100%)' }} >Free </div>
                <p className='text-[14px] leading-[18px] text-white font-semibold ml-1 mt-1'>You can check-in once a day.</p>
              </div>
              <div className=' flex justify-between items-end pr-[10px] '>
                <p style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className='text-[18px] leading-[22px] text-[#fff] font-semibold'>Accelerator Card of <span style={{ textShadow: '0px 2px 4px rgba(0,0,0,0)' }} className='text-[30px] leading-[37px] text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF] font-semibold'>1

                  <span className='!text-[22px] leading-[18px] ml-[2px]'>%</span>
                </span> </p>
                <img className='w-[47px]' src={accelerate} alt="" />
              </div>
              {/* calculateUsdtAmountSmall */}
              <div className='flex mt-[10px] pb-2 justify-between'>
                {/* disabled={!el.remaining} */}
                <ButtonComp mt={0} disabled={!poolData?.pool?.free_accelerator_config_remaining} title="Check in" callBack={async () => {
                  // payHandle(el)
                  // setIndexActive(el)
                  await checkInHandle()
                }}>

                  {!!poolData?.pool?.free_accelerator_config_remaining && <div className='w-3 h-3 bg-[orangered] rounded-full absolute right-0 -top-1'></div>}

                </ButtonComp>
              </div>
            </div>
            {acceleratorData.map((el: any, index: number) =>
              <div key={`${index}-acceleratorData`} className='p-[14px] mt-[18px] special-class w-full relative  rounded-[20px]'>
                <div className='flex absolute top-0 left-0'>
                  <div className=' py-1 px-2 w-fit text-[14px] leading-[18px] text-[#fff] font-semibold' style={{ borderRadius: '13px 0px 13px 0px', background: 'linear-gradient( 135deg, #FB5445 0%, #FBB11B 100%)' }} >Tier {index + 1}</div>
                  <p className='text-[14px] leading-[18px] text-white font-semibold ml-1 mt-1'> Purchase limit: ({el[0].remaining || 0}/{el[0].count})</p>
                </div>
                <div className=' flex justify-between items-end pr-[10px] '>
                  <p style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className='text-[18px] leading-[22px] text-[#fff] font-semibold'>Accelerator Card of <span style={{ textShadow: '0px 2px 4px rgba(0,0,0,0)' }} className='text-[30px] leading-[37px] text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF] font-semibold'>{el.length > 0 && el[0]?.coefficient}
                    <span className='!text-[22px] leading-[18px] ml-[2px]'>%</span>
                  </span> </p>
                  <img className='w-[47px]' src={accelerate} alt="" />
                </div>
                {/* calculateUsdtAmountSmall */}
                <div className='flex mt-[10px] pb-2 justify-between'>
                  {el.map((el: any) =>
                    <ButtonComp disabled={!el.remaining} key={el.id} width={92} mt={0} title={el.pay_type == 4 ? `${calculateUsdtAmountSmall(el?.price / 100)} USTD` : el.pay_type == 3 ? `${fromNano(el?.price)} TON` : `${el?.price} STARS`} callBack={() => {
                      payHandle(el)
                      setIndexActive(el)
                    }}>
                    </ButtonComp>)}
                </div>
              </div>
            )}
            <p style={{ fontStyle: 'italic' }} className=' text-[14px] leading-[18px] text-[#fff] text-center mt-10'> Acceleration cards purchased in different mining pools are not interchangeable.</p>
          </div>
        </Popup>}
      <RulesDialog textArr={miningRules} type='mining' title="Mining Rules" dialog={rulesDialog} setDialog={setRulesDialog}></RulesDialog>

      <Pay type={'mining'} dataObj={data} callBack={(item) => {
        if (item?.code == '0') {
          setVisible(false)
          setPromptDialog(true)
          setTitle('Purchase Successfully!🎉')
          setPromptText(`You have successfully purchased a ${indexActive.coefficient}% Acceleration Card.`)
          init()
        }
      }}></Pay>
      <PromptDialog
        teleport={document.querySelector('body')}
        title={title}
        dialog={promptDialog}
        setDialog={setPromptDialog}
        callback={() => { }}
        text={promptText}>

      </PromptDialog>

    </div>
  )
}
export default Pool