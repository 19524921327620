import { Dialog } from "react-vant"
import close from "@/assets/images/task/close.png"
import ButtonComp from "../../components/common/ButtonComp"
import red_bg from "@/assets/images/myInvites/red_bg.png"
import redEnvelope_top from "@/assets/images/myInvites/redEnvelope_top.png"
import redEnvelope_bottom from "@/assets/images/myInvites/redEnvelope_bottom.png"

interface withdrawDialogProps {
  dialog: boolean
  setDialog: Function
}
const LuckyDrawprizeDialog = ({ dialog, setDialog }: withdrawDialogProps) => {
  return (
    <Dialog
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={true}
      onClose={() => { setDialog(false) }}
      width={'100%'}
    >

      <div style={{ backgroundSize: "100% 100% !important" }} className={`h-[100vh] relative flex items-center justify-center w-[100vw]`}>
        {/* <img className="w-5 h-5 absolute top-3 right-[14px]" src={close} alt="" onClick={() => { setDialog(false) }} /> */}
        <img className="backgroundRotation absolute w-[364px] h-[364px]" src={red_bg} alt="" />
        <div className="w-[237px] h-[307px] redZoom relative">
          <svg onClick={() => { setDialog(false) }} className=" absolute z-[100px] -top-12 -right-4" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="抽奖-抽中动画" transform="translate(-319.000000, -170.000000)">
                <g id="编组-12备份-3" transform="translate(319.000000, 170.000000)">
                  <circle id="椭圆形备份-2" fillOpacity="0.804359703" fill="#7F7F7F" cx="20" cy="20" r="20"></circle>
                  <g id="close备份-2" transform="translate(11.764706, 10.588235)" fillRule="nonzero">
                    <rect id="矩形" opacity="0" x="0" y="0" width="18.8235294" height="18.8235294"></rect>
                    <polygon id="路径" fill="#FFFFFF" points="14.4011874 4.17758233 9.17128256 9.41175567 14.4016227 14.6455134 13.4614261 15.5780933 8.23551178 10.3483105 3.00916212 15.5780933 2.06896552 14.6455134 7.29887034 9.41175567 2.06896552 4.17756426 3.00916212 3.24543611 8.23507645 8.47478529 13.4609908 3.24543611"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <img className=" w-[237px] relative z-[2] h-[307px]" src={redEnvelope_top} alt="" />
          <img className=" w-[237px] relative z-[1] -mt-[265px] h-[307px]" src={redEnvelope_bottom} alt="" />
        </div>
      </div>
    </Dialog>
  )
}
export default LuckyDrawprizeDialog