import { useRef } from "react"
import { Dialog } from "react-vant"
import close from "@/assets/images/task/close.png"
import ButtonComp from "./common/ButtonComp"
import rules_bg from "../assets/images/rules/rules_bg.png"
import rules_bg1 from "../assets/images/rules/rules_bg1.png"
import rules_bg2 from "../assets/images/rules/rules_bg2.png"
import rules_bg3 from "../assets/images/rules/rules_bg3.png"
import rules_bg4 from "../assets/images/rules/rules_bg4.png"
import rules_bg5 from "../assets/images/rules/rules_bg5.png"
import star from "../assets/images/rules/star.png"
import { HighlightPercentages } from "../utils"
import CloseComp from "./common/CloseComp"

interface RulesDialogProps {
  dialog: boolean
  setDialog: Function
  teleport?: any
  title: string
  textArr: Array<any>
  isShowClose?: boolean
  type: string
}
const RulesDialog = ({ dialog, setDialog, teleport, title, textArr, isShowClose = true, type }: RulesDialogProps) => {
  const dialogRef = useRef(null)
  const chooseBg = (key: number) => {
    switch (key % 6) {
      case 0:
        return rules_bg;
      case 1:
        return rules_bg1;
      case 2:
        return rules_bg2;
      case 3:
        return rules_bg3;
      case 4:
        return rules_bg4;
      case 5:
        return rules_bg5;
    }

  }
  return (
    <Dialog
      className={`overflow-visible`}
      visible={dialog}
      teleport={teleport}
      showConfirmButton={false}
      showCancelButton={false}
      onClose={() => { setDialog(false) }}
      width={'100%'}
    >
      <div ref={dialogRef} style={{ backgroundSize: "100% 100% !important" }} className={`refining mx-[16px] flex justify-center !bg-[length:100%_100%] px-[10px] overflow-hidden pt-10 relative pb-[90px] bg-[url(@/assets/images/refining/card_refining_guide.png)] `}>
        {isShowClose && <CloseComp position="absolute" top={14} right={16} callBack={() => { setDialog(false) }}></CloseComp>}

        <div className={` ${type == 'mining' ? '' : 'h-[320px]'}  pb-2 overflow-auto`}>
          <p className=" font-bold text-[20px] text-[#333] leading-[24px] mb-[24px] text-center">{title}</p>
          {textArr.map((el: any, index: number) =>
            <div key={`${index}-${el.title}`}>
              <p className=" font-bold text-[16px] text-[#333] leading-[18px] my-[10px] mb-[6px] text-center">{el.title}</p>
              <div className=" flex flex-col gap-[10px]">
                {el.textArr.map((e: any, ind: number) =>
                  <div>
                    <div key={`${index}-${ind}-${el.title}`} style={{ background: `url(${chooseBg(ind)})`, backgroundSize: "100% 100%" }} className=" p-[10px] pl-[6px] text-[14px] flex text-[#333] leading-[18px] rounded-[10px]">
                      <img className=" w-[14px] h-[13px] mt-[2px] mr-[1px]" src={star} alt="" />
                      <div className="text-[14px]text-[#333] inline leading-[18px]" dangerouslySetInnerHTML={{ __html: HighlightPercentages(e.text) }}></div>
                    </div>
                    {e.image &&
                      <div className=" w-full flex justify-center items-center">
                        <img className="w-[163px] mt-[10px]" src={e.image} alt="" />
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="absolute text-[12px] leading-[14px] bottom-6 text-center text-[#333]">
          <p className=" text-[12px] leading-[14px] mt-2 text-center text-[#A6A6A6] "> {type != 'mining' ? 'You can scroll up and down to view the detailed rules.' : ''}</p>
          <ButtonComp width={174} mt={10} title='OK' callBack={() => {
            setDialog(false)
          }}>
          </ButtonComp>
        </div>
      </div>
    </Dialog >
  )
}
export default RulesDialog