
import { gsap } from "gsap";

const NumberScroll = () => {

  var tl = gsap.timeline();
  const countF = (elem: any, start: number, end: number) => {
    try {
      if(!elem) return
      tl.fromTo(elem, {
        innerText: start,
        scale: 1,
      }, {
        innerText: end,
        sanp: {
          innerText: 1,
        },
        duration: 2,
        ease: "linear",
        onUpdate: () => {
          const currentText = elem.innerText;
          const integerValue = Math.floor(parseFloat(currentText));
          elem.innerText = integerValue.toString();
        }
      })
    } catch { }
  }
  return (
    countF
  );
};

export default NumberScroll;
