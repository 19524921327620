
import loading from '@/assets/images/cards/loading.png'
import selectedImg from '../../assets/images/pk/selected.png'

import { useNavigate } from "react-router-dom"
import '@/styles/index.less'
import { useEffect, useMemo, useRef, useState } from 'react';

import CardItem from '../../components/CardItem'
import { convertNumber } from '../../utils'

import { useDispatch, useSelector } from 'react-redux'

import { changeSelectionList } from '../../store/module/battle'




const BattleCardList = ({ cardsData, isPadding = true }: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { times, selectionList } = useSelector((state: any) => state.battle)

    // ------------------------ 滑动 -----------------------
    useEffect(() => {
        document.addEventListener('touchstart', touchStartHandle);
        return () => {
            document.removeEventListener('touchstart', touchStartHandle);
            // window.scrollTo(0, 0);
        }

    })

    const touchStartHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 0.5px)",
                "important"
            );
        }
    }
    // ------------------------ 滑动 end -----------------------
    const cardItemHandle = (el: any) => {

        if (selectionList.length < times) {
            if (selectionList.map((item: any) => item.id).includes(el.id)) {
                dispatch(changeSelectionList(el.id))
            } else {
                dispatch(changeSelectionList(el))
            }

        }else{
            if (selectionList.map((item: any) => item.id).includes(el.id)) {
                dispatch(changeSelectionList(el.id))
            }
        }

    }

    return (
        <div className={`role-box px-4 ${isPadding ? 'pt-[64px]' : ''}`}>
            {
                cardsData && cardsData.length == 0 ?
                    <div className=' flex flex-col items-center h-full justify-center'>
                        <img className='w-[185px]' src={loading} alt="" />
                        <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>Chat to get cards</p>
                    </div> :
                    <>
                        <div className="w-full grid grid-cols-3 gap-3">
                            {cardsData?.map((el: any, index: number) =>
                                <div key={el.id} className={`overflow-hidden rounded-[10px] relative`} onClick={() => { cardItemHandle(el) }}>
                                    <CardItem height={0} power={el.power} property={el.property} enhanceLevel={el.enhance_level} mint_state={el.mint_state} is_nft={el.is_nft} id={el.id} price={el.price} like={el.like_count} view={el.view_count} state={el.state} mine={1} no={`No. ${convertNumber(el.seq)}`} image={el.image} level={el.is_official ? 6 : el.level + 1}>
                                        {
                                            selectionList.map((item: any) => item.id).includes(el.id) && <div className="absolute w-full h-full top-0 left-0 bg-[#000]/40 z-[998] flex justify-center items-center">
                                                <img className='h-20' src={selectedImg} alt="" />
                                            </div>
                                        }

                                    </CardItem>

                                </div>
                            )}
                        </div>

                    </>
            }



        </div >
    )
}


export default BattleCardList;
