import WebApp from '@twa-dev/sdk'
import "./battle.less"
import { useEffect, useRef, useState } from 'react';
import { Tabs, Popup, Field, NumberKeyboard, Loading } from 'react-vant';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyCardsList } from "../../api/pk_url"
import { changeCardSelectActive } from '../../store/module/search.js';
import loading from '@/assets/images/cards/loading.png'
import { List } from 'react-vant';
import BattleCardList from './battleCardList.js';
import { PK_CARDS } from '../../constants/common-constants.js';

const pageSize = 30

const CardSelection = () => {
    const navigate = useNavigate()
    const isActive = useSelector((state: any) => state.search.cardSelectActive)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)


    const isInitLoading = useRef(true)
    const currPage = useRef(1)
    const total = useRef(0);
    const [saleCardsData, setSaleCardsData] = useState<any>([])



    const [finished, setFinished] = useState(false);
    const listRef = useRef(null)
    const [params] = useSearchParams();

    //change tabs
    const changeTabHandle = async (name: any) => {
        dispatch(changeCardSelectActive(name))
        setFinished(false)
    }

    //clickTabHandle
    const clickTabHandle = () => {
        isInitLoading.current = true;
        return false
    }


    useEffect(() => {
        isInitLoading.current = true;
        setIsLoading(false)

        currPage.current = 1
        setSaleCardsData([])
        getDataList()
    }, [isActive])

    const onLoad = async () => {
        if (finished || isInitLoading.current) return;

        currPage.current++
        await getDataList()
    }




    const getDataList = async () => {
        try {
            setIsLoading(true)
            const { code, data: { data, count } } = await getPropertyCardsList(currPage.current, pageSize, Number(isActive))
            if (code === '0') {
                setSaleCardsData((prevData: any) => [...prevData, ...data]);
                total.current = count;
                setIsLoading(false)
                isInitLoading.current = false
                if (data.length == 0) {
                    setFinished(true)
                }
            } else {
                setFinished(true)
                setIsLoading(false)
                total.current = 0
            }

        } catch (e) {
            setIsLoading(false)
            setFinished(true)
            total.current = 0
        }

        if (currPage.current * pageSize >= total.current) {
            setFinished(true)
            // return false;
        }
    }


    const updateHandle = () => {
        currPage.current = 1
        setSaleCardsData([])
        getDataList()
    }



    useEffect(() => {
        document.addEventListener('touchstart', touchStartHandle);
        return () => {
            document.removeEventListener('touchstart', touchStartHandle);
            // window.scrollTo(0, 0);
        }
    })

    const touchStartHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 0.5px)",
                "important"
            );
        }
    }
    return (



        <div className="tabs-list h-full w-[calc(100%)]">
            <Tabs className="w-full h-10 -z-[1px]" beforeChange={() => !isLoading} onClickTab={() => clickTabHandle()} onChange={(name) => { changeTabHandle(name) }} active={isActive} sticky align="start" swipeable={true} background="#050a0a" color="#fff">
                {PK_CARDS().map((item: any) => (

                    <Tabs.TabPane key={item.id} name={item.id} title={`${item.name}`}>
                        {
                            saleCardsData.length > 0 && <div className={`w-[100%] h-[calc(100vh_-_80px)] pb-[155px] box-border overflow-auto text-white `} >
                                <List ref={listRef} finished={finished} loading={isLoading} immediateCheck={false} onLoad={onLoad} loadingText="Loading...">
                                    {
                                        saleCardsData && saleCardsData.length > 0 &&

                                        <BattleCardList cardsData={saleCardsData} isPadding={false} updateHandle={() => { updateHandle() }}></BattleCardList>
                                    }
                                </List>


                            </div>
                        }
                        {
                            !isLoading && !saleCardsData.length &&
                            <div className={'w-[100%] h-[calc(100vh_-_120px)] py-4 flex flex-col items-center'}>
                                <img className="w-[225px] mt-10" src={loading}></img>
                                <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>Not Found</p>

                            </div>

                        }
                        {
                            isLoading && !saleCardsData.length &&
                            <div className={'w-[100%]  py-4 flex flex-col items-center'}>
                                <Loading type="spinner" size="20" color="#00E49F">
                                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                                </Loading>
                            </div>

                        }


                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>


    )
}


export default CardSelection;