
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Back from '../../components/common/BackComp.js';
import { useNavigate } from 'react-router-dom';
import { SHOP_NAV } from "../../constants/common-constants.js"
import Claim from './Claim.js'
import DownLoad from './Download.js'
import Enhance from './Enhance.js'
import Regenerate from './Regenerate.js'
import Refining from './Refining.js'
import Draw from "./Draw.js"
import { getShopConfig } from "@/api/shop.js"
import { useDispatch, useSelector } from 'react-redux';
import { changeShopConfigList } from '../../store/module/card.js';


const Shop = () => {
    const navigate = useNavigate()
    const [shopNavActive, setShopNavActive] = useState(1)
    const [shopNavList, setShopNavList] = useState<any>(null)
    const dispatch = useDispatch()
    const { shopConfigList } = useSelector((state: any) => state.card)

    const Content = () => useMemo(() => {
        let list = []
        if (shopConfigList.length) {
            list = shopConfigList.filter((item: any) => item.type == shopNavActive) || []
        }
        let dataList: any[] = [];
        let forList: number[] = []
        if (shopNavActive == 1) {
            forList = [1, 10, 20, 50]
        } else {
            forList = [10, 20, 50]
        }
        forList.forEach(e => {
            let arr: any = []
            list.map((item: any) => {
                if (item.count == e) {
                    let it = {
                        ...item,
                        title: shopNavList?.title || 'Bonus Pack',
                        description:`Do you want to buy ${shopNavList?.title || 'Bonus Pack'} in StarAI for ${item?.price} star?`
                    }
                    arr.push(it)
                }
            })
            dataList.push(arr)
        })
        switch (shopNavActive) {
            case 1:
                return <Claim dataList={dataList}></Claim>
            case 8:
                return <Draw dataList={dataList}></Draw>
            case 3:
                return <Regenerate dataList={dataList}></Regenerate>
            case 11:
                return <Enhance dataList={dataList}></Enhance>
            case 4:
                return <Refining dataList={dataList}></Refining>
            case 10:
                return <DownLoad dataList={dataList}></DownLoad>

        }
    }, [shopNavActive, shopConfigList])

    useEffect(() => {
        if (!shopConfigList.length) {
            getShopConfigHandle()
        }

    }, [])

    const getShopConfigHandle = async () => {
        const { code, data } = await getShopConfig()
        if (code == "0") {
            dispatch(changeShopConfigList(data))
        }
    }

    return (
        <div className="descriptionLogs w-full h-full text-white pt-12 px-4 box-border relative pb-[0px] overflow-x-hidden overflow-y-scroll">
            <div className="h-11 flex items-center justify-center fixed top-0 w-[calc(100%_-_32px)] z-[11] box-border bg-[#050A0A]">
                <Back isLeft={false} callback={() => { navigate(-1) }}>
                    <div className='w-[90%] h-10 text-white flex justify-around items-center'>
                        Shop
                    </div>
                </Back>
            </div>
            <div className='grid grid-cols-3 gap-3 h-[110px]'>
                {
                    SHOP_NAV().map((item: any) => {
                        return (
                            <div onClick={() => {
                                setShopNavList(item)
                                setShopNavActive(item.id)
                            }} key={item.id}
                                className={`${item.id === shopNavActive ? 'bg-gradient-to-l from-[#54ADFF] to-[#00E49F] text-shadow-lg' : ''} px-2 py-1 text-sm cursor-pointer bg-[#333333] text-white text-center flex justify-center items-center rounded-lg`}>{item.name}</div>
                        )
                    })
                }
            </div>

            <div className="w-full h-[calc(100%-150px)] mt-3 overflow-x-hidden overflow-y-auto">
                {Content()}
            </div>



        </div >
    )
}


export default Shop;