import { createSlice } from "@reduxjs/toolkit";
import { getShopPayCount } from "@/api/shop.js"


interface lotteryState {

    isShowRedPacket: boolean
    awardData: any,
    UsdtTransferOrderId: string
    transactionBoc: string
    settingsTransactionBoc: string
    isConnectWallet: boolean
    usdtTransferType: string
    shopCount: (number | string)[]

}




const initialState: lotteryState = {
    isShowRedPacket: false,
    awardData: {},
    UsdtTransferOrderId: "",
    transactionBoc: "",
    settingsTransactionBoc: "",
    isConnectWallet: false,
    usdtTransferType: "",
    shopCount: [0, 0, 0, 0] // 0:钻石重新生成 1:炼化 2:强化 3:下载
}
const LotteryStore = createSlice({
    name: 'lottery',
    initialState,
    reducers: {
        changeShowRedPacketStatus(state, action) {
            state.isShowRedPacket = action.payload
        },
        changeAwardData(state, action) {
            state.awardData = action.payload
        },
        changeUsdtTransferId(state, action) {
            state.UsdtTransferOrderId = action.payload
        },
        changeTransactionBoc(state, action) {
            state.transactionBoc = action.payload
        },
        changeSettingsTransactionBoc(state, action) {
            state.settingsTransactionBoc = action.payload
        },
        //获取usdt付款的类型（lottery||settings||shop）
        changeUsdtTransferType(state, action) {
            state.usdtTransferType = action.payload
        },
        changeShopCount(state, action) {
            state.shopCount[action.payload.type] = action.payload.count

        }


    }
})

export const { changeShopCount, changeUsdtTransferType, changeSettingsTransactionBoc, changeTransactionBoc, changeUsdtTransferId, changeShowRedPacketStatus, changeAwardData } = LotteryStore.actions

export default LotteryStore.reducer