import { useLocation, useNavigate } from "react-router-dom"
import { useRef, useEffect, useState } from 'react';
import tabbarmusic from "../assets/mp3/tabbar.mp3"
import WebApp from '@twa-dev/sdk'
import tabbarHook from "@/hook/useTabbar.tsx"
import CoinProgressBar from "../components/CoinProgressBar";
import { useDispatch, useSelector } from "react-redux";
import SettingsDialog from "../components/SettingsDialog";
import { PlaySound } from "../utils";
import { judgeClient } from "../utils/judgeClient";
import { changeArenaNavActive } from "../store/module/battle";
import { changeCharacterList } from "../store/module/tabbar";

const { tabbarList } = tabbarHook();
const Tabbar = ({ userInfo }: any) => {
    const location = useLocation()

    const touchmove = useRef<any>(null)
    const settings = useSelector((state: any) => state.settings.settings)
    const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
    const tabbarAudioRef = useRef<any>(null)
    const dispatch = useDispatch()



    const navigate = useNavigate();
    const handleClick = (item: any) => {
        window.localStorage.setItem("characterList", JSON.stringify([]))
        dispatch(changeCharacterList([]))
        // settings.sound && PlaySound('tabbar')
        if (tabbarAudioRef.current) {
            // tabbarAudioRef.current.currentTime = 0;
            tabbarAudioRef.current.play()
        }
        if (item.path == "/pk") {
            dispatch(changeArenaNavActive(0))
        }
        if (item.path == "/character") {
            sessionStorage.setItem('avatarListId', "1")
        }
        if (item.path === '/ranks') {
            localStorage.setItem('oldRoute', location.pathname)
        }
        if (settings.vibration) {
            WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
        }


        if (judgeClient() == "IOS") {
            setTimeout(() => {
                navigate(item.path);
                // tabbarAudioRef.current = null
            }, 300)
        } else {
            navigate(item.path);
        }


    }
    // var mo = function (e: { preventDefault: () => void; }) { console.log(123123);e.preventDefault(); };
    // useEffect(() => {
    //     if (touchmove.current) {
    //         touchmove.current.addEventListener("touchmove", mo, { passive: false });
    //     }
    // }, [touchmove.current])
    return (
        <>
            {/* <div ref={touchmove} className={` tabbar box-border fixed bottom-[0px] left-0 right-0 w-full h-[110px]`} style={{ zIndex: 200 }}> */}
            <div ref={touchmove} className={`${localStorage.getItem('userId') ? '' : 'pointer-events-none'}  tabbar box-border fixed bottom-0 left-0 right-0 w-full h-[110px]`} style={{ zIndex: 200 }}>
                <div className={`item w-full bg-no-repeat bg-cover h-full bg-[rgb(98,98,98,0.85)] rounded-[16px_16px_0_0] flex flex-col justify-around items-center py-[8px] pt-3 pb-2 relative overflow-hidden`}>
                    <div className="flex justify-around items-center px-4 mb-2 w-full">
                        <CoinProgressBar userInfo={userInfo} padding={0}></CoinProgressBar>
            
                    </div>

                    <div className="w-full px-2 mt-0.5 flex justify-around items-center">
                        {
                            tabbarList.map((item: any) => {
                                return (

                                    <div onClick={() => handleClick(item)} className={`${item.isHidden ? 'hidden' : ''} flex flex-col justify-center items-center transition-all text-center z-10`} key={item.id}>
                                        {
                                            item.isHome && <div className="absolute top-[calc(50%-1px)] flex justify-center items-center left-[calc(50%-1px)] -translate-x-[calc(50%-0px)] -translate-y-[calc(50%-0px)] w-[50px] h-[50px] rounded-full bg-[#32726E]/30">
                                                <img className="w-10" src={item.imgUrlActive} alt="" />
                                                
                                            </div>
                                        }


                                        <div className={`${item.isHome ? 'opacity-0' : ""} h-[30px] w-[30px] tabbarIcon p-1 flex justify-center items-center rounded bg-[#32726E]/30`}>
                                            <img className='w-[20px]  h-[20px]' src={item.path?.includes(location.pathname) ? item.imgUrlActive : item.imgUrl} alt="" />
                                        </div>

                                        <div className={`${item.path?.includes(location.pathname) ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]' : 'text-white'} mt-[2px] font-bold text-[14px] leading-[18px] `}>{item.title}</div>

                                    </div>
                                )
                            })
                        }

                    </div>
                </div>

            </div>
     

            {isSoundPlay && <audio ref={tabbarAudioRef} src={tabbarmusic} id="audio"></audio>}

        </>
    )
}


export default Tabbar