import { Popup, Toast } from "react-vant"
import Turntable from "./turntable"
import winners_list from "@/assets/images/myInvites/winners_list.png"
import WebApp from "@twa-dev/sdk"
import { useCallback, useEffect, useMemo, useState } from "react"
import { getRewards, getCardLotteryList, cardLotteryHistory, getCardLottery, getCardLotteryLink } from '@/api/url.js'


import card4 from "@/assets/images/myInvites/card4_4.png"
import card3 from "@/assets/images/myInvites/card3_3.png"
import card0 from "@/assets/images/myInvites/card.png"
import card1 from "@/assets/images/myInvites/card1.png"
import card2 from "@/assets/images/myInvites/card2.png"

import usdt from "@/assets/images/myInvites/usdt.png"
import coin from '@/assets/images/coin.png'

import Back from "./common/BackComp"
import { formatIdString, FormattingNumber } from "../utils"
import RedPacket from "./redPacket/RedPacket"
import { useSelector } from "react-redux"

interface TurntableDrawCardsProps {
  dialog: boolean
  setDialog: (val: boolean) => void
}
const TurntableDrawCardsDialog = ({ dialog, setDialog }: TurntableDrawCardsProps) => {
  const isShowRedPacket = useSelector((state: any) => state.lottery.isShowRedPacket)
  const [IsAll, setIsAll] = useState(0)
  const [lottery, setLottery] = useState<any>([0])
  const [count, setCount] = useState<any>(0)
  const [isStar, setIsStar] = useState<any>(0)
  const [orderId, setOrderId] = useState<any>('')
  const [dataLottery, setDataLottery] = useState<any>({})
  const [activityList, setActivityList] = useState<any>({})

  const dataList = useMemo(() => {
    return IsAll == 1 ? activityList.my || [] : activityList.all || []
  }, [IsAll, activityList])

  const setPddInfo = useCallback(async () => {
    try {
      const { code, data } = await getCardLotteryList()
      if (code == "0") {
        setLottery(data.prize)
        setCount(data.count)
        setIsStar(data.star)
        setOrderId(data.order_id)
        setDataLottery(data)
      } else {
        const { code, data } = await getCardLotteryList()
        if (code == "0") {
          setLottery(data.prize)
          setCount(data.count)
          setIsStar(data.star)
        }
      }
    } catch (e) {
      console.log("🚀 ~ newData ~ newData:", e)
    }
  }, [])

  const setCardLotteryLink = useCallback(async () => {
    if (!orderId) {
      try {
        const { data, msg, code } = await getCardLotteryLink({ title: 'Draw Cards', description: 'Draw Cards', total: 5, })
        if (code == '0') {
          if (data.link) {
            return { data, msg, code }
          } else {
            const res = await getCardLottery({ order_id: data.order_id })
            return res
          }
        } else {
          return { data, msg, code }
        }
      } catch (e) {
        console.log("🚀 ~ setCardLotteryLink ~ e:", e)
      }
    } else {
      const res = await getCardLottery({ order_id: orderId })
      return res
    }
  }, [orderId])


  const setUserPddInfo = useCallback(async () => {
    try {
    } catch (e) {

    }
  }, [])

  const setActivity_list = useCallback(async () => {
    try {
      const { code, data } = await cardLotteryHistory()
      if (code == "0") {
        setActivityList(data)
      } else {
        const { code, data } = await cardLotteryHistory()
        if (code == "0") {
          setActivityList(data)
        }
      }
    } catch (e) {
    }
  }, [])

  const switchImg = (val: any) => {
    switch (val) {
      case 'card0':
        return card0;
      case 'card1':
        return card1;
      case 'card2':
        return card2;
      case 'card3':
        return card3;
      default:
        return card4;
    }
  }



  useEffect(() => {
    setActivity_list()
    setUserPddInfo()
  }, [])
  useEffect(() => {
    setPddInfo()
  }, [dialog])
  const prizeListDom = [
    {
      rotate: 60,
      text: 'Diamond Cards',
      img: card4,
      bgColor: '',
      imgRotate: '0',
      imgWidth: '24px',
      Width: '50%'
    },
    {
      rotate: 120,
      text: 'Gold Cards',
      img: card3,
      bgColor: '',
      imgWidth: '24px',
      imgRotate: '0',
      Width: '50%'
    },
    {
      rotate: 180,
      text: 'Silver Cards',
      img: card2,
      bgColor: '',
      imgRotate: '0',
      imgWidth: '24px',
      Width: '50%'
    },
    {
      rotate: 240,
      text: 'Bronze Cards',
      img: card1,
      bgColor: '',
      imgWidth: '24px',
      imgRotate: '0',
      Width: '50%'
    },
    {
      rotate: 300,
      text: 'Nature Cards',
      img: card0,
      bgColor: '',
      imgRotate: '0',
      imgWidth: '24px',
      Width: '50%'
    },
    {
      rotate: 360,
      text: '30K - 150K $STAR',
      img: coin,
      bgColor: '',
      imgRotate: '0',
      imgWidth: '38px',
      Width: '50%'
    },
  ]

  return (
    <Popup
      visible={dialog}
      style={{ width: '100%', height: '100vh', overflow: 'auto', paddingBottom: '16px', backgroundColor: 'rgba(0,0,0,0.6)' }}
      position='right'
      onClose={() => { setDialog(false) }}
    >
      <div className="fixed w-[100%] top-0 left-0">
        <Back title="Draw Cards" position={true} callback={() => { setDialog(false) }}></Back>
      </div>

      <div className=" w-full pt-[51px] px-4">
        <div className="mt-5">
          <Turntable dataLottery={dataLottery} getLottery={setCardLotteryLink} prizeListDom={prizeListDom} type={'card'} setUserPddInfo={setPddInfo} setActivity_list={setActivity_list} number_of_draws={count} lottery={lottery} orderId={orderId} isStar={orderId ? false : isStar} itemNum={6} offset={0}></Turntable>
        </div>
        <div className="bg-[#FFE0C1] rounded-[30px] w-[100%] h-[350px] mt-5 pb-[14px] flex items-center px-2 flex-col pt-2">
          <div
            style={{ backgroundImage: `url(${winners_list})`, textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }}
            className=" text-[#fff] h-11 min-h-11 w-[182px] bg-cover font-semibold text-[16px] leading-[19px] flex items-center justify-center">
            Winners List
          </div>
          <div className="flex gap-[73px] mt-[10px] justify-center items-center w-full">
            <span onClick={() => { setIsAll(0) }} className={`${IsAll === 0 ? 'text-style-1 active_INVITE' : 'text-[#C08010]'} relative !text-[16px] font-semibold !leading-[19px]`}>All</span>
            <span onClick={() => { setIsAll(1) }} className={`${IsAll === 1 ? 'text-style-1 active_INVITE' : 'text-[#C08010]'} !text-[16px] relative font-semibold !leading-[19px] `} >My</span>
          </div>
          <div style={{ border: '1px solid #FFFFFF' }} className="h-[1.5px] mt-[2px] w-[calc(100%_-_16px)]"></div>
          <div className=" flex flex-col w-full h-[380px] mt-[10px] overflow-auto gap-[10px]">
            {Array.isArray(dataList) && dataList.length == 0 ?
              <div className="text-[14px] justify-center leading-[18px] text-[#C08010] w-full h-full flex items-center">
                No lottery records available.
              </div>
              : <>
                {dataList.map((el: any, index: number) =>
                  <div key={`${index}_sss1223`} className="flex flex-shrink-0 bg-[#FEEDCF] rounded-[10px] justify-between h-[40px] px-[10px] w-full items-center">
                    <div className=" w-[33%] text-[14px] leading-[18px] text-[#C08010] text-start flex items-center">{formatIdString(el.account_id)}</div>
                    {el.code == 'star' ?
                      <div className=" w-[33%] text-[14px] leading-[18px] text-[#EE3F30] text-start flex items-center">
                        <img className=" h-5 w-5" src={coin} alt="" />
                        {FormattingNumber(el.amount.toString())} $STAR
                      </div> : el.code == 'usdt' ?
                        <div className=" w-[33%] text-[14px] leading-[18px] text-[#EE3F30] text-start flex items-center">
                          <img className=" rotate-45 h-5 w-5" src={usdt} alt="" />
                          ${el.amount}
                        </div> :
                        <div className=" w-[30%] justify-center  text-[14px] leading-[18px] text-[#EE3F30] text-start flex items-center">
                          <img className="w-5" src={switchImg(el.code)} alt="" />
                        </div>
                    }
                    <div className=" w-[25%] text-[14px] leading-[18px] text-[#C08010] justify-end flex items-center">{el.create_at}</div>
                  </div>
                )}</>}
          </div>
        </div>
        {
          isShowRedPacket && <RedPacket></RedPacket>
        }
      </div>
    </Popup>
  )
}
export default TurntableDrawCardsDialog
