import Back from "../../components/common/BackComp"
import ButtonComp from "../../components/common/ButtonComp"
import Turntable from "../../components/turntable"
import day from "@/assets/images/day.png"
import close from "@/assets/images/task/close.png"
import { CountDown, Dialog } from "react-vant"
import envelope_top from "@/assets/images/myInvites/envelope_top.png"
import copy_Completed from '@/assets/images/tabbar/copy_Completed.png'
import copy from '@/assets/images/tabbar/copy.png'
import finger from "@/assets/images/myInvites/finger.png"
import right from "@/assets/images/myInvites/right.png"
import left from "@/assets/images/myInvites/left.png"
import winners_list from "@/assets/images/myInvites/winners_list.png"
import WebApp from '@twa-dev/sdk'
import { useCallback, useEffect, useMemo, useState } from "react"
import { rewards_count, getRewards, getPddInfo, getUserPddInfo, getActivity_list, getPddLottery } from '@/api/url.js'

import card4 from "@/assets/images/myInvites/card4.png"
import card3 from "@/assets/images/myInvites/card3.png"
import usdt from "@/assets/images/myInvites/usdt.png"
import coin from '@/assets/images/coin.png'
import diamond_card from "@/assets/images/myInvites/diamond_card.png"
import gold_cards from "@/assets/images/myInvites/gold_cards.png"

import star from '@/assets/images/ranks/star.png'
import rules_bg from "@/assets/images/myInvites/rules_bg.png"
import rules_bg1 from "@/assets/images/myInvites/rules_bg1.png"
import rules_bg2 from "@/assets/images/myInvites/rules_bg2.png"
import rules_bg3 from "@/assets/images/myInvites/rules_bg3.png"
import rules_bg4 from "@/assets/images/myInvites/rules_bg4.png"
import rules_bg5 from "@/assets/images/myInvites/rules_bg5.png"
import start_bg from "@/assets/images/myInvites/start_bg.png"
import start_bg1 from "@/assets/images/myInvites/start_bg1.png"
import { formatIdString, FormattingNumber, HighlightPercentages } from "../../utils"
import InvitingPopup from "../inviting/invitingPopup"
import WithdrawDialog from "./withdrawDialog"
import LuckyDrawprizeDialog from "./LuckyDrawprizeDialog"
import { useNavigate } from "react-router-dom"
import RedPacket from "../../components/redPacket/RedPacket"
import { useSelector } from "react-redux"
import CloseComp from "../../components/common/CloseComp"

const myInvites = () => {

  const isShowRedPacket = useSelector((state: any) => state.lottery.isShowRedPacket)
  const [invitation_code] = useState<any>(localStorage.getItem('inviteCode'))
  const [invitation_msy] = useState(localStorage.getItem('invitation_msy'))
  const [loading, setLoading] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [isWithdraw, setIsWithdraw] = useState(false)
  const [startDialog, setStartDialog] = useState(false)
  const [rewardsList, setRewardsList] = useState([])
  const [visibleMy, setVisibleMy] = useState(false)
  const [luckyDrawprize, setLuckyDrawprize] = useState(false)
  const [IsAll, setIsAll] = useState(0)
  const [lottery, setLottery] = useState<any>([0])
  const [userInfo, setUserInfo] = useState<any>([0])
  const [activityList, setActivityList] = useState<any>({})
  const navigate = useNavigate()
  const [rewardsData, setRewardsData] = useState<any>({
    count: 0,
    invitation_code: "",
    premium_reward: '',
    standard_reward: '',
    premium: [
      { count: 0, people: 0 },
      { count: 0, people: 0 }
    ],
    standard: [
      { count: 0, people: 0 },
      { count: 0, people: 0 }
    ]
  })



  const dataList = useMemo(() => {
    return IsAll == 1 ? activityList.my || [] : activityList.all || []
  }, [IsAll, activityList])

  const setPddInfo = useCallback(async () => {
    try {
      const { code, data } = await getPddInfo()
      if (code == "0") {
        setLottery(data)
      } else {
        const { code, data } = await getPddInfo()
        if (code == "0") {
          setLottery(data)
        }
      }
    } catch (e) {
      console.log("🚀 ~ newData ~ newData:", e)
    }
  }, [])

  const setUserPddInfo = useCallback(async () => {
    try {
      const { code, data } = await getUserPddInfo()
      if (code == "0") {
        setUserInfo(data)
      } else {
        const { code, data } = await getUserPddInfo()
        if (code == "0") {
          setUserInfo(data)
        }
      }
    } catch (e) {

    }
  }, [])

  const setActivity_list = useCallback(async () => {
    try {
      const { code, data } = await getActivity_list()
      if (code == "0") {
        setActivityList(data)
      } else {
        const { code, data } = await getActivity_list()
        if (code == "0") {
          setActivityList(data)
        }
      }
    } catch (e) {
    }
  }, [])

  const getRewardsData = useCallback(async () => {
    try {
      const res = await rewards_count()
      if (res.code == 0) {
        setRewardsData(res.data)
      }
      const resRewards = await getRewards(1, 9999)
      if (resRewards.code == 0) {
        setRewardsList(resRewards.data.data)
      }
    } catch (e) {
      console.log("🚀 ~ getRewardsData ~ e:", e)
    }
  }, [])


  useEffect(() => {
    getRewardsData()
    setPddInfo()
    setActivity_list()
    setUserPddInfo()
    const time = setInterval(() => {
      setActivity_list()
    }, 30000)
    return () => {
      clearInterval(time)
    };
  }, [])

  const Invitation_code = async () => {
    if (!loading) {
      setLoading(true)
      WebApp.openTelegramLink(`https://t.me/share/url?text=${invitation_msy}&url=https://t.me/TheStarAIBot/StarAI?startapp%3D${invitation_code}`)
      setLoading(false)
    }
  }

  const rulesArr =
    [
      {
        img: rules_bg,
        text: 'Participate in the <$100 USDT> event, where the invitation counts are refreshed every seven days.',
      },
      {
        img: rules_bg1,
        text: 'Earn 10 roulette draw chances by inviting your first 10 users, plus one additional chance for every ten users thereafter.',
      },
      {
        img: rules_bg2,
        text: 'The <USDT> earned can be withdrawn directly by connecting to your wallet.',
      },
      {
        img: rules_bg3,
        text: 'Both you and your invitee will each receive <30,000 $STAR>.',
      },
      {
        img: rules_bg4,
        text: 'Inviting a Telegram Premium user will earn both parties <200,000 $STAR> each.',
      },
      {
        img: rules_bg5,
        text: 'Earn <20% $STAR> from direct referrals and 10% from secondary referrals.',
      },
    ]

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`
        https://t.me/TheStarAIBot/StarAI?startapp=${invitation_code}
        ${(decodeURIComponent(invitation_msy || ''))}`);
      setIsCopy(true)
    } catch (err) {
    }
    // setTimeout(() => {
    //   setIsCopy(false)
    // }, 2000)
  };

  let lens = 0;
  useEffect(() => {
    const timer: any = setInterval(() => {
      lens += 2;
      if (lens >= 100) {
        clearInterval(timer)
        setStartDialog(true)
      }
    }, 40)
    return () => clearInterval(timer);
  }, [lens])

  function dateToTimestamp(date: string) {
    console.log("🚀 ~ dateToTimestamp ~ new Date(date).getTime():", new Date(date).getTime())
    return new Date(date).getTime();
  }

  const prizeListDom = [
    {
      rotate: 90,
      text: 'Diamond Cards',
      img: diamond_card,
      bgColor: '#FEEDCF',
      imgRotate: '-45',
      imgWidth: '50px',
      Width: '50%'
    },
    {
      rotate: 180,
      text: 'Gold Cards',
      img: gold_cards,
      bgColor: '',
      imgWidth: '50px',
      imgRotate: '135',
      Width: '50%'
    },
    {
      rotate: 270,
      text: '10K - 200K $STAR',
      img: coin,
      bgColor: '#FEEDCF',
      imgRotate: '0',
      imgWidth: '38px',
      Width: '50%'
    },
    {
      rotate: 360,
      text: '0.1-2 USDT',
      img: usdt,
      bgColor: '',
      imgWidth: '38px',
      imgRotate: '45',
      Width: '50%'
    }
  ]

  return (
    <div style={{ background: "linear-gradient( 178deg, #E52313 0%, #FEB574 42%, #FFBC78 100%)" }}
      className="relative overflow-auto pt-11 pb-[30px] h-[100vh] w-[100vw]">
      <div className="px-4">
        <div style={{ borderRadius: '10px 0px 0px 10px' }} className="bg-[rgb(255,255,255,0.3)] z-10 fixed pl-[10px] top-3 right-0 w-[58px] h-5 font-semibold text-[#fff] text-[12px] leading-5"
          onClick={() => {
            setDialog(true)
            // setLuckyDrawprize(true)
          }}>
          Rules</div>
        <div className="fixed top-0 w-full h-11 p-[10px] bg-[#e94026] px-4 z-[9] left-0">
          {/* <Back isHiddenIcon={false} position={false} title={'INVITE'} callback={() => { navigate("/pk") }}></Back> */}
        </div>
        <div className=" px-[6px] z-[1] relative mt-[6px]">
          <div className=" w-[100%] h-[305px] relative rounded-[30px] pt-5 px-4 bg-white" style={{ border: "4px solid #FFD09F" }}>
            <p onClick={() => { setVisibleMy(true) }} className="text-[#C08010] bg-[#FFD09F] text-[14px] absolute top-0 -right-1 leading-[30px] w-[82px] rounded-tr-[30px] flex items-center justify-center font-semibold ">My Invites</p>
            <p className=" text-[46px] font-bold text-[#EE3F30] leading-[56px] text-center"> <span className=" text-[30px] font-bold text-[#EE3F30] leading-[37px]">$</span>{userInfo.total || 0}</p>
            <div className="px-1 mt-[10px] w-full">
              <div className="w-full rounded-lg h-4 bg-[#DEDEDE]">
                <div style={{ background: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)', width: `${(userInfo.total * 100 / userInfo.max) || 0}%` }} className="w-[85%] relative rounded-lg h-4">
                  <p className={`text-style-1 absolute top-[18px] ${(userInfo.total * 100 / userInfo.max) ? 'right-0' : 'right-1'}  !text-[14px] font-semibold !leading-[18px]`}>{(userInfo.total * 100 / userInfo.max) || 0}%</p>
                </div>
              </div>
            </div>
            <p className="text-[#C08010] mt-[28px] text-center text-[14px] leading-[18px] font-semibold">
              Only
              <span className="text-[#EE3F30] text-[14px] leading-[18px] font-semibold"> ${(userInfo.max * 1000 - userInfo.total * 1000) / 1000 || 0} </span>
              left to withdraw
              <span className="text-[#EE3F30] text-[14px] leading-[18px] font-semibold"> ${userInfo.max || 0}</span>
            </p>
            <div className="bg-[rgb(254,237,207,0.5)] mt-[2px] rounded-[10px] relative w-full h-[100px] pt-[6px] flex items-center flex-col">
              <p className="text-[#C08010] text-[14px] text-center leading-[18px] font-semibold">
                Amount Withdrawable
                <span className="text-[#EE3F30] ml-[10px] text-[14px] leading-[18px] font-semibold">${userInfo.withdrawn_total || 0}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full -mt-[195.2px] bg-[#EA2D09] pt-[84px] rounded-[30px]">
          <div style={{ backgroundImage: `url(${envelope_top})` }} className="w-full bg-cover pb-5 bg-no-repeat relative pt-[42px] rounded-b-[30px] z-[2]">
            <div className="z-40 -top-2 left-[50%] -translate-x-[50%] absolute">
              <ButtonComp width={67} disabled={userInfo?.is_withdrawn ? false : true} height={18} title="Withdraw" fontSize={12} callBack={() => { setIsWithdraw(true) }} mt={6}></ButtonComp>
            </div>
            <img className=" absolute top-0 right-0 h-full" src={right} alt="" />
            <img className=" absolute top-0 left-0 h-full" src={left} alt="" />
            <Turntable getLottery={getPddLottery} offset={45} prizeListDom={prizeListDom} setUserPddInfo={setUserPddInfo} setActivity_list={setActivity_list} number_of_draws={userInfo.number_of_draws} lottery={lottery} itemNum={4}></Turntable>
            <div
              className="w-full">
              <div className="flex justify-between mb-[10px] items-center px-4">
                <div className="flex gap-1 items-center">
                  <p className="text-[16px] leading-[19px] text-[#fff] font-bold">My invite link: </p>
                  <p className="font-semibold text-[14px] text-[#fff] leading-[16px]">
                    https://t.me/…app={invitation_code}
                  </p>
                </div>
                <div onClick={(e: any) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleCopy()
                }} className={`${isCopy ? '' : 'pt-[6px]'} items-center from-[#00E49F] bg-gradient-to-r rounded-[10px] flex justify-center button_shadow to-[#54ADFF] flex-shrink-0 w-9 h-9`}>
                  <img className={` ${isCopy ? 'w-[20px]' : 'w-[28px]'}`} src={isCopy ? copy_Completed : copy} alt="" />
                </div>
              </div>
              <div className=" flex justify-center items-center">
                <div className=" w-fit relative">
                  <ButtonComp width="174px" isLoading={loading} title='Invite' callBack={() => {
                    Invitation_code()
                  }}></ButtonComp>
                  <img className='absolute zoom w-9 h-9 z-10 -right-0 bottom-[-11px]' src={finger} alt="" />
                </div>

              </div>
            </div>
            <p style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className=" text-[12px] text-center px-2 mt-5 leading-[15px] text-[#fff] font-semibold">
              Earn 10 roulette draw chances by inviting your first 10 users, plus one additional chance for every ten users thereafter.
            </p>
          </div>
        </div>
        <div className="mt-[10px] mb-5 flex justify-center leading-[15px] text-[12px] font-semibold text-center text-[#fff]">
          <CountDown
            time={(dateToTimestamp(userInfo.activity_date)) - new Date().getTime()}
            millisecond
            format="DD:HH:mm:ss:SS"
          // onFinish={() => { onFinishHandle() }}
          >
            {({ days, hours, minutes, seconds }) => (
              <div className="flex w-full px-6 mr-[30px]">
                <div className="flex-1 text-center text-white  mr-[15px] flex flex-col items-center justify-center">
                  <div className="time-item h-6 w-6 relative z-0 flex items-center justify-center">
                    <img src={day} alt="" className="w-full h-full absolute z-[-1]" />
                    <span className="text-[#333] text-base ">{days > 9 ? days : "0" + days}</span>
                  </div>
                  <p className="text-white  text-center text-xs mt-[2px]">DAY{days > 9 ? "S" : ""}</p>

                </div>
                <div className="flex-1 text-center text-white">
                  <div className="text-center text-white flex flex-col items-center justify-center">
                    <div className="time-item h-6 w-6  bg-gradient-to-br from-[#00E49F] to-[#54ADFF] rounded-md relative z-0 flex items-center justify-center">
                      <span className="text-[#fff] text-base ">{hours > 9 ? hours : "0" + hours}</span>
                    </div>
                    <p className="text-white text-xs mt-[2px]">HOUR{hours > 9 ? "S" : ""}</p>

                  </div>
                </div>
                <span className="text-white  mr-[7px] ml-[11px]">:</span>
                <div className="flex-1 text-center text-white">
                  <div className="text-center text-white flex flex-col items-center justify-center">
                    <div className="time-item h-6 w-6  bg-gradient-to-br from-[#00E49F] to-[#54ADFF] rounded-md relative z-0 flex items-center justify-center">
                      <span className="text-[#fff] text-base ">{minutes > 9 ? minutes : "0" + minutes}</span>
                    </div>
                    <p className="text-white text-xs mt-[2px]">MINUTE{minutes > 9 ? "S" : ""}</p>

                  </div>
                </div>
                <span className="text-white  mx-[6px]">:</span>
                <div className="flex-1 text-center text-white">
                  <div className="text-center text-white flex flex-col items-center justify-center">
                    <div className="time-item h-6 w-6  bg-gradient-to-br from-[#00E49F] to-[#54ADFF] rounded-md relative z-0 flex items-center justify-center">
                      <span className="text-[#fff] text-base ">{seconds > 9 ? seconds : "0" + seconds}</span>
                    </div>
                    <p className="text-white text-xs mt-[2px]">SECOND{seconds > 9 ? "S" : ""}</p>

                  </div>
                </div>
              </div>
            )}
          </CountDown>
          {/* 7 days, 23 hours, 59 minutes. */}
        </div>
      </div>
      <div className="bg-[#FFE0C1] rounded-t-[30px] w-[100vw] h-[530px] pb-[120px] flex items-center px-4 flex-col pt-2">
        <div
          style={{ backgroundImage: `url(${winners_list})`, textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }}
          className=" text-[#fff] h-11 w-[182px] bg-cover font-semibold text-[16px] leading-[19px] flex items-center justify-center">
          Winners List
        </div>
        <div className="flex gap-[73px] mt-[10px] justify-center items-center w-full">
          <span onClick={() => { setIsAll(0) }} className={`${IsAll === 0 ? 'text-style-1 active_INVITE' : 'text-[#C08010]'} relative !text-[16px] font-semibold !leading-[19px]`}>All</span>
          <span onClick={() => { setIsAll(1) }} className={`${IsAll === 1 ? 'text-style-1 active_INVITE' : 'text-[#C08010]'} !text-[16px] relative font-semibold !leading-[19px] `} >My</span>
        </div>
        <div style={{ border: '1px solid #FFFFFF' }} className="h-[1.5px] mt-[2px] w-[calc(100%_-_16px)]"></div>
        <div className=" flex flex-col w-full h-[380px] mt-[20px] overflow-auto gap-[10px]">
          {dataList.length == 0 ?
            <div className="text-[14px] justify-center leading-[18px] text-[#C08010] w-full h-full flex items-center">
              No lottery records available.
            </div>
            : <>
              {Array.isArray(dataList) && dataList.map((el: any, index: number) =>

                <div key={`${index}_sss1223`} className="flex flex-shrink-0 bg-[#FEEDCF] rounded-[10px] justify-between h-[50px] px-[10px] w-full items-center">
                  <div className=" w-[25%] text-[14px] leading-[18px] text-[#C08010] text-start flex items-center">{formatIdString(el.account_id)}</div>
                  <div className=" w-[20%] text-[14px] leading-[18px] text-[#C08010] text-start flex items-center">Referrals {el.invitation}</div>
                  {el.code == 'star' ?
                    <div className=" w-[30%] text-[14px] leading-[18px] text-[#EE3F30] text-start flex items-center">
                      <img className=" h-5 w-5" src={coin} alt="" />
                      {FormattingNumber(el.amount.toString())} $STAR
                    </div> : el.code == 'usdt' ?
                      <div className=" w-[30%] text-[14px] leading-[18px] text-[#EE3F30] text-start flex items-center">
                        <img className=" rotate-45 h-5 w-5" src={usdt} alt="" />
                        ${el.amount}
                      </div> :
                      el.code == 'card3' ?
                        <div className=" w-[30%] justify-center text-[14px] leading-[18px] text-[#EE3F30] text-start flex items-center">
                          <img className="w-5" src={card3} alt="" />
                        </div> : <div className=" w-[30%] justify-center  text-[14px] leading-[18px] text-[#EE3F30] text-start flex items-center">
                          <img className="w-5" src={card4} alt="" />
                        </div>
                  }
                  <div className=" w-[25%] text-[14px] leading-[18px] text-[#C08010] justify-end flex items-center">{el.create_at}</div>
                </div>
              )}</>}
        </div>
      </div>
      <Dialog
        visible={dialog}
        showConfirmButton={false}
        showCancelButton={false}
        closeOnClickOverlay={true}
        onClose={() => { setDialog(false) }}
        width={'100%'}
      >

        <div style={{ backgroundSize: "100% 100% !important" }} className={`w-[calc(100%_-_24px)] !bg-[length:100%_100%] overflow-visible px-[14px] relative bg-[url(@/assets/images/dialog_bg.png)] pt-[31px] mx-[12px] pb-[50px]`}>
          <CloseComp position="absolute" top={14} right={16} callBack={() => { setDialog(false) }}></CloseComp>
          <p className="font-bold text-[20px] leading-6 mb-[20px] text-center text-[#333]">Rules</p>
          {rulesArr.map((el: any, index: any) =>
            <div style={{ backgroundImage: `url(${el.img})`, backgroundSize: '100% 100%' }} key={`rulesArr_${index}`} className="p-[6px] mt-[10px] flex">
              <img src={star} className="w-[14px] h-[14px] mr-[2px]" alt="" />
              <p className="text-[14px] leading-[18px] font-semibold text-[#333]" dangerouslySetInnerHTML={{ __html: HighlightPercentages(el.text) }}></p>
            </div>
          )}
        </div>
      </Dialog>

      <WithdrawDialog setUserPddInfo={setUserPddInfo} userInfo={userInfo} dialog={isWithdraw} setDialog={setIsWithdraw}></WithdrawDialog>
      <LuckyDrawprizeDialog dialog={luckyDrawprize} setDialog={setLuckyDrawprize}></LuckyDrawprizeDialog>

      <InvitingPopup
        title={'My Invites'}
        visible={visibleMy}
        rewardsData={rewardsData}
        rewardsList={rewardsList}
        setVisible={setVisibleMy}
        setLoading={setLoading}
        Invitation_code={Invitation_code}
        loading={loading} isCopy={isCopy}
        setIsCopy={setIsCopy}
        handleCopy={handleCopy}>
      </InvitingPopup>

      <Dialog
        visible={!startDialog}
        showConfirmButton={false}
        overlayStyle={{ backgroundColor: ' rgb(0 0 0 )' }}
        showCancelButton={false}
        width={'100%'}
      >
        <div className={`w-[100vw] flex items-center justify-center relative`}>
          <img className="fade-right w-full" src={start_bg} alt="" />
          <div className="absolute fade-left w-full">
            <img className="w-full " src={start_bg1} alt="" />
            <div className=" absolute w-full flex justify-center flex-col h-full top-0 left-0">
              <p style={{ textShadow: "0px 2px 4px rgba(130,0,0,0.5)" }} className="text-[20px] text-center  leading-6 font-bold text-[#fff]">
                Invite friends to help you and earn a
              </p>
              <p style={{ textShadow: "0px 2px 4px rgba(130,0,0,0.5)" }} className="text-[20px] text-center mt-[2px] leading-6 font-bold text-[#fff]">
                <span style={{ textShadow: "0px 2px 4px rgba(130,0,0,0.5)" }} className="text-style-3 text-[30px] leading-[30px] font-bold"> 100 USDT </span>
                reward!
              </p>
            </div>
          </div>
        </div>
      </Dialog>
      {
        isShowRedPacket && <RedPacket></RedPacket>
      }


    </div>
  )
}
export default myInvites
