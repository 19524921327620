
import { useCallback, useEffect, useRef, useState } from 'react'
import coinImg from '@/assets/images/coin.png'
import bgImg from "@/assets/images/pk/bg.png"
import loadingImg from '@/assets/images/cards/loading.png'
import pk_activeImg from "../../assets/images/tabbar/battle_active.png"
import pkImg from "@/assets/images/tabbar/battle.png"
import protectImg from "@/assets/images/pk/protect.png"

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';


import { getBattleRecords } from '@/api/pk_url.js'


import Back from '../../components/common/BackComp.js';
import RecordDetails from "./recordsDetails"

import { formatNameString, formatNumber, FormattingTwoNumber } from '../../utils/index.js'
import CardItem from '../../components/CardItem.js'

import { List } from 'react-vant'

import coinsMusic from "@/assets/mp3/coins.mp3"
import moment from "moment"

const Battle = () => {
    const [isShowOther, setShowOther] = useState(false)
    const [isShowMenuList, setShowMenuList] = useState(false)
    const [isShowCreateArena, setCreateArena] = useState(false)

    const [dataList, setDataList] = useState<any>([])
    const currPage = useRef(1)
    const total = useRef(0);
    const pageSize = 20
    const [finished, setFinished] = useState(false);
    const isInitLoading = useRef(true)
    const [isLoading, setIsLoading] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const clickHandle = (e: Event) => {
        e.stopPropagation();
        if (isShowMenuList) {
            setShowMenuList(false)
        }
    }

    useEffect(() => {
        // isInitLoading.current = true;
        // setFinished(true)
        currPage.current = 1
        setDataList([])
        if (!isShowCreateArena) {
            getBattleRecordsHandle()

        }



    }, [isShowCreateArena])

    useEffect(() => {
        document.addEventListener("click", (e) => { clickHandle(e) })
        return () => {
            document.removeEventListener("click", (e) => { clickHandle(e) })
        }
    }, [isShowMenuList])

    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
    const AudioRef = useRef<any>()
    const boxRef = useRef<any>(null)
    const reCordsDetails = useRef<any>(null)


    const onLoad = async () => {
        if (finished || isInitLoading.current) return;
        currPage.current++
        await getBattleRecordsHandle();

    }


    //获取pk记录
    const getBattleRecordsHandle = useCallback(async () => {
        setIsLoading(true)
        try {
            const params = {
                limit: pageSize,
                page: currPage.current,
            }
            const { code, data } = await getBattleRecords(params)

            if (code == "0") {
                setIsLoading(false)
                total.current = data.count
                isInitLoading.current = false
                setFinished(false)
                if (!data?.logs.length) {
                    setShowOther(true)
                } else {
                    setShowOther(false)
                }
                setDataList((prevData: any) => [...prevData, ...data.logs]);
            } else {
                setShowOther(true)
                setFinished(true)
                total.current = 0
            }
        } catch (e) {
            setShowOther(true)
            console.log("��� ~ getBattleRecordsHandle ~ e:", e)
            setFinished(true)
            setIsLoading(false)
            total.current = 0
        }

        if (currPage.current * pageSize >= total.current) {
            setFinished(true)
            return false;
        }



    }, [])

    return (
        <div ref={boxRef} className="descriptionLogs w-full h-full text-white pt-12 px-4 box-border relative pb-[30px] overflow-x-hidden overflow-y-scroll">
            <div className="h-11 flex items-center justify-center fixed top-0 w-[calc(100%_-_32px)] z-[11] box-border bg-[#050A0A]">
                <Back isLeft={false} callback={() => { navigate("/pk") }}>
                    <div className='w-[90%] h-10 text-white flex justify-around items-center'>
                        PK Records
                    </div>
                </Back>
            </div>
            <List className='list w-full grid grid-cols-1 gap-4' finished={finished} loading={isLoading} immediateCheck={false} onLoad={onLoad} loadingText="Loading...">
                {

                    dataList.length > 0 && dataList.map((item: any, index: number) => {
                        return (
                            <div key={item.id} onClick={() => { reCordsDetails.current = item; setShowDetails(true) }} className={`bg-gradient-to-r flex items-center ${item?.is_victory ? 'from-[#00E49F] to-[#54adff]' : 'from-[#333] to-[#333]'}  h-[80px] w-full rounded-md p-[10px]`}>
                                <div className="left flex-1 flex flex-col justify-around h-full">
                                    <div className='flex items-center'>
                                        <p className='text-white text-base text-shadow-lg'>{moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}</p>
                                        <img className='w-4 ml-3' src={item?.account_type == 1 ? protectImg : pkImg} alt="" />
                                    </div>

                                    <div className='flex'>
                                        <div className='flex items-center'>
                                            {/* <img className='w-4 h-4' src={item?.is_victory ? pkImg : pk_activeImg} alt="" /> */}
                                            <span className='text-white text-base text-shadow-lg'>{formatNameString(item?.account_type == 1 ? item?.challenger_name : item?.arena_owner_name)}</span>
                                        </div>
                                        <div className='text-white flex ml-3 text-shadow-lg'>Power:
                                            <p className='ml-1'>
                                                {item?.account_type == 1 ? item?.challenger_final_power : item?.arena_owner_final_power}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="right w-2/5 h-full flex justify-around items-center">
                                    <span className={` text-lg italic ${item?.is_victory ? 'text-white' : 'text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'}`}>{item?.is_victory ? 'You Win' : 'You Lose'}</span>
                                    {
                                        item.reward_type != 0 ?
                                            <div className='w-10 h-[60px] ml-2 relative'>
                                                {
                                                    item?.reward_card_url ?

                                                        <CardItem is_enhance={true} height={60} rounded={6} width={40} mine={1} image={item?.reward_card_url} level={item?.reward_card_level+1}>

                                                        </CardItem>
                                                        :
                                                        <img className='w-full h-full' src={""} alt="" />
                                                }


                                            </div>
                                            :
                                            <div className="flex flex-col items-center text-style-level font-bold" >
                                                <img className="w-6 h-6" src={coinImg} alt="" />
                                                <p className={`${item?.is_victory ? 'text-style-3' : 'text-style-level'} mt-1`}>{FormattingTwoNumber(item.reward_coins.toString())}</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        )
                    })

                }

            </List>
            {
                showDetails && <RecordDetails callback={() => { setShowDetails(false) }} details={reCordsDetails.current} title={moment(reCordsDetails.current.created_at).format('YYYY-MM-DD HH:mm:ss')}></RecordDetails>
            }


            {
                isShowOther && !dataList.length && <div className='flex flex-col justify-center items-center mt-16 text-white'>
                    <img className="w-[225px] mt-10" src={loadingImg}></img>
                    <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>
                        No Records</p>

                </div>
            }


            {
                isSoundPlay && <audio ref={AudioRef} src={coinsMusic} id="audio"></audio>
            }


        </div >
    )
}


export default Battle;