
import Shop from "../../pages/shop/Index";


const shopRouter = () => {
    return [
        {
            path: "/shop",
            element: <Shop></Shop>,
        }
    ]
}

export default shopRouter;