import WebApp from '@twa-dev/sdk'
import './App.less'
import '@/styles/index.less'
import '@/styles/font.less'
import coin from '@/assets/images/coin.png'
import mining from '@/assets/images/mining.png'
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import cardTurntable from '@/assets/images/cards/cardTurntable.png'
import IntroductoryPage from "./pages/introductory/index"

import { useDispatch, useSelector } from 'react-redux';
import Tabbar from "@/layout/Tabbar.tsx"
import AOS from 'aos';
import { login, getUserInfo, getEmoji, getCharactersList, getSetting, getInvitation_code, getRefiningTips } from "./api/url.js";
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react'
import { changeCharacterList, changeInviteData, changeUserInfo } from './store/module/tabbar.js'
import UpLevelDialog from './components/UpLevelDialog.js'
import LoadingDialog from './components/LoadingDialog.js'
import CheckIn from "./components/checkin/CheckIn"
import NftMint from "./components/nftMint/NftMint"



import wallet from "./hook/wallet/contentWallet"
import { Address } from '@ton/core';
import { changeSettings, changeTurntableDialog } from './store/module/settins.js'
import music from "../src/assets/mp3/horse.mp3"
import coinsMusic from "../src/assets/mp3/coins.mp3"
import TurntableDrawCardsDialog from './components/TurntableDrawCardsDialog.js'
import { changeCardReport } from './store/module/card.js'
import PromptDialog from './components/PromptDialog.js'


const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
function App() {
  const navigate = useNavigate()
  const audioRef = useRef<any>(null)
  const [typeLogin, setTypeLogin] = useState(false)
  // const [turntableDialog, setTurntableDialog] = useState(false)
  const { settings, isShowIntroductory, turntableDialog, miningPoolConfig } = useSelector((state: any) => state.settings)
  const coinDomCoordinate = useSelector((state: any) => state.tabbar.coinDomCoordinate)
  const address_params = localStorage.getItem('address_params')
  const boxRef = useRef<any>(null)

  AOS.init();
  const check_in_loading = useSelector((state: any) => state.tasks.check_in_loading)
  const flag = useSelector((state: any) => state.tabbar.value);
  const userInfo = useSelector((state: any) => state.tabbar.userInfo)
  const isPlayBgMusic = useSelector((state: any) => state.settings.settings.music)

  const { pathname } = useLocation();
  // let invitation_code: string = localStorage.getItem('startapp') || ''
  const dispatch = useDispatch()
  const init = useCallback(async () => {
    await getEmoji()
    try {
      const res = await login({
        id: window.localStorage.getItem("userId") || "",
        init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA,
        invitation_code: WebApp.initDataUnsafe.start_param || '',
        chat_id: window.localStorage.getItem("chatInstance") || "",
        user_name: window.localStorage.getItem("userName") || "",
        first_name: window.localStorage.getItem("firstName") || "",
        last_name: window.localStorage.getItem("lastName") || "",
        is_primer: window.Telegram.WebApp.initDataUnsafe.user?.is_premium ? true : false,
      })
      if (res.code == 0) {
        getTips()
        setTypeLogin(true)
      }
      localStorage.setItem('token', res.data)
      getCharactersLists();

      const UserInfo = await getUserInfo(address_params)
      if (UserInfo.code == "0") {
        dispatch(changeUserInfo(UserInfo.data))
      } else {
        for (let i = 0; i < 3; i++) {
          const UserInfo = await getUserInfo(address_params)
          if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))
            break
          } else {
            await sleep(1000)
          }
        }
      }
      // changeInviteData
      const resInvite = await getInvitation_code()
      if (resInvite.code == '0') {
        dispatch(changeInviteData(resInvite.data))
        localStorage.setItem('inviteCode', resInvite.data.code || '')
        localStorage.setItem('invitation_msy', resInvite.data.invitation_msy || '')
      } else {
        const resInvite = await getInvitation_code()
        if (resInvite.code == "0") {
          dispatch(changeInviteData(resInvite.data))
          localStorage.setItem('inviteCode', resInvite.data.code || '')
          localStorage.setItem('invitation_msy', resInvite.data.invitation_msy || '')

        }
      }

      const setting = await getSetting(localStorage.getItem("userId") || '')
      if (setting.code == 0) {
        dispatch(changeSettings(setting.data))
      }

    } catch (e) {
      setTypeLogin(true)
      await login({
        id: window.localStorage.getItem("userId") || "",
        invitation_code: WebApp.initDataUnsafe.start_param || '',
        init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA,
        chat_id: window.localStorage.getItem("chatInstance") || "",
        user_name: window.localStorage.getItem("userName") || "",
        first_name: window.localStorage.getItem("firstName") || "",
        last_name: window.localStorage.getItem("lastName") || "",
        is_primer: window.Telegram.WebApp.initDataUnsafe.user?.is_premium ? true : false,
      })
      console.log(e)
    }
  }, [])


  useLayoutEffect(() => {
    window.addEventListener("touchstart", () => {
      if (audioRef.current) {
        audioRef.current?.play()
      }


    })
    return (
      window.removeEventListener("touchstart", () => {
        if (audioRef.current) {
          audioRef.current.play()
        }

      })
    )
  }, [])

  const handleVisibilityChange = () => {
    if (document.hidden) {
      // 页面切换到后台，暂停音频
      if (audioRef.current) {
        audioRef.current?.pause();
      }
    } else {
      // 页面切换到前台，可以选择恢复音频
      if (!isPlayBgMusic) {
        return false
      }
      if (audioRef.current) {
        audioRef.current?.play();
      }
    }
  };

  useEffect(() => {


    document.addEventListener('visibilitychange', handleVisibilityChange);

    // 清理函数
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [])

  useEffect(() => {
    // alert(123)
    init()
  }, [])


  const [characterList, setCharacterList] = useState<any>([])
  const getCharactersLists = async () => {
    try {
      const params = {
        type: 1,
        limit: 10,
        page: 1,
        query: ""
      }
      const { code, data } = await getCharactersList(params)

      if (code == '0' && data && data.list.length) {
        setCharacterList(data.list)
        window.localStorage.setItem("characterList", JSON.stringify(data.list))
        dispatch(changeCharacterList(data.list))
      }

    } catch (e) {
      console.log('获取数字人列表失败', e)
    }
  }
  const { useGetWalletAddress } = wallet();
  const address = useGetWalletAddress()
  if (address) {
    const address_params: any = Address.parse(address ? address : '')
    if (address_params && address_params != 'undefined') {
      localStorage.setItem('address_params', address_params)
    } else {
      localStorage.setItem('address_params', '')
    }
  }

  const isShowTabbar = () => {
    switch (pathname) {
      case '/ranks':
      case "/pk":
      case "/cards":
      case "/character":
      case "/myInvites":
      case "/tasks":
        return true;
      default:
        return false;
    }
  }

  // ---------------------------金币掉落音效--------------------
  const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
  const AudioRef = useRef<any>()
  const getCoinAnimation = useCallback((x: any, y: any) => {
    const R = 100; // 扩散半径
    const time = 0.25; //扩散过度时间
    const time2 = 1; // 收集过度时间
    const w = 26; // 金币大小
    const Num = 8 //金币数量
    setlits(x, y)



    if (AudioRef.current) {
      // AudioRef.current.currentTime = 0;
      AudioRef.current.play()
    }


    const total = document.getElementsByClassName('total_1')[0] // 获取金币最终飞向
    function setlits(X: any, Y: any) {
      var species = document.createElement('div');
      species.className = 'species'
      for (let i = 0; i < Num; i++) {
        var img = document.createElement('img');
        img.className = 'speciesstyle'
        img.style.width = w + 'px',
          img.style.height = w + 'px',
          img.style.left = X + 'px',
          img.style.top = Y + 'px',
          img.style.transform = 'translate(0%, 0%)',
          img.style.transition = `${time}s`
        img.src = coin
        species.appendChild(img);
      }
      boxRef.current.appendChild(species)
      setTimeout(() => {
        clickss(X, Y)
      }, 0)
    }
    // 计算金币扩散范围和坐标
    function clickss(X: number, Y: number) {
      const specielist: any = document.getElementsByClassName('speciesstyle')
      const num = specielist.length
      const center = {
        x: X,
        y: Y
      }
      for (let i = 0; i < num; i++) {
        const xz = center.x + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.sin(2 * Math.PI * i / num)
        const yz = center.y + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.cos(2 * Math.PI * i / num)
        specielist[i].style.transform = `translate(${xz - (X + w / 2)}px, ${yz - (Y + w / 2)}px)`
      }
      setTimeout(() => {
        for (let i = 0; i < num; i++) {
          specielist[i].style.transition = `${time2}s`
          specielist[i].style.transform = `translate(0,0)`
          specielist[i].style.left = Math.floor(total?.getBoundingClientRect().x) + 'px'
          specielist[i].style.top = Math.floor(total?.getBoundingClientRect().y) + 'px'
        }
        // 结束清除蒙层
        setTimeout(() => {
          remove()
        }, (Number(time2) + 0.5) * 500) //飞行时间结束

      }, (Number(time) + 0.5) * 500) //扩散时间结束
    }
    // 清除dom
    function remove() {
      document.getElementsByClassName('species')[0].remove()
    }
  }, [coinDomCoordinate.x, coinDomCoordinate.y, userInfo, flag])

  const audioDOM = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (audioDOM.current) {
      if (settings.music) {
        audioDOM.current.loop = true;
        audioDOM.current.muted = false;
        audioDOM.current.play();
      } else {
        audioDOM.current.pause()
      }
    }
  }, [audioDOM.current, settings]);

  const getTips = useCallback(async () => {
    try {
      const { code, data } = await getRefiningTips()
      if (code == 0 && Array.isArray(data.card_report)) {

        dispatch(changeCardReport(data))
      }
    } catch (error) {
      console.log(error)
    }

  }, [])

  const [refDom, setRefDom] = useState(false)
  useEffect(() => {
    window.addEventListener('unhandledrejection', (error) => {
      const str = error.reason.toString();
      if (str.includes("WebAppMethodUnsupported")) {
        setRefDom(true)
        return false;
      }
    })
    return () => {
      window.removeEventListener('unhandledrejection', () => { })
    }
  }, [])

  return (
    <>
      {typeLogin &&
        <div ref={boxRef} className={` ${(localStorage.getItem('userId')) ? '' : 'pointer-events-none'} App w-full relative h-full`}>
          {
            !isShowTabbar() && <div className='absolute bottom-12 left-[100px] z-[1000] opacity-0 w-10 h-[30px] total_1'></div>
          }

          {/* <div ref={boxRef} className={` App w-full h-full`}> */}
          {/* <audio ref={audioDOM} src={horse}></audio> */}
          <LoadingDialog dialog={check_in_loading.loading} point={check_in_loading.point} setDialog={() => { }} title='Checked in on-chain successfully!' getCoinAnimation={getCoinAnimation}></LoadingDialog>
          <UpLevelDialog getCoinAnimation={getCoinAnimation}></UpLevelDialog>
          {/* <GetMedalDialog></GetMedalDialog> */}
          <Outlet></Outlet>
          {/* card_rewards */}
          {
            isShowTabbar() && flag && <Tabbar userInfo={userInfo}></Tabbar>
          }
          {localStorage.getItem('userId') &&
            <>
              {isShowTabbar() && <CheckIn getCoinAnimation={getCoinAnimation}></CheckIn>}
              {isShowTabbar() && <NftMint></NftMint>}
              {isShowTabbar() &&
                <div className='w-[52px] h-[52px] flex justify-center items-center z-[800] overflow-hidden top-[180px] left-4 absolute'>
                  {/* <div className='miningAnimation z-[1]'>
                  </div> */}
                  <img
                    onClick={() => {
                      navigate('/mining')
                    }}
                    className=' w-[52px] relative z-[2] h-[52px]'
                    src={mining} />
                  <div className={`${miningPoolConfig?.standard_remaining == 0 && miningPoolConfig?.vip_remaining == 0 ? 'hide' : ''} w-3 h-3 bg-[red] rounded-full z-[3] absolute right-0 top-0`}></div>
                </div>
              }
              {/* {
                isShowTabbar() && <GateWallet />
              } */}


            </>
          }
          {pathname == '/cards' &&
            <>
              <img src={cardTurntable} onClick={() => { dispatch(changeTurntableDialog(true)) }} className='z-[800] w-[52px] absolute right-4 top-[250px] animate-rotate' alt="" />
            </>
          }
          <TurntableDrawCardsDialog dialog={turntableDialog} setDialog={() => { dispatch(changeTurntableDialog(false)) }}></TurntableDrawCardsDialog>
        </div >
      }

      {
        isShowIntroductory && <IntroductoryPage></IntroductoryPage>
      }


      {isPlayBgMusic && <audio ref={audioRef} src={music} autoPlay loop id="audio"></audio>}
      {
        isSoundPlay && <audio ref={AudioRef} src={coinsMusic} id="audio"></audio>
      }
      <PromptDialog title={'Attention'} dialog={refDom} setDialog={setRefDom} callback={() => { setRefDom(false) }} text={'Your Telegram version is too low. Please update it and try again!'}></PromptDialog>

    </>

  )
}

export default App

