import { useEffect, useRef } from "react";
import usePlaySound from "@/hook/usePlaySound.tsx"
import WebApp from "@twa-dev/sdk";
import { useSelector } from "react-redux";
interface ClipPathBorderRadiusProps {
  borderRadius?: number
  borderWidth?: number
  background?: string
  width?: number
  height?: number
  n?: number
  buttonActive: number
  setButtonActive: Function
  text: string
  flag: number
  Refresh?: boolean
  getCardsData?: Function
  loading?: boolean,
  name?: string
}

const ClipPathBorderRadius = ({
  name,
  borderRadius = 25,
  borderWidth = 1,
  background = 'linear-gradient(to right, #00E49F 0%, #54ADFF 100%)',
  width = 200,
  height = 50,
  n = 10,
  Refresh = false,
  flag,
  text,
  buttonActive,
  getCardsData,
  setButtonActive,
  loading = false }: ClipPathBorderRadiusProps) => {
  const closePlaySound = usePlaySound('close')
  const clipPath = useRef<HTMLDivElement>(null);
  const quadrantFunc: any = {
    1: {
      getX: (radian: number, lr: number, sr: number) => {
        const swap = sr * Math.cos(Math.PI / 2 - radian) - lr;
        const x = `calc(100% + ${swap}px)`;
        return x;
      },
      getY: (radian: number, lr: number, sr: number) => {
        const y = lr - sr * Math.sin(Math.PI / 2 - radian);
        return `${y}px`;
      }
    },
    2: {
      getX: (radian: number, lr: number, sr: number) => {
        const x = lr - sr * Math.cos(radian);
        return `${x}px`;
      },
      getY: (radian: number, lr: number, sr: number) => {
        const y = lr - sr * Math.sin(radian);
        return `${y}px`;
      }
    },
    3: {
      getX: (radian: number, lr: number, sr: number) => {
        const x = lr - sr * Math.cos(Math.PI / 2 - radian);
        return `${x}px`;
      },
      getY: (radian: number, lr: number, sr: number) => {
        const swap = sr * Math.sin(Math.PI / 2 - radian) - lr;
        const y = `calc(100% + ${swap}px)`;
        return y;
      }
    },
    4: {
      getX: (radian: number, lr: number, sr: number) => {
        const swap = sr * Math.cos(radian) - lr;
        const x = `calc(100% + ${swap}px)`;
        return x;
      },
      getY: (radian: number, lr: number, sr: number) => {
        const swap = sr * Math.sin(radian) - lr;
        const y = `calc(100% + ${swap}px)`;
        return y;
      }
    },
  };
  const { settings } = useSelector((state: any) => state.settings)

  function start() {
    if (clipPath.current) {
      const node = clipPath.current;
      const cssClipPath = getClipPath();
      node.style.cssText = cssClipPath;
      node.style.borderRadius = borderRadius + 'px';
      node.style.width = width + 'px';
      node.style.height = height + 'px';
      node.style.background = buttonActive == flag ? background : '';
    }
  }

  function getClipPath() {
    const innerRadius = borderRadius - borderWidth;
    const step = Math.PI / 2 / n;
    const quadrant1 = getQuadrant(1, borderRadius, innerRadius, n, step);
    const quadrant2 = getQuadrant(2, borderRadius, innerRadius, n, step);
    const quadrant3 = getQuadrant(3, borderRadius, innerRadius, n, step);
    const quadrant4 = getQuadrant(4, borderRadius, innerRadius, n, step);
    const res =
      `clip-path: polygon(
      0 50%,
      0 100%, 
      100% 100%,
      100% 0, 
      0 0, 
      0 50%, 
      ${borderWidth}px 50%,
      ${quadrant2},
      ${quadrant1},
      ${quadrant4},
      ${quadrant3},
      ${borderWidth}px 50%,
      0 50%
    );`;
    return res;
  }

  function getQuadrant(type: number, outsideR: number, innerR: number, length: number, interval: number) {
    const getXY = quadrantFunc[type];
    const res = [];
    for (let i = 0; i <= length; i++) {
      const radian = i * interval;
      const x = getXY.getX(radian, outsideR, innerR);
      const y = getXY.getY(radian, outsideR, innerR);
      res.push(`${x} ${y}`);
    }
    const str = res.join(',');
    return str;
  }
  useEffect(() => {
    // if (buttonActive == flag) {
    // start()
    // }
  }
    , [buttonActive]);
  return (
    <div onClick={() => {
      if (Refresh) {
        getCardsData && getCardsData()
      }
      if (settings.vibration) {
        WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
      }
      closePlaySound()
      setButtonActive(flag)
    }} style={{ height: `${height}px` }} className={`${buttonActive == flag ? 'border border-[#00E49F] text-xs py-1' : ''} px-1 rounded-full text-center relative flex BorderRadius justify-center items-center left-0 z-[100]`}>
      <div ref={clipPath}
        className={`absolute cursor-pointer z-1`}>
      </div>
      <p className={`${buttonActive == flag ? 'text-style-1 text-[13px]' : 'text-[#A6A6A6] text-[14px]'} font-bold z-40 leading-[19px]`}>{name}  {name ? buttonActive == flag ? text : '' : text}</p>
      {Refresh &&
        <svg className={`ml-[6px] ${loading ? 'animate-spin' : ''}`} width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <linearGradient x1="-2.72351586e-14%" y1="-1.94289029e-13%" x2="100%" y2="100%" id="linearGradient-1">
              <stop stopColor={buttonActive == 2 ? '#00E49F' : '#A6A6A6'} offset="0%"></stop>
              <stop stopColor={buttonActive == 2 ? '#54ADFF' : '#A6A6A6'} offset="100%"></stop>
            </linearGradient>
          </defs>
          <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="10-cards-sale" transform="translate(-279.000000, -60.000000)" fill="url(#linearGradient-1)" fillRule="nonzero">
              <g id="编组" transform="translate(279.000000, 60.000000)">
                <path d="M11.7148409,2.30618042 C11.4911427,2.21958428 11.2443829,2.34576723 11.1659732,2.58576224 L10.8338852,3.58532915 C10.444143,2.64266829 9.8283965,1.8138195 9.04429979,1.19280144 C6.55133349,-0.786538945 2.99983662,-0.247168689 1.13184152,2.40019909 C0.227824132,3.68182199 -0.154999551,5.26034596 0.0571677919,6.84381828 C0.269335135,8.43471312 1.05573801,9.84251898 2.26647558,10.8049732 C3.28118897,11.6090803 4.46886487,12 5.6473161,12 C7.3700227,12 9.06966762,11.1711512 10.1789338,9.60004977 C10.6701474,8.90480646 11.0045416,8.12791535 11.1775041,7.28669569 C11.2282397,7.03680397 11.0806451,6.7918606 10.8500284,6.73742873 C10.6194117,6.68299687 10.388795,6.83886993 10.3380593,7.08876165 C10.1927708,7.79885002 9.90911231,8.4569807 9.4940023,9.04336029 C7.90966572,11.2874375 4.89550572,11.7451599 2.7784446,10.0627206 C1.75220038,9.24871686 1.08802434,8.05863844 0.908143328,6.71268697 C0.728262313,5.37415803 1.05112567,4.04057745 1.81677305,2.95688857 C3.39880347,0.712811404 6.41065731,0.252614756 8.53002459,1.93505409 C9.29797813,2.54370126 9.87221366,3.38739511 10.1835462,4.33995266 L8.70529326,3.72140878 C8.48390124,3.62986429 8.23483523,3.74615054 8.14720089,3.98367139 C8.05956655,4.22119224 8.17026256,4.4884032 8.39165457,4.58242186 L10.568676,5.49539262 C10.6194117,5.5176602 10.6724535,5.5275569 10.7254954,5.5275569 C10.7324139,5.5275569 10.7393324,5.52508272 10.7462509,5.52508272 C10.762394,5.5275569 10.7785372,5.53497942 10.7946804,5.53497942 C10.9722552,5.53497942 11.1382992,5.416219 11.2005657,5.22818165 L11.9754378,2.90245669 C12.0538474,2.65751334 11.9385391,2.39277656 11.7148409,2.30618042 Z" id="路径"></path>
              </g>
            </g>
          </g>
        </svg>}
    </div>
  )
}
export default ClipPathBorderRadius;