
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { OKXTonConnect, OKX_CONNECT_ERROR_CODES, Account, Wallet, OKX_TON_CONNECTION_AND_TRANSACTION_EVENT } from "okxconnect";

import { changeOKWalletAddress } from '../../store/module/tontransaction'
import { useDispatch } from 'react-redux';
const okxTonConnect = new OKXTonConnect({
    metaData: {
        "name": "StarAI",
        "icon": "https://sjc1.vultrobjects.com/events/tg/logo.png"
    }
});

export default function () {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [account, setAccount] = useState("connect")
    const [walletInfo, setWalletInfo] = useState<any>(0)

    useEffect(() => {

        okxTonConnect.onStatusChange((walletInfo) => {
            setWalletInfo(walletInfo)
        }, (err) => {
            console.log('Connection status:', err);
        }
        )
    })

    useEffect(() => {
        okxTonConnect.restoreConnection();
    }, [])

    useEffect(() => {
        if (walletInfo) {
            setAccount(walletInfo.account.address)
            const address = walletInfo.account.address.split(":")[1]
            dispatch(changeOKWalletAddress(address))
        } else {
            if (walletInfo !== 0) {
                dispatch(changeOKWalletAddress(""))
            }

            setAccount("connect")
        }
    }, [walletInfo])

    const connectHandle = async () => {
        try {
            await okxTonConnect.connect({
                tonProof: "",
                redirect: "tg://resolve",
                openUniversalLink: true
            })
        } catch (error: any) {
            if (error) {
                if (error.code === OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR) {
                    alert('User reject');
                } else if (error.code === OKX_CONNECT_ERROR_CODES.ALREADY_CONNECTED_ERROR) {
                    // alert('Already connected');
                    navigate("/tasks")
                } else {
                    alert('Unknown error happened');
                }
            } else {
                alert('Unknown error happened');
            }
        }

        // console.log(isPC())
    }

    const disConnectHandle = async () => {
        try {
            await okxTonConnect.disconnect()
        } catch (error: any) {
            if (error) {
                switch (error.code) {
                    case OKX_CONNECT_ERROR_CODES.NOT_CONNECTED_ERROR:
                        alert('Not connected');
                        break;
                    default:
                        alert('Unknown error happened');
                        break;
                }
            } else {
                alert('Unknown error happened');
            }
        }
    }

    return {
        connectHandle,
        disConnectHandle
    }
}
