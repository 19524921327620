import { CountDown, Dialog } from "react-vant"
import day from "@/assets/images/day.png"

function dateToTimestamp(date: string) {
    return new Date(date).getTime();
}


interface PropsType {
    date: any,
    endHandle?: (time: number) => void

}

const CountDownComp = ({ date, endHandle }: PropsType) => {
    return (
        <div className="w-full">
            <CountDown
                time={(dateToTimestamp(date)) - new Date().getTime()}
                millisecond
                format="DD:HH:mm:ss:SS"
                onFinish={() => { endHandle && endHandle((dateToTimestamp(date)) - new Date().getTime()) }}
            >
                {({ days, hours, minutes, seconds }) => (
                    <div className="grid grid-cols-7">
                        <div className="text-center text-white flex flex-col items-center justify-center">
                            <div className="time-item h-6 w-6 relative z-0 flex items-center justify-center">
                                <img src={day} alt="" className="w-full h-full absolute z-[-1]" />
                                <p className="text-[#333] text-sm mt-1">{days > 9 ? days : "0" + days}</p>
                            </div>
                            <p className="text-white  text-center text-xs mt-[2px]">DAY{days > 9 ? "S" : ""}</p>

                        </div>
                        <div className="text-white text-center opacity-0">:</div>
                        <div className="text-center text-white">
                            <div className="text-center text-white flex flex-col items-center justify-center">
                                <div className="time-item h-6 w-6  bg-gradient-to-br from-[#00E49F] to-[#54ADFF] rounded-md relative z-0 flex items-center justify-center">
                                    <span className="text-[#fff] text-sm ">{hours > 9 ? hours : "0" + hours}</span>
                                </div>
                                <p className="text-white text-xs mt-[2px]">HOUR{hours > 9 ? "S" : ""}</p>

                            </div>
                        </div>
                        <div className="text-white text-center">:</div>
                        <div className="text-center text-white">
                            <div className="text-center text-white flex flex-col items-center justify-center">
                                <div className="time-item h-6 w-6  bg-gradient-to-br from-[#00E49F] to-[#54ADFF] rounded-md relative z-0 flex items-center justify-center">
                                    <span className="text-[#fff] text-sm ">{minutes > 9 ? minutes : "0" + minutes}</span>
                                </div>
                                <p className="text-white text-xs mt-[2px]">MINUTE{minutes > 9 ? "S" : ""}</p>

                            </div>
                        </div>
                        <div className="text-white text-center">:</div>
                        <div className="text-center text-white">
                            <div className="text-center text-white flex flex-col items-center justify-center">
                                <div className="time-item h-6 w-6  bg-gradient-to-br from-[#00E49F] to-[#54ADFF] rounded-md relative z-0 flex items-center justify-center">
                                    <span className="text-[#fff] text-sm ">{seconds > 9 ? seconds : "0" + seconds}</span>
                                </div>
                                <p className="text-white text-xs mt-[2px]">SECOND{seconds > 9 ? "S" : ""}</p>

                            </div>
                        </div>
                    </div>
                )}
            </CountDown>
        </div>

    )
}


export default CountDownComp