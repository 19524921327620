

import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react"
import wallet from "../../hook/wallet/contentWallet"
import { starPayHandle } from "@/api/starpay.js"
import { getAcceleratorPayLink, postAcceleratorPaycheck } from "@/api/mining.js"

import { lotteryHandleApi } from "@/api/url.js"
import { getShopPayLink, getShopPayCheck } from "@/api/shop.js"
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
import {
    Address,
    beginCell,
    Cell,
    toNano,
} from "@ton/core"
import { Toast } from "react-vant"
import { calculateUsdtAmount, PAYTYPE, PAYHANDLETYPE } from "../../utils/common-helpers"
import WebApp from "@twa-dev/sdk"



const TonTransfer = () => {
    const [tonConnectUI] = useTonConnectUI()
    const address = useTonAddress()


    const transferHandle = async (id: string, amount: any, payload: any, type?: PAYHANDLETYPE, callback?: any, is_paid?: any) => {
        if (is_paid) {
            const toast = Toast.loading({ message: "The transaction is being confirmed.Please wait for 1 minute.", duration: 0, forbidClick: true, className: 'custom-toast' });
            let res;
            if (type == PAYHANDLETYPE.SHOP) {
                res = await getShopPayCheck({ order_id: id, transaction_id: '' })
            } else {
                res = await lotteryHandleApi({ order_id: id, transaction_id: '' })
            }
            toast.clear()
            return res
        }
        const transaction = {
            messages: [
                {
                    address: import.meta.env.VITE_INVOICE_WALLET_ADDRESS, // 目的地址
                    amount: toNano(amount).toString(), //以nanotons计的Toncoin
                    payload: payload.toBoc().toString("base64")
                }
            ],
            validUntil: Date.now() + 1 * 60 * 1000, // 5 minutes for user to approve

        }
        return await tonConnectUI.sendTransaction(transaction).then(async (res) => {

            const boc = res?.boc
            console.log("🚀 ~ returnawaittonConnectUI.sendTransaction ~ boc:", boc)
            const hash = Cell.fromBase64(boc)
                .hash()
                .toString("base64");
            console.log("🚀 ~ returnawaittonConnectUI.sendTransaction ~ hash:", hash)
            if (callback) {
                callback({ order_id: id, transaction_id: boc })
                return false
            } else {
                const toast = Toast.loading({ message: "The transaction is being confirmed.Please wait for 1 minute.", duration: 0, forbidClick: true, className: 'custom-toast' });

                let res;
                if (type == PAYHANDLETYPE.SHOP) {
                    res = await getShopPayCheck({ order_id: id, transaction_id: boc })
                } else {
                    res = await lotteryHandleApi({ order_id: id, transaction_id: boc })
                }

                toast.clear()
                return res
            }
        }).catch((e) => {
            Toast.fail({
                message: 'Payment failed! The TON wallet connection is abnormal, please try again later.',
                forbidClick: true,
                className: 'custom-toast'
            })
        })
    }

    const miningTransferHandle = async (id: string, amount: any, payload: any, type?: PAYHANDLETYPE, callback?: any, config_id?: any, is_paid?: any) => {
        if (is_paid) {
            const toast = Toast.loading({ message: "The transaction is being confirmed.Please wait for 1 minute.", duration: 0, forbidClick: true, className: 'custom-toast' });
            let res = await postAcceleratorPaycheck({ order_id: id, transaction_id: '', config_id: config_id })
            toast.clear()
            return res
        }
        const transaction = {
            messages: [
                {
                    address: import.meta.env.VITE_INVOICE_WALLET_ADDRESS, // 目的地址
                    amount: toNano(amount).toString(), //以nanotons计的Toncoin
                    payload: payload.toBoc().toString("base64")
                }
            ],
            validUntil: Date.now() + 1 * 60 * 1000, // 5 minutes for user to approve

        }

        return await tonConnectUI.sendTransaction(transaction).then(async (res) => {
            const boc = res?.boc
            if (callback) {
                callback({ order_id: id, transaction_id: boc })
                return false
            } else {
                const toast = Toast.loading({ message: "The transaction is being confirmed.Please wait for 1 minute.", duration: 0, forbidClick: true, className: 'custom-toast' });
                let res = await postAcceleratorPaycheck({ order_id: id, transaction_id: boc, config_id: config_id })
                toast.clear()
                return res
            }


        }).catch((e) => {
            Toast.fail({
                message: 'Payment failed! The TON wallet connection is abnormal, please try again later.',
                forbidClick: true,
                className: 'custom-toast'
            })
        })
    }

    //任务抽奖ton支付

    const transformHandle = async (amount: string | number) => {
        const params = {
            title: "",
            description: "",
            total: toNano(amount),
            type: PAYTYPE.ton,
            relevance: Address.parse(address)
        }

        const toast = Toast.loading({ message: "Loading...", duration: 0, forbidClick: true, });
        const res = await starPayHandle(params)
        toast.clear()
        try {
            if (res.code == '0') {
                const body = beginCell()
                    .storeUint(0, 32) // 写入32个零位以表示后面将跟随文本评论
                    .storeStringTail(JSON.stringify(
                        {
                            "type": "Task Lottery", "orderID": res.order_id
                        }
                    )) // 写下我们的文本评论
                    .endCell();
                const data = await transferHandle(res.order_id, amount, body, PAYHANDLETYPE.LOTTERY, '', res.is_paid)
                return data
            }
        } catch (e) {
            toast.clear()
            return null
        }

    }

    //挖矿加速包ton支付

    const miningTransformHandle = async (amount: string | number, id: string | number) => {
        const toast = Toast.loading({ message: "Loading...", duration: 0, forbidClick: true, });
        try {
            const params = {
                title: "",
                description: "",
                total: toNano(amount),
                type: PAYTYPE.ton,
                relevance: Address.parse(address),
                id: id
            }

            const { code, data } = await getAcceleratorPayLink(params)
            toast.clear()
            if (code == "0") {
                const body = beginCell()
                    .storeUint(0, 32) // 写入32个零位以表示后面将跟随文本评论
                    .storeStringTail(JSON.stringify(
                        {
                            "type": "Mining Pool Acceleration", "orderID": data.order_id
                        }
                    )) // 写下我们的文本评论
                    .endCell();
                const res = await miningTransferHandle(data.order_id, amount, body, PAYHANDLETYPE.SHOP, '', id, data.is_paid)
                return res
            }
        } catch {
            toast.clear()
            return null
        }


    }

    //商城ton支付

    const shopTransformHandle = async (amount: string | number, id: string | number, message: string) => {
        let toast: any = null
        try {

            const params = {
                title: "",
                description: "",
                total: toNano(amount),
                type: PAYTYPE.ton,
                relevance: Address.parse(address),
                id: id
            }

            toast = Toast.loading({ message: "Loading...", duration: 0, forbidClick: true, });
            const { code, data } = await getShopPayLink(params)
            toast.clear()
            if (code == "0") {
                const body = beginCell()
                    .storeUint(0, 32) // 写入32个零位以表示后面将跟随文本评论
                    .storeStringTail(JSON.stringify(
                        {
                            "type": message, "orderID": data.order_id
                        }
                    )) // 写下我们的文本评论
                    .endCell();
                const res = await transferHandle(data.order_id, amount, body, PAYHANDLETYPE.SHOP, '', data.is_paid)
                return res
            } else {
                return data
            }

        } catch (e) {
            toast.clear()
            return null
        }


    }


    //pay for star
    const shopStarPayHandle = async (item: any) => {
        const toast = Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            duration: 0
        });
        try {
            const params = {
                title: item.title,
                description: item.description,
                total: item.price,
                type: PAYTYPE.star,
                relevance: "",
                id: item.id,
            }
            const { code, data: res } = await getShopPayLink(params)
            toast.clear()
            if (code == '0' && res?.link) {
                WebApp.openInvoice(res.link, async (status) => {
                    if (status === "paid") {
                        return await loopOrder(res.order_id)
                    }
                })
            }
            if (res && !res.link) {
                return await loopOrder(res.order_id)
            }
        } catch (e) {
            toast.clear()
        }


    }
    //pay for star
    const miningShopStarPayHandle = async (item: any) => {
        const toast = Toast.loading({
            message: 'Loading...',
            forbidClick: true,
            duration: 0
        });
        try {

            const params = {
                title: item.title,
                description: item.description,
                total: item.price,
                type: PAYTYPE.star,
                relevance: "",
                id: item.id,
            }
            const { code, data: res } = await getAcceleratorPayLink(params)
            toast.clear()
            if (code == '0' && res?.link) {
                WebApp.openInvoice(res.link, async (status) => {
                    if (status === "paid") {
                        return await miningLoopOrder(res.order_id, item.id)
                    }
                })
            }
            if (res && !res.link) {
                return await miningLoopOrder(res.order_id, item.id)
            }
        } catch (e) {
            toast.clear()
        }


    }

    const loopOrder = async (order_id: string) => {
        let timeCount = 0;
        let code_ = null;
        let data_ = null;

        while (code_ != "0" && timeCount < 5) {
            await sleep(2000)
            const res = await getShopPayCheck({ order_id, transaction_id: "" })
            if (res.code == "0") {
                code_ = res.code
                data_ = res.data
            }
            timeCount++
        }
        return data_
    }

    const miningLoopOrder = async (order_id: string, id: any) => {
        let timeCount = 0;
        let code_ = null;
        let data_ = null;

        while (code_ != "0" && timeCount < 5) {
            await sleep(2000)
            const res = await postAcceleratorPaycheck({ order_id, transaction_id: "", config_id: id })
            if (res.code == "0") {
                code_ = res.code
                data_ = res
            }
            timeCount++
        }
        return data_
    }


    return {
        transformHandle,
        transferHandle,
        shopTransformHandle,
        shopStarPayHandle,
        miningTransformHandle,
        miningShopStarPayHandle

    }
}


export default TonTransfer;
