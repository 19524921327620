import request from '@/utils/axios-req'
import { data } from 'autoprefixer'
import axios from "axios"

// mini app 登录
export function login(data) {
  return request({
    url: '/api/v1/miniapp/account',
    method: 'post',
    data,
  })
}

// mini app 获取签到信息
export function getCheck_in(addr) {
  return request({
    url: '/api/v1/miniapp/task/check_in',
    method: 'get',
    data: {
      addr
    }
  })
}

// mini app 签到
export function postCheck_in(data) {
  return request({
    url: '/api/v1/miniapp/task/check_in',
    method: 'post',
    data
  })
}

// mini app 获取任务列表
export function getTask(state, type) {
  return request({
    url: '/api/v1/miniapp/task?state=' + state + '&type=' + type,
    method: 'get',
  })
}

// mini app 获取卡片列表
export function getCards(id, page, limit, level = "", name = "", type = 0, enhance_level = '-1', property = '', sort_type = 0, mining = false) {
  return request({
    url: `/api/v1/miniapp/account/${id}/cards?page=${page}&limit=${limit}&level=${level}&name=${name}&type=${type}&enhance_level=${enhance_level}&property=${property}&sort_type=${sort_type}&mining=${mining}`,
    method: 'get',
  })
}

// mini app 获取卡片列表
export function getBattleCards(data) {
  return request({
    url: `/api/v1/miniapp/battle/property_cards?`,
    method: 'get',
    data: {
      page: data.page,
      limit: data.limit,
      level: data.level,
      property: data.property,
      type: data.type ? data.type : 0
    }
  })
}

// mini app 获取贡献者列表
export function getRewards(page, limit) {
  return request({
    url: `/api/v1/miniapp/rewards?page=${page}&limit=${limit}`,
    method: 'get',
  })
}

// mini app 获取贡献者积分统计
export function rewards_count() {
  return request({
    url: `/api/v1/miniapp/rewards_count`,
    method: 'get',
  })
}


//完成任务
export function startTask(data) {
  return request({
    url: `/api/v1/miniapp/task/claim_points`,
    method: "get"
  })
}
// mini app 获取 邀请码
export function getInvitation_code() {
  return request({
    url: `/api/v1/miniapp/invitation_code`,
    method: 'get',
  })
}

// mini app 获取角色对话
export function getCharacter(id) {
  return request({
    url: `/api/v1/miniapp/character/${id}/chat`,
    method: 'post',
  })
}

// mini app 获取用户信息
export function getUserInfo(address) {
  const ID = window.localStorage.getItem("userId") || "";
  return request({
    url: `/api/v1/miniapp/account/${ID}?addr=${address}`,
    method: 'get',
  })
}

// 卡片举报
export function reportCard(data) {
  return request({
    url: `/api/v1/miniapp/cards/report`,
    method: 'post',
    data: {
      id: data.id,
      type: data.type
    }
  })
}

// mini app 获取奖励
export function getClaimPoints(data) {
  return request({
    url: `/api/v1/miniapp/task/claim_points`,
    method: 'post',
    data
  })
}

//获取奖励
export const getReward = (data) => {
  return request({
    url: `/api/v1/miniapp/task/claim_points`,
    method: 'post',
    data
  })
}
//获取聊天背景
export const getCharBackground = (id) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/background`,
    method: 'get',
  })
}

//获取官方卡牌
export const getSaleCards = (page, limit, name = "", level = -1, price = [], sort_type = 0, property = '', card_type = 0) => {
  let priceStr = ""
  if (price[0] !== 0 && price[1] !== 0) {
    switch (price.length) {
      case 1:
        priceStr = `&price=${price[0]}`
        break;
      case 2:
        priceStr = `&price=${price[0]}&price=${price[1]}`
        break;
      default:
        break;
    }
  } else if (price[0] === 0 && price[1] !== 0) {
    priceStr = `&price=${0}&price=${price[1]}`
  }

  return request({

    url: `/api/v1/miniapp/cards?page=${page}&limit=${limit}&name=${name}&level=${level}${priceStr}&sort_type=${sort_type}&property=${property}&card_type=${card_type}`,
    method: 'get',
  })
}

//获取官方卡牌
export const getCardSaleCards = (page, limit, sort_type = 0, card_type = 0, level = -1) => {
  return request({

    url: `/api/v1/miniapp/cards?page=${page}&limit=${limit}&sort_type=${sort_type}&card_type=${card_type}&level=${level}`,
    method: 'get',
  })
}
//购买官方卡牌
export const postBuy = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/buy`,
    method: 'post',
    data: {
      id: id,

    }
  })
}

//出售卡牌
export const postSell = (id, price) => {
  return request({
    url: `/api/v1/miniapp/cards/sell`,
    method: 'post',
    data: {
      id: id,
      price: price
    }
  })
}

//出售卡牌下架
export const postRetract = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/retract`,
    method: 'post',
    data: {
      id: id,
    }
  })
}

//查询卡片状态
export const checkCardStatus = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/${id}`,
    method: 'get',
  })
}


export const chainCheckIn = (data) => {
  return request({
    url: '/api/v1/miniapp/task/chain_check_in_callback',
    method: 'post',
    data
  })
}


//获取数字人（角色）列表
export const getCharactersList = ({ type, page, limit, query }) => {
  return request({
    url: `/api/v1/miniapp/character?page=${page}&limit=${limit}&query=${query}&type=${type}`,
    method: "get"
  })
}

export const getCharacterInfo = (id) => {
  return request({
    url: `/api/v1/miniapp/character/${id}?index=${true}`,
    method: "get"
  })
}

//点赞
export const postLike = (id, flag) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/like`,
    method: 'post',
    data: { flag },
  })
}
//获取排行榜
export const getRanks = (type) => {
  return request({
    url: `/api/v1/miniapp/ranks?type=${type}`,
    method: 'get',
  })
}

//获取背景图片
export const getBackground = (id, page, limit) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/background?page=${page}&limit=${limit}`,
    method: 'get',
  })
}

// 设置背景图片  
export const setBackground = (id, data) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/background`,
    method: 'post',
    data: { id: data },
  })
}

//获取聊天动作剩余次数
export const getActionCount = (id) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/action`,
    method: 'get',
  })
}
//获取聊天动作
export const getActionList = (id) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/action`,
    method: 'post',
  })
}


// 获取活动文字
export const setAnnouncement = () => {
  return request({
    url: `/api/v1/miniapp/announcement`,
    method: 'get',
  })
}


//获取表情
export const getEmoji = async () => {
  try {
    const { status, data } = await axios(import.meta.env.VITE_EMOJI_URL)
    window.localStorage.setItem("emojiList", JSON.stringify(data))
  } catch (e) {
    console.log("Error,emoji get reject")
  }
}

export const getCardDetail = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/${id}`,
    method: 'get',
  })
}

// 卡片炼化提示
export const getRefiningTips = (flag) => {
  return request({
    url: `/api/v1/miniapp/merge/tips`,
    method: 'get',
    data: { flag: flag }
  })
}
// 卡片点赞
export const getThumbup = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/${id}/thumbup`,
    method: 'post',
    data: {
      flag: true
    }
  })
}


export const getAnnouncement = () => {
  return request({
    url: `/api/v1/miniapp/announcement`,
    method: 'get',
  })

}


//nft mint
export const postMintNft = (data) => {
  return request({
    url: `/api/v1/miniapp/mint`,
    method: 'post',
    data,
  })
}

//card refining merge
export const cardMerge = (data) => {
  return request({
    url: `/api/v1/miniapp/merge`,
    method: 'post',
    data,
  })
}

//card pay with star details
export const postPayWithStarDetails = (data) => {
  return request({
    url: `/api/v1/miniapp/star_merge`,
    method: 'post',
    data,
  })
}

//lottery
export const lotteryHandleApi = (data, url) => {
  console.log(data)
  let path = ''
  if (url) {
    path = url
  } else {
    path = '/api/v1/miniapp/pay/lottery'
  }
  return request({
    url: path,
    method: 'post',
    data: data
  })
}

//lottery count
export const lotteryCount = () => {
  return request({
    url: `/api/v1/miniapp/pay/lottery_count`,
    method: 'get'
  })
}

//get star Invoice  link
export const getStarInvoiceLink = (params) => {
  const { title, description, total, type, relevance = "" } = params
  return request({
    url: `/api/v1/miniapp/pay/lottery_link?title=${title}&description=${description}&total=${total}&type=${type}&relevance=${relevance}`,
    method: 'get'
  })
}

export const getSetting = (id) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/setting`,
    method: 'get'
  })
}
export const postSetting = (id, data) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/setting`,
    method: 'post',
    data,
  })
}

//regenerate card pay link
export const regenerateCardPayLink = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/regenerate/link`,
    method: 'get'
  })
}


//regenerate card
export const regenerateCard = (data) => {
  return request({
    url: `/api/v1/miniapp/cards/regenerate`,
    method: 'post',
    data
  })
}

// 获取活动奖品
export const getPddInfo = () => {
  return request({
    url: `/api/v1/miniapp/activity/pdd/info`,
    method: 'get',
  })
}

// 获取个人活动信息
export const getUserPddInfo = () => {
  const ID = window.localStorage.getItem("userId") || "";
  return request({
    url: `/api/v1/miniapp/activity/pdd/${ID}/info`,
    method: 'get',
  })
}

// 获取获取活动数据信息
export const getActivity_list = () => {
  const ID = window.localStorage.getItem("userId") || "";
  return request({
    url: `/api/v1/miniapp/activity/pdd/${ID}/activity_list`,
    method: 'get',
  })
}

// 获取卡牌抽奖 数据信息
export const getCardLotteryList = () => {
  return request({
    url: `/api/v1/miniapp/activity/card_lottery/info`,
    method: 'get',
  })
}

// 获取pdd抽奖
export const getPddLottery = () => {
  return request({
    url: `/api/v1/miniapp/activity/pdd/lottery`,
    method: 'post',
  })
}


export const getCardLottery = (data) => {
  return request({
    url: `/api/v1/miniapp/activity/card_lottery/lottery`,
    method: 'post',
    data
  })
}


export const getCardLotteryLink = (data) => {
  return request({
    url: `/api/v1/miniapp/activity/card_lottery/link`,
    method: 'get',
    data,
  })
}

// 获取提现数据
export const getWithdrawInfo = () => {
  return request({
    url: `/api/v1/miniapp/activity/pdd/withdraw`,
    method: 'get',
  })
}

// 获取提现数据
export const postWithdraw = (data) => {
  return request({
    url: `/api/v1/miniapp/activity/pdd/withdraw`,
    method: 'post',
    data
  })
}

// 获取活动抽奖记录
export const cardLotteryHistory = (data) => {
  return request({
    url: `/api/v1/miniapp/activity/card_lottery/history`,
    method: 'get',
  })
}

export const createAvatars = (data) => {

  return request({
    url: `/api/v1/miniapp/character`,
    method: 'post',
    data
  })
}


export const getVoice = () => {
  return request({
    url: `/api/v1/miniapp/character/voice`,
    method: 'get',
  })
}

export const getSearchHistory = () => {
  return request({
    url: `/api/v1/miniapp/character/history`,
    method: 'get',
  })
}

export const deleteSearchHistory = () => {
  return request({
    url: `/api/v1/miniapp/character/history`,
    method: 'delete',
  })
}
export const reportCreateAvatar = (data) => {
  return request({
    url: `api/v1/miniapp/character/report`,
    method: 'post',
    data
  })
}

export const deleteAvatarRequest = (id) => {
  return request({
    url: `api/v1/miniapp/character/${id}`,
    method: 'delete',
  })
}

export const getAvatarDetails = (id) => {
  return request({
    url: `api/v1/miniapp/character/${id}`,
    method: 'get',
  })
}

export const getAvatarImage = (id) => {
  return request({
    url: `api/v1/miniapp/character/${id}/gen_image`,
    method: 'post',
  })
}

export const putAvatarDetails = (id, data) => {
  return request({
    url: `api/v1/miniapp/character/${id}`,
    method: 'put',
    data
  })
}


export const getAvatarCommercial = (id) => {
  return request({
    url: `api/v1/miniapp/character/${id}/commercial`,
    method: 'get',
  })
}

export const postAvatarCommercial = (id) => {
  return request({
    url: `api/v1/miniapp/character/${id}/commercial`,
    method: 'post',
  })
}


export const checkAvatar = () => {
  return request({
    url: "/api/v1/miniapp/character/check",
    method: 'get',
  })
}

//get language list
export const getLanguageList = () => {
  return request({
    url: "/api/v1/miniapp/character/language",
    method: 'get',
  })
}

//get current avatar language
export const getCurrentAvatarLanguage = (id) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/language`,
    method: 'get',
  })
}

//get current avatar language
export const putCurrentAvatarLanguage = (id, language) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/language`,
    method: 'put',
    data: {
      language
    }
  })
}

//get logs history
export const getHistoryList = (params) => {
  const { id, page = 1, limit = 30 } = params
  return request({
    url: `/api/v1/miniapp/character/${id}/history?page=${page}&limit=${limit}`,
    method: 'get',
  })
}


//logs share
export const logsShareApi = (data) => {
  return request({
    url: `/api/v1/miniapp/logs`,
    method: 'post',
    data
  })
}

//logs list
export const logsListApi = (params) => {
  const { page = 1, limit = 30, query } = params
  return request({
    url: `/api/v1/miniapp/logs?page=${page}&limit=${limit}&query_str=${query}`,
    method: 'get',
  })
}
//logs details
export const logsDetailsApi = (id) => {
  return request({
    url: `/api/v1/miniapp/logs/${id}`,
    method: 'get',
  })
}

export const loadLoadApi = (id) => {
  return request({
    url: `/api/v1/miniapp/logs/${id}`,
    method: 'post',
  })
}


export const paySaveLogs = (id, order_id, is_open) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/history/pay`,
    method: 'post',
    data: {
      order_id: order_id,
      is_open: is_open
    }
  })
}

export const getSaveLogsLink = (id, title, description, total) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/history/link`,
    method: 'get',
    data: {
      title: title,
      description: description,
      total: total
    }
  })
}

// 数字人聊天记录支付状态查询
export const searchSaveLogs = (id) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/history/pay`,
    method: 'get',
  })
}

export const postLogsLike = (id) => {
  return request({
    url: `/api/v1/miniapp/logs/${id}/like`,
    method: 'post',
  })
}

//获取用户全局设置链接
export const getUserGlobalSettingLink = (id, params) => {
  const { type, relevance } = params
  return request({
    url: `/api/v1/miniapp/account/${id}/nsfw_setting?type=${type}&relevance=${relevance}`,
  })
}
export const getEnhanceTips = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/${id}/enhance/tips`,
    method: 'get',
  })
}

//修改全局配置
export const postUserGlobalSettingLink = (id, params) => {
  return request({
    url: `/api/v1/miniapp/account/${id}/nsfw_setting`,
    method: 'post',
    data: params
  })
}
export const postCardEnhance = (id, data) => {
  return request({
    url: `/api/v1/miniapp/cards/${id}/enhance`,
    method: 'post',
    data: data
  })
}

export const getAvatarTags = () => {
  return request({
    url: `/api/v1/miniapp/character/tags`,
    method: 'get',
  })
}

export const loginRewards = () => {
  const ID = window.localStorage.getItem("userId") || "";
  return request({
    url: `/api/v1/miniapp/task/login_rewards`,
    method: 'get',
    data: {
      accountID: ID
    }
  })
}

export const loginCheckIn = () => {
  const ID = window.localStorage.getItem("userId") || "";
  return request({
    url: `/api/v1/miniapp/task/login_rewards`,
    method: 'post',
    data: {
      accountID: ID
    }
  })
}


export const getIntroductory = (flag) => {
  return request({
    url: `/api/v1/miniapp/battle/tips?is_ok=${flag}`,
    method: 'get',

  })
}

export const removeChatLogs = (id) => {
  return request({
    url: `/api/v1/miniapp/character/${id}/del_all_msg`,
    method: 'post',

  })
}

//更新卡牌下载次数


export const updateDownLoadCount = (id) => {
  return request({
    url: `/api/v1/miniapp/cards/${id}/download`,
    method: 'get',

  })
}
// 查询最近一百条抽奖记录usdt
export const getPddMarquee = () => {
  return request({
    url: `/api/v1/miniapp/activity/pdd/marquee`,
    method: 'get',

  })
}

export const getTaskBannerMsg = () => {
  return request({
    url: `/api/v1/miniapp/task/bannermsg`,
    method: 'get',

  })
}


