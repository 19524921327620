
import { useCallback, useEffect, useRef, useState } from 'react'

import ButtonComp from '../../components/common/ButtonComp.js';
import tonImg from "../../assets/images/shop/ton.png"
import usdtImg from "../../assets/images/shop/usdt.png"
import starImg from "../../assets/images/shop/star.png"
import PayDialog from '../../components/common/PayDialog.js';
import { calculateUsdtAmountSmall } from '../../utils/common-helpers.js';


interface PropType {
    info: any[]
    title: string
}
const BuyComp = ({ info, title }: PropType) => {
    const [showPayDialog, setShowPayDialog] = useState(false)
    const [payTypeList, setPayTypeList] = useState<any>([])
    return (

        <>
            <div className='flex-1 px-4'>
                <ButtonComp title={`$ ${calculateUsdtAmountSmall(info.filter((item: any) => item.pay_type == 4)[0]?.price / 100)} Buy`} width={100} callBack={() => { setPayTypeList(info), setShowPayDialog(true) }}></ButtonComp>
                <div className='flex mt-3 justify-around px-4'>
                    <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={starImg} alt="" />
                    <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={usdtImg} alt="" />
                    <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={tonImg} alt="" />
                </div>
            </div>

            {
                showPayDialog && <PayDialog dataList={payTypeList} title={`${payTypeList[0].count} ${title}`} callBack={() => {
                    setShowPayDialog(false)
                }} />
            }

        </>


    )
}


export default BuyComp;