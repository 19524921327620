
import dayjs from 'dayjs';
import loading from '@/assets/images/cards/loading.png'
import { List, Loading, Popup } from 'react-vant'
import { useNavigate, useSearchParams } from 'react-router-dom'
import coinImg from "../../assets/images/coin.png"
import { getAccountLogsType, getRecordsLogsType } from "@/api/account.js"
import Back from '../../components/common/BackComp.js';
import ButtonComp from '../../components/common/NewButtonComp';
import { useEffect, useRef, useState } from 'react'
import { FormattingTwoNumber } from '../../utils/index.js'


const tabList = [{
    id: 1,
    name: "Acquire"
}, {
    id: -1,
    name: "Consumption"
}]

const timeArray: any = [];
for (let i = 6; i >= 0; i--) {
    const pastDate = dayjs().subtract(i, 'day').format('YYYYMMDD');
    let nameDate = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
    if (i == 0) {
        nameDate = "Today"
    }
    timeArray.unshift(
        {
            code: pastDate,
            name: nameDate
        }
    );
    timeArray.unshift();
}
timeArray.unshift(
    {
        code: 'ALL',
        name: 'ALL',
    }
);


const Battle = () => {
    const [searchParams] = useSearchParams()
    const id = localStorage.getItem('userId')
    const [isShowPopup, setShowPopup] = useState(false)
    const [timeActive, setTimeActive] = useState(timeArray[0].code)
    const [typeActive, setTypeActive] = useState(['ALL'])
    const [isShowOther, setShowOther] = useState(false)
    const [isShowMenuList, setShowMenuList] = useState(false)
    const [isShowCreateArena, setCreateArena] = useState(false)
    const [tabActive, setTabActive] = useState(1)
    const [dataList, setDataList] = useState<any>([])
    const currPage = useRef(1)
    const total = useRef(0);
    const pageSize = 20
    const [finished, setFinished] = useState(false);
    const isInitLoading = useRef(true)
    const [isLoading, setIsLoading] = useState(false)
    const type: any = searchParams.get("type") as string;
    const clickHandle = (e: Event) => {
        e.stopPropagation();
        if (isShowMenuList) {
            setShowMenuList(false)
        }
    }

    useEffect(() => {
        currPage.current = 1
        setDataList([])
        if (!isShowCreateArena) {
            getBattleRecordsHandle()
        }
    }, [isShowCreateArena])

    useEffect(() => {
        document.addEventListener("click", (e) => { clickHandle(e) }, false)
        return () => {
            document.removeEventListener("click", (e) => { clickHandle(e) }, false)
        }
    }, [isShowMenuList])
    const navigate = useNavigate();
    const boxRef = useRef<any>(null)


    const onLoad = async () => {
        if (finished || isInitLoading.current) return;
        currPage.current++
        await getBattleRecordsHandle();

    }


    // //获取记录
    const getBattleRecordsHandle = async (val: any = 0) => {
        setIsLoading(true)
        try {
            const params = {
                limit: pageSize,
                page: currPage.current,
                sales_type: val ? val : tabActive,
                query_time: timeActive,
                code: typeActive,
                type: type
            }
            const { code, data } = await getRecordsLogsType(id, params)
            if (code == "0") {
                setIsLoading(false)
                total.current = data.Count
                isInitLoading.current = false
                setFinished(false)
                if (!data?.Items.length) {
                    setShowOther(true)
                } else {
                    setShowOther(false)
                }
                setDataList((prevData: any) => [...prevData, ...data.Items]);
            } else {
                setShowOther(true)
                setFinished(true)
                total.current = 0
            }
        } catch (e) {
            setShowOther(true)
            setFinished(true)
            setIsLoading(false)
            total.current = 0
        }

        if (currPage.current * pageSize >= total.current) {
            setFinished(true)
            return false;
        }
    }

    const [typeArray, setTypeArray] = useState([])

    const logsTypeArray = async () => {
        const { data, code } = await getAccountLogsType(id, tabActive, type)
        if (code == '0') {
            setTypeArray(data)
        }
    }

    useEffect(() => {
        logsTypeArray()
    }, [])


    return (
        <div ref={boxRef} className="descriptionLogs w-full h-full text-white pt-12 px-4 box-border relative pb-[30px] overflow-x-hidden overflow-y-scroll">
            <div className="h-11 flex items-center justify-center fixed top-0 w-[calc(100%_-_32px)] z-[11] box-border bg-[#050A0A]">
                <Back isLeft={false} callback={() => {
                    isShowPopup ? setShowPopup(false) : navigate(-1)
                }}>
                    <div className='w-[90%] h-10 text-white flex justify-around items-center'>
                        {type == "0" ? '$STAR Records' : '$SAI Records'}
                    </div>
                    {type != 1 && <div className="w-[10%] flex justify-end">
                        <div className={`search`} onClick={() => { setShowPopup(!isShowPopup) }}>
                            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <title>编组</title>
                                <g id="页面-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="挖矿搜索备份" transform="translate(-341.000000, -56.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g id="编组" transform="translate(341.000000, 56.000000)">
                                            <path d="M13.4715779,0 C13.7286437,0 13.9785502,0.0918376784 14.1826388,0.261341432 C14.6948262,0.68677585 14.7917133,1.48155167 14.3990045,2.03644506 L9.50997291,8.9447421 L9.50997291,17.0375799 C9.50997314,17.187256 9.47774998,17.3348651 9.41586907,17.4686544 L9.40530474,17.4908184 C9.17423025,17.9595894 8.63618059,18.1367032 8.20346953,17.8863777 L6.03223476,16.6302776 C5.4995079,16.3220745 5.16681264,15.7209804 5.16681264,15.0666795 L5.16681264,8.97173108 L0.242431151,2.03816354 C0.0901319512,1.82375228 0.0052405765,1.56203282 0.000223476298,1.29143911 L0,1.26610252 C0,0.566856661 0.523218962,0 1.16861851,0 L13.4715779,0 Z M17.392307,15.2944224 C17.7279278,15.2944224 18,15.5892081 18,15.9527992 C18,16.3112128 17.7356479,16.6027379 17.4066501,16.6109778 L17.392307,16.611154 L11.5957991,16.611154 C11.2601783,16.611154 10.9881264,16.3164123 10.9881264,15.9527992 C10.9881264,15.5943856 11.2524786,15.3028606 11.581456,15.2945986 L11.5957991,15.2944224 L17.392307,15.2944224 L17.392307,15.2944224 Z M17.392307,11.7493706 C17.7279278,11.7493706 18,12.0441122 18,12.4077254 C18,12.766139 17.7356479,13.057664 17.4066501,13.065926 L17.392307,13.0661022 L11.5957991,13.0661022 C11.2601783,13.0661022 10.9881264,12.7713165 10.9881264,12.4077254 C10.9881264,12.0493118 11.2524786,11.7577867 11.581456,11.7495468 L11.5957991,11.7493706 L17.392307,11.7493706 L17.392307,11.7493706 Z M17.392307,8.20429674 C17.7279278,8.20429674 18,8.49906044 18,8.86267357 C18,9.2210872 17.7356479,9.51261222 17.4066501,9.52085212 L17.392307,9.52102838 L11.5957991,9.52102838 C11.2601783,9.52102838 10.9881264,9.22626468 10.9881264,8.86267357 C10.9881264,8.50425995 11.2524786,8.21273493 11.581456,8.20447299 L11.5957991,8.20429674 L17.392307,8.20429674 Z" id="形状"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>}
                </Back>
            </div >
            <div className="tab h-9 mb-3 w-full bg-[#333] rounded-lg overflow-hidden grid grid-cols-2 gap-0">
                {
                    tabList.map((item: any) => {
                        return (
                            <div onClick={() => {
                                if (!isLoading) {
                                    currPage.current = 1
                                    setTabActive(item.id)
                                    setDataList([])
                                    setTypeActive(['ALL'])
                                    getBattleRecordsHandle(item.id)
                                    logsTypeArray()
                                }
                            }} className={`${item.id == tabActive ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF]' : ''}  w-full h-full  rounded-lg text-white text-sm text-center flex justify-center items-center`}>{item.name}</div>

                        )
                    })
                }

            </div>

            {
                dataList.length > 0 ?
                    <List className='list w-full grid grid-cols-1 gap-0' finished={finished} loading={isLoading} immediateCheck={false} onLoad={onLoad} loadingText="Loading...">
                        {dataList.map((el: any) =>
                            <div key={el.CreatedAt} className={`w-full flex justify-between h-[52px] px-4 ${tabActive == 1 ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF]' : 'bg-[#333333]'} mb-3 rounded-lg items-center`}>
                                <div className={` w-[25%] flex-shrink-0 ${tabActive == 1 ? 'text-black' : 'text-white'}  text-sm`}>{dayjs(el.CreatedAt).format('YYYY-MM-DD')}</div>
                                <div className={`px-1 w-fit max-w-[50%] ellipsis flex justify-center ${tabActive == 1 ? 'text-white' : 'text-style-8'} !text-[18px] !font-bold`} style={{ fontStyle: 'italic' }} >{el.Msg}</div>
                                <div className='  w-[25%] flex-shrink-0  text-style-level flex font-bold items-center justify-end !text-sm'><img className='h-4 mr-1' src={coinImg} alt="" /> {el.Points < 0 ? `${el.Points.toString().charAt(0)}${FormattingTwoNumber(el.Points.toString().slice(1))}` : `+${FormattingTwoNumber(el.Points)}`}</div>
                            </div>)}

                    </List> :
                    <>{!isLoading ?
                        <div className=' flex flex-col items-center h-full justify-center'>
                            <img className='w-[185px]' src={loading} alt="" />
                            <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>There is no record for the time being.</p>
                            <p className='text-[#a6a6a6] bottom-[34px] left-[50%] ellipsis -translate-x-[50%] leading-[18px] fixed text-[14px]'>(Only save records from the past 7 days.)</p>
                        </div> :
                        <div className={'w-[100%]  py-4 flex flex-col items-center'}>
                            <Loading type="spinner" size="20" color="#00E49F">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                            </Loading>
                        </div>
                    }
                    </>
            }

            <Popup visible={isShowPopup} overlay={false} closeOnClickOverlay={true} position="right" style={{ minHeight: '30%', width: "100%", background: "#101010", marginTop: '44px', paddingBottom: "24px" }} >
                <div className='px-4 w-full h-full text-white'>
                    <div className="w-fll text-white text-base mb-2">
                        Date
                    </div>

                    <div className="grid grid-cols-3 gap-3">
                        {timeArray.map((el: any) =>
                            <div key={el.code} onClick={() => { setTimeActive(el.code) }} className={`${timeActive == el.code ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-shadow-lg' : 'bg-[#333]'}  text-sm text-white flex justify-center items-center py-2 rounded-md`}>{el.name}</div>
                        )}
                    </div>

                    <div className="w-fll text-white text-base mt-5 mb-2">
                        Type
                    </div>
                    <div className="grid grid-cols-3 gap-3">
                        {typeArray.map((el: any) =>
                            <div key={el} onClick={() => {
                                if (typeActive.includes(el)) {
                                    setTypeActive(typeActive.filter((e: any) => e != el))
                                } else {
                                    setTypeActive([...typeActive, el])
                                }
                            }}
                                className={`${typeActive.includes(el) ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-shadow-lg' : 'bg-[#333]'}  text-sm text-white flex justify-center items-center py-2 rounded-md`}>{el}</div>
                        )}
                    </div>

                    <div className='w-full flex justify-between mt-5'>
                        <ButtonComp title='OK' callBack={() => {
                            setShowPopup(false)
                            setDataList([])
                            currPage.current = 1
                            getBattleRecordsHandle()

                        }}></ButtonComp>
                        <ButtonComp
                            title='Reset'
                            callBack={() => {
                                setTabActive(-1)
                                setTimeActive('ALL')
                                setTypeActive(['ALL'])
                                setDataList([])
                                currPage.current = 1
                                setShowPopup(false)
                                getBattleRecordsHandle()
                            }}
                        >

                        </ButtonComp>
                    </div>


                </div>

            </Popup>



        </div >
    )
}


export default Battle;