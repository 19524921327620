import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Image {
  image: any,
  width?: number,
  height?: number,
  rounded?: number
}

const LazyLoad = ({ image, height, width, rounded = 22 }: Image) => (
  <div className='object-cover flex justify-center items-center w-full custom-aspect-ratio h-full' style={{ overflow: "hidden" }}>
    <LazyLoadImage
      style={{ width: width ? `${width}px` : 'calc(100%)', minHeight: height ? `${height}px` : height === 0 ? '' : '254px', borderRadius: `${rounded}px` }}
      src={image}
    />
  </div>
);

export default LazyLoad;