import { Dialog } from "react-vant"
import close from "@/assets/images/task/close.png"
import { useEffect, useRef } from "react"
import usePlaySound from "@/hook/usePlaySound.tsx"
import CloseComp from "./common/CloseComp"


interface DialogProps {
  width?: string,
  dialog: boolean
  setDialog: () => void
  content: React.ReactNode
  closeOnClickOverlay?: boolean,
  isPadding?: boolean
}
const PublicDialog = ({ width = "100%", dialog, setDialog, content, closeOnClickOverlay = true, isPadding = true }: DialogProps) => {
  const dialogRef = useRef<any>(null)
  const closePlaySound = usePlaySound('close')
  var mo = function (e: any) { e.preventDefault(); };
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      className={`${width === "100%" ? 'PublicDialog' : ''} overflow-visible`}
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={closeOnClickOverlay}
      onClose={() => { setDialog() }}
      width={width}
    >

      <div ref={dialogRef} style={{ backgroundSize: "100% 100%" }} className={`w-full !bg-[length:100%_100%] overflow-visible relative bg-[url(@/assets/images/dialog_bg.png)] ${isPadding ? 'pb-[50px]' : ''} `}>
        {content}
        {!closeOnClickOverlay && <CloseComp position="absolute" top={14} right={16} callBack={() => { setDialog() }}></CloseComp>}
      </div>
    </Dialog>
  )
}
export default PublicDialog
