
import "./index.less"
import { Popup } from 'react-vant';
import { useRef, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import top from "../../assets/images/redpacket/redbag-top.png"
import down from "../../assets/images/redpacket/redbag-down.png"
import bg from "../../assets/images/redpacket/bg.jpg"
import Award from "./award.tsx"
import Usdt from "./Usdt.tsx"
import { changeUserInfo } from "../../store/module/tabbar.js"
import { getUserInfo } from "../../api/url"
import { useDispatch, useSelector } from 'react-redux';
import { changeShowRedPacketStatus } from '../../store/module/lottery'
import Coins from "./Coins.tsx";
import DrawCardsDialog from "../DrawCardsDialog.tsx";
import { useNavigate } from "react-router-dom";

interface PropsType {
    cardInfo?: any
}

const RedBag = ({ cardInfo }: PropsType) => {
    const dispatch = useDispatch()
    const awardData = useSelector((state: any) => state.lottery.awardData)
    const [drawCard] = useState(false)
    const [isShowBg, setIsShowBg] = useState(false)
    const topRef = useRef<any>(null)
    const downRef = useRef<any>(null)
    const containerRef = useRef<any>(null)
    const redPacketRef = useRef<any>(null)
    const navigate = useNavigate()


    const drawHandle = () => {
        let timer = null


        if (containerRef.current) {
            containerRef.current.classList.add("animate-redBag-rotate")
        }

        timer = setTimeout(() => {
            if (topRef.current) {
                topRef.current.classList.add("animate-rotate-top")
            }
            if (downRef.current) {
                downRef.current.classList.add("animate-rotate-down")
            }
        }, 600)

        setTimeout(() => {
            setIsShowBg(true)
        }, 1500)
    }
    const setUserInfo = async () => {
        const UserInfo = await getUserInfo()
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))
        }
    }

    const childrenComp = useMemo(() => {
        switch (awardData.code) {
            case "star":
                return <Coins count={awardData.amount}></Coins>
            case "usdt":
                return <Usdt count={awardData.amount}></Usdt>
            default:
                if (cardInfo) {
                    return <DrawCardsDialog
                        isOverlay={false}
                        width="70%"
                        teleport={redPacketRef.current}
                        isShowBtn={false}
                        dialog={true}
                        setDialog={() => { }}
                        cardLevel={cardInfo.level}
                        imgUrl={cardInfo.url}
                        power={cardInfo.power}
                        property={cardInfo.property}
                        setCardUrl={() => { }}>
                    </DrawCardsDialog>

                }
                return <DrawCardsDialog
                    isOverlay={false}
                    width="70%"
                    teleport={redPacketRef.current}
                    isShowBtn={false}
                    power={awardData?.card?.power}
                    property={awardData?.card?.property}
                    dialog={true}
                    setDialog={() => { }}
                    description={awardData?.card?.name}
                    cardLevel={awardData?.card?.level}
                    imgUrl={awardData?.card?.url}
                    setCardUrl={() => { }}>
                </DrawCardsDialog>
        }
    }, [awardData])

    return (
        <Popup
            style={{ width: "100%", height: "100%", background: "transparent" }}
            visible={true}
        >

            <div ref={redPacketRef} className='redPacket-container w-full h-full flex items-center justify-center'>
                {
                    !isShowBg && <div ref={containerRef} className='absolute w-[239px] h-[308px] z-20 ' onClick={() => { drawHandle() }}>
                        <img ref={topRef} className='w-[239px] absolute top-0' src={top} alt="" />
                        <img ref={downRef} className='w-[236px] absolute bottom-0 left-[2px]' src={down} alt="" />
                    </div>
                }

                {
                    isShowBg && <div className="absolute z-20 animate-none">
                        <Award callback={async () => {
                            if(cardInfo){
                                navigate("/pk")
                            }
                            setIsShowBg(false)
                            dispatch(changeShowRedPacketStatus(false))
                            if (awardData.code == 'star') {
                                await setUserInfo()

                            }

                        }} >
                            {childrenComp}
                        </Award>
                    </div>
                }
                {
                    <img className='w-[420px] -z-1 animate-rotate' src={bg} alt="" />
                }
            </div>
        </Popup >


    )
}


export default RedBag;