
import { useSelector, useDispatch } from 'react-redux';
import { NumberKeyboard, Popup, Sticky, Toast } from "react-vant"
import Back from "../../components/common/BackComp";
import { useNavigate } from "react-router-dom";
import coinImg from '@/assets/images/coin.png'
import bg from "../../assets/images/talk/bg.png"
import ButtonComp from '../../components/common/ButtonComp';
import Chars from "@/components/Chat.tsx"
import { useEffect, useLayoutEffect, useRef, useState, useCallback, useMemo } from "react";
import PromptDialog from "../../components/PromptDialog";
import { logsShareApi, loadLoadApi, getUserInfo } from '@/api/url'
import { changeCharacterList, changeUserInfo } from '../../store/module/tabbar';
import WebApp from '@twa-dev/sdk';
import { formatNumber, FormattingTwoNumber } from '../../utils';
import CardSelection from './cardSelection';
import { changeCardSelectActive } from '../../store/module/search';
import mySelection from '../../components/pk/mySelection';
import MySelection from '../../components/pk/mySelection';
import { changeSelectionList, changeTimes } from '../../store/module/battle';
import { createBattleArenas, checkBattleDeck } from '@/api/pk_url.js'
import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index';
interface PropType {
    callback: (type?: string) => void,
}

const ShareLog = ({ callback }: PropType) => {

    const timesList = [
        {
            id: 1,
            count: 1,
            text: "1 time"
        },
        {
            id: 2,
            count: 3,
            text: "3 times"
        }, {
            id: 3,
            count: 5,
            text: "5 times"
        },
        {
            id: 4,
            count: 10,
            text: "10 times"
        }
    ]
    const betList = [
        {
            id: 0,
            text: "STAR",
            img: coinImg
        },
        {
            id: 1,
            text: "CARDS",
            img: ""
        }
    ]

    const [isError, setError] = useState(false)
    const [errorText, setErrorText] = useState<string>("")
    const [isActive, setActive] = useState(1)
    const [betActive, setBetActive] = useState(0)
    const [deckDialog, setDeckDialog] = useState(false)
    const [valueSlider, setValueSlider] = useState<number>();
    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const { selectionList, times, arenaNavActive } = useSelector((state: any) => state.battle)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(changeCardSelectActive(-1))
        dispatch(changeTimes(1))
        setActive(1)
        dispatch(changeSelectionList(true))
    }, [betActive])


    useEffect(() => {
        dispatch(changeSelectionList(true))
        dispatch(changeTimes(isActive))
    }, [isActive])


    const checkDeckHandle = async () => {
        try {
            const { code } = await checkBattleDeck(arenaNavActive)
            if (code == 0) {
                await createBattleArenasHandle()
            } else {
                setDeckDialog(true)
            }
        } catch (e) {
            setDeckDialog(true)
        }


    }


    const createBattleArenasHandle = async () => {
        if (betActive == 0) {
            if (!valueSlider) {
                Toast.fail({
                    message: "The minimum bet is 10000 STAR",
                    duration: 1500,
                    className: 'custom-toast'
                })
                return
            }
            if (valueSlider < 10000) {
                Toast.fail({
                    message: "The minimum bet is 10000 STAR",
                    duration: 1500,
                    className: 'custom-toast'
                })
                return
            }
        }
        if (betActive == 1) {
            if (!selectionList.length) {
                return false
            }
        }
        const toast = Toast.loading({
            message: "Loading...",
            duration: 0,
            forbidClick: true
        })
        try {

            const params = {
                count: isActive,
                reward_type: betActive,
                reward_coins: betActive == 0 ? Number(valueSlider) : 0,
                reward_cards: betActive == 1 ? selectionList.map((item: any) => item.id) : [],
                type: arenaNavActive
            }
            const { code, data } = await createBattleArenas(params)
            if (code == "0") {
                setTimeout(() => {
                    callback("created")
                }, 600)
            } else if (code == '65547') {
                setError(true)
                setErrorText(data)
            } else {
                setError(true)
                setErrorText("Arena Creation Failed!")

            }

            toast.clear()

        } catch (e) {
            console.log(e)
            setError(true)
            setErrorText("Arena Creation Failed!")
            toast.clear()
        }
    }



    return (
        <Popup visible={true} position="right" style={{ zIndex: 1088, width: '100%', height: "100%", background: "#000" }} closeable={false}>

            <div className='w-full h-[44px] px-4 z-[1088] fixed top-0 left-0 overflow-hidden bg-[#000]'>
                <Back title='Create Arena' background="" callback={() => { callback && callback() }}></Back>
            </div>
            <div className="w-full h-full overflow-auto pt-[56px] bg-[#000]">
                <div className="flex flex-col px-4" >
                    <div className="title text-white" style={{ fontSize: '16px' }}>End Conditions: (Number of PK Losses)</div>
                    <div className="card-level grid grid-cols-4 gap-2 mt-2">
                        {
                            timesList.map(((item: any) => {
                                return (
                                    <div onClick={() => { setActive(item.count) }} key={item.id} className={`${isActive == item.count ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-shadow-lg' : ''} mb-2 h-9 rounded flex justify-center items-center bg-[#333] text-white text-base`}>
                                        {item.text}
                                    </div>
                                )
                            }))
                        }
                    </div>
                </div>
                <div className="flex flex-col mt-2 px-4" >
                    <div className="title text-white" style={{ fontSize: '16px' }}>Arena Bet :</div>
                    <div className="card-level grid grid-cols-4 gap-2 mt-2">
                        {
                            betList.map(((item: any) => {
                                return (
                                    <div onClick={() => { setValueSlider(0), setBetActive(item.id) }} key={item.id} className={`${betActive == item.id ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-shadow-lg' : ''} mb-2 h-9 rounded flex justify-center items-center bg-[#333] text-white text-base`}>
                                        {
                                            item.img && <img className='h-5 w-5 mr-2' src={item.img} alt="" />
                                        }
                                        {item.text}
                                    </div>
                                )
                            }))
                        }
                    </div>
                </div>

                {/* betActive === 1 */}
                {
                    betActive == 0 && <div className="flex flex-col mt-2 px-4" >
                        <div className="title text-white flex items-center" style={{ fontSize: '16px' }}>
                            <div className='text-white'>STAR Quantity:(one Time)</div>
                            <div className='text-white'>
                                <div className='flex items-center'>
                                    <img className='h-3 w-3 mr-2' src={coinImg} alt="" />
                                    <span className='text-white whitespace-nowrap text-sm'>My STAR:</span>
                                    {userInfo.balance ?
                                        <p style={{ right: 0 }} className="text-style-4 textStroke text-[14px] pr-[2px] leading-[18px] font-semibold " data-content={userInfo.balance.toString() && FormattingTwoNumber(userInfo.balance.toString())}>{userInfo.balance.toString() && FormattingTwoNumber(userInfo.balance.toString())}</p> :
                                        <p style={{ right: 0 }} className="text-style-4 textStroke text-[14px] pr-[2px] leading-[18px] font-semibold " data-content={0}>{0}</p>}
                                </div>

                            </div>
                        </div>
                        <div className="card-level w-full mt-4">
                            <div className={` transition-all ${'relative'} w-full`}>
                                <input
                                    type="number"
                                    style={{ background: 'linear-gradient( 135deg, #FFFFFF 100%, #FFFFFF 100%)' }}
                                    value={formatNumber(valueSlider as number)}
                                    placeholder={`${formatNumber(10000)}`}

                                    onChange={(e: any) => {
                                        const val = e.target.value
                                        setValueSlider(val)
                                        // if (val < 10000) {
                                        //     setValueSlider(10000)
                                        // } else {

                                        // }


                                    }}

                                    className={`text-[#333333] publishInput tracking-[1.5px] text-[18px] leading-[16px] w-[100%] h-10 rounded-[10px] p-2 px-[10xp] font-bold`}
                                />
                                <img src={coinImg} className='h-[24px] absolute top-[7px] right-[10px]' alt="" />
                            </div>
                        </div>

                        <div className="btn w-full mt-10">
                            <ButtonComp width={130} title="Create" callBack={() => { checkDeckHandle() }}></ButtonComp>
                        </div>
                    </div>
                }

                {
                    betActive == 1 &&

                    <div className="flex flex-col mt-2" >
                        <div className="title text-white px-4" style={{ fontSize: '16px' }}>
                            <div className='text-white'>Card Selection:</div>
                        </div>
                        <CardSelection></CardSelection>
                        <MySelection createHandle={() => { checkDeckHandle() }}></MySelection>
                    </div>

                }


            </div>
            <PromptDialog isClose={true} lineBreak={true} title={'Attention'} dialog={isError} setDialog={setError} text={errorText}></PromptDialog>
            <PromptDialog isClose={true} lineBreak={true} title={'Attention'} dialog={deckDialog} setDialog={setDeckDialog} text={"It is advisable to complete the configuration of all the cards in your deck before participating in Pk, as this will impact your win/loss results!"}>
                <div className='w-full flex'>
                    <ButtonComp title='Configure Deck' width={120} callBack={() => {
                        navigate("/myDeck")
                    }} ></ButtonComp>
                    <ButtonComp title="Continue" width={100} callBack={() => {
                        createBattleArenasHandle()
                        setDeckDialog(false)
                    }}></ButtonComp>
                </div>

            </PromptDialog>

        </Popup >
    )
}


export default ShareLog