import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { changeLoading, popAvatarsChatList, createAvatarsChatList, changeAvatarAudio } from '@/store/module/progress';
import { changeCreateData } from "../../../store/module/createAvatar"
import markdownit from 'markdown-it'

import "../../talk/Talk.less"
import audioIcon from '../../../assets/images/audio_icon_1.png';
import audioIcon1 from '../../../assets/images/audio_icon_2.png';
import audioIcon2 from '../../../assets/images/audio_icon_3.png';

import sendImg from "@/assets/images/talk/send.png"
import bg from "../../../assets/images/talk/avatar_bg.png"
import Chars from "@/components/Chat.tsx"
import { judgeClient } from '../../../utils/judgeClient';

import { fetchEventSource } from '@microsoft/fetch-event-source';
import { v4 as uuidv4 } from 'uuid';
import { getVoice } from '@/api/url'
import { useNavigate } from 'react-router-dom';



import TalkMessage from '../../../components/message/TalkMessageToast';
import Back from '../../../components/common/BackComp';
import ButtonComp from '../../../components/common/ButtonComp';

import "./create.less"
import PromptDialog from '../../../components/PromptDialog';
import { Toast } from 'react-vant';


type Message = {
    id: number,
    status: string,
    content: string | any[],
    direction: string,
    type?: string
    audioUrl?: string
    durations?: number
    isError?: boolean
}


const md = markdownit()



const Talk = () => {
    //从缓存中拉取数字人列表数据
    // const characterList = JSON.parse(localStorage.getItem("characterList")!)

    const [isTalkMessage, setIsTalkMessage] = useState(false)
    const talkRef = useRef<any>(null);
    const chatContainer = useRef<any>(null);
    const inputRef = useRef<any>(null);
    const [isShowTry, setShowTry] = useState(false)
    const [sendIsShow, setSendIsShow] = useState(false);
    const address_params = localStorage.getItem('address_params')
    const [tasksData] = useState<any>(useSelector((state: any) => state.tasks.tasksData))
    const chatMessageList = useSelector((state: any) => state.progress.avatarsChatList)
    const { avatarsImgList, currAvatarAudio } = useSelector((state: any) => state.progress)
    const dispatch = useDispatch()
    const [inputValue, setInput] = useState("");
    let [chatText, setChatText] = useState("");

    const myText = useRef("")
    const [isConfirm, setConfirm] = useState(false)
    const [isNext, setNext] = useState(false)

    const [session_id, setSession_id] = useState("")
    const chatState = useRef(0)
    const [chatConfirmData, setChatConfirmData] = useState<any>(null)
    // const [avatarImgList, setAvatarImgList] = useState<any>([])
    const avatarImgList = useRef<any[]>([])
    const isGetVoice = useRef(false)
    const [avatarsAudioList, setAvatarsAudioList] = useState<any>([])
    const avatarPrompt = useRef("")
    const [isAllowSay, setAllowSay] = useState(true) //是否允许说话
    const [networkError, setNetWorkError] = useState(false)
    const isError = useRef(false)
    const controllerRef = useRef<any>(null)
    const [backWarning, setBackWarning] = useState(false)




    // useLayoutEffect(() => {
    // window.localStorage.se1tItem("isFromTalkPage", "yes");
    // setTimeout(() => {
    //     scrollToBottom();
    // }, 200)
    // }, [])

    useEffect(() => {
        getVoiceList()
        createAvatarsHandle()
    }, [])
    //获取音频
    const getVoiceList = async () => {
        setAvatarsAudioList([])
        const { code, data } = await getVoice()
        if (code == 0) {
            setAvatarsAudioList((prev: any) => [...prev, ...data])
        }
    }

    //获取sessionid 
    const createAvatarsHandle = async () => {
        const toast = Toast.loading({
            message: 'Loading...',
            forbidClick: true,
        });
        const token = window.localStorage.getItem("token");
        const params = {
            "state": chatState.current,
        }
        // fetchEventSource(`/api/v1/miniapp/character`, {
        fetchEventSource(`${import.meta.env.VITE_CHARACTER_API}/api/v1/miniapp/character`, {
            openWhenHidden: true,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(params),
            async onmessage(res) {
                if (res.data.indexOf("code") !== -1) {

                } else {

                    setSession_id(res.data)
                }
                // 
            },
            onclose() {
                toast.clear()
            },
            onerror(err: any) {
                toast.clear()
                throw err;
            }
        },
        ).catch((e: any) => {
            toast.clear()
            setNetWorkError(true)
            console.log(e)
            console.log("报错了")
            // createAvatarsHandle()
        })
        // navigate("/create-avatars")
    }

    const sendMessage = async (text: string = "", type?: string, params?: any) => {
        controllerRef.current = new AbortController();
        const signal = controllerRef.current.signal;
        setAllowSay(false)
        const token = window.localStorage.getItem("token");
        const item = {
            id: uuidv4(),
            status: "pending",
            content: '',
            direction: 'left'
        }
        dispatch(createAvatarsChatList(item))
        setChatText("");
        myText.current = ""
        setInput("");
        dispatch(changeLoading(false))

        setShowTry(true)
        fetchEventSource(`${import.meta.env.VITE_CHARACTER_API}/api/v1/miniapp/character`, {
            // fetchEventSource(`/api/v1/miniapp/character`, {
            openWhenHidden: true,
            signal: signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: params ? JSON.stringify(params) : JSON.stringify({
                message: type != 'audio' ? text + "\n" : "",
                session_id: session_id,
                state: chatState.current
            }),

            async onmessage(res) {

                setShowTry(false)
                if (res.data.indexOf("code") !== -1) {

                    if (JSON.parse(res.data).code == -1) {

                        isError.current = true
                        dispatch(popAvatarsChatList());
                        setNetWorkError(true)

                        return false
                    }
                    isError.current = false
                    setAllowSay(true)
                    dispatch(popAvatarsChatList());
                    const data = JSON.parse(res.data).data;

                    if (data && data.need_confirm) {
                        setConfirm(true)
                        if (chatState.current == 0) {
                            setChatConfirmData(data)
                        }

                    }

                    const item: Message = {
                        id: uuidv4(),
                        status: "success",
                        content: myText.current,
                        direction: "left",
                        type: "message",
                    }
                    dispatch(createAvatarsChatList(item))

                    if (chatState.current == 1) {
                        avatarPrompt.current = data.prompt
                        setChatConfirmData({ ...chatConfirmData, introduction: data.introduction })
                    }
                    if (data && data.image_meta && data.image_meta.length && chatState.current == 2) {
                        let imgList = data.image_meta
                        const itemImage: Message = {
                            id: uuidv4(),
                            status: "success",
                            content: imgList,
                            direction: "left",
                            type: "image",

                        }
                        // setChatConfirmData({ ...chatConfirmData, prompt: data.message })

                        dispatch(createAvatarsChatList(itemImage))

                    }



                    if (chatState.current == 2 && isGetVoice.current) {
                        dispatch(popAvatarsChatList())
                        const avatarsAudioListCopy = avatarsAudioList.map((item: any, i: number) => `
                                <div class="audio-style" id=${item.uuid}>
                                    <span>${"Voice" + (i + 1)}</span>
                                    <div class = "audio-icon">
                                        <img src="${audioIcon}">
                                        <div style="width:2px;height: 2px;background:#fff;"> </div>
                                        <img style="width:3px; margin-left:0px;" src="${audioIcon1}">
                                        <img style="width:6px; margin-left:-2px;"  src="${audioIcon2}">
                                    </div>
                                    <audio src=${item.tg_url} hidden=${true}></audio>
                                </div>
                            `)
                        const itemAudio: Message = {
                            id: uuidv4(),
                            status: "success",
                            content: avatarsAudioListCopy.join(""),
                            direction: "left",
                            type: "audio",

                        }

                        setNext(true)
                        dispatch(createAvatarsChatList(itemAudio))
                    }


                    setTimeout(() => {
                        scrollToBottom();
                    }, 200)
                } else {
                    res.data = res.data.toString().replace(/\\n+/g, '\n\n')
                    if (res.data !== '==end==') {
                        setChatText(prev => prev + res.data);
                    }
                    myText.current += res.data;


                    // myText.current = md.render(myText.current)
                    if (res.data === '==end==') {
                        myText.current = md.render(myText.current.replace("==end==", "").toString());
                        setChatText(prev => md.render(prev));
                    }

                    setTimeout(() => {
                        scrollToBottom();
                    }, 200)
                }


            },
            onerror(err) {
                setTimeout(() => {
                    setShowTry(false)
                }, 2000)
                setAllowSay(true)
                throw err;
            },
            async onopen(response) {
                console.log("🚀 ~ onopen ~ response:", response)
                if (!response.ok) {
                    setShowTry(false)
                    // dispatch(popAvatarsChatList());
                    isError.current = true
                    dispatch(popAvatarsChatList());
                    setNetWorkError(true)
                    // if (chatState.current === 0) {
                    //     sendMessage(inputValue)
                    // } else {
                    //     confirmHandle()
                    // }

                }
            },
            async onclose() {

                if (chatState.current == 1 && !isError.current) {
                    console.log("running")
                    confirmHandle()
                }

            }
        },
        ).catch((e) => {
            setShowTry(false)
            dispatch(popAvatarsChatList());
            isError.current = true
            setNetWorkError(true)
            console.log("聊天报错了")
        })
    }
    //确认按钮，创建人物下一步
    const confirmHandle = async () => {
        if (isConfirm) {
            setConfirm(false)
        }
        let params = null

        if (chatState.current < 2) {
            chatState.current++;
            const { description, gender, name, message } = chatConfirmData
            params = {
                message: "" + "\n",
                session_id: session_id,
                state: chatState.current,
                name,
                gender,
                description
            }
        } else {
            // setGetVoice(true)
            isGetVoice.current = true
            params = {
                session_id: session_id,
                state: chatState.current,
                images: avatarsImgList
            }
        }
        await sendMessage("", "", params)
    }


    const sendHandle = async () => {
        //创建人物语音出来以后，无法再发送消息
        if (isNext || isError.current) {
            return false
        }
        if (!isAllowSay) {
            // Toast.info({ message: '提示内容' });
            setIsTalkMessage(true)

            setTimeout(() => {
                setIsTalkMessage(false)
            }, 900)
            return false;
        }
        if (inputValue.trim() !== '') {
            setConfirm(false)
            const item: Message = {
                id: uuidv4(),
                status: "success",
                content: inputValue + "\n",
                direction: "right"
            }

            dispatch(createAvatarsChatList(item))

            setSendIsShow(false)
            setEmoji(false);
            if (chatState.current == 2) {
                const { description, gender, name } = chatConfirmData
                const params: any = {
                    message: inputValue + "\n",
                    session_id: session_id,
                    state: chatState.current,
                    name,
                    gender,
                    description
                }

                sendMessage("", "", params)
            } else {
                sendMessage(inputValue)
            }

            setTimeout(() => {
                scrollToBottom();

                if (judgeClient() == 'IOS') {
                    window.scrollTo(0, 0);
                }
            }, 200)

        }
    }
    //键盘事件
    const keyDownHandle = async (e: any) => {
        e.stopPropagation();
        if (e.keyCode == 13) {
            // dispatch(changeTabbar(false))
            blurHandle();
            sendHandle();

        }
    }

    useEffect(() => {
        if (inputValue.trim() !== '') {
            setSendIsShow(true)
        }
    }, [inputValue])

    //-------------------- 聊天页相关---------------------
    const mainRef = useRef<any>(null);
    useEffect(() => {
        // talkRef.current?.addEventListener('touchmove', (e) => {
        //     e.preventDefault();
        // }, {
        //     passive: false
        // })

        mainRef.current?.addEventListener('touchstart', (e: any) => {
            e.stopPropagation();
            blurHandle()
        })
        return () => {
            mainRef.current?.removeEventListener('touchstart', (e: any) => {
                e.stopPropagation();
                blurHandle()
            })
        }

    }, [])

    const isAudioActive = useRef(-1)
    const audioUUid = useRef("")

    useLayoutEffect(() => {
        const audioList = document.querySelectorAll(".audio-style")
        for (let i = 0; i < audioList.length; i++) {
            audioList[i].addEventListener('click', () => {
                if (isAudioActive.current !== -1) {
                    audioList[isAudioActive.current].children[1].children[1].classList.remove("voice_1")
                    audioList[isAudioActive.current].children[1].children[2].classList.remove("voice_2")
                    audioList[isAudioActive.current].children[1].children[3].classList.remove("voice_3")
                    audioList[isAudioActive.current]?.children[2].pause()
                    audioList[isAudioActive.current].classList.remove("active")
                }

                isAudioActive.current = i
                const uuid = audioList[isAudioActive.current].getAttribute("id") as string
                dispatch(changeAvatarAudio(uuid))

                audioList[i].classList.add("active")
                audioList[i].children[2].currentTime = 0;
                audioList[i].children[2].play()
                audioList[i].children[1].children[1].classList.add("voice_1")
                audioList[i].children[1].children[2].classList.add("voice_2")
                audioList[i].children[1].children[3].classList.add("voice_3")
                audioList[i].children[2].addEventListener("ended", () => {
                    audioList[i].children[1].children[1].classList.remove("voice_1")
                    audioList[i].children[1].children[2].classList.remove("voice_2")
                    audioList[i].children[1].children[3].classList.remove("voice_3")
                })

            })
        }
    })




    const scrollToBottom = () => {
        chatContainer.current!.scrollIntoView({ behavior: "smooth" });
    };

    const blurHandle = () => {
        // handleScroll()

        inputRef.current.blur();
        if (judgeClient() == 'IOS') {
            setTimeout(() => {
                const dom = document.querySelector(".input-container");
                if (dom) {
                    dom.style.paddingBottom = "0px"
                }

            }, 100)
            window.scrollTo(0, 0); //解决白边问题，ios

        }
    }

    const focusHandle = () => {
        if (judgeClient() == 'IOS') {
            setTimeout(() => {
                scrollToBottom();
            }, 200)
            setTimeout(() => {
                const dom = document.querySelector(".input-container");
                if (dom) {
                    dom.style.paddingBottom = "80px"
                }

            }, 100)
            inputRef.current?.focus();

        } else {
            setTimeout(() => {
                scrollToBottom();
            }, 400)

        }
    }





    //--------------------金币进度条end---------------------

    //表情弹窗消失
    document.addEventListener("click", (e) => {
        e.stopPropagation();
        if (emoji) {
            setEmoji(false);
        }
    })


    //表情
    const [emoji, setEmoji] = useState(false);



    const navigate = useNavigate()


    // ------------------------ 滑动 -----------------------
    useEffect(() => {
        if (judgeClient() == 'Android') {
            document.addEventListener('touchstart', touchStartHandle);
            return () => {
                document.removeEventListener('touchstart', touchStartHandle);
                // window.scrollTo(0, 0);
            }
        }

    })

    const touchStartHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 0.5px)",
                "important"
            );

        }
    }
    const goConfirmPageHandle = () => {
        const params = { ...chatConfirmData, prompt: avatarPrompt.current, images: avatarsImgList, voice: currAvatarAudio }
        console.log(params)
        dispatch(changeCreateData(params))
        navigate("/edit-avatars")
    }
    //网络错误重试
    const resetApiHandle = () => {
        if (isError.current) {
            if (chatState.current === 0) {
                sendMessage(inputValue)
            } else {
                if (!isGetVoice.current) {
                    chatState.current--
                }
                confirmHandle()


            }
        } else {
            setNetWorkError(false)
            createAvatarsHandle()
            if (!avatarsAudioList.length) {
                getVoiceList()
            }
        }

    }
    //返回
    const backHandle = () => {
        setAllowSay(true);
        if (controllerRef.current) {
            controllerRef.current.abort(); // 关闭连接
            console.log('Chat connection closed by user.');
        }
        dispatch(popAvatarsChatList("remove"));
        navigate(`/character?id=${3}`);
    }

    return (
        <div className={`talk-box w-full h-[100vh] px-4 box-border relative bg-no-repeat bg-cover bg-center`} style={{ backgroundImage: `url(${bg})` }} ref={talkRef}>
            <div className='w-full h-11 px-4 py-3 z-[200] absolute top-0 left-0 bg-gradient-to-b from-[#000000] to-[#fff]/[0] overflow-hidden'>
                <div className="avatar h-11 mb-2">
                    <Back title='Create Avatars' callback={() => {
                        setBackWarning(true)

                    }} background={false}>


                    </Back>
                </div>

            </div>

            <div className="chats-content  box-border overflow-auto" ref={mainRef}>
                {
                    chatMessageList.map((item: any, index: number) => {
                        // return <div className='chats'>asdjasljdlasjdlasj</div>

                        return <Chars info={item} content={item.status != 'pending' ? item.content : isShowTry ? chatState.current == 1 ? `StarAI is generating Avatar's introduction...` : chatState.current == 2 && !isGetVoice.current ? `StarAI is generating Avatar's images...` : 'StarAI is typing...' : chatText} key={index}></Chars>
                    })
                }
                <div className={`${isConfirm && !isNext ? '' : 'hidden'} my-2`}>
                    <p className='p-2 text-sm text-[#fff] text-center text-wrap rounded-lg mb-2 bg-[#333]/[.5]'>
                        You can continue dialogue to optimize AI Avatar personality, or you can press "confirm" to end optimization after you select the images.
                    </p>
                    <ButtonComp title='Confirm' disabled={(!avatarsImgList.length && chatState.current == 2) ? true : false} callBack={() => { confirmHandle() }}></ButtonComp>
                </div>
                <div className={`${isNext ? '' : 'hidden'} my-2`}>
                    <ButtonComp title='Confirm' disabled={currAvatarAudio == '' ? true : false} callBack={() => { goConfirmPageHandle() }}></ButtonComp>
                </div>
                <div className="blank h-[10px] w-full" ref={chatContainer}></div>
            </div>
            <div className='input-container h-[62px] box-border relative'>
                {
                    isTalkMessage && <TalkMessage text={"Please let me finish."}></TalkMessage>
                }
                <div className={`say-input w-[calc(100%_-_0px)] h-[52px] rounded-lg bg-white/40 z-999  px-2 py-[6px] flex items-center justify-between relative`} >

                    <div className={`input ${sendIsShow ? 'w-[calc(100%_-_30px)]' : 'w-[calc(100%_-_0px)]'} h-full rounded-lg overflow-hidden z-[600] relative`} >

                        <input ref={inputRef} style={{ userSelect: "text" }} value={inputValue} onFocus={() => { focusHandle() }} onBlur={(e) => { e.stopPropagation(); blurHandle() }} onKeyDown={(e) => { keyDownHandle(e) }} onInput={(e: any) => setInput(e.target.value)} enterKeyHint="done" className="input-content w-full h-full outline-none bg-white/60 backdrop-blur-[20px] text-[#333333] p-[11px] pr-[28px]" type="text" placeholder='Send a message...' />
                    </div >

                    <div className={sendIsShow ? "face w-[26px] h-[26px]" : 'hide'} onClick={() => { sendHandle() }}>
                        <img className='w-full h-full' src={sendImg} alt="" />
                    </div>


                    {/* </div> */}
                </div >
            </div >
            <PromptDialog isClose={true} title={'Network Error'} dialog={networkError} setDialog={setNetWorkError} callback={() => {
                resetApiHandle()

            }} text={"Please try again later."}></PromptDialog>

            <PromptDialog title={'Warning'} isClose={true} dialog={backWarning} setDialog={setBackWarning} callback={() => {
                backHandle()
            }} text={"If you leave the page, the data will be lost. Do you want to continue?"}></PromptDialog>
        </div >
    )
}


export default Talk;