import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTonClient } from '../../hook/contract/useTonClient';
import { useAsyncInitialize } from '../../hook/contract/useAsyncInitialize';
import { useTonConnect } from '../../hook/contract/useTonConnect';
import { Address, comment, OpenedContract, toNano } from '@ton/core';
import { INVOICE_WALLET_ADDRESS, USDT_MASTER_ADDRESS } from '../../constants/common-constants';
import { JETTON_TRANSFER_GAS_FEES } from '../../constants/fees.constants';
import { JettonMaster } from '@ton/ton';
import { JettonWallet } from '../../hook/JettonWallet.ts';
import { calculateUsdtAmount, PAYTYPE } from '../../utils/common-helpers';
import { changeTransactionBoc, changeUsdtTransferId } from '../../store/module/lottery'
import { Toast } from 'react-vant';
import { useTonAddress } from '@tonconnect/ui-react';
import { starPayHandle } from "@/api/starpay.js"
import { useDispatch, useSelector } from 'react-redux';
import { getShopPayLink } from "@/api/shop.js"
import { getAcceleratorPayLink, postAcceleratorPaycheck } from "@/api/mining.js"




export default function USDTTransfer() {

  const { UsdtTransferOrderId } = useSelector((state: any) => state.lottery)
  // const orderId = useRef("")
  // useEffect(() => {
  //   orderId.current = UsdtTransferOrderId
  //   if (transactionBoc) {
  //     transferHandle(orderId.current, transactionBoc)
  //   }
  // }, [UsdtTransferOrderId, transactionBoc])
  const dispatch = useDispatch()
  const address = useTonAddress()
  const tonClient = useTonClient();
  const { usdtSender, walletAddress } = useTonConnect();

  const UsdtTransformHandle = async (amount: number, message: string, type?: string, id?: string | number) => {
    try {
      let res = null
      if (!tonClient || !walletAddress) return;

      if (!type) {
        let params = {}
        if (id) {
          params = {
            title: "",
            description: "",
            total: calculateUsdtAmount(amount * 100),
            type: PAYTYPE.usdt,
            relevance: Address.parse(address),
            id: id
          }
        } else {
          params = {
            title: "",
            description: "",
            total: calculateUsdtAmount(amount * 100),
            type: PAYTYPE.usdt,
            relevance: Address.parse(address),

          }
        }

        const toast = Toast.loading({ message: "Loading...", duration: 0, forbidClick: true, });


        if (id) {
          const { code, data } = await getShopPayLink(params)
          res = data
        } else {
          res = await starPayHandle(params)
        }
        toast.clear()
        if (res.is_paid) {
          dispatch(changeTransactionBoc("paid"))
          dispatch(changeUsdtTransferId(res.order_id))
          return false
        }


        dispatch(changeUsdtTransferId(res.order_id))
      }
      const jettonMaster = tonClient.open(JettonMaster.create(USDT_MASTER_ADDRESS));
      const usersUsdtAddress = await jettonMaster.getWalletAddress(walletAddress);

      const jettonWallet = tonClient.open(JettonWallet.createFromAddress(usersUsdtAddress));

      const { balance } = await jettonWallet.getWalletData()

      if (Number(balance) > Number(calculateUsdtAmount(amount * 100))) {
        await jettonWallet.sendTransfer(usdtSender, {
          fwdAmount: 1n,
          jettonAmount: calculateUsdtAmount(amount * 100),
          toAddress: INVOICE_WALLET_ADDRESS,
          value: JETTON_TRANSFER_GAS_FEES,
          comment: JSON.stringify({
            type: message,
            orderID: type == "setting" ? UsdtTransferOrderId : res.order_id
          })
        })
      } else {
        Toast.fail({
          message: 'Insufficient USDT balance'
        })
      }

      // if (usersUsdtAddress.toString() != jettonWallet.address.toString()) {
      //   return
      // }

    } catch (error: any) {
      Toast.fail({
        message: 'Payment encountered an issue. Please check your wallet balance or network connection and try again.',
        forbidClick: true,
        className: 'custom-toast'
      })
      console.log('Error during transaction check:', error);
    }

  }

  const UsdtMiningTransformHandle = async (amount: number, type?: string, id?: string | number) => {
    try {
      let res = null
      if (!tonClient || !walletAddress) return;

      if (!type) {
        let params = {}
        if (id) {
          params = {
            title: "",
            description: "",
            total: calculateUsdtAmount(amount * 100),
            type: PAYTYPE.usdt,
            relevance: Address.parse(address),
            id: id

          }
        } else {
          params = {
            title: "",
            description: "",
            total: calculateUsdtAmount(amount * 100),
            type: PAYTYPE.usdt,
            relevance: Address.parse(address),

          }
        }

        const toast = Toast.loading({ message: "Loading...", duration: 0, forbidClick: true, });


        if (id) {
          const { code, data } = await getAcceleratorPayLink(params)
          res = data
        } else {
          res = await starPayHandle(params)
        }
        toast.clear()
        if (res.is_paid) {
          dispatch(changeTransactionBoc("paid"))
          dispatch(changeUsdtTransferId(res.order_id))
          return false
        }
        dispatch(changeUsdtTransferId(res.order_id))
       
      }



      const jettonMaster = tonClient.open(JettonMaster.create(USDT_MASTER_ADDRESS));
      const usersUsdtAddress = await jettonMaster.getWalletAddress(walletAddress);

      const jettonWallet = tonClient.open(JettonWallet.createFromAddress(usersUsdtAddress));

      const { balance } = await jettonWallet.getWalletData()

      if (Number(balance) > Number(calculateUsdtAmount(amount * 100))) {
        await jettonWallet.sendTransfer(usdtSender, {
          fwdAmount: 1n,
          jettonAmount: calculateUsdtAmount(amount * 100),
          toAddress: INVOICE_WALLET_ADDRESS,
          value: JETTON_TRANSFER_GAS_FEES,
          comment: JSON.stringify({
            type: "Mining Pool Acceleration",
            orderID: res.order_id
          })
        })
      } else {
        Toast.fail({
          message: 'Insufficient USDT balance'
        })
      }

      // if (usersUsdtAddress.toString() != jettonWallet.address.toString()) {
      //   return
      // }

    } catch (error: any) {
      Toast.fail({
        message: 'Payment encountered an issue. Please check your wallet balance or network connection and try again.',
        forbidClick: true,
        className: 'custom-toast'
      })
      console.log('Error during transaction check:', error);
    }

  }



  return {
    UsdtTransformHandle,
    UsdtMiningTransformHandle
  }
}
