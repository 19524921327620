const Usdt = ({ count }: { count: string | number }) => {
    return (
        <div className="w-[200px] h-[150px] flex  bg-gradient-to-r rounded-[30px] text-[#fff] p-3 text-sm from-[#00E49F] to-[#54ADFF]">
            <div className="bg-white w-full h-[60%] rounded-t-3xl flex justify-center items-center">
                <span className="text-5xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">${count}</span>
            </div>
        </div>
    )
}


export default Usdt