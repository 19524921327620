import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface TabbarState {
    value: boolean;
    checkDialog: boolean;
    invitation_code: string;
    hotRewardTaskDialog: boolean;
    addressParams: string;
    homePage: {
        showHomePage: boolean;
        id: string;
    }
    characterList: any[];
    userInfo: {
        level: number,
        next_level_points: number,
        now_level_points: number,
        card_rewards: Array<string>,
        points: number,
        level_points: number,
        rewards: number,
        up: boolean
    }
    coinDomCoordinate: {
        x: string
        y: string
    }
    inviteData: any,
}

const initialState: TabbarState = {
    value: true,
    checkDialog: false,
    hotRewardTaskDialog: true,
    addressParams: '',
    invitation_code: '',
    userInfo: {
        level: 0,
        next_level_points: 0,
        now_level_points: 0,
        level_points: 0,
        card_rewards: [],
        points: 0,
        rewards: 0,
        up: false
    },
    inviteData: {
        count: 0,
        invitation_code: "",
        premium_reward: '',
        standard_reward: '',
        premium: [
            { count: 0, people: 0 },
            { count: 0, people: 0 }
        ],
        standard: [
            { count: 0, people: 0 },
            { count: 0, people: 0 }
        ]
    },
    coinDomCoordinate: {
        x: '0',
        y: '0'
    },
    characterList: [],
    homePage: {
        showHomePage: false,
        id: '',
    }
}
const tabbarStore = createSlice({
    name: 'tabbar',
    initialState,
    reducers: {
        // 修改tabbar状态
        changeTabbar(state, action: PayloadAction<boolean>) {
            state.value = action.payload
        },
        changeInvitation_code(state, action: PayloadAction<string>) {
            state.invitation_code = action.payload
        },
        changeUserInfo(state, action: PayloadAction<any>) {
            if (action.payload.now_level_points || action.payload.next_level_points) {
                state.userInfo = action.payload
            }
        },
        changeCoinDomCoordinate(state, action: PayloadAction<any>) {
            state.coinDomCoordinate = action.payload
        },
        setHotRewardTaskDialog(state, action: PayloadAction<any>) {
            state.hotRewardTaskDialog = action.payload
        },
        setAddressParams(state, action: PayloadAction<any>) {
            state.addressParams = action.payload
        },
        changeCharacterList(state, action: PayloadAction<any>) {
            state.characterList = action.payload
        },
        changeInviteData(state, action: PayloadAction<any>) {
            state.inviteData = action.payload
        },

        changeCheckDialog(state, action: PayloadAction<any>) {
            state.checkDialog = action.payload
        },

        changeHomePage(state, action: PayloadAction<any>) {
            state.homePage = action.payload
        },

    }
})

export const { changeHomePage, changeTabbar, changeCheckDialog, changeInvitation_code, changeUserInfo, changeCoinDomCoordinate, setHotRewardTaskDialog, setAddressParams, changeCharacterList, changeInviteData } = tabbarStore.actions

export default tabbarStore.reducer