// 毛玻璃特效


import show from '@/assets/images/cards/show.svg'


interface PropType {
    callback?: () => void,
    eyesIsSmall?: boolean,
    isShowEyes?: boolean
}
const BlurDialog = ({ callback, eyesIsSmall, isShowEyes = true }: PropType) => {
    return (
        <div onClick={() => {
            callback && callback()
        }} className='w-[100%] h-[100%] absolute top-0 left-0 flex items-center justify-center !backdrop-blur-[1.25rem] z-1'
        >
            {isShowEyes && <img className={`${eyesIsSmall ? 'h-3' : ''} `} src={show} alt="" />}
        </div>

    )
}

export default BlurDialog