import attribute from '@/assets/images/attribute/attribute.png'


export const rulesTextArr = [
  {
    title: 'Origins of Card Power',
    textArr:
      [
        {
          text: 'Each card you acquire (excluding regular cards) will be assigned a power value and random attributes.',
          image: '',
        },
        {
          text: 'The final power value of the card is influenced by several factors, including the quality of the card, AI-generated effect scores, enhancement levels, and synthesis scores, among others.',
          image: '',
        },
      ]
  },
  {
    title: 'Card Attributes',
    textArr:
      [
        {
          text: 'Each card (excluding regular and hero cards) will possess one of five fundamental attributes: electric, fire, water, grass, and ground.',
          image: '',
        },
        {
          text: "The attributes have relationships of restraint and being restrained. The specific restraint relationships are as shown in the following diagram:",
          image: attribute,
        },
        {
          text: "In PK battles, if your card's attribute can restrain the opponent's attribute, you will receive a 20% bonus to your card's power, while the opponent's power will be correspondingly reduced by 20%.",
          image: '',
        },
      ]
  },
  {
    title: 'Card Enhance',
    textArr:
      [
        {
          text: 'Each card can be enhanced up to five times. Enhancement levels 1/2/3/4/5 will respectively bring a 10%/10%/10%/15%/25% increase in combat power. ',
          image: '',
        },
        {
          text: "During the enhancement process, you will need to consume a corresponding amount of <$STAR>.",
          image: '',
        },
        {
          text: "However, please note that the success rate of enhancement decreases as the level of enhancement increases. ",
          image: '',
        },
        {
          text: "If the enhancement fails, the card will not disappear, but its enhancement level will reset to 0.",
          image: '',
        },
      ]
  },
  {
    title: 'Hero Cards',
    textArr:
      [
        {
          text: 'Hero cards are your generated digital persona cards with unique combat power values.',
          image: '',
        },
        {
          text: "It's important to note that hero cards do not have attributes and cannot be enhanced.",
          image: '',
        },
        {
          text: "Their combat power is influenced by the number of likes and the number of people chatting with the digital persona.",
          image: '',
        },
        {
          text: "The more likes and chat interactions it receives, the more significant the power bonus will be.",
          image: '',
        },
      ]
  }
]

export const cardPkRulesTextArr = [
  {
    title: 'Configuring Deck',
    textArr:
      [
        {
          text: "The deck configuration is divided into four decks, corresponding to the four arena PK zones: <Standard>, <NFT>, <Diamond>, and <Gold>.",
          image: ""
        },
        {
          text: "Each deck can configure six cards. After completing the configuration, you can click the",
          image: ""
        },

        {
          text: 'You can configure cards in various slots to create a powerful deck, thereby enhancing your overall combat power.',
          image: '',
        },
        {
          text: 'Each quality slot must use a corresponding quality card for configuration.',
          image: '',
        },
        {
          text: 'For example, the bronze slot can only accommodate bronze-quality cards, the <NFT> slot can only hold <NFT> cards, and the hero slot is specifically designated for hero cards.',
          image: '',
        },
        {
          text: 'Once the deck configuration is complete, you will be able to see the total combat power of the deck as well as the attribute composition of the cards within the deck.',
          image: '',
        },
        {
          text: 'In the deck configuration, the position of each card is fixed. When engaging in arena PK with others, each card in the same position will compete in a 1-to-1 power value PK.',
          image: '',
        },
      ]
  },
  {
    title: 'Hero Cards',
    textArr:
      [
        {
          text: "Each deck can be configured with hero cards.",
          image: ""
        },
        {
          text: 'Hero cards are your generated digital persona cards with unique combat power values.',
          image: '',
        },
        {
          text: "It's important to note that hero cards do not have attributes and cannot be enhanced.",
          image: '',
        },
        {
          text: 'Their combat power is influenced by the number of likes and the number of people chatting with the digital persona.',
          image: '',
        },
        {
          text: 'The more likes and chat interactions it receives, the more significant the power bonus will be.',
          image: '',
        },
      ]
  }
]

export const PK_RULES = [
  {
    title: 'Card Arena PK',
    textArr:
      [
        {
          text: 'After configuring your card deck (you can learn about the relevant rules in the deck configuration section), you can participate in the card PK game.',
          image: '',
        },
        {
          text: 'The arena is divided into 4 zones: <Standard>, <NFT>, <Diamond>, and <Gold>. You can choose to initiate PKs in different zones based on your deck configuration.',
          image: '',
        },
        {
          text: 'In each arena zone, you can view information about all the arenas in that zone, including partial card information of your PK opponents (you can always see the opponent\'s hero card and two randomly selected cards with only their attributes), the number of available PKs in the arena, and the rewards for the arena.',
          image: '',
        },
        {
          text: 'After winning a PK in the arena, you can earn <$STAR> or cards. However, if you lose, you will also lose an equivalent number of <$STAR> or a card of equivalent quality that you choose.',
          image: '',
        },
      ]
  },
  {
    title: 'Creating an Arena',
    textArr:
      [
        {
          text: 'You can only create an arena after configuring at least 3 cards in your deck. You can create arenas in each zone.',
          image: '',
        },
        {
          text: "The first time you create an arena in each zone, you will receive corresponding <$STAR> rewards. Each account can receive this reward only once per day.",
          image: '',
        },
        {
          text: 'When creating an arena, you need to set the number of PKs (based on PK failures as the condition), and the type of rewards for wins and losses (either <$STAR> or cards). If you do not have enough <$STAR> or cards, you will not be able to successfully create the arena.',
          image: '',
        },
        {
          text: 'When setting rewards as <$STAR>, you need to specify the rewards for each PK win or loss; when setting rewards as cards, you must indicate the specific cards for each PK win or loss.',
          image: '',
        }

      ]
  },
  {
    title: 'Win/Loss Determination',
    textArr:
      [
        {
          text: 'Each card in your deck will engage in a 1-to-1 power value PK against the card in the same position in the opponent\'s deck.',
          image: '',
        },
        {
          text: "In PK battles, if your card's attribute can restrain the opponent's attribute, you will receive a 20% bonus to your card's power, while the opponent's power will be correspondingly reduced by 20%.",
          image: attribute,
        },
        {
          text: "There will be a total of six rounds of competition. ",
          image: '',
        },
        {
          text: 'When you act as the challenger and choose an arena for PK, you must win at least 4 rounds to be considered the winner of the PK. ',
          image: '',
        },
        {
          text: 'When you are the arena creator, you only need to win 3 rounds to be deemed the winner.',
          image: '',
        },
        {
          text: 'Each user can initiate a maximum of five arena PKs per day in each zone. Additionally, in the same arena, only one PK can be initiated per day.',
          image: '',
        }

      ]
  }
]

export const miningRules = [
  {
    title: '',
    textArr:
      [
        {
          text: '<$SAI> is a token on the <TON> blockchain,with future airdrop rewards and on-chain trading.',
          image: '',
        },
        {
          text: 'You can engage in Mining with the <$STAR> Coins, Cards, and Avatars you acquire in StarAI.',
          image: '',
        },
        {
          text: 'Joining the VIP pool and using accelerator cards will significantly boost your mining efficiency.',
          image: '',
        },
      ]
  },
]
