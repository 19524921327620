import close_new from '@/assets/images/close_new.png'

interface CloseCompProps {
  top: number
  right: number
  position: string
  callBack: Function
}
const CloseComp = ({ top, right, position, callBack }: CloseCompProps) => {
  return (
    <div
      onClick={() => {
        setTimeout(() => {
          callBack()
        }, 200)
      }}
      className={`${position} close_comp z-30 w-5 h-5 right-[${right}px] top-[${top}px]`}>
      <img src={close_new} alt="" />
    </div>
  )
}
export default CloseComp