import { Popup } from "react-vant"
import BackComp from "../../components/common/BackComp"
import invite_list from '@/assets/images/inviting/invite_list.png'
import copy_Completed from '@/assets/images/tabbar/copy_Completed.png'
import copy from '@/assets/images/tabbar/copy.png'
import { useState } from "react"
import ButtonComp from "../../components/common/ButtonComp"
import { useSelector } from "react-redux"
import { formatNumber } from "../../utils"
import coin from '@/assets/images/coin.png'

interface invitingProps {
  visible: boolean
  rewardsList: Array<any>
  rewardsData: any
  setVisible: Function
  setLoading: Function
  Invitation_code: Function
  loading: boolean
  isCopy: boolean
  setIsCopy: Function
  handleCopy: Function
  title: string
}
const invitingPopup = ({ visible, rewardsList, setVisible, title, Invitation_code, handleCopy, rewardsData, isCopy, setIsCopy, loading }: invitingProps) => {

  const [invitation_code] = useState(localStorage.getItem("inviteCode"))


  return (
    <Popup
      visible={visible}
      style={{ width: '100%', height: '100%', background: '#050909' }}
      position='right'
      onClose={() => { setVisible(false) }}
    >
      <div className=" px-4 pt-[10px]">
        <BackComp title={title} callback={() => { setVisible(false), setIsCopy(false) }}></BackComp>
      </div>
      {title !== 'Invite' && <div className="px-4">
        <div className="flex justify-between mt-6 items-center pr-[10px]">
          <div className="flex gap-1 items-center" onClick={() => {
            if (rewardsList.length > 0) {
              setVisible(true)
            }
          }}>
            <p className="font-bold text-[20px] text-[#fff] leading-[24px]">Stats</p>
          </div>
          <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] mr-1 h-[14px]" src={coin} alt="" /> + {formatNumber(rewardsData?.count) || 0}</p>
        </div>
        <div className=" bg-[#333333] rounded-[10px] mt-[10px] p-[10px]">
          <p className="text-[16px] leading-[19px] text-[#fff] font-bold">Premium</p>
          <div className="flex justify-between mt-[10px] items-center">
            <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Direct Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.premium[0].people) || 0}</span> friends</p>
            <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.premium[0].count) || 0}</p>
          </div>
          <div className="flex justify-between mt-[10px] items-center">
            <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Secondary Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.premium[1].people) || 0}</span> friends</p>
            <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.premium[1].count) || 0}</p>
          </div>
        </div>
        <div className=" bg-[#333333] rounded-[10px] mt-[10px] p-[10px]">
          <p className="text-[16px] leading-[19px] text-[#fff] font-bold">Standard</p>
          <div className="flex justify-between mt-[10px] items-center">
            <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Direct Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.standard[0].people) || 0}</span> friends</p>
            <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.standard[0].count) || 0}</p>
          </div>
          <div className="flex justify-between mt-[10px] items-center">
            <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Secondary Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.standard[1].people) || 0}</span> friends</p>
            <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.standard[1].count) || 0}</p>
          </div>
        </div>
      </div>}
      {rewardsList.length > 0 ?
        <div className="mt-[20px] px-4">
          <div className="flex border-b-[1px] border-solid border-[#232323] justify-between pb-[10px] items-center">
            <p className="font-bold text-[20px]  text-[#fff] leading-[24px]">Invited</p>
            <p className="font-bold text-[20px] text-[#fff] leading-[24px]">Earned $STAR</p>
          </div>
          <div className={`${title !== 'Invite' ? "h-[calc(100vh_-_351px)]" : "h-[calc(100vh_-_88px)]"} pb-[130px] overflow-auto`}>
            {
              rewardsList.map((el: any) =>
                <div key={`Friends${el.account_id}`} className="flex border-b-[1px] border-solid border-[#232323] justify-between py-[20px] items-center">
                  <div className=" flex items-center">
                    <img className="w-6 h-6" src={el.image} alt="" />
                    <p className="text-[14px] text-[#fff] leading-[18px] ml-[10px]">{el.contributor}</p>
                  </div>
                  <p className="text-[14px] text-style-6 flex items-center gap-1 leading-[18px]"><img className="w-4 h-4" src={coin} alt="" /> +{formatNumber(el.points)}</p>
                </div>
              )
            }
          </div>
        </div> :
        <div className=" flex mt-[30px]  justify-center items-center flex-col">
          <img className="w-[152px]" src={invite_list} alt="" />
          <p className="text-[14px] text-[#fff] font-bold leading-[18px] mt-[20px]">Invite friends to get $STAR</p>
        </div>}
      <div style={{ background: 'linear-gradient( 180deg, rgba(5,9,9,0) 0%, rgba(5,9,9,0.82) 24%, #050909 100%)' }}
        className=" fixed bottom-0 left-0 w-[100vw] pt-[10px] h-[128px]">
        <ButtonComp width="174px" isLoading={loading} title='Invite' callBack={() => {
          Invitation_code()
        }}></ButtonComp>
        <div className="mt-[10px] flex justify-between items-center px-4">
          <div className="flex gap-1 items-center">
            <p className="text-[16px] leading-[19px] text-[#fff] font-bold">My invite link:</p>
            <p className="font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">
              https://t.me/…startapp={invitation_code}
            </p>
          </div>
          <div onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            handleCopy()
          }} className={`${isCopy ? '' : 'pt-[6px]'} items-center from-[#00E49F] bg-gradient-to-r rounded-[10px] flex justify-center button_shadow to-[#54ADFF] flex-shrink-0 w-9 h-9`}>
            <img className={` ${isCopy ? 'w-[20px]' : 'w-[28px]'}`} src={isCopy ? copy_Completed : copy} alt="" />
          </div>
        </div>
      </div>
    </Popup>
  )
}
export default invitingPopup