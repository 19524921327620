
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'


import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import { getDeckReward } from '@/api/pk_url.js'
import { loginRewards, getUserInfo, loginCheckIn } from '@/api/url.js'
import { changeUserInfo } from '../store/module/tabbar'

import coin from '@/assets/images/coin.png'
import coinsMusic from "@/assets/mp3/coins.mp3"
import DrawCardsDialog from "../components/DrawCardsDialog"
import NFTDrawCardsDialog from "../components/NFTDrawCardsDialog"
import NewUserGift from "../components/NewUserGIftDialog"
import CardPresentDialog from "../pages/battle/myDeck/CardPresent"
import { changeShowNewUserGiftDialog } from '../store/module/settins';
const width = window.innerWidth;
const height = window.innerHeight;


interface PropType {
    tele?: any
}
const Init = ({ tele }: PropType) => {
    const { isShowIntroductory, isShowNewUserGiftDialog } = useSelector((state: any) => state.settings)

    const [isShowPresent, setShowPresent] = useState(false)
    const [isSHowNewUserGiftDialog, setShowNewUserGiftDialog] = useState(false)
    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [arrayData, setArrayData] = useState<any>([])
    const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
    const AudioRef = useRef<any>()
    const [getPresent, setPresent] = useState(false)
    const [getPresentData, setPresentData] = useState<any>(null)

    const [deckReward, setDeckReward] = useState<any>([])
    const getRewards = async () => {
        try {
            const { code, data } = await loginRewards()
            if (code == '0' && data.tasks.length > 0) {
                setShowNewUserGiftDialog(true)
                dispatch(changeShowNewUserGiftDialog(true))
                setArrayData(data.tasks)
                if (data.tasks[0].status != 'Acquired') {
                    await deckCardReward()
                }
            }
        } catch {

        }
    }

    const deckCardReward = async () => {
        try {
            const { code, data } = await getDeckReward()
            if (code == 0) {
                setDeckReward(data)
            }
        } catch (e) {

        }

    }

    useEffect(() => {
        getRewards()
    }, [])
    const getCoinAnimation = (x: any, y: any) => {
        const R = 100; // 扩散半径
        const time = 0.25; //扩散过度时间
        const time2 = 1; // 收集过度时间
        const w = 26; // 金币大小
        const Num = 8 //金币数量
        setlits(x, y)



        if (AudioRef.current) {
            // AudioRef.current.currentTime = 0;
            AudioRef.current.play()
        }



        const total = document.getElementsByClassName('total_1')[0] // 获取金币最终飞向
        function setlits(X: any, Y: any) {
            var species = document.createElement('div');
            species.className = 'species'
            for (let i = 0; i < Num; i++) {
                var img = document.createElement('img');
                img.className = 'speciesstyle'
                img.style.width = w + 'px',
                    img.style.height = w + 'px',
                    img.style.left = X + 'px',
                    img.style.top = Y + 'px',
                    img.style.transform = 'translate(0%, 0%)',
                    img.style.transition = `${time}s`
                img.src = coin
                species.appendChild(img);
            }
            tele.current.appendChild(species)
            setTimeout(() => {
                clickss(X, Y)
            }, 0)
        }
        // 计算金币扩散范围和坐标
        function clickss(X: number, Y: number) {
            const specielist: any = document.getElementsByClassName('speciesstyle')
            const num = specielist.length
            const center = {
                x: X,
                y: Y
            }
            for (let i = 0; i < num; i++) {
                const xz = center.x + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.sin(2 * Math.PI * i / num)
                const yz = center.y + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.cos(2 * Math.PI * i / num)
                specielist[i].style.transform = `translate(${xz - (X + w / 2)}px, ${yz - (Y + w / 2)}px)`
            }
            setTimeout(() => {
                for (let i = 0; i < num; i++) {
                    specielist[i].style.transition = `${time2}s`
                    specielist[i].style.transform = `translate(0,0)`
                    specielist[i].style.left = Math.floor(total?.getBoundingClientRect().x) + 'px'
                    specielist[i].style.top = Math.floor(total?.getBoundingClientRect().y) + 'px'
                }
                // 结束清除蒙层
                setTimeout(() => {
                    remove()
                }, (Number(time2) + 0.5) * 500) //飞行时间结束

            }, (Number(time) + 0.5) * 500) //扩散时间结束
        }
        // 清除dom
        function remove() {
            document.getElementsByClassName('species')[0].remove()
        }
    }

    const closeDrawCard = (val: any) => {
        dispatch(changeUserInfo({ ...userInfo, card_rewards: [], coins: 0 }))
    }

    const setUserInfo = useCallback(async () => {
        const UserInfo = await getUserInfo()
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))

        }
    }, [])



    return (
        // ${!isShowNewUserGiftDialog ? 'h-0 opacity-0 z-[-1]' : ''}
        <div className={`${!isShowNewUserGiftDialog ? 'h-0 opacity-0 z-[-1]' : ''} role-box descriptionLogs absolute top-0 left-0 w-full h-full text-white pt-12 px-4 box-border pb-[117px] overflow-auto `}>
            {
                Array.isArray(userInfo.card_rewards) && (userInfo.card_rewards.length > 0 || !!userInfo?.coins) && <>
                    {(userInfo.card_rewards.length > 0 ? userInfo.card_rewards[0]?.is_card : !!userInfo?.coins) ?
                        <DrawCardsDialog
                            getCoinAnimation={getCoinAnimation}
                            coins={userInfo?.coins}
                            property={userInfo?.card_rewards[0]?.property}
                            power={userInfo?.card_rewards[0]?.power}
                            dialog={userInfo?.card_rewards.length > 0 ? userInfo?.card_rewards[0]?.is_card : userInfo?.coins}
                            setDialog={closeDrawCard}
                            description={userInfo?.card_rewards.length > 0 ? userInfo?.card_rewards[0]?.is_card : ''}
                            cardLevel={userInfo?.card_rewards.length > 0 ? userInfo?.card_rewards[0]?.is_card : ''}
                            imgUrl={userInfo?.card_rewards.length > 0 ? userInfo?.card_rewards[0]?.is_card : ''}
                            setCardUrl={() => { }}
                        ></DrawCardsDialog> :
                        <NFTDrawCardsDialog></NFTDrawCardsDialog>
                    }
                </>
            }
            {
                isSoundPlay && <audio ref={AudioRef} src={coinsMusic} id="audio"></audio>
            }

            {/* isSHowNewUserGiftDialog && !isShowIntroductory &&  */}
            {
                isSHowNewUserGiftDialog && !isShowIntroductory && <NewUserGift arrayData={arrayData} closeHandle={async () => {

                    // setPresentData([
                    //     {
                    //         "reward": 250000,
                    //         "type": "star",
                    //         "id": 0,
                    //         "card": "",
                    //         "level": 0,
                    //         "description": "",
                    //         "is_card": false,
                    //         "power": 0,
                    //         "property": ""
                    //     },
                    //     {
                    //         "reward": 1,
                    //         "type": "card3",
                    //         "id": 0,
                    //         "card": "/api/v1/file/image/a661e898-dda7-43c7-983f-7c22747ead65_0.webp",
                    //         "level": 3,
                    //         "description": "River Serenade: Palace Beauty in Ice and Shadow",
                    //         "is_card": false,
                    //         "power": 95,
                    //         "property": "fire"
                    //     },
                    //     {
                    //         "reward": 1,
                    //         "type": "card0",
                    //         "id": 0,
                    //         "card": "/api/v1/file/image/0f31de20-a0fe-4c0f-b763-af93c29bfac9_0.webp",
                    //         "level": 0,
                    //         "description": "Enchanted Forest Muse: A Blend of Allure and Magic",
                    //         "is_card": false,
                    //         "power": 42,
                    //         "property": "electric"
                    //     }
                    // ])
                    // setPresent(true)
                    try {
                        const { code, data } = await loginCheckIn()

                        if (code == 0) {
                            setPresentData(data)
                            dispatch(changeShowNewUserGiftDialog(false))
                            setShowNewUserGiftDialog(false)
                            setPresent(true)
                        }
                    } catch (e) {
                        dispatch(changeShowNewUserGiftDialog(false))
                        setShowNewUserGiftDialog(false)
                    }



                }}></NewUserGift>
            }


            {
                getPresent && <CardPresentDialog type='2' title={`You have received 2 cards and ${getPresentData[0]?.reward} $STAR.`} coinsCount={getPresentData[0]?.reward} data={getPresentData.filter((item: any) => item.type != 'star')} callBack={() => {
                    setPresent(false);
                    getCoinAnimation(width / 2, height / 2)
                    setUserInfo()
                    if (arrayData.length > 0 && arrayData[0]?.status != 'Acquired') {
                        setShowPresent(true)
                    }
                }}></CardPresentDialog>
            }
            {
                isShowPresent && !isShowIntroductory && <CardPresentDialog title1='Gift cards cannot be sold.' title='You have received 4 gift cards.' data={deckReward} callBack={(url: any) => { dispatch(changeShowNewUserGiftDialog(false)), navigate(url), setShowPresent(false) }}></CardPresentDialog>
            }


        </div>


    )
}


export default Init;