
import refining_img from '@/assets/images/cards/refining.svg'
import sortImg from '@/assets/images/cards/sort.svg'
import down from '@/assets/images/cards/down.svg'
import loading from '@/assets/images/cards/loading.png'
import { Dialog, Toast, Loading, Popover, PopoverInstance, Switch } from 'react-vant'
import { useNavigate, useSearchParams } from "react-router-dom"
import '@/styles/index.less'
import close from "@/assets/images/task/close.png"
import { useCallback, useEffect, useRef, useState } from 'react';
import { getCards, getRefiningTips, getCardSaleCards } from '@/api/url.js'
import { useDispatch, useSelector } from 'react-redux'
import ClipPathBorderRadius from '../../components/ClipPathBorderRadius'
import CardItem from '../../components/CardItem'
import { convertNumber, FormattingNumber } from '../../utils'
import BScroll from 'better-scroll'
import { changeCardData, changeCardType, changeSaleCardData } from '../../store/module/tasks'
import Sale from './Sale'
import Main from './Main'
import { changeActive, changeIsSaveCard, changeRefiningActive, successRateList } from '../../store/module/search';
import PromptDialog from '../../components/PromptDialog'
import usePlaySound from "@/hook/usePlaySound.tsx"
import CardRefiningGuide from '../../components/rafining/CardRefiningGuide'
import { setRefiningLoadingText } from '../../store/module/progress'
import ButtonComp from '../../components/common/ButtonComp'
import { changeSettings } from '../../store/module/settins'
import WebApp from '@twa-dev/sdk'
import Back from '../../components/common/BackComp'
import CloseComp from '../../components/common/CloseComp'
// import tonicon from "@/assets/images/settings/tonicon.png"
// import usdticon from "@/assets/images/settings/usdticon.png"
// import icon from "@/assets/component/starspay/icon.png"




new BScroll('.role-box', {
    pullUpLoad: true,
    scrollbar: true,
    pullDownRefresh: true
    // and so on
})



const Card = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const type: any = searchParams.get("type") as string;
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const closePlaySound = usePlaySound('close')
    const dispatch = useDispatch()
    const [valueSlider, setValueSlider] = useState('');
    const [limit] = useState(12)
    const [pay, setPay] = useState<any>({})
    const [buyPopup, setBuyPopup] = useState(false)
    const popover = useRef<PopoverInstance>(null)
    const [dialog, setDialog] = useState(false)
    const settings = useSelector((state: any) => state.settings.settings)
    const [refining, setRefining] = useState(false)
    const [buttonActive, setButtonActive] = useState(2)
    const [promptText, setPromptText] = useState<any>('')
    const dialogRef = useRef<any>(null)
    const boxRef = useRef<any>(null)
    const [payDialog, setPayDialog] = useState<any>(false)
    const [active, setActive] = useState<number>(0)
    const [cardsData, setCardsData] = useState<any>(useSelector((state: any) => state.tasks.cardData))
    let [saleCardsData, setSaleCardsData] = useState<any>(useSelector((state: any) => state.tasks.resSale))
    const isSaveCard = useSelector((state: any) => state.search.isSaveCard)
    const [saleCardsCount, setSaleCardsCount] = useState<any>('')
    const [saleDisplayCount, setDisplayCardsCount] = useState<any>('')

    const [cardsCount, setCardsCount] = useState<any>('')
    const [sort, setSort] = useState(0)
    const [is_NSFW, setIs_NSFW] = useState(false)
    const id: any = searchParams.get("id") as string;

    const cardId: string = searchParams.get("cardId") as string;

    useEffect(() => {

        if (id == 1) {
            setButtonActive(Number(id))
            scrollToTop()
            setPage(2)
        }
    }, [id])

    useEffect(() => {
        setTimeout(() => {
            setValueSlider('')
        }, 500)
    }, [buyPopup])

    const getCardsData = useCallback(async (index: number = 0) => {

        setIsLoading(true)
        const ID = window.localStorage.getItem("userId") || "";
        try {
            const res = await getCards(ID, 1, limit, -1, '', 0, '-1', '', index)
            setIsLoading(false)
            if (res.code == 0) {
                setCardsCount(res.data.count)
                setCardsData(res.data.data)
                dispatch(changeCardData(res.data.data))
            }
        } catch (e) {
            setIsLoading(false)
        }
    }, [sort])
    const getSaleCardsData = useCallback(async (index: number, type?: number) => {
        setIsLoading(true)
        try {
            const resSale = await getCardSaleCards(1, limit, index, type)
            setIsLoading(false)
            if (resSale.code == 0) {
                if (!type) {
                    setSaleCardsCount(resSale.data.count)
                } else {
                    setDisplayCardsCount(resSale.data.count)
                }

                setSaleCardsData(resSale.data.data)
                dispatch(changeSaleCardData(resSale.data.data))
            }
        } catch (e) {
            setIsLoading(false)
        }
    }, [sort])
    useEffect(() => {
        getCardsData(0)
        getSaleCardsData(0)
    }, [])

    const [loadingPage, setLoading] = useState(true)
    const [page, setPage] = useState(2)

    function scrollToTop() {
        boxRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth' // 可选，平滑滚动效果
        });
    }
    const pageScroll = async () => {
        let count = buttonActive == 2 ? Math.ceil(saleCardsCount / limit) : buttonActive == 3 ? Math.ceil(saleDisplayCount / limit) : Math.ceil(cardsCount / limit)
        if (loadingPage && count >= page) {
            if (boxRef.current) {
                if (boxRef.current.scrollHeight - boxRef.current.scrollTop <= boxRef.current.clientHeight + 10) {
                    await setLoading(false)
                    if (buttonActive == 2 || buttonActive == 3) {
                        let type = buttonActive == 2 ? 0 : 2
                        const resSale = await getCardSaleCards(page, limit, sort, type)
                        setSaleCardsData([...saleCardsData, ...resSale.data.data])
                    } else {
                        const ID = window.localStorage.getItem("userId") || "";
                        const res = await getCards(ID, page, limit, -1, '', 0, '-1', '', sort)
                        setCardsData([...cardsData, ...res.data.data])
                    }
                    setPage(page + 1)
                    setLoading(true)
                }
            }
        }
    }
    //mint
    const goToMint = (id: string) => {
        navigate(`/nft?id=${id}`)
    }

    // ------------------------ 滑动 -----------------------
    useEffect(() => {
        document.addEventListener('touchmove', touchMoveHandle, false);
        return () => {
            document.removeEventListener('touchmove', touchMoveHandle, false);
            // window.scrollTo(0, 0);
        }

    })

    const touchMoveHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 0.5px)",
                "important"
            );
        }
    }

    // ------------------------ 滑动 -----------------------
    const [tips, setTips] = useState([])
    const [tipsData, setTipsData] = useState('')
    const getTips = useCallback(async () => {
        try {
            const { code, data } = await getRefiningTips()
            setTipsData(data.tips)
            dispatch(successRateList(data.ups))
            dispatch(setRefiningLoadingText(data.rafining_loading_text))
            if (code == 0 && Array.isArray(data.copywriting)) {
                setTips(data.copywriting)
            }
        } catch (error) {
            console.log(error)
        }

    }, [])
    useEffect(() => {
        getTips()
    }, [])

    useEffect(() => {
        setSaleCardsCount("")
        setSaleCardsData("")
        setDisplayCardsCount("")
    }, [buttonActive])

    const lotteryHandle = async () => {
        if (Object.keys(pay).length > 0 && pay.link) {
            WebApp.openInvoice(pay.link, async (status) => {
                if (status === "paid") {
                    dispatch(changeSettings({ ...settings, star_nsfw: true }))
                }
            })
        }
    }
    return (
        <div ref={boxRef} onScroll={pageScroll} className="w-full relative role-box h-[100vh] overflow-auto pb-[140px]">
            <div className='flex items-center justify-center bg-[#050A0A] fixed z-[801] w-full gap-[20px]'>
                <Back isHiddenIcon={type == 'myDeck' ? false : true} isLeft={type == 'myDeck' ? true : false} isMl={type == 'myDeck' ? true : false} background='#050A0A' callback={() => {
                    type == 'myDeck' && navigate("/myDeck")
                }}>
                    <div className={`${type == 'myDeck' ? 'w-[calc(100%_-_96px)]' : 'w-[calc(100%_-_96px)]'} text-center grid grid-cols-3 gap-0  ${buttonActive == 1 ? '' : ''}`}>
                        <ClipPathBorderRadius setButtonActive={() => {

                            setButtonActive(2)
                            scrollToTop()
                            setPage(2)
                            dispatch(changeCardType(2))
                            setSort(0)
                            navigate('/cards?id=2')
                            localStorage.setItem('level', "-1")
                            dispatch(changeActive("-1"))
                        }} getCardsData={() => { getSaleCardsData(0) }} buttonActive={buttonActive} loading={isLoading} Refresh={true} borderRadius={16} height={31} flag={2} name="Sale" text={`${saleCardsCount > 0 ? FormattingNumber(saleCardsCount.toString()) : ''}`} ></ClipPathBorderRadius>
                        <ClipPathBorderRadius setButtonActive={() => {

                            getSaleCardsData(0, 2)
                            setButtonActive(3)
                            scrollToTop()
                            setPage(2)
                            dispatch(changeCardType(3))
                            setSort(0)
                            navigate('/cards?id=3')
                            localStorage.setItem('level', "-1")
                            dispatch(changeActive("-1"))
                        }} getCardsData={() => { getSaleCardsData(0, 2) }} buttonActive={buttonActive} loading={isLoading} borderRadius={16} height={31} flag={3} name="Display" text={`${saleDisplayCount > 0 ? FormattingNumber(saleDisplayCount.toString()) : ''}`} ></ClipPathBorderRadius>
                        <ClipPathBorderRadius setButtonActive={() => {

                            getCardsData()
                            setButtonActive(1)
                            scrollToTop()
                            setPage(2)
                            dispatch(changeCardType(1))
                            navigate('/cards?id=1')
                            localStorage.setItem('level', "-1")
                            dispatch(changeActive("-1"))
                        }} getCardsData={getCardsData} buttonActive={buttonActive} borderRadius={16} height={31} flag={1} name="Mine" text={`${cardsCount > 0 ? FormattingNumber(cardsCount.toString()) : ''}`}></ClipPathBorderRadius>
                    </div>
                    <div className='w-[56px] h-[10] flex items-center justify-end'>
                        <div className={`right-11 search absolute top-[14px]`} onClick={() => { closePlaySound(); navigate(`/search?id=${buttonActive}`); }}>
                            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="10-cards-sale" transform="translate(-341.000000, -56.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g id="编组" transform="translate(341.000000, 56.000000)">
                                            <path d="M17.7932931,16.780932 L14.4487269,13.4363657 C15.6420385,12.0147435 16.3617488,10.1821212 16.3617488,8.18082326 C16.3617488,3.66268725 12.6990615,0 8.18092555,0 C3.66268725,0 0,3.66258496 0,8.18072097 C0,12.698857 3.66268725,16.3615442 8.18082326,16.3615442 C10.1821212,16.3615442 12.0147435,15.6419362 13.4363657,14.4486246 L16.780932,17.7931908 C17.0609287,18.0699859 17.5119001,18.0687007 17.7903146,17.7903143 C18.0687292,17.5119279 18.0700599,17.0609566 17.7932931,16.780932 L17.7932931,16.780932 Z M1.43164663,8.18072097 C1.43164663,4.45921714 4.45931943,1.43154434 8.18082326,1.43154434 C11.9023271,1.43154434 14.9299999,4.45921714 14.9299999,8.18072097 C14.9299999,11.9022248 11.9023271,14.9298976 8.18082326,14.9298976 C4.45931943,14.9298976 1.43164663,11.9022248 1.43164663,8.18072097 Z" id="形状"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className={`${buttonActive == 1 ? '' : ''}  absolute w-[18px] h-[18px] right-3 top-[14px]`}>
                            <Popover
                                ref={popover}
                                placement='bottom-end'
                                theme='dark'
                                className=''
                                reference={
                                    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <g id="页面-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="人物聊天三个按钮" transform="translate(-341.000000, -56.000000)" fill="#FFFFFF" fillRule="nonzero">
                                                <g id="编组" transform="translate(341.000000, 56.000000)">
                                                    <path d="M17.2208518,1.78487866 L1.63820742,1.78487866 C1.24863332,1.78487866 0.859059226,1.33865246 0.859059226,0.892426255 C0.859059226,0.446200055 1.24863332,0 1.63820742,0 L17.2208518,0 C17.6104259,0 18,0.4462262 18,0.892426255 C18,1.33862631 17.6104259,1.78487866 17.2208518,1.78487866 L17.2208518,1.78487866 Z M17.3939958,9.59366722 L5.27416133,9.59366722 C4.97115925,9.59366722 4.66815718,9.14746716 4.66815718,8.70124096 C4.66815718,8.25501476 4.97115925,7.80881471 5.27416133,7.80881471 L17.3939958,7.80881471 C17.6969979,7.80881471 18,8.25501476 18,8.70124096 C18,9.14746716 17.6969979,9.59366722 17.3939958,9.59366722 Z M17.3939958,17.4024819 L5.27416133,17.4024819 C4.97115925,17.4024819 4.66815718,16.9562557 4.66815718,16.5100557 C4.66815718,16.0638556 4.97115925,15.6176033 5.27416133,15.6176033 L17.3939958,15.6176033 C17.6969979,15.6176033 18,16.0638295 18,16.5100557 C18,16.9562819 17.6969979,17.4024819 17.3939958,17.4024819 Z M1.81133371,17.4024819 L0.691867913,18 L0.905666857,16.7344364 L-5.41860487e-14,15.8381593 L1.25160081,15.6535161 L1.81133371,14.5020683 L2.37106661,15.6535161 L3.62266743,15.8381593 L2.71700057,16.7344364 L2.93079951,18 L1.81133371,17.4024819 Z M1.81133371,9.66804551 L0.691867913,10.2655636 L0.905666857,9 L-5.41860487e-14,8.10372289 L1.25160081,7.91907965 L1.81133371,6.76763186 L2.37106661,7.91907965 L3.62266743,8.10372289 L2.71700057,9 L2.93079951,10.2655636 L1.81133371,9.66804551 Z" id="形状"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                }
                            >
                                {buttonActive == 1 ?
                                    <div className='px-[10px] py-[20px] bg-[#333] relative !z-[100000] w-[180px] rounded-[8px]'>
                                        <div className='flex font-semibold text-[16px] leading-[28px] text-[#fff] items-center justify-between'
                                            onClick={async () => {
                                                let index = sort === 0 ? 1 : 0
                                                setSort(index)
                                                scrollToTop()
                                                setPage(2)
                                                // getSaleCardsData(index)
                                                getCardsData(index)
                                            }}
                                        >

                                            Time Acquired
                                            {sort == 0 || sort == 1 ? <img className={`w-[20px] ${sort == 0 ? 'rotate-180' : ''}`} src={down} alt="" /> : <img className='w-[22px]' src={sortImg} alt="" />}
                                        </div>

                                        <div className='flex font-semibold mt-4 text-[16px] leading-[28px] text-[#fff] items-center justify-between'
                                            onClick={async () => {
                                                let index = sort == 2 ? 3 : 2
                                                setSort(index)
                                                scrollToTop()
                                                setPage(2)
                                                // getSaleCardsData(index)
                                                getCardsData(index)
                                            }}
                                        >
                                            Card Power
                                            {sort == 2 || sort == 3 ? <img className={`w-[20px] ${sort == 2 ? 'rotate-180' : ''}`} src={down} alt="" /> : <img className='w-[22px]' src={sortImg} alt="" />}
                                        </div>
                                    </div>
                                    :
                                    <div className='px-[10px] py-[20px] bg-[#333] relative !z-[100000] w-[180px] rounded-[8px]'>
                                        <div className={`${buttonActive == 3 ? 'hidden' : ''} flex mb-4 font-semibold text-[16px] leading-[28px] text-[#fff] items-center justify-between`}
                                            onClick={async () => {
                                                let index = sort === 1 ? 2 : 1
                                                setSort(index)
                                                scrollToTop()
                                                setPage(2)
                                                getSaleCardsData(index)
                                            }}
                                        >

                                            Price
                                            {sort == 1 || sort == 2 ? <img className={`w-[20px] ${sort == 2 ? 'rotate-180' : ''}`} src={down} alt="" /> : <img className='w-[22px]' src={sortImg} alt="" />}
                                        </div>

                                        <div className='flex font-semibold text-[16px] leading-[28px] text-[#fff] items-center justify-between'
                                            onClick={async () => {
                                                let index = sort == 3 ? 4 : 3
                                                let type = buttonActive == 3 ? 2 : 0
                                                setSort(index)
                                                scrollToTop()
                                                setPage(2)
                                                getSaleCardsData(index, type)
                                            }}
                                        >
                                            Likes
                                            {sort == 3 || sort == 4 ? <img className={`w-[20px] ${sort == 4 ? 'rotate-180' : ''}`} src={down} alt="" /> : <img className='w-[22px]' src={sortImg} alt="" />}
                                        </div>
                                    </div>
                                }

                            </Popover>
                        </div>
                    </div>
                </Back>

            </div>
            {
                buttonActive === 1 && cardsData && cardsData.length == 0 ?
                    <div className=' flex flex-col items-center h-full justify-center'>
                        <img className='w-[185px]' src={loading} alt="" />
                        <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>Chat to get cards</p>
                    </div> : <>
                        {buttonActive === 1 ?
                            <Main cardsData={cardsData} setPage={setPage} updateHandle={getCardsData} cardId={cardId}></Main> :
                            buttonActive === 3 ? <Sale sort={sort} saleCardsData={saleCardsData} scrollToTop={scrollToTop} setPage={setPage} setSaleCardsData={setSaleCardsData} setCardsData={setCardsData} ></Sale> :
                                <Sale sort={sort} saleCardsData={saleCardsData} scrollToTop={scrollToTop} setPage={setPage} setSaleCardsData={setSaleCardsData} setCardsData={setCardsData} ></Sale>}
                        <Dialog
                            className='PublicDialog overflow-visible'
                            visible={visible}
                            showConfirmButton={false}
                            showCancelButton={false}
                            closeOnClickOverlay={true}
                            onClose={() => { setVisible(false) }}
                            width={'85%'}
                        >
                            <div
                                className="w-[30px] h-[30px] absolute bottom-[-50px] left-[50%] translate-x-[-50%] " >
                                <CloseComp position="absolute" top={14} right={16} callBack={() => { setVisible(false) }}></CloseComp>
                            </div>
                            <div ref={dialogRef} className="w-[100%] items-center flex justify-center p-2 overflow-hidden relative">
                                <CardItem no={`No. ${convertNumber(cardsData[active]?.seq)}`} image={cardsData[active]?.image} big={true} text={cardsData[active]?.description} level={cardsData[active]?.is_official ? 6 : cardsData[active]?.level + 1}></CardItem>
                            </div>
                        </Dialog>
                    </>
            }
            {
                !loadingPage &&
                <div className={'w-[100%]  py-4 flex flex-col items-center'}>
                    <Loading type="spinner" size="20" color="#00E49F">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                    </Loading>
                </div>
            }

            {
                <div className='w-full flex justify-center items-center fixed left-[50%] z-[100] bottom-[120px] translate-x-[-50%]'>
                    <ButtonComp title='Refining' callBack={() => {
                        if (cardsData.length > 0) {
                            if (tips.length > 0) {
                                setRefining(true)
                            }
                            else {
                                if (isSaveCard) {
                                    dispatch(changeIsSaveCard())
                                }

                                navigate("/refining")
                            }
                            dispatch(changeRefiningActive("4"))
                        } else {
                            setPromptText("Chat with AI Avatars will get 2 Cards per day!")
                            setDialog(true)
                        }
                    }}>
                        <img className='h-[14px] w-[14px] mr-1' src={refining_img} alt="" />
                    </ButtonComp>
                    {/* <button onClick={() => {
                    }} style={{ textShadow: ' 0px 2px 4px rgba(0,0,0,0.5)' }} className="bg-gradient-to-r button_shadow flex gap-1 justify-center items-center t-center w-[174px] rounded-[10px] h-[36px] text-[#fff] from-[#00E49F] to-[#54ADFF]"><img src={refining_img} alt="" /> Refining </button> */}
                </div>
            }
            <CardRefiningGuide tips={tips} tipsData={tipsData} dialog={refining} setDialog={setRefining}></CardRefiningGuide>


            <PromptDialog
                title='Warning'
                dialog={is_NSFW}
                is_Stars={'Stars'}
                price='9 Stars'
                setDialog={setIs_NSFW}
                text_mt={10}
                text={'You must be at least 18 years old to enable NSFW content. By turning on this setting, you will unlock all NSFW content for a period of six months, which requires a payment of 9 Stars.'}
                text_1={'Do you want to proceed?'}
                callback={lotteryHandle}
            ></PromptDialog>
            <PromptDialog title={'Attention'} dialog={payDialog} setDialog={setPayDialog} callback={() => { setDialog(false) }} text={promptText}></PromptDialog>
        </div >
    )
}


export default Card;
