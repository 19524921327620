import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface tasksState {
  createData: any;
  languageList: any;
  shareLogList: any
}

const initialState: tasksState = {
  createData: {
    session_id: '',
    name: '',
    gender: '',
    introduction: '',
    avatar_image: '',
    opening_statements: '',
    chat_example: [{ question: '', answer: '' }],
    images: [],
    avatar_type: 0,
    price: '',
    voice: '',
    is_nsfw: false,
    tags: '',
    prompt: '',
  },
  languageList: [],
  shareLogList: [] //分享时选中的聊天记录
}
const createStore = createSlice({
  name: 'create',
  initialState,
  reducers: {
    changeCreateData(state, action: PayloadAction<any>) {
      state.createData = { ...state.createData, ...action.payload }
    },
    languageList(state, action) {
      state.languageList = action.payload
    },
    changeShareLogList(state, action) {
      state.shareLogList = [...state.shareLogList, action.payload]
    },
    delShareLogList(state, action) {
      state.shareLogList = state.shareLogList.filter((item: string) => item !== action.payload)
    },
    removeShareLogList(state) {
      state.shareLogList = []
    }
  }
})

export const { changeCreateData, languageList, changeShareLogList, delShareLogList, removeShareLogList } = createStore.actions

export default createStore.reducer