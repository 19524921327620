import { Dialog } from "react-vant"
import { useEffect, useRef } from "react"
import coinsImg from '@/assets/images/task/coins.png'
import CardItem from "./CardItem"
import { formatNumber } from "../utils"
import ButtonComp from "./common/ButtonComp"
import { changeUserInfo } from "../store/module/tabbar"
import { useDispatch, useSelector } from "react-redux"
interface DrawCardsDialogProps {
  dialog?: boolean
  setDialog: (val: boolean) => void
  imgUrl: string
  description?: string
  cardLevel: number
  isShowBtn?: boolean
  teleport?: any
  isOverlay?: boolean
  width?: string
  setCardUrl: Function
  coins?: number
  getCoinAnimation?: any
  power?: any
  property?: any
}

const DrawCardsDialog = ({ power = 0, property = '', dialog, setDialog, imgUrl, cardLevel, description, isShowBtn = true, teleport, isOverlay = true, width, setCardUrl, coins, getCoinAnimation }: DrawCardsDialogProps) => {
  var mo = function (e: any) { e.preventDefault(); };
  const userInfo = useSelector((state: any) => state.tabbar.userInfo)
  const dialogRef = useRef<any>(null)
  const dispatch = useDispatch()
  const coinsRef = useRef<any>(null)
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={true}
      onClose={() => { setDialog(false), setCardUrl('') }}
      width={'100%'}
      teleport={teleport}
      overlay={isOverlay}
    >
      <div ref={dialogRef} className={`flex ${dialog ? '' : 'hidden'} px-10 flex-col items-center justify-center`}>
        <div
          data-aos="flip-left"
          data-aos-duration="1000"
          className={`items-center flex ${width ? `w-[${width}]` : 'w-[100%]'} flex-col justify-center relative`}>
          {imgUrl && <CardItem power={power} property={property} no={""} image={imgUrl} rounded={25} text={description} big={true} level={cardLevel + 1}></CardItem>}
        </div>
        {coins && <div style={{ fontSize: `${imgUrl ? '' : '36px'}`, marginBottom: `${imgUrl ? '' : '48px'}` }} className=" flex items-center text-style-6 !text-[24px] my-6 gap-3 font-bold" > <img ref={coinsRef} style={{ width: `${imgUrl ? '' : '120px'}` }} className="w-12 " src={coinsImg} alt="" /> +{formatNumber(coins)}</div>}
        {
          isShowBtn &&
          <>
            <ButtonComp title="OK" callBack={() => {
              if (coins && coinsRef.current) {
                getCoinAnimation(coinsRef.current.getBoundingClientRect().x, coinsRef.current.getBoundingClientRect().y)
              }
              setDialog(false)
              setCardUrl('')
            }}></ButtonComp>
          </>


          // <div onClick={} style={{ marginTop: `${coins ? '' : '48px'}` }} className="bg-gradient-to-r flex justify-center  items-center text-center w-[174px] rounded-[10px] h-[36px] text-[#fff] from-[#00E49F] to-[#54ADFF]">OK</div>

        }
      </div>
    </Dialog>
  )
}
export default DrawCardsDialog
