import { CountDown, Loading, Overlay } from "react-vant"
import day from "../../assets/images/day.png"
import loadingImg from '@/assets/images/task/loading.svg'
import CardItem from "../../components/CardItem"
import { convertNumber } from "../../utils"
import { NFT_ICON } from "../../utils/nftIcon.jsx"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { getCardDetail, postMintNft } from '../../api/url';
import wallet from "../../hook/wallet/contentWallet"
import { useNftContract } from "../../hook/contract/nft/contract.mint_nft"
import PublicDialog from "../../components/PublicDialog.js"
import WebApp from "@twa-dev/sdk"
import { useDispatch, useSelector } from "react-redux"
import { Address } from "@ton/core"
import TonNetworkError from "../../hook/useTonNetworkError.js"
import Back from "../../components/common/BackComp.js"
import ButtonComp from "../../components/common/ButtonComp.js"
import PromptDialog from "../../components/PromptDialog.js"
import { changeMintTransactionStatus } from "../../store/module/tontransaction.js"


const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));


interface PropsType {
    cardId?: string
    callback?: (id: string) => void
    cardState?: string | number
}

const NftComponent = ({ cardId, callback, cardState }: PropsType) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const [id, setId] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const cardInfo = useRef<any>(null)
    const nftContract = useNftContract()
    const { useConnectWallet, useGetWalletAddress } = wallet();
    const address = useGetWalletAddress()
    const [visible, setVisible] = useState(false)
    const isSendMintSuccess = useSelector((state: any) => state.ton.isSendMintSuccess)

    //链接钱包

    const connectWallet = async () => {
        if (address) {
            return false
        }
        useConnectWallet();
    }

    //签到时链接钱包弹窗的显示隐藏
    const [checkInWallet, setCheckInWallet] = useState(false)
    const [isConnect, setIsConnect] = useState(false)


    useEffect(() => {
        setIsConnect(false);
        if (address) {
            // setDialog(true)
            setCheckInWallet(false)
        }
    }, [address])

    //签到链接钱包的弹窗
    const connect_wallet_button = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <p className='text-sm text-[#333]'>Please connect your TON wallet.</p>
                <ButtonComp callBack={() => { connectWallet() }} title="Connect"></ButtonComp>
            </div>
        )
    }


    // 当前 nft 的index
    const [currIndex, setCurrIndex] = useState(0)
    const [nftAddress, setNftAddress] = useState<any>("")
    //控制抢锁
    const [lock, setLock] = useState(false)
    //mint loading
    const [isMintLoading, setMintLoading] = useState(false)
    //error dialog
    const [mintErrorDialog, setMintErrorDialog] = useState(false)
    const [mintErrorText, setMintErrorText] = useState(["High Traffic", "Please try again later.", ""])
    const [nftUrlList, setNftUrlList] = useState([])
    const [tonNetworkVisible, setTonNetworkVisible] = useState(false)

    const time = useRef<any>("")



    //占锁失败弹窗
    const lock_error_button = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <div className="text flex flex-col justify-center items-center">
                    <p className='text-[#333] text-2xl mb-5'>Attention</p>
                    <p className='text-sm text-[#333]'>You are minting another NFT.</p>
                    <p className='text-sm text-[#333]'>Wait 1 minute for minting to complete.</p>
                </div>

                <ButtonComp title="OK" callBack={() => {
                    setLock(false)
                }}></ButtonComp>

            </div>
        )
    }

    // mint 失败弹窗
    const mint_error_button = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <div className="text flex flex-col justify-center items-center">
                    <p className='text-[#333] text-2xl mb-5'>{mintErrorText[0]}</p>
                    <p className='text-sm text-[#333]'>{mintErrorText[1]}</p>
                    <p className='text-sm text-[#333]'>{mintErrorText[2] ? mintErrorText[2] : ''}</p>
                </div>

                <ButtonComp title="OK" callBack={() => {
                    setMintErrorDialog(false)
                }}></ButtonComp>
            </div>
        )
    }

    useLayoutEffect(() => {
        // const id: string = searchParams.get("id") as string;
        // setId(id)
        // getCardInfo(id)
    }, [])

    const getCardInfo = async (id: string) => {

        setLoading(true)
        try {
            const { code, data } = await getCardDetail(id)
            setLoading(false)
            if (code == "0") {
                cardInfo.current = data
                if (data.nfts && data.nfts.length) {
                    setNftUrlList(data.nfts)
                }

                if ((new Date(data.mint_start_time).getTime() - new Date().getTime()) < 0) {
                    if ((new Date(data.mint_end_time).getTime() - new Date().getTime()) > 0) {
                        time.current = (new Date(data.mint_end_time).getTime() - new Date().getTime())
                    } else {
                        time.current = 0
                    }

                } else {
                    time.current = (new Date(data.mint_start_time).getTime() - new Date().getTime())
                }

            }

        } catch (e) {
            console.log(e)
        }

    }


    //捕获链上交易异常
    useEffect(() => {
        if (isSendMintSuccess?.includes("mintTransactionSuccess")) {
            loopTransaction()
        } else if (isSendMintSuccess == "-1") {
            console.log("🚀 ~ useEffect ~ isSendMintSuccess:", isSendMintSuccess)

            setTimeout(async () => {
                await mintCallbackHandle(-1)
                setMintLoading(false)
            }, 500)

        }
    }, [isSendMintSuccess])

    const [networkError, setNetworkError] = useState(false)
    const errorText = useRef("")
    const loopTransaction = async () => {
        try {
            const params = {
                card_id: cardId,
                wallet: Address.parse(address).toString(),
                index: currIndex,
                state: 1,
                boc: isSendMintSuccess.split("_@_")[1]
            }
            setVisible(true)
            const res = await postMintNft(params)
            dispatch(changeMintTransactionStatus(""))
            setVisible(false)
            if (res?.code == "0") {
                callback && callback(cardId)
            } else if (res?.code == "65551") {
                errorText.current = "Transaction failed, please try again."
                setNetworkError(true)
            } else if (res?.code == "65539") {
                errorText.current = "Blockchain network congested.Please wait for 5 minutes."
                setNetworkError(true)
            } else {
                errorText.current = "Network Error."
                setNetworkError(true)
            }
            console.log("🚀 ~ loopTransaction ~ res:", res)
        } catch (e) {
            dispatch(changeMintTransactionStatus(""))
            setVisible(false)
            errorText.current = "Network Error."
            setNetworkError(true)
        }


    }

    const mintHandle = async (state: number) => {

        if (cardState == 4) {
            errorText.current = "Your card is currently in the PK Deck and temporarily cannot be minted. Please finish the PK before trying again."
            setNetworkError(true)
            return false
        }
        if (!address) {
            setCheckInWallet(true)
            return
            // useConnectWallet()
        }
        // if (isMintLoading) {
        //     return false
        // }
        // setMintLoading(true);
        let nftItem;
        while (!nftItem) {
            nftItem = await nftContract.latestIndex()
        }
        const index = nftItem?.next_item_index;
        const nft_address = await nftContract.mintingNftAddress(index)
        setCurrIndex(Number(index))
        setNftAddress(nft_address)


        try {
            setMintLoading(true)
            const params = {
                card_id: cardId,
                wallet: Address.parse(address).toString(),
                index: Number(index),
                state,
            }
            const { code, data } = await postMintNft(params)
            if (code != 0) {
                setLock(true)
                setMintLoading(false)
                return
            } else {
                const _d = data
                if (nft_address?.toString()) {
                    await nftContract.mint()

                }

            }

        }
        catch (e) {
            console.log(e)
            setMintLoading(false)
        }
        setVisible(false)
    }

    // mint error callback
    const mintCallbackHandle = async (state: number, nft_id?: number, urlList?: any, nft_addr?: string) => {
        const params = {
            card_id: cardId,
            index: nft_id,
            nft_addr,
            state,
            callback: urlList
        }
        try {
            const { code, data } = await postMintNft(params)
            if (code == 0) {
                console.log(data)
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    const [promptDialog, setPromptDialog] = useState(false)
    const [promptText, setPromptText] = useState('')
    return (
        <>
            {/* <div onClick={() => {

            }} className="w-[130px] h-9 mx-auto button_shadow flex justify-center items-center rounded-md text-white bg-gradient-to-r from-[#00E49F] to-[#54ADFF]  focus:outline-none">
                <span className="text-base">Mint</span>
            </div> */}

            <ButtonComp width={130} title="Mint" callBack={() => {
                if (cardState != 6) {
                    mintHandle(0)
                } else {
                    setNetworkError(true)
                    errorText.current = 'Your card is currently in the Mining and temporarily cannot be minted.Please finish the Mining before trying again.'
                }
            }}></ButtonComp>

            {/* 链接钱包的弹窗 */}
            <PublicDialog width={"290px"} isPadding={false} dialog={checkInWallet} setDialog={setCheckInWallet} content={connect_wallet_button()} customClassName="customClassName"></PublicDialog>
            {/* 抢占锁失败弹窗 */}
            <PublicDialog width={"290px"} isPadding={false} dialog={lock} setDialog={setLock} content={lock_error_button()}></PublicDialog>
            {/* mint reject dialog */}
            <PublicDialog width={"290px"} isPadding={false} dialog={mintErrorDialog} setDialog={setMintErrorDialog} content={mint_error_button()}></PublicDialog>



            {/* ton network dialog and anytime cancel*/}
            {/* {
                tonNetworkVisible && <TonNetworkError closeHandle={() => { setTonNetworkVisible(false) }}></TonNetworkError>
            } */}
            <Overlay visible={visible}
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '50px',
                }}>
                <Loading type="spinner" size="50" color="#00E49F">
                </Loading>
                <p className=" text-[14px] mt-[30px] text-[#fff] font-bold leading-[18px]">
                    Your NFT is minting.
                </p>
                <p className=" text-[14px] text-[#fff] font-bold leading-[18px]">
                    Please do not leave this page to avoid errors.
                </p>
                <p className=" text-[14px] text-[#fff] font-bold leading-[18px]">
                    Wait about one minute.
                </p>
            </Overlay>
            <PromptDialog isClose={true} lineBreak title={'Attention'} dialog={networkError} setDialog={setNetworkError} text={errorText.current}></PromptDialog>

        </>
    )
}


export default NftComponent