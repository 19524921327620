import React from 'react';
const Emoji = ({ sendMessageEmoji }: any) => {

    const emojiList = JSON.parse(localStorage.getItem("emojiList") as string) || [];
    const sendEmoji = (em: any) => {
        sendMessageEmoji(em)
    }
    return (
        <div className="emoji-swiper grid grid-cols-8">
            {
                emojiList.map((el: any, index: number) => {
                    return (
                        // <div key={index} className="grid grid-cols-8">

                        <div key={index} className="mt-2" onClick={(e) => {e.stopPropagation(), sendEmoji(el) }}>
                            <img src={el.base64} alt="" />
                        </div>


                        // </div>
                    )
                })
            }
        </div>
    )
}


export default Emoji