
import Index from "../../pages/starRecord/Index";
import Search from "../../pages/starRecord/Search";


const shopRouter = () => {
    return [
        {
            path: "/starRecord",
            element: <Index></Index>,
        },
        {
            path: "/starRecord-search",
            element: <Search></Search>,
        }
    ]
}

export default shopRouter;