import loading from '@/assets/images/talk/loading.png';
import voice_1 from '@/assets/images/talk/voice_1.png';
import voice_3 from '@/assets/images/talk/voice_3.png';
import voice_2 from '@/assets/images/talk/voice_2.png';
import voice from '@/assets/images/talk/voice.png';
import activeImg from "@/assets/images/selectActive.png"
import audioIcon from '@/assets/images/talk/audio-icon.png';
import "./Chat.less"
import { useEffect, useRef, useState } from 'react';
import { addAvatarsImgList, delAvatarsImgList } from '@/store/module/progress';
import { changeShareLogList, delShareLogList } from "@/store/module/createAvatar"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
let isPlayAudio = false;
let audioSrc: string;
// const list = JSON.parse(localStorage.getItem("emojiList")as string) || [];
// console.log(list)
let list: any[] = [];
const Chats = (props: any) => {
    const dispatch = useDispatch()
    const avatarsImgList = useSelector((state: any) => state.progress.avatarsImgList)
    const shareLogList = useSelector((state: any) => state.createAvatar.shareLogList)
    const [active, setActive] = useState(false);
    const [isImgSelected, setImgSelected] = useState(false);

    const [currImg, setCurrImg] = useState<any[]>([])

    useEffect(() => {
        list = JSON.parse(localStorage.getItem("emojiList") as string)
        // setList((prevData: any) => [...]);
    }, [])


    const { info, content, isShowVoice = true, isSelect = false, isAvatar = false, logsList, main_url = "" } = props;
    const { direction, status, type, audioUrl, durations, isError, hidden, isSelected } = info
    const avatarClass = direction === "right" ? "avatar-right" : "";
    const textClass = direction === "right" ? "text-left" : "text-left";
    const contentText = () => {
        const refs = content.toString().replace(/\[([^(\]|\[)]*)\]/g, (item: string) => {
            if (direction == 'right') {
                if (list) {
                    for (let i = 0; i < list.length; i++) {
                        const itemList = list[i]
                        if (itemList && itemList.alt == item) {
                            const imgstr = `<img src="${itemList.base64}" style="width: 32px;height:32px;display: inline-block;vertical-align: middle;" class="dom_emoji" />`;
                            return imgstr;
                        }
                    }
                }
            } else {
                return ""
            }

        })

        return refs

    }
    const html = { __html: contentText() };
    var audios: any = null;
    const playAudio = () => {
        console.log("点击播放了")
        if (!isPlayAudio) {
            audios = new Audio(audioUrl);
        }
        if (isPlayAudio && audios && audios.paused) {
            return false;
        }
        audioSrc = audioUrl;
        if (audioUrl.indexOf("blob") == -1) {
            audioSrc = "blob:" + audioUrl
        }
        if (audios && audios.paused) {
            isPlayAudio = true;
            setActive(true)
            audios.currentTime = 0;
            audios.play(); // 这样就能播放了
        } else {
            isPlayAudio = false;
            setActive(false)
            audios.pause(); // 这样就能播放了
        }

        audios.addEventListener('ended', function () {
            isPlayAudio = false;
            setActive(false)
        }, false);


    }


    const selectImgHandle = (item: any) => {
        if (avatarsImgList.indexOf(item) !== -1) {
            dispatch(delAvatarsImgList(item))
        } else {
            if (avatarsImgList.length < 4) {
                dispatch(addAvatarsImgList(item))
            }
        }

    }
    const shareLogsHandle = () => {
        if (!isSelect) {
            return false
        }
        for (let i = 0; i < logsList.length; i++) {
            if (logsList[i].id == info.id) {
                if (info.direction == "left") {
                    if (shareLogList.includes(info.id)) {
                        dispatch(delShareLogList(logsList[i - 1].id))
                        dispatch(delShareLogList(logsList[i].id))
                    } else {
                        dispatch(changeShareLogList(logsList[i - 1].id))
                        dispatch(changeShareLogList(logsList[i].id))
                    }
                }
                if (info.direction == "right") {
                    if (shareLogList.includes(info.id)) {
                        dispatch(delShareLogList(logsList[i].id))
                        dispatch(delShareLogList(logsList[i + 1].id))
                    } else {
                        dispatch(changeShareLogList(logsList[i].id))
                        dispatch(changeShareLogList(logsList[i + 1].id))
                    }
                    // console.log(logsList[i])
                    // console.log(logsList[i + 1])
                }

            }
        }

    }

    return (

        <div className={avatarClass + ` chats flex justify-start ${isAvatar ? 'items-start' : 'items-end'}  mb-4 relative`}>
            {
                direction == 'left' && isAvatar && <div style={{ backgroundImage: `${main_url ? `url(${main_url}` : ''})` }} className={`avatar mr-2 w-10 h-10 rounded-full  bg-cover`}>

                </div>
            }


            {
                isSelect && <div onClick={() => {
                    shareLogsHandle()
                }} className={`w-5 h-5 ${isSelected ? '' : ' border-2'} absolute left-0 top-[calc(50%_-_10px)] rounded-full`}>
                    {
                        isSelected && <img src={activeImg} alt="" />
                    }

                </div>
            }
            {
                type === 'audio' && direction === 'right' ?
                    (
                        <div className={`${textClass} bg-[#225255]/80 text-[#43FFD4] text max-w-[78%] min-h-10 min-w-20 text-lg leading-5 pl-[9px] py-[11px] pr-[11px] flex justify-end`} onClick={playAudio}>
                            <span className='mr-5'>{durations}</span>
                            <img src={audioIcon} className='w-3 h-5' alt="" />
                            {/* <audio src={audioUrl} hidden={true} controls ref={audioRef}></audio>Í */}
                        </div>
                    ) :
                    type == 'image' ?
                        (
                            <div className={`${'flex  justify-around items-center'}  ${textClass} ${direction == 'right' ? `bg-[#225255]/80 text-[#43FFD4]` : `bg-[#00AF84]/90  ${isError ? `text-red-500` : `text-white`} `} "text max-w-[78%] min-h-10 text-lg leading-5 pl-[9px] py-[11px] pr-[11px]`}>
                                {
                                    content.map((item: any) => {
                                        return (
                                            <div className={`${avatarsImgList.indexOf(item) != -1 ? ' border-[#00E49F]' : ''} relative rounded-[4px] flex-1 ml-1 border-2 `} onClick={() => { selectImgHandle(item) }}>
                                                <img src={activeImg} className={`${avatarsImgList.indexOf(item) != -1 ? '' : 'hidden'} absolute w-4 h-4 top-[4px] right-[4px]`} />
                                                {/* <img src="${item}" style="margin-left:2px;width: 60px;height:90px;display: inline-block;vertical-align: middle; border-radius:6px;" /> */}
                                                <img src={item} className='w-[66px] h-[100px] rounded-[4px]' alt="" />
                                            </div>

                                        )
                                    })
                                }

                            </div>
                        )
                        : (
                            <div onClick={() => {
                                shareLogsHandle()
                            }} dangerouslySetInnerHTML={html} className={`${hidden ? 'hidden' : ""} ${type == 'image' ? 'flex  justify-around items-center' : type == 'audio' ? 'flex flex-wrap' : ''}  ${textClass} ${direction == 'right' ? `bg-[#225255]/80 text-[#43FFD4]` : `${isSelect ? 'ml-[30px]' : ''}  bg-[#00AF84]/90  ${isError ? `text-red-500` : `text-white`} `} ${isAvatar ? 'truncate' : ' '} "text max-w-[78%] min-h-10 text-lg leading-5 pl-[9px] py-[11px] pr-[11px] `}>

                            </div>
                        )

            }

            {
                status === 'pending' && <img className='w-6 h-6 animate-spin ml-2' src={loading} alt="" />
            }

            {
                isShowVoice && audioUrl && direction === 'left' &&
                <div onClick={playAudio} className={`flex ml-[6px] items-center`}>
                    <img className='w-3' src={voice} alt="" />
                    <img className={`${active ? 'voice_1' : ''} rounded-full w-[3px]`} src={voice_1} alt="" />
                    <img className={`${active ? 'voice_2' : ''} w-[4px] ml-[0px]`} src={voice_2} alt="" />
                    <img className={`${active ? 'voice_3' : ''} w-[6px] ml-[-2px]`} src={voice_3} alt="" />
                </div>
            }


            {status === 'success' && <audio src={audioUrl} hidden={true} controls></audio>}

        </div>
    )
}


export default Chats