import { useNavigate } from "react-router-dom";
import CardItem from "../CardItem";
import RefinedLoading_bg from "@/assets/images/refining/RefinedLoading.jpg"
import coins from '@/assets/images/task/coins.png'
import refiningFailure from '@/assets/images/refining/refiningFailure.png'
import { useSelector } from "react-redux";
import { formatNumber } from "../../utils";
import Back from "../common/BackComp";
import ButtonComp from "../common/ButtonComp";


function RefiningFailure() {
  const navigate = useNavigate();
  const refiningValue = useSelector((state: any) => state.progress.refiningValue)
  console.log("🚀 ~ RefiningFailure ~ refiningValue:", refiningValue)
  const successRateList = useSelector((state: any) => state.search.successRate)
  const isSaveCard = useSelector((state: any) => state.search.isSaveCard)
  const selectCardList = useSelector((state: any) => state.search.selectCardList)

  return (
    <div className={`px-4 h-[100vh] RefiningFailure ${refiningValue?.is_ok ? 'flex overflow-hidden justify-center items-center flex-col' : ''}  pt-[60px] overflow-auto `}>
      <div className="fixed left-0 top-0 z-[1200] h-11 w-full text-[16px] justify-between px-4 font-bold leading-[19px] text-white flex items-center">
        <Back title="Refining" callback={() => { navigate('/refining') }}></Back>

      </div>
      <div className="w-full flex justify-center items-center relative">
        {refiningValue?.is_ok ?
          <div className="card relative z-20">
            <div className="content  content_1">
              <div className="back">
                <div className="back-content !gap-0">
                  <CardItem big={true} power={refiningValue.result.card.power} property={refiningValue.result.card.property} image={refiningValue.result.card.url} text={refiningValue.result.card.name} level={refiningValue.result.card.level + 1}></CardItem>
                </div>
              </div>
              <div className="front">

                <div className="img">
                  <div className="circle">
                  </div>
                  <div className="circle" id="right">
                  </div>
                  <div className="circle" id="bottom">
                  </div>
                </div>
                <div className="front-content">
                  <div className="card mx-auto">
                    <div className="bg">
                      <img className=" rounded-[24px]" src={RefinedLoading_bg} alt="" />
                    </div>
                    <div className="blob"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="w-[286px] mx-auto relative z-20">
            {!isSaveCard ?
              <CardItem big={true} power={refiningValue.result.card.power} property={refiningValue.result.card.property} image={refiningValue?.result.card.url} text={refiningValue?.result.card.name} level={refiningValue?.result.card.level + 1}></CardItem>
              :
              <>
                <div className="w-[286px] overflow-hidden relative">
                  <CardItem big={true} power={selectCardList[0].power} property={selectCardList[0].property} height={429} image={selectCardList[0]?.image} level={Number(selectCardList[0]?.level)} rounded={20}></CardItem>
                </div>
                <div className="w-[286px] overflow-hidden absolute top-[25px] left-[20px]">
                  <CardItem big={true} power={selectCardList[1].power} property={selectCardList[1].property} height={429} image={selectCardList[1]?.image} level={Number(selectCardList[1]?.level)} rounded={20}></CardItem>
                </div>
              </>

            }
          </div>
        }
        {refiningValue?.is_ok && <img className=" absolute refiningAnimation_1 w-[100vw] bottom-4 z-10" src={refiningFailure} alt="" />}
      </div>

      <div className="flex justify-center flex-col items-center">
        {!refiningValue?.is_ok && <p className="text-[#FFFFFF] text-[14px] text-center font-semibold mt-[32px] leading-[18px]">Refining Unsuccessful—try again, and you might succeed!</p>}
        {(!refiningValue?.is_ok && Number(refiningValue.result.points) > 0) && <div className="flex justify-center my-[42px] gap-[10px] items-center">
          <img className="w-12" src={coins} alt="" />
          <p className="text-style-6 text-[14px] font-semibold">+{formatNumber(Number(refiningValue.result.points))}</p>
        </div>}

        <div className=" z-[1000] ">
          <ButtonComp mt={refiningValue?.is_ok ? 60 : 0} title='OK' callBack={() => { navigate('/refining') }}></ButtonComp>
        </div>

        {/* 
        <button className={`bg-gradient-to-r button_shadow rounded-[10px] w-[174px] z-30 h-[36px] text-[#fff] from-[#00E49F] to-[#54ADFF] ${refiningValue.is_ok ? 'mt-[60px]' : ''}`}
          onClick={() => {
            navigate('/refining')
          }
          } >OK
        </button> */}
        {!refiningValue?.is_ok && <p className="w-[230px] mt-[10px] text-[12px] font-semibold leading-[15px] text-[#A6A6A6]">Refine two cards into a higher-level one with success rates of {successRateList[0]?.chance}%, {successRateList[1]?.chance}%, {successRateList[2]?.chance}%, and {successRateList[3]?.chance}%.</p>}
      </div>
    </div>
  )
}
export default RefiningFailure