import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';


function PlaySoundButton(type: string) {
  const settings = useSelector((state: any) => state.settings.settings)

  // const [audio] = useState(new Audio(file));
  function playSound() {
    if (true) return
    if (!settings.sound) return
    // audio.play()
    //   .then(() => {
    //   })
    //   .catch((error) => {
    //     console.error('无法播放音频:', error);
    //   });
  }

  return playSound;
}

export default PlaySoundButton;