import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFakeProgress } from "react-use-fakeprogress";
import RefinedLoading_bg from "@/assets/images/refining/RefinedLoading.jpg"
import { useDispatch, useSelector } from "react-redux";
import { cardMerge, regenerateCardPayLink, regenerateCard } from "../../api/url.js"

import { setRefiningValue } from "../../store/module/progress.js";
import { HighlightPercentages } from "../../utils/index.js";
import Back from "../../components/common/BackComp.js";
import PublicDialog from "../../components/PublicDialog.js";
import LotteryCardDialog from "../../components/LotteryCardDialog.js";
import WebApp from "@twa-dev/sdk";
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

function RefinedLoading() {
    const [searchParams] = useSearchParams()

    const [regenerateDialog, setRegenerateDialogDialog] = useState(false)
    const [cardInfo, setCardInfo] = useState<any>(null)
    const regenerateData = useSelector((state: any) => state.search.regenerateData)

    const navigate = useNavigate();


    const [progress, start, done, stop] = useFakeProgress(
        15000 /* timeconstant value */
    );

    useEffect(() => {
        getPayLink()
    }, [])

    const getPayLink = async () => {


        start()
        await loopOrder()
        // const { code, data } = await regenerateCardPayLink()
        // if (code == "0") {
        //     const { link, order_id } = data
        //     if (link) {
        //         try {
        //             WebApp.openInvoice(link, async (status) => {
        //                 if (status === 'paid') {
        //                     await loopOrder(order_id)

        //                 } else if (status == "cancelled") {
        //                     navigate(-1)
        //                 }
        //             })
        //         } catch (e) {
        //             navigate(-1)
        //         }
        //     } else {
        //         await loopOrder(order_id)
        //     }
        // }

    }
    const loopOrder = async () => {
        const params = {
            id: regenerateData.id,
            prompt: searchParams.get("message"),
            order: searchParams.get("order"),
        }
        try {
            let timeCount = 0;
            let code_ = null;
            let data_ = null;
            while (code_ != "0" && timeCount < 10) {
                await sleep(2000)
                const { code, data } = await regenerateCard(params)
                if (code == "0") {
                    code_ = code
                    data_ = data
                    done()
                }
                timeCount++
            }

            setCardInfo(data_?.card)

            setRegenerateDialogDialog(true)

        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="pt-[60px] RefiningLoading overflow-auto h-[100vh] pb-8 px-4">
            <div className="fixed bg-black left-0 top-0 h-11 w-full z-[1200] text-[16px] justify-between px-4 font-bold leading-[19px] text-white flex items-center">
                <Back title="Regenerate" callback={() => { navigate(-1) }}></Back>
            </div>

            <div className="card mx-auto mt-20">
                <div className="bg">
                    <img className=" rounded-[24px]" src={RefinedLoading_bg} alt="" />
                </div>
                <div className="blob"></div>
            </div>
            <div className="flex justify-center w-full items-center mb-12 px-2">
                <div style={{ border: '1px solid #6A6A6A' }} className="h-4 mt-6 relative bg-[#343434] rounded-lg w-full">
                    <div style={{ background: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)', width: `${Math.floor(progress * 100)}%` }} className="h-[14px] rounded-lg  w-0"> </div>
                    <span style={{ left: `${Math.floor(progress * 100)}%` }} className="text-transparent absolute  left-[0%] translate-x-[-50%] bottom-[-24px] bg-clip-text bg-gradient-to-r text-[14px] leading-[18px] from-[#00E49F] to-[#54ADFF]">{Math.floor(progress * 1000) / 10}%</span>
                </div>
            </div>


            <div className="bg-gradient-to-r from-[#00E49F]  to-[#54ADFF] inline-block mb-[10px] rounded-[10px] text-[14px] leading-[23px] font-semibold p-[10px] text-[#333]">
                Each Diamond card boosts $STAR earning speed by 0.5%. 💎
            </div>
            {/* <PublicDialog closeOnClickOverlay={false} RefinedLoading={false} dialog={dialog} width="290" setDialog={setDialog} content={attention()}></PublicDialog> */}
            {
                regenerateDialog && <LotteryCardDialog title="Lottery" img={cardInfo?.url} name={cardInfo?.name} level={cardInfo?.level} close={() => { setRegenerateDialogDialog(false), navigate(`/cards?id=${1}&cardId=${cardInfo.id}`) }}></LotteryCardDialog>

            }
        </div>
    )
}
export default RefinedLoading