import { useNavigate, useSearchParams } from 'react-router-dom'
import tipImg from "@/assets/images/attribute/tip.svg"
import Back from '../../components/common/BackComp'
import mining_icon from '@/assets/images/mining/mining_icon.png'
import speedImg from '@/assets/images/mining/speed.png'
import timeImg from '@/assets/images/mining/time.png'
import starImg from '@/assets/images/rules/star.png'
import { getProfits, getMiningRanks, getMiningPoolList } from '@/api/mining'
import { useCallback, useEffect, useState } from 'react'
import { miningRules } from '../../text/rulesText'
import RulesDialog from '../../components/RulesDialog'
import PromptDialog from '../../components/PromptDialog'
import { FormattingTwoNumber } from '../../utils'
import Pay from '../shop/pay'
import personImg from '@/assets/images/mining/person.png'
import { Toast } from 'react-vant';
const Pool = () => {
  const navigate = useNavigate()

  const [poolData, setPoolData] = useState<any>({})
  const [rulesDialog, setRulesDialog] = useState(false)

  const getPoolListHandle = async () => {
    const toast = Toast.loading({
      message: "Loading...",
      forbidClick: true,
      duration: 0
    })
    try {
      const { code, data } = await getMiningPoolList(1)
      toast.clear()
      if (code == "0") {
        setPoolData(data)
      }

    } catch (e) {
      toast.clear()
    }

  }

  useEffect(() => {
    getPoolListHandle()
  }, [])


  return (
    <div className='px-4 relative pt-[53px] h-[100vh] overflow-auto pb-10'>
      <Back background={'#000'} position={true} title="Past Mining Pools" callback={() => { navigate('/mining') }}>

      </Back>
      <img src={tipImg} onClick={() => {
        setRulesDialog(true)
      }} className='fixed top-3 z-[1001] right-4' />


      <div className='w-full'>
        <div className='text-white my-[12px] ml-2'>Phase 1-1</div>
        <div className='w-full bg-[#333] p-[10px] rounded-[20px] h-[228px]'>
          <div className='flex justify-center items-center w-full'>
            <img className='w-[12px] h-[12px]' src={starImg} alt="" />
            <p className='!text-[20px] text-white font-bold !leading-[18px] px-[8px]'>Standard Pool</p>
            <img className='w-[12px] h-[12px]' src={starImg} alt="" />
          </div>

          <div className='w-full flex items-start justify-between mt-[15px]'>
            <div className='flex-1 flex justify-start items-start'>
              <img className='w-[18px] h-[18px]' src={mining_icon} alt="" />
              <span className='text-white text-base ml-2'>Total Profits:</span>
            </div>
            <div className='text-[20px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              <p className='text-style-6 !text-[20px] font-bold !leading-[18px] pr-[8px]'> {FormattingTwoNumber(poolData?.standard?.total_profits || "0")} </p>
              <p className='text-style-6 !text-[12px] !leading-[16px]'> $ </p>
              <p className='text-style-6 !text-[14px] font-bold !leading-[16px]'> SAI </p>
            </div>

          </div>
          <div className='w-full flex items-start justify-between my-[10px]'>
            <div className='flex-1 flex justify-start items-center'>
              <img className='h-[18px] w-[18px]' src={speedImg} alt="" />
              <span className='text-white text-base ml-2' >Total Efficiency:</span>
            </div>
            <div className='text-[14px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              {poolData?.standard?.total_profit_coefficient || 0}
              <span className='text-[12px] leading-5'>%</span>
            </div>

          </div>
          <div className='w-full flex items-start justify-between mb-[10px]'>
            <div className='flex-1 flex justify-start items-center'>
              <img className='h-[18px] w-[18px]' src={personImg} alt="" />
              <span className='text-white text-base ml-2' >Participants:</span>
            </div>
            <div className='text-[14px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              {poolData?.standard?.participants || 0}
            </div>

          </div>
          <div className='w-full flex items-start justify-between mb-[10px]'>
            <div className='flex-1 flex justify-start items-center'>
              <img className='h-[18px] w-[18px]' src={timeImg} alt="" />
              <span className='text-white text-base ml-2' >End Time:</span>
            </div>
            <div className='text-[14px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              {poolData?.standard?.end_time || ''}
            </div>

          </div>

          <div className="h-[40px] w-full flex items-item px-2 justify-between  bg-gradient-to-r from-[#00E49F] to-[#54ADFF] rounded-[12px]">
            <div className='flex-1 flex justify-start items-center'>
              <img className='w-[18px] h-[18px]' src={mining_icon} alt="" />
              <span className='text-white text-base ml-2' style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} >My Profits:</span>
            </div>
            <div className='text-[#fff] font-bold flex items-center  justify-center'>
              <p className='text-style-6 !text-[20px] font-bold !leading-[18px] pr-[4px]'> {FormattingTwoNumber(poolData?.standard_profits?.profits || "0")} <span className='text-[16px]'>$SAI</span> </p>
            </div>
          </div>

        </div>
      </div>


      <div className='w-full'>
        <div className='text-white my-[12px] ml-2'>Phase 1-2</div>
        <div className='w-full bg-[#333] p-[10px] rounded-[20px] h-[228px]'>
          <div className='flex justify-center items-center w-full'>
            {
              [1, 2].map(item => {
                return (
                  <svg className='ml-2' width="12px" height="11px" viewBox="0 0 12 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <title>星形备份 15</title>
                    <defs>
                      <linearGradient x1="-2.72351586e-14%" y1="-1.94289029e-13%" x2="100%" y2="100%" id="linearGradient-1">
                        <stop stopColor="#FFE600" offset="0%"></stop>
                        <stop stopColor="#FFAB00" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g id="页面-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="mining中2.0" transform="translate(-113.000000, -711.000000)" fill="url(#linearGradient-1)">
                        <path d="M118.534659,720.744644 L116.678975,721.720235 C116.190131,721.977236 115.585504,721.789289 115.328503,721.300445 C115.226164,721.105784 115.190849,720.882816 115.228026,720.666058 L115.58243,718.599724 C115.638068,718.27533 115.530519,717.94433 115.294833,717.714593 L113.793554,716.251207 C113.39807,715.865706 113.389978,715.232593 113.775479,714.837109 C113.928988,714.679626 114.13013,714.577138 114.347767,714.545514 L116.422484,714.24404 C116.748195,714.196711 117.02976,713.992142 117.175422,713.696998 L118.103264,711.816986 C118.347686,711.321732 118.947312,711.118393 119.442566,711.362815 C119.639778,711.460146 119.799406,711.619773 119.896736,711.816986 L120.824578,713.696998 C120.97024,713.992142 121.251805,714.196711 121.577516,714.24404 L123.652233,714.545514 C124.198778,714.624931 124.577459,715.132374 124.498041,715.678919 C124.466417,715.896556 124.36393,716.097699 124.206446,716.251207 L122.705167,717.714593 C122.469481,717.94433 122.361932,718.27533 122.41757,718.599724 L122.771974,720.666058 C122.865335,721.210395 122.499747,721.727351 121.955411,721.820712 C121.738653,721.857889 121.515685,721.822574 121.321025,721.720235 L119.465341,720.744644 C119.174017,720.591486 118.825983,720.591486 118.534659,720.744644 Z" id="星形备份-15"></path>
                      </g>
                    </g>
                  </svg>
                )
              })
            }
            <p className='text-style-6 !text-[20px] font-bold !leading-[18px] px-[8px]'>VIP Pool</p>
            {
              [1, 2].map(item => {
                return (
                  <svg className='ml-2' width="12px" height="11px" viewBox="0 0 12 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <title>星形备份 15</title>
                    <defs>
                      <linearGradient x1="-2.72351586e-14%" y1="-1.94289029e-13%" x2="100%" y2="100%" id="linearGradient-1">
                        <stop stopColor="#FFE600" offset="0%"></stop>
                        <stop stopColor="#FFAB00" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g id="页面-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="mining中2.0" transform="translate(-113.000000, -711.000000)" fill="url(#linearGradient-1)">
                        <path d="M118.534659,720.744644 L116.678975,721.720235 C116.190131,721.977236 115.585504,721.789289 115.328503,721.300445 C115.226164,721.105784 115.190849,720.882816 115.228026,720.666058 L115.58243,718.599724 C115.638068,718.27533 115.530519,717.94433 115.294833,717.714593 L113.793554,716.251207 C113.39807,715.865706 113.389978,715.232593 113.775479,714.837109 C113.928988,714.679626 114.13013,714.577138 114.347767,714.545514 L116.422484,714.24404 C116.748195,714.196711 117.02976,713.992142 117.175422,713.696998 L118.103264,711.816986 C118.347686,711.321732 118.947312,711.118393 119.442566,711.362815 C119.639778,711.460146 119.799406,711.619773 119.896736,711.816986 L120.824578,713.696998 C120.97024,713.992142 121.251805,714.196711 121.577516,714.24404 L123.652233,714.545514 C124.198778,714.624931 124.577459,715.132374 124.498041,715.678919 C124.466417,715.896556 124.36393,716.097699 124.206446,716.251207 L122.705167,717.714593 C122.469481,717.94433 122.361932,718.27533 122.41757,718.599724 L122.771974,720.666058 C122.865335,721.210395 122.499747,721.727351 121.955411,721.820712 C121.738653,721.857889 121.515685,721.822574 121.321025,721.720235 L119.465341,720.744644 C119.174017,720.591486 118.825983,720.591486 118.534659,720.744644 Z" id="星形备份-15"></path>
                      </g>
                    </g>
                  </svg>
                )
              })
            }
          </div>

          <div className='w-full flex items-start justify-between mt-[15px]'>
            <div className='flex-1 flex justify-start items-start'>
              <img className='w-[18px] h-[18px]' src={mining_icon} alt="" />
              <span className='text-white text-base ml-2' style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} >Total Profits:</span>
            </div>
            <div className='text-[20px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              <p className='text-style-6 !text-[20px] font-bold !leading-[18px] pr-[8px]'> {FormattingTwoNumber(poolData?.vip?.total_profits || "0")} </p>
              <p className='text-style-6 !text-[12px] !leading-[16px]'> $ </p>
              <p className='text-style-6 !text-[14px] font-bold !leading-[16px]'> SAI </p>
            </div>

          </div>
          <div className='w-full flex items-start justify-between my-[10px]'>
            <div className='flex-1 flex justify-start items-center'>
              <img className='h-[18px] w-[18px]' src={speedImg} alt="" />
              <span className='text-white text-base ml-2' >Total Efficiency:</span>
            </div>
            <div className='text-[14px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              {poolData?.vip?.total_profit_coefficient || 0}
              <span className='text-[12px] leading-5'>%</span>
            </div>

          </div>
          <div className='w-full flex items-start justify-between mb-[10px]'>
            <div className='flex-1 flex justify-start items-center'>
              <img className='h-[18px] w-[18px]' src={personImg} alt="" />
              <span className='text-white text-base ml-2' >Participants:</span>
            </div>
            <div className='text-[14px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              {poolData?.vip?.participants || 0}
            </div>

          </div>
          <div className='w-full flex items-start justify-between mb-[10px]'>
            <div className='flex-1 flex justify-start items-center'>
              <img className='h-[18px] w-[18px]' src={timeImg} alt="" />
              <span className='text-white text-base ml-2' >End Time:</span>
            </div>
            <div className='text-[14px] leading-6 text-[#fff] font-bold flex items-end  justify-center'>
              {poolData?.vip?.end_time || ""}
            </div>

          </div>

          <div className="h-[40px] w-full flex items-item px-2 justify-between  bg-gradient-to-r from-[#00E49F] to-[#54ADFF] rounded-[12px]">
            <div className='flex-1 flex justify-start items-center'>
              <img className='w-[18px] h-[18px]' src={mining_icon} alt="" />
              <span className='text-white text-base ml-2' style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} >My Profits:</span>
            </div>
            <div className='text-[#fff] font-bold flex items-center  justify-center'>
              <p className='text-style-6 !text-[20px] font-bold !leading-[18px] pr-[4px]'> {FormattingTwoNumber(poolData?.vip_profits?.profits || "0")} <span className='text-[16px]'>$SAI</span> </p>
            </div>
          </div>

        </div>
      </div>



      <RulesDialog textArr={miningRules} type='mining' title="Mining Rules" dialog={rulesDialog} setDialog={setRulesDialog}></RulesDialog>

    </div>
  )
}
export default Pool