import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface SearchState {

    isSendSuccess: string | null
    isSendMintSuccess: string | null
    okWalletAddress: string

}




const initialState: SearchState = {
    isSendSuccess: null,
    isSendMintSuccess: null,
    okWalletAddress: ""
}
const TonStore = createSlice({
    name: 'ton',
    initialState,
    reducers: {
        changeTransactionStatus(state, action) {
            state.isSendSuccess = action.payload
        },
        changeMintTransactionStatus(state, action) {
            state.isSendMintSuccess = action.payload
        },
        changeOKWalletAddress(state, action) {
            state.okWalletAddress = action.payload
        }
    }
})

export const { changeTransactionStatus, changeMintTransactionStatus, changeOKWalletAddress } = TonStore.actions

export default TonStore.reducer