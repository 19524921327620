import { FormattingTwoNumber } from "../../utils"
import storyBg from '@/assets/images/homePage/storyBg.png'
import test from '@/assets/images/homePage/test.png'
import storyText from '@/assets/images/homePage/storyText.png'
import storyLogo from '@/assets/images/homePage/storyLogo.png'
import like from '@/assets/images/homePage/like.png'
import message from '@/assets/images/homePage/message.png'
import title_icon from '@/assets/images/homePage/title_icon.png'
import content_bg_1 from '@/assets/images/homePage/content_bg_1.png'
import '@/styles/homePage.less'


interface ScreenshotProps {
  accountProfile: any
  captureDom: any
}
const Screenshot = ({ accountProfile, captureDom }: ScreenshotProps) => {
  return (
    <div ref={captureDom} style={{ backgroundImage: `url(${storyBg})`, backgroundSize: '100% 100%' }} className='screenshot absolute pt-[261px] -top-[200px] left-[10000px] z-[100] h-[812px] w-[375px]'>
      <img className=" absolute top-[94px] w-[330px] left-[12px]" src={storyText} alt="" />
      <img className=" absolute top-[147px] w-[104px] right-[16px]" src={storyLogo} alt="" />
      <div className="contentPPP w-[343px] h-[475px] p-[3px] relative overflow-hidden mx-auto">
        <img className=" absolute top-0 left-0" src={content_bg_1} alt="" />
        <div className="contentBorder relative p-[11px]">
          <div className=" relative z-10">
            <div className=" flex items-center">
              <div className=" relative w-[60px] bg-transparent h-[60px]">
                {
                  accountProfile.avatar_url ?
                    <img className="w-[60px] h-[60px] rounded-full" src={`${accountProfile.avatar_url}?id=${Date.now()}`} alt="" />
                    :
                    <img className="w-[60px] h-[60px] rounded-full" src={test} alt="" />
                }
              </div>

            </div>
            <div className={` absolute top-[9px] left-[64px]`}>
              <p className={`text-[16px] flex gap-1 leading-[19px] 
            ${accountProfile.nick_name ? 'text-[#333]' : accountProfile.name ? 'text-[#333]' : 'text-[#A6A6A6]'}`}>{accountProfile.nick_name ? accountProfile.nick_name : accountProfile.name ? accountProfile.name : 'Username Not Set'}</p>
              <p className="text-[12px] mt-[6px] text-[#a6a6a6] leading-[15px">ID: {accountProfile.id}</p>
            </div>
            <p className=" parent text-[16px] flex gap-[6px] justify-center items-center leading-[19px] mt-5 text-center font-semibold text-[#01825D]">
              <img className="h-[3px]" src={title_icon} alt="" />
              Most Popular AI Avatar
              <img className="h-[3px] rotate-180" src={title_icon} alt="" />
            </p>
            {accountProfile.avatar?.cards?.length < 1 ?
              <p className="text-[14px] leading-[18px] py-[15px] font-semibold text-center text-[#333333]">You have not created a AI Avatar yet.</p>
              :
              <div className=" flex gap-9 w-[313px] justify-center items-center mt-[10px]">
                {accountProfile.avatar?.cards?.map((el: any, index: number) =>
                  <div key={`accountProfile_avatar_${index}`} className="w-[80px] rounded-[10px] h-[120px] relative">
                    {el.is_nsfw && <div className=" !backdrop-blur-[20px] z-[10] w-full h-full rounded-[10px] absolute top-0 left-0">
                    </div>}
                    <img className=" relative z-[1] rounded-[10px]" src={el.url} alt="" />

                    <div className=" p-1 z-[3] absolute bottom-0 left-0">
                      <p className=" text-[12px] leading-[15px] font-bold text-[#fff]" >{el.name}</p>
                      <div className="flex mt-1 gap-[2px]">
                        <div className=" flex rounded-[3px] p-[2px] items-center bg-[#7f7f7f]/50 ">
                          <img className="w-2 mr-[2px]" src={like} alt="" />
                          <p className=" text-[12px] text-[#fff] leading-3 font-[400]">{FormattingTwoNumber(el.like_count)}</p>
                        </div>
                        <div>
                          <div className=" flex rounded-[3px] p-[2px] items-center bg-[#7f7f7f]/50 ">
                            <img className="w-2 mr-[2px]" src={message} alt="" />
                            <p className=" text-[12px] text-[#fff] leading-3 font-[400]">{FormattingTwoNumber(el.chat_count)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
            <div className="px-5 rounded-[10px] mt-[6px] justify-between flex" style={{ background: 'linear-gradient( 90deg, rgba(0, 228, 159,0.3) 0%, rgba(84, 173, 255,0.3) 100%)', alignItems: 'center' }}>
              <p className="text-[14px] leading-[30px] text-[#333333] font-semibold">Own: {accountProfile.avatar?.own || '0'}</p>
              <p className="text-[14px] leading-[30px] text-[#333333] font-semibold">Chat: {accountProfile.avatar?.chat || '0'}</p>
              <p className="text-[14px] leading-[30px] text-[#333333] font-semibold">Logs: {accountProfile.avatar?.logs || '0'}</p>
            </div>
            <p className=" parent text-[16px] flex gap-[6px] justify-center items-center leading-[19px] mt-5 text-center font-semibold text-[#01825D]">
              <img className="h-[3px]" src={title_icon} alt="" />
              Frequently Used Decks
              <img className="h-[3px] rotate-180" src={title_icon} alt="" />
            </p>
            {accountProfile.decks?.cards?.every((item: any) => item == null) ?
              <p className=" text-[14px] leading-[18px] py-[15px] font-semibold text-center text-[#333333]">You have not configured a deck yet.</p>
              :
              <div className=" flex items-center justify-center mt-[10px] gap-2">
                {accountProfile.decks?.cards.map((el: any) => el &&
                  <div key={el.url} className={` relative rounded-[6px] w-[46px] ${el ? ' ' : 'hidden'}`}>
                    {el.is_nsfw && <div className=" !backdrop-blur-[20px] z-[10] w-full h-full rounded-[6px] absolute top-0 left-0">
                    </div>}
                    <img className={`rounded-[6px] relative z-[1] w-[46px] ${el ? ' ' : 'hidden'}`} src={el.url} alt="" />
                  </div>
                )}
              </div>
            }
            <div className="p-5 rounded-[10px] mt-[6px] flex justify-between items-center py-[6px]" style={{ background: 'linear-gradient( 90deg, rgba(0, 228, 159,0.3) 0%, rgba(84, 173, 255,0.3) 100%)' }}>
              <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">PK: {accountProfile.decks?.pk || '0'}</p>
              <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">Wins: {accountProfile.decks?.wins_count || '0'}</p>
              <p className="text-[14px] leading-[18px] text-[#333333] font-semibold">Wins%: {accountProfile.decks?.wins_rate || '0'}{accountProfile.decks?.wins_rate ? '%' : ''}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Screenshot