import { useState } from "react";
import PublicDialog from "../components/PublicDialog";
import ButtonComp from "../components/common/ButtonComp";

const TonNetworkError = ({ closeHandle }: any) => {

    // const [tonNetworkVisible, setTonNetworkVisible] = useState(false)

    const setTonNetworkVisible = () => {
        closeHandle()
    }

    //ton网络提示弹窗
    const tonNetwork_error_button = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <div className="text flex flex-col justify-center items-center px-4">
                    <p className='text-[#333] text-2xl mb-5'>Attention</p>
                    <p className='text-sm text-[#333]'>Transactions are not processed by the TON network at the moment.</p>
                    <p className='text-sm text-[#333] mt-2'>Please wait for a while.</p>
                </div>

                <ButtonComp title="OK" callBack={() => {
                    setTonNetworkVisible()
                }}></ButtonComp>
            </div>
        )
    }
    return (
        <PublicDialog width={"290px"} isPadding={false} dialog={true} setDialog={() => { }} content={tonNetwork_error_button()}></PublicDialog>
    )
}


export default TonNetworkError