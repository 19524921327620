import { useRef, useState } from "react"
import { Dialog } from "react-vant"
import close from "@/assets/images/task/close.png"
import { useNavigate } from "react-router-dom"
import attribute from '@/assets/images/attribute/attribute.png'
import progressRight from "@/assets/images/refining/progressRight.png"
import lostImg from "../assets/images/home/lost.png"
import acquiredImg from "../assets/images/home/acquired.png"

import coinsImg from '@/assets/images/task/coins.png'
import goldImg from '@/assets/images/deck/gold.png'
import bronzeImg from '@/assets/images/deck/bronze.png'
import silverImg from '@/assets/images/deck/silver.png'
import naturalImg from '@/assets/images/myInvites/natural_cards.png'

import ButtonComp from "./common/ButtonComp"
import { formatNumber } from "../utils"

interface ReinforceRulesProps {
  arrayData: any
  teleport?: any,
  closeHandle: Function
}
const imgList: any = {
  card0: naturalImg,
  card3: goldImg,
  card2: silverImg,
  card1: bronzeImg
}
const ReinforceRulesDialog = ({ closeHandle, teleport, arrayData }: ReinforceRulesProps) => {
  const dialogRef = useRef(null)
  return (
    <Dialog
      className="new-user-gift"
      visible={true}
      teleport={teleport}
      showConfirmButton={false}
      showCancelButton={false}
      width={'100%'}
    >
      <div ref={dialogRef} style={{ backgroundSize: "100% 100% !important" }} className={`refining mx-[16px] flex justify-center !bg-[length:100%_100%] h-[426px] px-[10px] overflow-hidden py-10 relative bg-[url(@/assets/images/refining/card_refining_guide.png)] `}>
        <div className=" overflow-auto w-full">

          <p className="font-bold text-[20px] text-[#333] leading-[24px]  mb-[22px] text-center">New User Gift</p>
          {
            arrayData.map((item: any, index: number) => {
              return (
                <div key={`${index}-ReinforceRulesDialog`} className="relative bg-gradient-to-r from-[#00E49F] to-[#54ADFF] mt-[12px] text-[14px] h-[60px] text-[#fff] w-full leading-[18px] rounded-[10px] flex items-center justify-between">
                  {
                    item.status != 'NIL' && <div className="absolute h-full w-full z-0 rounded-[10px] bg-[#fff]/40"></div>
                  }
                  <div className="w-full h-full px-[20px] flex justify-between items-center border">
                    <div className="text-sm w-[40px] font-bold">Day {index + 1}</div>
                    {
                      item.status == 'NIL' && <div className="w-[54px] h-[20px] flex overflow-hidden">
                        <img className="w-[84px] h-full translate-x-[-84px] progressRight_1" src={progressRight} alt="" />
                        <img className="w-[84px] h-full translate-x-[-84px] progressRight_2 " src={progressRight} alt="" />
                      </div>
                    }
                    {
                      item.status == 'LOST' && <div className="h-full relative z-[1] flex justify-center items-center ">
                        <img className="h-full z-[1]" src={lostImg} alt="" />
                      </div>
                    }
                    {
                      item.status == 'Acquired' && <div className="h-full relative z-[1] flex justify-center items-center">
                        <img className="h-full z-[1]" src={acquiredImg} alt="" />
                      </div>
                    }
                    <div className="coins">
                      <div className="flex items-center text-style-3 text-base my-6 gap-2 font-bold" > <img className="w-8 h-5" src={coinsImg} alt="" /> +{formatNumber(item.rewards && item.rewards[0].reward)}</div>
                    </div>
                    <div className="cards flex items-center justify-between">
                      <img className="h-8" src={item?.rewards && imgList[item.rewards[1].type]} alt="" />
                      <span className="text-[#005FC4] text-xl mx-1">+</span>
                      <img className="h-8" src={item?.rewards && imgList[item.rewards[2].type]} alt="" />
                    </div>
                  </div>
                </div>
              )
            })
          }


        </div>
        <div className="absolute text-[12px] leading-[14px] bottom-6 text-center text-[#333]">
          <ButtonComp width={174} mt={10} title='ok' callBack={() => {
            closeHandle && closeHandle()
          }}>
          </ButtonComp>
          <p className="text-[12px] leading-[14px] mt-2  px-[38px] text-center text-[#333]">New users can only receive this gift bylogging in for the frst three days.</p>
        </div>
      </div>
    </Dialog>
  )
}
export default ReinforceRulesDialog