


import { Toast } from "react-vant";
import { lotteryHandleApi } from "@/api/url.js"



export const waitForTransaction = async (id: string, boc: string, url?: string) => {
  const toast = Toast.loading({ message: "The transaction is being confirmed.Please wait for 1 minute.", duration: 0, forbidClick: true, className: 'custom-toast' });
  const r = await lotteryHandleApi({ order_id: id, transaction_id: boc }, url)
  toast.clear()
  return r
};
export const miningWaitForTransaction = async (id: string, boc: string, url?: string, config_id?: string) => {
  const toast = Toast.loading({ message: "The transaction is being confirmed.Please wait for 1 minute.", duration: 0, forbidClick: true, className: 'custom-toast' });
  const r = await lotteryHandleApi({ order_id: id, transaction_id: boc, config_id }, url)
  toast.clear()
  return r
};