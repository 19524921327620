
import { useCallback, useEffect, useRef, useState } from 'react'
import Back from '../../components/common/BackComp.js';
import { useNavigate } from 'react-router-dom';
import { SHOP_NAV } from "../../constants/common-constants.js"
import tableTurnImg from "../../assets/images/shop/table-turn.png"
import leftCoinImg from "../../assets/images/shop/coin-left.png"
import rightCoinImg from "../../assets/images/shop/coin-right.png"
import tableTurnSmallImg from "../../assets/images/cards/cardTurntable.png"
import handImg from "../../assets/images/myInvites/finger.png"
import ButtonComp from '../../components/common/ButtonComp.js';
import flowerImg from "../../assets/images/shop/flower.png"
import tonImg from "../../assets/images/shop/ton.png"
import usdtImg from "../../assets/images/shop/usdt.png"
import starImg from "../../assets/images/shop/star.png"
import PayDialog from '../../components/common/PayDialog.js';
import BuyComp from '../../components/common/BuyComp.js';
import { changeTurntableDialog } from '../../store/module/settins.js';
import { useDispatch } from 'react-redux';


interface PropType {
    dataList: any[]
}
const Draw = ({ dataList }: PropType) => {
    const [showPayDialog, setShowPayDialog] = useState(false)
    const [payTypeList, setPayTypeList] = useState<any>([])
    const dispatch = useDispatch()
    return (

        <>
            <div style={{ backgroundSize: '100% 100%' }} className="bg-[url(@/assets/images/shop/bg-2.png)]  flex justify-between items-center px-4 py-4 bg-cover bg-no-repeat h-[220px] w-full">
                <div className='w-[140px] h-[140px] flex justify-center items-center relative'>
                    <img className='w-[120px] animate-rotate' src={tableTurnImg} alt="" />
                    <img className='absolute right-0 bottom-0 w-14 ' src={rightCoinImg} alt="" />
                    <img className='absolute left-0 bottom-0 w-14 ' src={leftCoinImg} alt="" />
                    <img className='absolute left-[50%] top-[50%] bottom-0 w-8 animate-rotate-turntable' src={handImg} alt="" />
                </div>
                <div className="text flex-1 px-5 text-center">
                    <p className='text-sm text-shadow-lg text-white'>You can obtain gold or cards of any quality through the card draw.</p>
                    <ButtonComp width={80} mt={10} title='Spin' callBack={() => {
                        dispatch(changeTurntableDialog(true))
                    }}></ButtonComp>
                </div>
                {/* <img className='z-[10] absolute w-[120px] left-[calc(50%-63px)] top-10 animate-rotate-turntable' src={presentImg} alt="" /> */}
                {/* <p className='text-white text-shadow-lg w-full h-full text-center flex items-end justify-end'>A large amount of $STAR plus either a Diamond, Gold, or Silver Card.</p> */}
            </div>
            <div className='grid grid-cols-1 gap-4 mt-4'>
                {
                    dataList.map((item: any, index) => {
                        return (
                            <div key={index}>
                                {
                                    item.length > 0 && <div key={index} className='relative w-full flex items-center justify-around px-4 py-2 h-[90px] rounded-[20px] special-class '>
                                        <div className={`${item[0].discount <= 0 ? 'hidden' : ''} w-8 absolute -top-1 -left-0 flex justify-center items-center`}>
                                            <img className='w-8' src={flowerImg} alt="" />
                                            <span className='absolute text-xs text-white'>{item[0].discount}%</span>
                                        </div>

                                        <div className='flex flex-1 items-center justify-center'>
                                            <img className='w-12 mr-2' src={tableTurnSmallImg} alt="" />
                                            <p className='text-sm text-white font-[900]'><span className='text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'>{item[0].count}</span> Card Draw Chances</p>
                                        </div>
                                        <BuyComp title='Card Draw Chances' info={item}></BuyComp>
                                    </div>
                                }
                            </div>

                        )
                    })
                }
            </div>

        </>


    )
}


export default Draw;