
import coin from '@/assets/images/task/coin.png'
import progressRight from "@/assets/images/refining/progressRight.png"
import activeImg from "../../assets/images/selectActive.png"
// import starImg from "../../assets/images/inviting/star.png"
import tonImg from "../../assets/images/shop/ton.png"
import usdtImg from "../../assets/images/shop/usdt.png"
import starImg from "../../assets/images/shop/star.png"

import CardItem from '../CardItem';

import { Dialog } from 'react-vant';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../utils';
import { useLayoutEffect, useMemo, useState } from 'react';
import ButtonComp from '../common/ButtonComp';
import { calculateUsdtAmountSmall } from '../../utils/common-helpers';

interface refiningProps {
  setDialog: Function;
}
function RefiningConsumption({ setDialog }: refiningProps) {
  const refiningCardList = useSelector((state: any) => state.search.refiningCardList)
  const isSaveCard = useSelector((state: any) => state.search.isSaveCard)
  const successRateList = useSelector((state: any) => state.search.successRate)
  const selectCardList = useSelector((state: any) => state.search.selectCardList)
  const { shopCount } = useSelector((state: any) => state.lottery)
  const [starCount, setStarCount] = useState(9)

  useLayoutEffect(() => {
    setStarCount((Number(selectCardList[0]?.level) - 2) * 9)
  }, [])

  const showPayIcon = useMemo(() => {
    if (refiningCardList[0]?.level == 3) {
      if (shopCount[1] < 2) {
        return true
      } else {
        return false
      }
    }
    if (refiningCardList[0]?.level == 2) {
      if (shopCount[1] < 1) {
        return true
      } else {
        return false
      }
    }
  }, [shopCount, refiningCardList])

  return (
    <Dialog
      className={`refining-dialog`}
      visible={true}
      showConfirmButton={false}
      showCancelButton={false}
      closeable={true}
      onClose={() => { setDialog("close") }}
      width={'92%'}
    >
      <div style={{ backgroundSize: "100% 100% !important" }} className={`w-full !bg-[length:100%_100%] box-border overflow-visible relative bg-[url(@/assets/images/dialog_bg.png)] pb-[50px] `}>
        <div className=" flex flex-col items-center justify-center">
          <div className=" flex p-[42px] pb-[0px] items-center justify-center">
            <div className="w-[60px] mr-6 relative">
              <div className="w-[60px] overflow-hidden relative">
                <CardItem height={90} image={selectCardList[0]?.image} level={Number(selectCardList[0]?.level)} rounded={10}></CardItem>
              </div>
              <div className="w-[60px] overflow-hidden absolute top-[5px] left-[5px]">
                <CardItem height={90} image={selectCardList[1]?.image} level={Number(selectCardList[1]?.level)} rounded={10}></CardItem>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center'>
              <div className={`${(isSaveCard && refiningCardList[0]?.level == 3 && shopCount[1] > 1) || (isSaveCard && refiningCardList[0]?.level == 2 && shopCount[1] > 0) ? 'hidden' : ''} flex items-center text-[14px] font-semibold leading-[18px] text-[#333]`}>
                Cost:
                {
                  isSaveCard ?
                    <>
                      {
                        Number(selectCardList[1]?.level) >= 3 ? <>

                          <span className='text-style-6 text-[14px] font-semibold ml-1 leading-[18px]'>${calculateUsdtAmountSmall(successRateList[refiningCardList[0]?.level]?.usdt_expend?.price / 100)}</span>
                        </>
                          :
                          <>
                            <img className="w-[12px] h-[12px]" src={coin} alt="" />
                            <span className='text-style-6 text-[14px] font-semibold leading-[18px]'>{formatNumber(Number(successRateList[refiningCardList[0]?.level]?.protect))}</span>
                          </>
                      }

                    </>
                    :
                    <>
                      <img className="w-[12px] h-[12px]" src={coin} alt="" />
                      <span className='text-style-6 text-[14px] font-semibold leading-[18px]'>{formatNumber(Number(successRateList[refiningCardList[0]?.level]?.expend))}</span>

                    </>
                }


              </div>
              <div className="w-[94px] mt-1 overflow-hidden flex">
                <img className=" w-[94px] translate-x-[-94px] progressRight_1 " src={progressRight} alt="" />
                <img className=" w-[94px] translate-x-[-94px] progressRight_2 " src={progressRight} alt="" />
              </div>
              {
                isSaveCard && showPayIcon &&
                <div className='flex mt-3 justify-around px-4'>
                  <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={starImg} alt="" />
                  <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px] mx-2' src={usdtImg} alt="" />
                  <img style={{ boxShadow: '0px 0px 6px rgba(0,0,0,.5)' }} className='w-5 rounded-[20px]' src={tonImg} alt="" />
                </div>
              }

            </div>
            <div className=" w-[60px] rounded-[8px] mr-6 bg-black ml-[18px] relative">
              <CardItem height={90} image={""} level={Number(selectCardList[2]?.level)}></CardItem>
              <p className="text-[12px] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[#fff] font-semibold">{selectCardList[2]?.name}</p>
            </div>
          </div>
          <div className={`${isSaveCard ? '' : 'hidden'} radio flex items-center mt-6`}>
            <img className="w-4 h-4" src={activeImg} alt="" />
            <div className="text-sm text-[#333] ml-2">Retain the Original Cards</div>
          </div>
          <p className="text-[14px] leading-[18px] text-[#333] font-semibold my-3">Success rate: {successRateList[refiningCardList[0]?.level]?.chance}%</p>
          <ButtonComp title='OK' callBack={() => { setDialog() }}></ButtonComp>
        </div>
      </div>
    </Dialog >

  )
}

export default RefiningConsumption