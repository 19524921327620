import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface SearchState {
    refiningActive: string | Number;
    isActive: string | Number;
    announcementList: [],
    refiningCardList: any[],
    isSelect: boolean,
    selectCardList: any[],
    successRate: any[], //refining success rate
    isSaveCard: boolean,
    isSaveEnhance: boolean
    regenerateData: any,
    cardSelectActive: string | Number
    cardAttributesIsActive: string
    cardEnhancementLevelIsActive: Number,
    enhanceSaveOrder: string
}




const initialState: SearchState = {
    refiningActive: 4,
    isActive: -1,
    announcementList: [],
    refiningCardList: [],
    selectCardList: [],
    successRate: [],
    isSelect: false,
    isSaveCard: false,
    isSaveEnhance: false,
    enhanceSaveOrder: "",
    regenerateData: null,
    cardSelectActive: -1,
    cardAttributesIsActive: '',
    cardEnhancementLevelIsActive: -1,
}
const SearchStore = createSlice({
    name: 'search',
    initialState,
    reducers: {
        changeActive(state, action) {
            state.isActive = action.payload
        },
        changeCardSelectActive(state, action) {
            state.cardSelectActive = action.payload
        },
        changeRefiningActive(state, action) {

            state.refiningActive = action.payload
        },
        getAnnouncementList(state, action) {
            state.announcementList = action.payload
        },
        // add refining card
        addRefiningCardList(state, action) {
            state.refiningCardList.push(action.payload)
        },
        //remove refining card
        removeRefiningCardList(state, action) {
            state.refiningCardList = state.refiningCardList.filter(item => item.id != action.payload)
        },
        //clear refining card list
        clearRefiningCardList(state) {
            state.refiningCardList = []
        },
        changeIsSelect(state, action) {
            state.isSelect = action.payload
        },
        changeSelectCardList(state, action) {
            state.selectCardList = action.payload
        },
        successRateList(state, action) {
            state.successRate = action.payload
        },
        changeIsSaveCard(state) {
            state.isSaveCard = !state.isSaveCard
        },
        changeIsSaveEnhance(state) {
            state.isSaveEnhance = !state.isSaveEnhance
        },
        changeEnhanceSaveOrder(state, action) {
            state.enhanceSaveOrder = action.payload
        },
        //regenerateData
        changeRegenerateData(state, action) {
            state.regenerateData = action.payload
        },
        changeCardAttributesIsActive(state, action) {
            state.cardAttributesIsActive = action.payload
        },

        changeCardEnhancementLevelIsActive(state, action) {
            state.cardEnhancementLevelIsActive = action.payload
        }





    }
})

export const { changeIsSaveEnhance, changeEnhanceSaveOrder, changeCardAttributesIsActive, changeCardEnhancementLevelIsActive, changeCardSelectActive, changeActive, getAnnouncementList, changeRefiningActive, addRefiningCardList, removeRefiningCardList, clearRefiningCardList, changeIsSelect, changeSelectCardList, successRateList, changeIsSaveCard, changeRegenerateData } = SearchStore.actions

export default SearchStore.reducer