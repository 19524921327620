import { Popup, Radio } from "react-vant"
import redPackImg from "../../../assets/images/myInvites/red_bg.png"
import presentImg from "../../../assets/images/cards/present.png"
// import "../../introductory/index.less"
import ButtonComp from '../../../components/common/ButtonComp';
import coinImg from '@/assets/images/redpacket/coins.png'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CardItem from "../../../components/CardItem";
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper'
SwiperCore.use([Pagination, Autoplay, Navigation])
import 'swiper/swiper-bundle.css'
import { formatNumber } from "../../../utils";




interface PropType {
    callBack?: (url?: string) => void
    data?: any[]
    title: string
    title1?: string
    type?: string,
    coinsCount?: number
}


const cardPresentDialog = ({ callBack, data, title, title1, coinsCount, type = '1' }: PropType) => {
    const [isShowCard, setShowCard] = useState(false)
    const navigate = useNavigate()
    console.log(data)
    return (
        <Popup
            className="introductory-page"
            teleport={document.querySelector("body")}
            visible={true}
            closeOnClickOverlay={false}
            style={{ width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,.7)", }}
            onClose={() => { }}>
            <div className='w-full  h-full relative px-4 flex flex-col items-center justify-center'>
                {
                    !isShowCard ? <div className="present-box w-full h-full flex flex-col items-center justify-center">
                        <img className="animate-rotate" src={redPackImg} alt="" />
                        <img className="absolute w-[220px] animate-rotate-turntable" src={presentImg} alt="" />
                        <ButtonComp title="OK" mt={20} callBack={() => { setTimeout(() => setShowCard(true), 500) }}></ButtonComp>
                    </div> :
                        <div className="present-box w-full h-full flex flex-col items-center justify-center">
                            <p className="text-xl text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Congratulations!</p>
                            <p className="text-white text-base mt-2">{title}</p>
                            <div className="h-[260px] w-[175px] my-4">
                                <Swiper
                                    loop={false}
                                    initialSlide={0}
                                    speed={700}
                                    observer={true}
                                    observeParents={true}
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 2500, // 自动滑动的时间间隔（毫秒）
                                        disableOnInteraction: false, // 用户交互后是否禁用自动滑动
                                    }}
                                    onReachEnd={() => { }}
                                >
                                    {
                                        data?.map((el: any, index) => {
                                            return (
                                                <SwiperSlide
                                                    key={index}
                                                    className="flex justify-between items-center"
                                                >
                                                    <div className="text-white w-[175px] h-[260px] rounded-[10px] overflow-hidden relative">
                                                        <CardItem power={el?.power} property={el?.property} is_nft={el?.is_nft} mint_state={el?.mint_state} enhanceLevel={el?.enhance_level} image={el?.url ? el?.url : el?.card} big={false} text="" level={el?.level + 1}>
                                                        </CardItem>

                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>
                            </div>
                            <p className="text-white text-base">{title1}</p>
                            <div className={`${type == "1" ? '' : 'hidden'} btn flex w-full mt-10`}>
                                <ButtonComp title="My Deck" width={140} callBack={() => { callBack && callBack("/myDeck") }}></ButtonComp>
                                <ButtonComp title="Happy to Accept" width={140} callBack={() => { callBack && callBack(`/cards?id=${2}`) }}></ButtonComp>
                            </div>

                            {
                                type == '2' && <div className="flex mt-4 items-center text-style-6 text-base font-bold">
                                    <img className="w-8 mx-2" src={coinImg} alt="" />
                                    <span>+{formatNumber(coinsCount ? coinsCount : 0)}</span>
                                </div>
                            }
                            <div className={`${type == "2" ? '' : 'hidden'} btn flex w-full mt-10`}>
                                <ButtonComp title="Happy to Accept" width={140} callBack={() => { callBack && callBack() }}></ButtonComp>
                            </div>
                        </div>

                }
            </div>

        </Popup>
    )
}


export default cardPresentDialog