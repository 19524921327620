import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface ProgressState {
    value: number;
    chatList: any;
    messageChatList: any;
    messageIsLoading: boolean;
    currCharacterInfo: any
    refiningValue: any;
    refining_loading_text: Array<string>;
    actionList: any[]
    avatarsChatList: any[]
    currAvatarId: string,
    avatarsImgList: any[],
    currAvatarAudio: string
}




const initialState: ProgressState = {
    value: 0,
    chatList: [],
    messageChatList: [],
    messageIsLoading: true,
    currCharacterInfo: {},
    refiningValue: {},
    refining_loading_text: [],
    actionList: [],
    avatarsChatList: [
        {
            content: "<p>I want to create an On-chain AI Avatar.</p> \n",
            direction: "right",
            status: "success",
            type: "message"
        },
        {
            content: "<p>Okay, let me help you. Please introduce the name, gender, personality, and background story of the Avatar you want?</p> \n",
            direction: "left",
            status: "success",
            type: "message"
        }
    ],
    avatarsImgList: [],

    currAvatarId: "",
    currAvatarAudio: ""
}
const progressStore = createSlice({
    name: 'progress',
    initialState,
    reducers: {
        addChatList(state, { payload: { id, item } }) {
            for (let i = 0; i < state.chatList.length; i++) {
                if (state.chatList[i].id == id) {
                    state.chatList[i].list = [...state.chatList[i].list, item]

                    state.messageChatList = [...state.chatList[i].list]
                }

            }
            // state.chatList.list = [...state.chatList.list, action.payload]
        },
        popChatList(state, { payload }) {
            for (let i = 0; i < state.chatList.length; i++) {
                if (state.chatList[i].id == payload) {
                    state.chatList[i].list.pop()
                    state.messageChatList = [...state.chatList[i].list]
                }

            }
            // state.chatList.list.pop()
        },
        removeChatList(state, { payload }) {
            for (let i = 0; i < state.chatList.length; i++) {
                if (state.chatList[i].id == payload) {
                    state.chatList[i].list = []
                    state.messageChatList = [...state.chatList[i].list]
                }

            }
        },
        //改变当前数字人聊天记录
        changeChatList(state, { payload }) {
            for (let i = 0; i < state.chatList.length; i++) {
                try {
                    if (state.chatList[i].id == payload.id) {

                        state.chatList[i].list = payload.list
                        state.messageChatList = [...state.chatList[i].list]
                    }
                } catch (e) { }



            }
            // state.chatList.list.pop()
        },
        changeActionList(state, { payload }) {
            for (let i = 0; i < state.chatList.length; i++) {
                if (state.chatList[i].id == payload.id) {
                    state.chatList[i].actionList = payload.list
                    state.actionList = [...state.chatList[i].actionList]
                }

            }
        },
        createAvatarsChatList(state, { payload }) {
            state.avatarsChatList = [...state.avatarsChatList, payload]
        },
        popAvatarsChatList(state, { payload }) {
            if (payload) {
                state.avatarsChatList = [
                    {
                        content: "<p>I want to create an On-chain AI Avatar.</p> \n",
                        direction: "right",
                        status: "success",
                        type: "message"
                    },
                    {
                        content: "<p>Okay, let me help you. Please introduce the name, gender, personality, and background story of the Avatar you want?</p> \n",
                        direction: "left",
                        status: "success",
                        type: "message"
                    }
                ]
            } else {
                state.avatarsChatList.pop()
            }
        },
        setCurrAvatarId(state, action) {
            state.currAvatarId = action.payload
        },

        changeLoading(state, action) {
            state.messageIsLoading = action.payload
        },
        pushChatListId(state, action) {
            state.chatList = [...state.chatList, action.payload]
        },
        setCurrCharacterInfo(state, action) {
            state.currCharacterInfo = action.payload;
        },
        setRefiningValue(state, action) {
            state.refiningValue = action.payload;
        },
        setRefiningLoadingText(state, action) {
            state.refining_loading_text = action.payload;
        },
        addAvatarsImgList(state, action) {
            state.avatarsImgList = [...state.avatarsImgList, action.payload];
        },
        delAvatarsImgList(state, action) {
            state.avatarsImgList = state.avatarsImgList.filter(item => item !== action.payload);
        },
        removeAvatarsImgList(state) {
            state.avatarsImgList = [];
        },
        changeAvatarAudio(state, action) {
            state.currAvatarAudio = action.payload;
        }

    }
})

export const { addChatList, popChatList,removeChatList, changeLoading, pushChatListId, setCurrCharacterInfo, setRefiningValue, setRefiningLoadingText, changeActionList, createAvatarsChatList, popAvatarsChatList, setCurrAvatarId, addAvatarsImgList, delAvatarsImgList, removeAvatarsImgList, changeAvatarAudio, changeChatList } = progressStore.actions

export default progressStore.reducer