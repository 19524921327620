import { Address } from '@ton/core';

import introductoryImg1 from "../assets/images/introduction1.png"
import introductoryImg2 from "../assets/images/introduction2.png"


export const USDT_MASTER_ADDRESS = Address.parse(import.meta.env.VITE_USDT_MASTER_ADDRESS);
export const INVOICE_WALLET_ADDRESS = Address.parse(import.meta.env.VITE_INVOICE_WALLET_ADDRESS);
export const PK_CARDS = () => {
    return [
        {
            "id": "-1",
            "name": "All"
        },

        {
            "id": "5",
            "name": "Diamond"
        },
        {
            "id": "4",
            "name": "Gold"
        },
        {
            "id": "3",
            "name": "Silver"
        },
        {
            "id": "2",
            "name": "Bronze"
        }

    ]
}
export const INTRODUCTORY_LIST = () => {
    return [
        {
            id: 0,
            title: "AI Avatars",
            text: [
                {
                    index: 0,
                    content: "🕹 AI Avatars are available 24/7 to chat with you."
                },
                {
                    index: 1,
                    content: "🎨 You can also create Avatars based on your imagination."
                },
                {
                    index: 2,
                    content: "💡 When other users interact with your Avatars, you earn rewards."
                },
                {
                    index: 3,
                    content: "⚔️ Your Avatar can also be used as a hero card in card battles!"
                }

            ]
        },
        {
            id: 1,
            title: "Cards Battle",
            text: [
                {
                    index: 0,
                    content: "🎮 You can set up card combinations and battle against other players."
                },
                {
                    index: 1,
                    content: "🃏 Cards of different levels and power can be obtained on the 'Cards' page."
                },
                {
                    index: 2,
                    content: "🤖 Hero cards can be earned by creating Avatars on the 'AI' page."
                },
                {
                    index: 3,
                    content: "🎯 You can also set the stakes for PK battles."
                },
                {
                    index: 4,
                    content: "💰 Win <$STAR> or Cards from the showdown!"
                }
            ]
        },
        {
            id: 2,
            title: "AI Cards and NFTs",
            text: [
                {
                    index: 0,
                    content: "🔄 You can trade, refine, or enhance your cards."
                },
                {
                    index: 1,
                    content: "⚙️ Each card has attributes, levels, power values, enhancement levels, and more."
                },
                {
                    index: 2,
                    content: "🛠 AI can create cards based on your input."
                },
                {
                    index: 3,
                    content: "🎯 You can also set the stakes for PK battles."
                },
                {
                    index: 4,
                    content: "💎 Every card can be minted as an <NFT>, allowing you to earn profits!"
                }
            ]
        },

        {
            id: 3,
            title: "Invite SPIN",
            text: [
                {
                    index: 0,
                    content: "🎁 Invite friends and earn up to 100 <USDT> in rewards."
                },
                {
                    index: 1,
                    content: "🎡 Each friend you invite gives you SPINs for the lucky wheel."
                },
                {
                    index: 2,
                    content: "🎯 <USDT> won from SPINs can be withdrawn instantly."
                },
                {
                    index: 3,
                    content: "🌟 You can also win a large amount of <$STAR> and card rewards!"
                }
            ]
        },
        {
            id: 4,
            title: "Tasks",
            text: [
                {
                    index: 0,
                    content: "💪 Complete tasks to earn a large amount of <$STAR>."
                },
                {
                    index: 1,
                    content: "🎰 Join the lottery for a chance to win even more <$STAR> and exclusive cards ahead of others!",

                },
                {
                    index: 2,
                    isImage: true,
                    image: introductoryImg1
                }

            ]
        },
        {
            id: 5,
            title: "Level and Settings",
            text: [

                {
                    index: 1,
                    content: "⚙️ Click Settings to adjust music, link your wallet, and configure NSFW options."
                },
                {
                    index: 2,
                    content: "📊 Tap on your level to check your ranking—top users each week can win 20 <USDT>!",

                },
                {
                    index: 2,
                    isImage: true,
                    image: introductoryImg2
                }
            ]
        }
    ]
}

export const ARENA_LIST = () => {
    return [
        {
            id: 0,
            name: "Standard",
            type: "all",
            level: "",
            starCount: "100K"
        },
        {
            id: 1,
            name: "NFT",
            type: "NFT",
            level: 6,
            starCount: "100K"
        },
        {
            id: 2,
            name: "Diamond",
            type: "Diamond",
            level: 5,
            starCount: "80K"
        },
        {
            id: 3,
            name: "Gold",
            type: "Gold",
            level: 4,
            starCount: "60K"
        }
    ]

}

export const SHOP_NAV = () => {
    return [
        {
            id: 1,
            name: "Claim Bonus Pack",
            title: "Bonus Pack",
            description: "Do you want to buy Bonus Pack in StarAI for 9 star?",

        },
        {
            id: 8,
            name: "Card Draw" ,
            title: "Draw Cards",
            description: "Do you want to buy Draw Cards in StarAI for 5 star?"

        },
        {
            id: 3,
            name: "Regenertate Bundle",
            title: "Regenertate Bundle",
            description: "Do you want to buy Regenertate Bundle in StarAI for 5 star?"

        },
        {
            id: 11,
            name: "Enhance Protection",
            title: "Enhance Protection",
            description: "Do you want to buy Enhance Protection in StarAI for 30 star?"

        },
        {
            id: 4,
            name: "Refining Bundle",
            title: "Refining Bundle",
            description: "Do you want to buy Refining Bundle in StarAI for 5 star?"

        },
        {
            id: 10,
            name: "Download Bundle",
            title: "Download Bundle",
            description: "Do you want to buy Download Bundle in StarAI for 5 star?"

        }
    ]
}
