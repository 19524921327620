
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ConnectWallet from '../common/ConnectWallet';
import nft from "../../assets/images/nftIcon.png"
import wallet from "../../hook/wallet/contentWallet"
import WebApp from '@twa-dev/sdk';


const NftMint = () => {

    const { useConnectWallet, useGetWalletAddress } = wallet();
    const address = useGetWalletAddress()

    const isShowWallet = useRef(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (address) {
            // setIsShowWallet(false)
            // const url = import.meta.env.VITE_TRADE_URL + address
            // setTimeout(() => {
            //     WebApp.openLink(url)
            // }, 2000)

        }
    }, [address])


    const connectWalletHandle = () => {
        // navigate('/cards?id=1')
        // if (!address) {
        //     useConnectWallet()
        //     // isShowWallet.current = true
        // } else {
        //     const url = import.meta.env.VITE_TRADE_URL + import.meta.env.VITE_MINT_CONTRACT_ADDRESS
        //     WebApp.openLink(url)
        // }

        const url = import.meta.env.VITE_TRADE_URL + import.meta.env.VITE_MINT_CONTRACT_ADDRESS
        WebApp.openLink(url)
    }

    return (
        <>
            <img className={`fixed z-[800] w-[52px] right-[16px] top-[180px]`} onClick={(event) => {
                event.stopPropagation();
                connectWalletHandle()
            }} src={nft} alt="" />
            {/* {
                isShowWallet.current && <ConnectWallet></ConnectWallet>
            } */}

        </>

    )
}


export default NftMint;
