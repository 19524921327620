

import enhanceImg from "../../assets/images/shop/enhance.png"

import flowerImg from "../../assets/images/shop/flower.png"

import progressRight from "@/assets/images/refining/progressRight.png"
import selectedImg from "../../assets/images/selectActive.png"
import starGreenImg from "../../assets/images/rules/star.png"
import BuyComp from '../../components/common/BuyComp';


interface PropType {
    dataList: any[]
}
const Regenerate = ({ dataList }: PropType) => {
    return (

        <>
            <div style={{ backgroundSize: '100% 100%' }} className="bg-[url(@/assets/images/shop/bg-2.png)] flex justify-center flex-col items-center  px-4 py-8 bg-cover bg-no-repeat h-[280px] w-full">
                <div className='w-[80%] flex justify-around items-center relative'>
                    <img className='w-[50px] h-[70px]' src={enhanceImg} alt="" />
                    <div className='text-center'>
                        <p className='text-white text-sm'>Enhance Failed</p>
                        <div className="w-[94px] mt-1 overflow-hidden flex">

                            <img className=" w-[94px] translate-x-[-94px] progressRight_1 " src={progressRight} alt="" />
                            <img className=" w-[94px] translate-x-[-94px] progressRight_2 " src={progressRight} alt="" />
                        </div>
                    </div>

                    <img className='w-[50px] h-[70px]' src={enhanceImg} alt="" />
                </div>
                <div className='flex items-center mt-2'>
                    <img className='w-3 h-3 mr-2' src={selectedImg} alt="" />
                    <p className='text-white text-xs'>Enhance Protection (Chances)</p>
                </div>
                <div className="text flex-1 text-left mt-3">
                    <p className='text-sm text-shadow-lg text-white flex items-start'><img className='w-3 h-3 mr-2 mt-1' src={starGreenImg} alt="" />Enhance Protection allows you to retain the enhance level of a high-level card prior to enhance if the enhance attempt fails.</p>
                    <p className='text-sm text-shadow-lg text-white flex items-start'><img className='w-3 h-3 mr-2 mt-1' src={starGreenImg} alt="" />This applies only to enhancement levels of 3 or higher.</p>
                    <p className='text-sm text-shadow-lg text-white flex items-start'><img className='w-3 h-3 mr-2 mt-1' src={starGreenImg} alt="" />After a successful purchase, the number of chances you have will be displayed in the functional area.</p>
                </div>

            </div>
            <div className='grid grid-cols-1 gap-4 mt-4'>
                {
                    dataList.map((item: any, index) => {
                        return (
                            <div key={index}>
                                {
                                    item.length > 0 && <div key={index} className='relative w-full flex items-center justify-around px-4 py-2 h-[90px] rounded-[20px] special-class'>
                                        <div className={`${item[0].discount <= 0 ? 'hidden' : ''} w-8 absolute -top-1 -left-0 flex justify-center items-center`}>
                                            <img className='w-8' src={flowerImg} alt="" />
                                            <span className='absolute text-xs text-white'>{item[0].discount}%</span>
                                        </div>

                                        <div className='flex flex-1 items-center justify-center'>
                                            <p className='text-sm font-[900] text-white'>Includes<span className='text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'> {item[0].count} Chances</span> for Enhance Protection</p>
                                        </div>
                                        <BuyComp title='Enhance Protection Chances' info={item}></BuyComp>
                                    </div>
                                }
                            </div>

                        )
                    })
                }
            </div>
        </>


    )
}


export default Regenerate;