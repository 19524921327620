
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import coinImg from '@/assets/images/coin.png'
import bgImg from "@/assets/images/pk/bg.png"

import unknow from "../../assets/images/pk/unknow.png"
import unknownnoneImg from "../../assets/images/pk/unknow-none.png"
import pk_activeImg from "../../assets/images/tabbar/battle_active.png"
import pkImg from "@/assets/images/tabbar/battle.png"

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import {getBattleRecordsDetails } from '@/api/pk_url.js'
import { loginRewards, getUserInfo, loginCheckIn } from '@/api/url.js'
import Back from '../../components/common/BackComp.js';
import { List, Loading, Popup } from 'react-vant'
import RecordDetailsContent from "../../components/pk/recordDetails"

interface PropsType {
    callback?: () => void
    title?: string
    details?: any
}
const recordDetails = ({ callback, title, details }: PropsType) => {
    useEffect(() => {
        getRecordDetailsHandle()
    }, [])
    const [recordResult, setRecordResult] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const getRecordDetailsHandle = async () => {
        setLoading(true)
        try {
            const { code, data } = await getBattleRecordsDetails(details.id)

            if (code == 0) {
                setRecordResult(data)
            }
            setTimeout(() => {
                setLoading(false)
            }, 300)

        } catch (e) {
            setLoading(false)
        }


    }
    return (
        <Popup visible={true} position="right" style={{ width: '100%', height: "100%", background: "#000" }} closeable={false}>
            <div className="h-11 flex items-center justify-center fixed top-0 w-full px-4 z-[11] box-border bg-[#000]">
                <Back isLeft={false} callback={callback}>
                    <div className='w-full h-10 text-white flex justify-around items-center'>
                        {title}
                    </div>
                </Back>
            </div>
            <div className="text-white pt-[44px] pb-5 h-full text-center">
                {
                    loading && <Loading type="spinner" size="20" color="#00E49F">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                    </Loading>
                }
                {
                    !loading && <RecordDetailsContent closeHandle={callback} recordResult={recordResult} recordInfo={details}></RecordDetailsContent>
                }


            </div>





        </Popup>
    )
}


export default recordDetails;