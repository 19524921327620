import { Popup, Radio, Swiper } from "react-vant"
import level_logo from "@/assets/images/tabbar/level_logo.svg";
import level_bg from "@/assets/images/tabbar/level_bg.png";
import leftImg from "../../assets/images/left.png"
import rightImg from "../../assets/images/right.png"
import isActiveImg from "../../assets/images/selectActive.png"
import tabbarHook from "@/hook/useTabbar.tsx"
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { SwiperInstance } from 'react-vant';
import ButtonComp from '../../components/common/ButtonComp';
import rules_bg from "../../assets/images/rules/rules_bg.png"
import rules_bg1 from "../../assets/images/rules/rules_bg1.png"
import rules_bg2 from "../../assets/images/rules/rules_bg2.png"
import rules_bg3 from "../../assets/images/rules/rules_bg3.png"
import rules_bg4 from "../../assets/images/rules/rules_bg4.png"
import rules_bg5 from "../../assets/images/rules/rules_bg5.png"
import { HighlightPercentages } from "../../utils";
import * as echarts from 'echarts'
import { format } from "path";


interface PropType {
    callback?: () => void
    data: any
}
const powerDialog = ({ callback, data }: PropType) => {
    const chartRef = useRef<any>(null)
    const options = {
        color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
        legend: {
            show: false
        },
        tooltip: {


        },
        radar: {
            radius: 80,
            startAngle: 90,
            splitNumber: 5,
            axisName: {
                formatter: (el: any, val: any) => {
                    return `${el}\n (${val.max})`
                },
                color: '#428BD4'
            },
            indicator: [
                { name: 'Enhance', max: data?.max_enhance_power },
                { name: 'Refine', max: data?.synthesis_power },
                { name: 'Quality', max: 25 },
                { name: 'Aesthetic', max: 25 },
                { name: 'Level', max: data?.basic_power },

            ]
        },
        series: [
            {
                type: 'radar',
                tooltip: {
                    trigger: 'item',
                    formatter: (param: any) => {
                        let str = ''
                        for (let i = 0; i < 5; i++) {
                            str += `<p>${param.data.obj[i]}: ${param.value[i]}</p>`
                        }
                        return str;
                    }
                },
                data: [
                    {
                        obj: [
                            'Enhance',
                            'Refine',
                            'Quality',
                            'Aesthetic',
                            'Level',
                        ],
                        value: [data?.enhance_power, data?.synthesis_power, data?.quality_power, data?.aesthetic_power, data?.basic_power],
                        areaStyle: {
                            color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                                {
                                    color: '#00E49F',
                                    offset: 0
                                },
                                {
                                    color: '#54ADFF',
                                    offset: 1
                                }
                            ])
                        }
                    }
                ]
            }
        ]
    }

    useEffect(() => {
        const chart = echarts.init(chartRef.current)
        chart.setOption(options)
        return () => {
            chart.dispose()
        }
    }, [])

    const chooseBg = (key: number) => {
        switch (key % 6) {
            case 0:
                return rules_bg;
            case 1:
                return rules_bg1;
            case 2:
                return rules_bg2;
            case 3:
                return rules_bg3;
            case 4:
                return rules_bg4;
            case 5:
                return rules_bg5;
        }

    }
    return (
        <Popup
            className="introductory-page"
            teleport={document.querySelector("body")}
            visible={true}
            closeOnClickOverlay={false}
            style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
            onClose={() => { }}>
            <div className='w-full  h-full relative px-4 flex flex-col justify-center'>
                <div style={{ backgroundSize: "100% 100%" }} className="flex flex-col justify-between py-10 px-4 items-center h-[80%] bg-[url(@/assets/images/refining/card_refining_guide.png)] bg-cover bg-no-repeat">
                    <div className="flex flex-col items-center  h-full overflow-auto">
                        <div className="text-2xl">Power: <span style={{

                        }} className="text-transparent font-semibold bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">{data?.power}</span></div>
                        <div ref={chartRef} className="echarts h-[280px] w-[280px] flex-shrink-0">

                        </div>
                        <div style={{ background: `url(${chooseBg(0)})`, backgroundSize: "100% 100%" }} className=" p-[10px] pl-[6px] text-[14px] mt-4 flex justify-center text-[#333] leading-[18px] rounded-[10px]">
                            <div className="text-[14px]text-[#333] inline leading-[18px]" dangerouslySetInnerHTML={{ __html: HighlightPercentages("The power for each Level of card is independent and fixed, while the refined power will be determined by the Level of card being refined.") }}></div>
                        </div>
                        <div style={{ background: `url(${chooseBg(1)})`, backgroundSize: "100% 100%" }} className="w-full p-[10px] pl-[6px] text-[14px] mt-4 flex justify-center text-[#333] leading-[18px] rounded-[10px]">
                            <div className="text-[14px]text-[#333] inline leading-[18px]" dangerouslySetInnerHTML={{ __html: HighlightPercentages("The quality and aesthetic power will be determined by AI.") }}></div>
                        </div>

                    </div>


                    <div className="mt-4">
                        <ButtonComp title="OK" callBack={() => {
                            callback && callback()
                        }}></ButtonComp>
                    </div>
                </div>


            </div>

        </Popup>
    )
}


export default powerDialog