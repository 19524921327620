interface buttonProps {
    title?: any,
    width?: number | string,
    height?: number,
    children?: any,
    isLoading?: boolean,
    callBack?: () => void,
    isShadow?: boolean,
    fontSize?: number,
    disabled?: boolean,
    isPlayAudio?: boolean,
    mt?: number,
    isGradient?: string
}
import loadingImg from '@/assets/images/task/loading.svg'
import { useRef } from "react";
import backMusic from "../../assets/mp3/close.mp3"
import { useSelector } from 'react-redux';
import WebApp from '@twa-dev/sdk';


const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));


const ButtonComp = ({ title, children, width = 174, height = 36, isLoading, callBack, isShadow = true, fontSize = 16, disabled, isPlayAudio = true, mt, isGradient }: buttonProps) => {
    const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
    const { settings } = useSelector((state: any) => state.settings)
    const AudioRef = useRef<any>()
    const buttonHandle = async () => {

        if (disabled) {
            return false
        }
        if (AudioRef.current && isPlayAudio) {
            // AudioRef.current.currentTime = 0;
            AudioRef.current.play()
        }
        if (settings.vibration) {
            WebApp.HapticFeedback.impactOccurred('heavy'); // 震动1000毫秒
        }
        await sleep(200)
        callBack && callBack()
    }
    return (
        <>
            <div className={`flex items-center rounded-[10px] mx-auto justify-center`}
                style={{
                    // background: `${disabled ? 'bg-[#a6a6a6]' : 'linear-gradient( 135deg, #AFFFD9 0%, #88D653 23%, #D5F8F2 58%, #7CE368 86%, #BDFBF2 100%, #C0FCF6 100%)'}`,
                    width: width,
                    height: height,
                    marginTop: mt + 'px',
                    // boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.5)',
                    // textShadow: isShadow ? '0px 2px 4px rgba(0,0,0,0.5)' : "",
                }}>
                <div onClick={(e) => {
                    e.stopPropagation();
                    buttonHandle()
                }}
                    style={{
                        zIndex: 4,
                        // boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.5)',
                        background: isGradient ? isGradient : "",
                        width: typeof width == 'string' ? width : width - 4 + 'px',
                        height: typeof height == 'string' ? height : height - 4 + 'px',
                    }}
                    className={`cursor-pointer rounded-[10px] relative ${disabled ? '!bg-[#a6a6a6]' : 'new_button_shadow'} mx-auto flex justify-center items-center text-white  focus:outline-none`}>
                    {
                        children && children
                    }
                    <span style={{ fontSize: fontSize + 'px' }} className="text-base text-white">{title}</span>
                    {isLoading && <img className='w-4 h-4 animate-spin ml-2' src={loadingImg} />}

                </div>
            </div >
            {
                isSoundPlay && <audio ref={AudioRef} src={backMusic} id="audio"></audio>
            }


        </>
    )
}



export default ButtonComp