
import { useCallback, useEffect, useRef, useState } from 'react'
import done from '@/assets/images/task/done.png'
import { useNavigate, useSearchParams } from 'react-router-dom';
import WebApp from '@twa-dev/sdk'
import lottery from '@/assets/images/home/lottery.png'
import up from '@/assets/images/home/up.png'
import card from '@/assets/images/home/card.png'
import task_check_in from '@/assets/images/task/task_check_in.png'
import right from '@/assets/images/task/right.png'
import down from '@/assets/images/task/down.png'
import coins from '@/assets/images/task/coins.png'
import reduce from '@/assets/images/task/reduce.png'
import coin from '@/assets/images/coin.png'
import inviting_bg from '@/assets/images/inviting/inviting_bg.png'
import rules_bg_4 from '@/assets/images/inviting/rules_bg_4.png'

import ton_ai from '@/assets/images/ton_ai/ton_ai.png'
import ton_ai_1 from '@/assets/images/ton_ai/ton_ai_1.png'
import ton_ai_2 from '@/assets/images/ton_ai/ton_ai_2.png'
import ton_ai_3 from '@/assets/images/ton_ai/ton_ai_3.png'
import ton_ai_4 from '@/assets/images/ton_ai/ton_ai_4.png'
import ton_ai_5 from '@/assets/images/ton_ai/ton_ai_5.png'
import ton_ai_6 from '@/assets/images/ton_ai/ton_ai_6.png'
import ton_ai_7 from '@/assets/images/ton_ai/ton_ai_7.png'
import ton_ai_8 from '@/assets/images/ton_ai/ton_ai_8.png'
import ton_ai_9 from '@/assets/images/ton_ai/ton_ai_9.png'

import rules_bg_2 from '@/assets/images/inviting/rules_bg_2.png'
import rules_bg_3 from '@/assets/images/inviting/rules_bg_3.png'
import done_green from '@/assets/images/task/done-green.png'
import PublicDialog from '../../components/PublicDialog'
import { getTask, postCheck_in, getCheck_in, getClaimPoints, chainCheckIn, getUserInfo, startTask } from '@/api/url.js'
import wallet from "../../hook/wallet/contentWallet"
import { useCheckInContract } from '@/hook/contract/useCheckInContract';
import { Address } from '@ton/core';
import { formatNumber } from '../../utils'
import { useSelector, useDispatch } from 'react-redux'
import GetCoinAnimationDialog from '../../components/GetCoinAnimationDialog'
import { changeCheckDialog, changeUserInfo, setAddressParams } from '../../store/module/tabbar'
import { changeCheck_in_loading, changeIsGate, changeTasks } from '../../store/module/tasks'
import { changeTransactionStatus } from '../../store/module/tontransaction'
import usePlaySound from "@/hook/usePlaySound.tsx"
import BScroll from 'better-scroll'
import { create } from "ad-pull";
import DynamicScript from '../../components/Script';
import { Circle, CountDown } from 'react-vant';
import "./task.less"
import { judgeClient } from '../../utils/judgeClient';
import Pay from '../../components/starsPay/Pay';
import ButtonComp from '../../components/common/ButtonComp';
import coinsMusic from "../../assets/mp3/coins.mp3"
import TonNetworkError from '../../hook/useTonNetworkError';
import walletHandle from "../../hook/wallet/connectOkWallet.tsx"
import { useTonWallet } from '@tonconnect/ui-react';
// import { TonAdInit } from 'ton-ai-sdk'
// import {TonExchangeBanner} from 'ton-ai-sdk'
// import 'ton-ai-sdk/dist/index.css'

new BScroll('.role-box', {
    pullUpLoad: true,
    scrollbar: true,
    pullDownRefresh: true
    // and so on
})
const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
const Task = () => {

    const { useConnectWallet, useGetWalletAddress, useDisconnectWallet, handleGateConnectClick } = wallet();
    const address = useGetWalletAddress()
    const [params] = useSearchParams();
    const closePlaySound = usePlaySound('close')
    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const tabbarPlaySound = usePlaySound('tabbar')
    const myRef = useRef<any>(null)
    const [loading, setLoading] = useState(false)
    const [point, setPoint] = useState('')
    const isClaim = useRef(false)
    const coinDomCoordinate = useSelector((state: any) => state.tabbar.coinDomCoordinate)
    const okWalletAddress = useSelector((state: any) => state.ton.okWalletAddress)
    const isSendSuccess = useSelector((state: any) => state.ton.isSendSuccess)
    // const check_in_loading = useSelector((state: any) => state.tasks.check_in_loading)
    const address_params = localStorage.getItem('address_params')
    const dispatch = useDispatch()
    const [data, setData] = useState<any>(useSelector((state: any) => state.tasks.tasksData))
    const boxRef = useRef<any>(null)
    const [dialog, setDialog] = useState(false)
    const [taskState, setTaskState] = useState(1)
    const timeRef = useRef<any>(null)
    const [timeCount, setTimeCount] = useState();
    const [rate, setRate] = useState(100);
    const [claiming, setClaiming] = useState("");
    const [claimPointsDialog, setClaimPointsDialog] = useState(false)
    const [adContainer, setAdContainer] = useState(true)
    const [checkInData, setCheckInData] = useState<any>({})
    const [tonAi, setTonAi] = useState<any>([])

    const StarLogo = [ton_ai, ton_ai_1, ton_ai_2, ton_ai_3, ton_ai_4, ton_ai_5, ton_ai_6, ton_ai_7, ton_ai_8, ton_ai_9]
    const navigate = useNavigate()
    const getCheckInData = useCallback(async () => {
        const resGetCheck_in = await getCheck_in()
        setCheckInData(resGetCheck_in.data)
    }, [dialog])
    useEffect(() => {
        if (params.get('check_in')) {
            setDialog(true)
        }
    }, [])

    // useEffect(() => {
    //     TonAdInit()
    // }, [])

    const getTaskData = useCallback(async () => {
        const res = await getTask(0, 0)
        if (res.code == '0') {
            setData(res.data.task)
            dispatch(changeTasks(res.data.task))

        }
        // setUserInfo()
    }, [])
    useEffect(() => {
        try {
            const ad = create({
                userId: localStorage.getItem('userId'),
                platform: WebApp.platform == 'unknown' ? 'web' : WebApp.platform,
                isPremium: WebApp.initDataUnsafe.user?.is_premium ? true : false

            });
            ad.addDid([
                {
                    html: "#ad-container",
                    placeCode: "1839150296874487808",
                    onClick: () => {
                        if (!data.filter((el: { code: string; }) => el.code == 'teleads')[0].state) {
                            setTimeout(() => {
                                getClaimPoints({ code: 'teleads', init_data: WebApp.initData || localStorage.getItem('init_data') }).then((res: any) => {
                                    setClaimPointsDialog(true)
                                    setPoint(res.data.points)
                                })
                            }, 5000)

                        }
                    },
                    onClose: () => {
                        setAdContainer(false)
                    }
                },
            ]);
            getTaskData()
        } catch {

        }

    }, [])

    const setCheck_in = useCallback(async () => {
        await postCheck_in({ init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA })
        const resGetCheck_in = await getCheck_in()
        setUserInfo()
        setCheckInData(resGetCheck_in.data)
    }, [])
    useEffect(() => {
        if (Object.keys(checkInData).length === 0) {
            getCheckInData()
        }
    }, [getCheckInData])



    //签到时链接钱包弹窗的显示隐藏
    const [checkInWallet, setCheckInWallet] = useState(false)
    //已经签到的提示语弹窗
    const [readyCheck, setReadyCheckIn] = useState(false)
    const [walletReadyCheck, setWalletReadyCheck] = useState(false)
    const [tonNetworkVisible, setTonNetworkVisible] = useState(false)
    //链上交互
    //链上交互
    const { checkIn } = useCheckInContract()
    useEffect(() => {
        if (isSendSuccess == "1") {
            setTimeout(async () => {
                await checkInHandler()
            }, 2000)

        } else if (isSendSuccess == "-1") {
            setTimeout(async () => {
                setLoading(false)
            }, 1000)

        }
    }, [isSendSuccess])
    const checkInHandle = async () => {
        // if (checkInData.is_chain_check_in) {
        //     setReadyCheckIn(true);
        //     return false;
        // }
        if (loading) {
            return false;
        }
        if (!address) {
            setCheckInWallet(true)
            setDialog(false)
            return
        }

        setLoading(true)
        const { code } = await chainCheckIn({ check_in_addr: Address.parse(address).toString() })
        if (code == "0") {
            await checkIn()
        } else {
            setWalletReadyCheck(true)
        }
    }
    //签到函数
    const checkInHandler = async () => {
        setLoading(false);
        const address_params = Address.parse(address);
        dispatch(setAddressParams(address_params.toString()))

        try {
            const res = await chainCheckIn({ check_in_addr: address_params.toString(), is_ok: true })
            if (res.code == '0') {

                dispatch(changeCheck_in_loading({
                    loading: true,
                    msg: '',
                    point: res.data.toString()
                    // point: (Math.floor(Number(checkInData.check_in_rewards[checkInData.check_in_data - 1]) * 5 * (1 + (userInfo.ponits_boost / 1000)))).toString()

                }))
                getCheckInData()
                setUserInfo()
            }
            dispatch(changeTransactionStatus(null))
        } catch (e) {
        }

    }

    const walletInfo: any = useTonWallet()
    const isGateAddress = useSelector((state: any) => state.tasks.isGateAddress)
    useEffect(() => {
        if (walletInfo && walletInfo.name == "GateWallet" && isGateAddress == 'gate') {
            callback({
                code: "GateConnect",
                points: 5000
            })
        }
    }, [walletInfo])


    //钱包相关
    const [isConnect, setIsConnect] = useState(false)
    //链接钱包
    const connectWallet = async (el?: any) => {
        if (address) {
            return false
        }
        if (el.code == 'GateConnect') {
            handleGateConnectClick(el, callback)
        } else {
            useConnectWallet(el, callback);
        }

    }


    useEffect(() => {
        setIsConnect(false);
        if (address) {
            // setDialog(true)
            setCheckInWallet(false)
        }
    }, [address])

    //钱包已签到弹窗
    const walletReadyCheckIn = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <div className='text-center w-full'>
                    <span className='block text-sm text-[#333]'>This wallet have already checked in today.
                    </span>
                    <span className='text-sm text-[#333]'>Please try again one.</span>
                </div>
                <ButtonComp title="OK" callBack={() => {
                    setWalletReadyCheck(false)
                }}></ButtonComp>

            </div>
        )
    }
    //用户钱包已签到弹窗
    const readyCheckIn = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <div className='text-center w-full'>
                    <span className='block text-sm text-[#333]'>You have already checked in today.
                    </span>
                    <span className='text-sm text-[#333]'> Please try again tomorrow.</span>
                </div>
                <ButtonComp title="OK" callBack={() => {
                    setReadyCheckIn(false)
                }}></ButtonComp>

            </div>
        )
    }
    //签到链接钱包的弹窗
    const connect_wallet_button = () => {
        return (
            <div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
                <p className='text-sm text-[#333]'>Please connect your TON wallet.</p>
                <ButtonComp callBack={() => { connectWallet() }} title="Connect"></ButtonComp>
            </div>
        )
    }
    const callback = async (el: any) => {
        const { code } = await getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA })
        if (code == '0') {
            setPoint(el.points)
            setClaimPointsDialog(true)
            await getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA }) //领取奖励
            await getTaskData() //重置任务列表
        }
    }

    // ---------------------------金币掉落音效--------------------
    const isSoundPlay = useSelector((state: any) => state.settings.settings.sound)
    const AudioRef = useRef<any>()
    const getCoinAnimation = useCallback((x: any, y: any) => {
        const R = 100; // 扩散半径
        const time = 0.25; //扩散过度时间
        const time2 = 1; // 收集过度时间
        const w = 26; // 金币大小
        const Num = 8 //金币数量
        setlits(x, y)


        if (AudioRef.current) {
            // AudioRef.current.currentTime = 0;
            AudioRef.current.play()
        }

        const total = document.getElementsByClassName('total_1')[0] // 获取金币最终飞向
        function setlits(X: any, Y: any) {
            var species = document.createElement('div');
            species.className = 'species'
            for (let i = 0; i < Num; i++) {
                var img = document.createElement('img');
                img.className = 'speciesstyle'
                img.style.width = w + 'px',
                    img.style.height = w + 'px',
                    img.style.left = X + 'px',
                    img.style.top = Y + 'px',
                    img.style.transform = 'translate(0%, 0%)',
                    img.style.transition = `${time}s`
                img.src = coin
                species.appendChild(img);
            }
            boxRef.current.appendChild(species)
            setTimeout(() => {
                clickss(X, Y)
            }, 0)
        }
        // 计算金币扩散范围和坐标
        function clickss(X: number, Y: number) {
            const specielist: any = document.getElementsByClassName('speciesstyle')
            const num = specielist.length
            const center = {
                x: X,
                y: Y
            }
            for (let i = 0; i < num; i++) {
                const xz = center.x + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.sin(2 * Math.PI * i / num)
                const yz = center.y + Math.floor(Math.random() * ((R * 1.1) - (R * 0.6) + 1)) + (0.6 * R) * Math.cos(2 * Math.PI * i / num)
                specielist[i].style.transform = `translate(${xz - (X + w / 2)}px, ${yz - (Y + w / 2)}px)`
            }
            setTimeout(() => {
                for (let i = 0; i < num; i++) {
                    specielist[i].style.transition = `${time2}s`
                    specielist[i].style.transform = `translate(0,0)`
                    specielist[i].style.left = Math.floor(total?.getBoundingClientRect().x) + 'px'
                    specielist[i].style.top = Math.floor(total?.getBoundingClientRect().y) + 'px'
                }
                // 结束清除蒙层
                setTimeout(() => {
                    remove()
                }, (Number(time2) + 0.5) * 500) //飞行时间结束

            }, (Number(time) + 0.5) * 500) //扩散时间结束
        }
        // 清除dom
        function remove() {
            const species = document.getElementsByClassName('species')[0].remove()
        }
        setDialog(false)
        getTaskData()
        setUserInfo()
    }, [coinDomCoordinate, dialog])

    const setUserInfo = useCallback(async () => {
        const UserInfo = await getUserInfo(address_params)
        if (UserInfo.code == "0") {
            dispatch(changeUserInfo(UserInfo.data))

        }
    }, [])

    const dialogContent = () => {
        return (
            <div className=' px-[14px] text-center'>
                <div className='text-center w-full pt-[42px] font-bold leading-6 text-[20px]'>Daily Check-in Rewards</div>
                <div className='flex  flex-wrap justify-center gap-[6px] mb-[30px] mt-5'>
                    {checkInData.check_in_rewards?.map((el: any, index: number) => {
                        return (
                            <div key={`Check-in${index}`} className={`bg-[#fff] ${checkInData.check_in_data > index ? 'opacity-50' : ''} rounded-[10px]  ${index == 6 ? 'w-[158px]  h-[70px] ' : ' w-[76px]  h-[70px]'} flex flex-col items-center py-[6px] text-center text-[12px] leading-[15px]`}>
                                Day {index + 1}

                                {el.rewardType == 'star' ?
                                    <>
                                        <img className={`w-[21px] h-[14px] my-[7px] ${checkInData.check_in_data > index ? 'hidden' : ''}`} src={coins} alt="" />
                                        <img className={`w-[18px] h-[18px] my-[5px] ${checkInData.check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
                                    </> :
                                    el.rewardType == 'card' ?
                                        <div className={`text-center  text-[12px] flex items-center gap-1 leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[16px] h-6' src={card} alt="" /> x1</div>
                                        :
                                        <div className={`text-center text-[12px] flex items-center gap-1 leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[30px] h-[30px]' src={lottery} alt="" /> x1</div>
                                }

                                <span className={`text-center text-[12px] leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}>
                                    {el.rewardType == 'star'
                                        ? `+${formatNumber(el.reward)}` : el.rewardType == "card" ? 'Card' : 'Invite Lottery'
                                    }
                                </span>

                            </div >
                        )
                    })}
                    <div className='bg-gradient-to-r mt-[10px] w-full text-[14px] px-5 leading-[18px flex items-center justify-between text-[#333333] relative h-[60px] rounded-[10px] from-[#00E49F] to-[#54ADFF]'>
                        {checkInData?.is_chain_check_in &&
                            <div style={{
                                width: ' 24px',
                                height: ' 24px',
                                background: 'linear-gradient( 125deg, #EF4444 0%, #E88585 100%)',
                                borderRadius: '0px 10px 0px 10px '
                            }} className=' absolute flex justify-center items-center right-0 top-0'>
                                <img className=' w-[18px] ' src={done} alt="" />
                            </div>
                        }
                        <p>Today of TON Check-in</p>
                        <div className='flex items-center gap-1'>
                            {Array.isArray(checkInData?.check_in_rewards) && checkInData?.check_in_data != 0 &&
                                <>
                                    {checkInData?.check_in_rewards[checkInData?.check_in_data - 1]?.rewardType == 'star' ?
                                        <div className='flex items-center gap-1'>
                                            <img className=' w-[31px]' src={coins} alt="" />
                                            <span className='text-style-3 !text-[14px] !leading-[18px] font-bold'>
                                                +{formatNumber(checkInData?.chain_check_in_rewards[checkInData?.check_in_data - 1]?.reward)}
                                            </span>
                                        </div>
                                        :
                                        <div className='flex items-center gap-1'>
                                            <img className=' h-[30px]' src={checkInData?.check_in_rewards[checkInData?.check_in_data - 1]?.rewardType == 'lottery' ? lottery : card} alt="" />
                                            <span className='text-style-3 !text-[14px] !leading-[18px] font-bold'>
                                                x{checkInData?.chain_check_in_rewards[checkInData?.check_in_data - 1]?.reward}
                                            </span>
                                        </div>}
                                </>}
                        </div>
                    </div>
                </div >
                <div className='relative w-[174px] h-[36px] mx-auto' >
                    {!loading
                        &&
                        <img className=' absolute -top-[13px] w-[27px] right-0' src={up} alt="" />
                    }
                    <div onClick={async (event) => {
                        event.stopPropagation();
                        dispatch(changeTransactionStatus(null))
                        await checkInHandle()

                    }} className='bg-gradient-to-r w-[174px] flex justify-center items-center rounded-[10px] button_shadow h-[36px] text-[#fff] from-[#00E49F] to-[#54ADFF]'>{!loading ? 'Check-in on TON' : <img className='w-5 h-5 animate-spin' src={loadingImg} />}</div>

                    {/* <div onClick={(event) => {
                    event.stopPropagation();
                    
                    setTonNetworkVisible(true)

                }} className='bg-[#a6a6a6] w-[174px] flex justify-center items-center rounded-[10px] button_shadow h-[36px] text-[#fff]'>{'Check-in on TON'}</div> */}
                </div>
            </div >
        )
    }

    useEffect(() => {

        try {
            let limit = 0
            data.forEach((element: any) => {
                if (element.code.includes("Ton.Ai")) {
                    limit++
                }
            });
            const options = {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    'x-api-key': import.meta.env.VITE_TONAI_KEY,
                }
            };
            fetch(`https://app.ton.ai/api/v2/openapi/matching/exchange?exchangeCampaignId=${import.meta.env.VITE_TONAI_ID}&telegramUserId=${localStorage.getItem('userId')}&limit=${limit}`, options)
                .then(response => response.json())
                .then(response => {
                    setTonAi(response.data.exchangeCampaigns)
                }).catch(err => console.error(err));
        } catch {

        }

    }, [])

    const reportExchange = (index: number) => {
        const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'x-api-key': import.meta.env.VITE_TONAI_KEY,
            },

            body: JSON.stringify({
                eventType: 'click',
                eventData: {
                    telegramUserId: localStorage.getItem('userId'),
                    inExchangeCampaignId: tonAi[index].inExchangeCampaignId,
                    outExchangeCampaignId: tonAi[index].outExchangeCampaignId
                }
            })
        };
        fetch('https://app.ton.ai/api/v2/openapi/exchange/event/report', options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));
    }
    //倒计时
    const timeChangeHandle = (val: any) => {
        setRate(100 / 15 * val.seconds)
        setTimeCount(val.seconds)
    }
    //点击完成任务 跳转链接
    const [taskLoading, setTaskLoading] = useState(false)
    let time: any = null
    const taskLinkHandle = async (e: any, el: any) => {
        clearTimeout(time)
        if (!taskLoading) {
            setTaskLoading(true)
            e.stopPropagation()
            closePlaySound()
            if (el.code !== 'ton') {
                if (el.code.includes('tg_')) {
                    WebApp.openTelegramLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
                } else {
                    if (el.code.includes("Ton.Ai")) {
                        reportExchange(el.image)
                        WebApp.openTelegramLink(tonAi[el.image].destination.url)
                    } else {
                        WebApp.openLink(judgeClient() == 'IOS' ? el.ios_link : el.link)
                    }
                }
                if (!el.state && el.claim_state !== 0) {
                    updateClaimState(el).then(() => {
                        getTaskData() //重置任务列表
                    })
                }
            }
        }
        time = setTimeout(() => {
            setTaskLoading(false)
        }, 1000)
    }
    const claimHandle = async (el: any) => {
        if (claiming) {
            return false;
        }
        setClaiming(el.code)
    }

    //更新claim状态以及任务列表
    const updateClaimState = async (el: any) => {
        const res = await getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') })
        await sleep(1000)
        return res.data.points || 0
    }
    // ------------------------ 滑动 -----------------------
    useEffect(() => {
        document.addEventListener('touchstart', touchStartHandle);
        return () => {
            document.removeEventListener('touchstart', touchStartHandle);
            // window.scrollTo(0, 0);
        }

    })

    const touchStartHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 0.5px)",
                "important"
            );
        }
    }

    const [okWallet, setOkWallet] = useState(false)
    const okWalletInfo = useRef<any>(null)
    const isTimeFinish = useRef(false)
    const taskCode = useRef("")

    const { connectHandle, disConnectHandle } = walletHandle()
    useEffect(() => {
        if (okWalletAddress && !okWalletInfo.current?.state) {
            okWalletRask()
        }
    }, [okWalletAddress])

    const okWalletRask = async () => {
        const { code } = await getClaimPoints({ code: okWalletInfo.current.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA })
        if (code == '0') {
            setPoint(okWalletInfo.current.points)
            setClaimPointsDialog(true)
            await getClaimPoints({ code: okWalletInfo.current.code, init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA }) //领取奖励
            await getTaskData() //重置任务列表
        }
    }


    // ------------------------ 滑动 -----------------------

    return (
        <div ref={boxRef} className="pt-[10px] Tasks pb-[122px]  px-4 role-box overflow-auto h-[100vh] Tasks !w-full">

            {/* ton network dialog and anytime cancel*/}
            {
                tonNetworkVisible && <TonNetworkError closeHandle={() => { setTonNetworkVisible(false) }}></TonNetworkError>
            }
            <PublicDialog dialog={dialog} setDialog={setDialog} closeOnClickOverlay={false} content={dialogContent()} customClassName="customClassName"></PublicDialog>
            {/* 签到链接钱包的弹窗 */}
            <PublicDialog width={"290px"} isPadding={false} dialog={checkInWallet} setDialog={setCheckInWallet} content={connect_wallet_button()} customClassName='customClassName'></PublicDialog>

            {/* 已经签到弹窗提示语 */}
            <PublicDialog width={"320px"} isPadding={false} dialog={readyCheck} setDialog={setReadyCheckIn} content={readyCheckIn()}></PublicDialog>


            {/* 该钱包已经签到弹窗提示语 */}
            <PublicDialog width={"320px"} isPadding={false} dialog={walletReadyCheck} setDialog={setWalletReadyCheck} content={walletReadyCheckIn()}></PublicDialog>

            <GetCoinAnimationDialog dialog={claimPointsDialog} point={point} getCoinAnimation={getCoinAnimation} setDialog={() => {
                dispatch(changeIsGate(""))
                setClaimPointsDialog(false)
                isClaim.current = false
                taskCode.current = ''
            }}></GetCoinAnimationDialog>
            {/* <PublicDialog dialog={dialog} setDialog={setDialog} content={hotRewardTask()}></PublicDialog> */}
            {/* <UpLevelDialog dialog={dialog} setDialog={setDialog}></UpLevelDialog> */}
            <img className="w-[197px] z-[-1] fixed top-[-88px] left-0" src={inviting_bg} alt="" />
            <img className="w-[232px] z-[-1] fixed bottom-0 right-0" src={rules_bg_4} alt="" />
            <img className="w-[175px] z-[-1] fixed bottom-0 left-0" src={rules_bg_3} alt="" />
            <img className="w-[213px] z-[-1] fixed top-[141px] right-0" src={rules_bg_2} alt="" />
            {/* <img className={`${checkInData.is_check_in ? 'hidden' : ''} fixed z-30 w-[60px] right-[16px] top-[165px]`} onClick={(event) => {
                closePlaySound()
                event.stopPropagation();
                setCheck_in()
                setDialog(true)
            }} src={sign_in} alt="" />
            <img onClick={(event) => {
                closePlaySound()
                event.stopPropagation();
                setDialog(true)
            }} className={`${checkInData.is_check_in ? '' : 'hidden'}  fixed w-[60px] z-30 right-[16px] top-[165px]`} src={done_sign_in} alt="" /> */}
            <div ref={myRef} className="">
                <p className="text-[20px] leading-[24px] font-bold text-[#fff]">Claim Bonus Pack</p>
                <Pay></Pay>
                {/* <div className="taskBackground mt-[10px] p-[15px] flex items-center justify-between tabbarIcon" onClick={(event) => { event.stopPropagation(), tabbarPlaySound(); navigate('/inviting') }}>
                    <div className='flex items-center'>
                        <div className='p-2  rounded-[10px] bg-[#fff]'>
                            <img className='w-6 h-6' src={Inviting} alt="" />
                        </div>
                        <div className='text-[#333333] text-[14px] font-bold leading-[19px] mx-[10px] mr-0'>Invite friends to get bonus</div>
                    </div>
                    <div> <img className='w-9 h-9' src={share} alt="" /></div>
                </div> */}
                <div className='mt-[30px] flex items-center mb-[10px] gap-2'>
                    <p className="text-[20px] font-bold  leading-[24px] text-[#fff]">Onboarding</p>
                    {Array.isArray(data) && !data.filter((el: any) => el.type == '1').every((el: any) => el.state == false) && <img className='w-5 h-5' onClick={(event) => {
                        event.stopPropagation();
                        closePlaySound()
                        setTaskState(taskState ? 0 : 1)
                    }} src={taskState ? down : reduce} alt="" />}
                </div>

                {/* <div className='mb-3 w-full ad_container rounded-[10px]'>
                <TonExchangeBanner exchangeId={'moJHPwHiGpSP7Lrz88xY1IAXXamF90'} />
                </div> */}

                {Array.isArray(data) && adContainer && data.filter(el => el.code == 'teleads').map(el =>
                    <div key={el.code} className=' overflow-hidden tabbarIcon relative flex items-center justify-center rounded-[10px]'>
                        <div className='mb-3 w-full ad_container rounded-[10px]' id='ad-container'></div>
                        {el.state &&
                            <img src={done} className='absolute z-[2000] flex-shrink-0 right-[15px] w-9 h-9' alt="" />
                        }
                    </div>
                )}

                {Array.isArray(data) && data.filter(el => el.code == 'openad').map(el =>
                    <div key={el.code} className=' overflow-hidden tabbarIcon relative flex items-center justify-center rounded-[10px]'
                        onClick={async (event) => {
                            event.stopPropagation()
                            closePlaySound()
                            if (!el.state) {
                                setTimeout(() => {
                                    getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') }).then((res: any) => {
                                        setClaimPointsDialog(true)
                                        setPoint(res.data.points)
                                    })
                                }, 6000)
                            }
                        }}>
                        <DynamicScript>
                        </DynamicScript>
                        {el.state && <img src={done} className='absolute flex-shrink-0 right-[15px] w-9 h-9 z-[10]' alt="" />}
                    </div>
                )}
                {/* {Array.isArray(data) && data.filter(el => el.code == 'openad').map(el =>
                    <div key={el.code} className=' overflow-hidden tabbarIcon relative flex items-center justify-center rounded-[10px]'
                        onClick={async (event) => {
                            event.stopPropagation()
                            closePlaySound()
                            if (!el.state) {
                                setTimeout(() => {
                                    getClaimPoints({ code: el.code, init_data: WebApp.initData || localStorage.getItem('init_data') }).then((res: any) => {
                                        setClaimPointsDialog(true)
                                        setPoint(res.data.points)
                                    })
                                }, 5000)
                            }
                        }}>
                        {el.state && <img src={done} className='absolute flex-shrink-0 right-[15px] w-9 h-9 z-[10]' alt="" />}
                    </div>
                )} */}
                {Array.isArray(data) && data.filter((el: any) => el.type == '1').filter((el: any) => taskState == 1 ? el.type != 3 : el.state == taskState).map((el: any, index: number) =>
                    <div
                        onClick={async (event) => {
                            if (el.code === 'GateConnect') {
                                if (!address) {
                                    event.stopPropagation();
                                    connectWallet(el);
                                    setPoint(el.points)
                                } else {
                                    event.stopPropagation();
                                }

                                return;
                            }
                            if (el.code === 'ton') {
                                if (!address) {
                                    event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points)
                                } else {
                                    event.stopPropagation();
                                    closePlaySound();
                                    setIsConnect(!isConnect)
                                }
                            } else {
                                taskLinkHandle(event, el)
                            }
                            if (el.code === 'tg_OKXConnect') {
                                okWalletInfo.current = el
                                // console.log(okWalletAddress)
                                if (okWalletAddress) {
                                    setOkWallet(!okWallet)
                                } else {
                                    await connectHandle()
                                    // setTimeout(() => {
                                    //     dispatch(changeOKWalletAddress("0x1232103012123412r9r9r0"))
                                    // }, 3000)
                                }

                            }

                        }}
                        key={`Onboarding${index}`} className={`taskBackground tabbarIcon mt-[10px] p-[15px] flex items-center ${el.code == 'openad' || el.code == 'teleads' ? 'hidden' : ''} justify-between`}>
                        <div className='flex items-center'>
                            <div className='p-2 rounded-[10px] bg-[#fff] h-10 w-10 flex-shrink-0'>
                                <img className='w-6 h-6 rounded-full' src={el.code.includes('Ton.Ai') ? tonAi[el.image]?.icon ? tonAi[el.image]?.icon : StarLogo[el.image] : el.image} alt="" />
                            </div>
                            <div className='text-[#333333] text-[16px] leading-[19px] font-bold ml-[10px]'>
                                {el.code.includes('Ton.Ai') ? tonAi[el.image]?.text ? tonAi[el.image]?.text : 'StarAI' : el.name}
                                <div className='text-[16px] mt-[7px] font-[600] text-style-3 w-fit leading-[18px]'>+{formatNumber(el.points)}</div>
                            </div>
                        </div>
                        {
                            el.code === 'tg_OKXConnect' ?
                                <div className='flex flex-col items-center relative'>
                                    {
                                        !okWalletAddress ?
                                            <div
                                                className=''
                                            // onClick={(event) => { event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points) }}
                                            >
                                                {
                                                    !el.state && <img className={`w-9 h-9 flex-shrink-0`} src={right} alt="" />
                                                }
                                                {
                                                    el.state && <img className={`w-9 h-9 flex-shrink-0 `} src={done} alt="" />
                                                }

                                            </div> :
                                            <>
                                                <div className='w-[84px] h-[30px] rounded-lg outline-none bg-white shadow-md flex items-center justify-around'>
                                                    <span>
                                                        {okWalletAddress.slice(0, 2) + '...' + okWalletAddress.slice(-3)}
                                                    </span>
                                                    <span className={`${!okWallet ? '' : 'hidden'}`}>
                                                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g id="08-tasks" transform="translate(-319.000000, -363.000000)" fillRule="nonzero">
                                                                    <g id="down" transform="translate(319.000000, 363.000000)">
                                                                        <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                                                                        <polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span className={`${okWallet ? '' : 'hidden'}`}>
                                                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g id="08-tasks" transform="translate(-319.000000, -334.000000)" fillRule="nonzero">
                                                                    <g id="down备份" transform="translate(326.000000, 341.000000) scale(1, -1) translate(-326.000000, -341.000000) translate(319.000000, 334.000000)">
                                                                        <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                                                                        <polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>

                                                </div>
                                                <div className={`${!okWallet ? 'hidden' : ''} text-sm w-[84px] h-[30px] rounded-lg outline-none bg-white/50 shadow-md flex items-center justify-around absolute top-[34px]`} onClick={(event) => { event.stopPropagation(), closePlaySound(), disConnectHandle() }}>
                                                    Disconnect
                                                </div>
                                            </>

                                    }

                                </div> :
                                el.code === 'ton' ?
                                    <div className='flex flex-col items-center relative'>
                                        {
                                            !address ?
                                                <div
                                                    className=''
                                                // onClick={(event) => { event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points) }}
                                                > <img className={`w-9 h-9 flex-shrink-0 ${el.state ? 'hidden' : ''}`} src={right} alt="" /></div> :
                                                <>
                                                    <div className='w-[84px] h-[30px] rounded-lg outline-none bg-white shadow-md flex items-center justify-around'>
                                                        <span>
                                                            {address.slice(0, 2) + '...' + address.slice(-3)}
                                                        </span>
                                                        <span className={`${!isConnect ? '' : 'hidden'}`}>
                                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="08-tasks" transform="translate(-319.000000, -363.000000)" fillRule="nonzero">
                                                                        <g id="down" transform="translate(319.000000, 363.000000)">
                                                                            <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                                                                            <polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className={`${isConnect ? '' : 'hidden'}`}>
                                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g id="08-tasks" transform="translate(-319.000000, -334.000000)" fillRule="nonzero">
                                                                        <g id="down备份" transform="translate(326.000000, 341.000000) scale(1, -1) translate(-326.000000, -341.000000) translate(319.000000, 334.000000)">
                                                                            <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                                                                            <polygon id="路径" fill="#333333" points="7.00199609 8.47 3.35365625 4.82332812 2.64666992 5.53 7.00232422 9.88333008 11.3536719 5.53 10.6463301 4.82332813"></polygon>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>

                                                    </div>
                                                    <div className={`${!isConnect ? 'hidden' : ''} text-sm w-[84px] h-[30px] rounded-lg outline-none bg-white/50 shadow-md flex items-center justify-around absolute top-[34px]`} onClick={(event) => { event.stopPropagation(), closePlaySound(), useDisconnectWallet() }}>
                                                        Disconnect
                                                    </div>
                                                </>
                                            // <TonConnectButton></TonConnectButton>
                                        }

                                        <div className='flex-shrink-0' onClick={(event) => { event.stopPropagation(); closePlaySound(); connectWallet(el); setPoint(el.points) }}> <img className={`w-9 flex-shrink-0 h-9 ${el.state && !address ? '' : 'hidden'}`} src={done} alt="" /></div>
                                    </div> :
                                    <>
                                        {
                                            el.claim_state == -1 && <div className='w-9 h-9 flex-shrink-0'> <img className={`w-9 h-9 flex-shrink-0`} src={right} alt="" /></div>

                                        }
                                        {
                                            (el.claim_state == 0 && el.code != claiming && taskCode.current != el.code) &&
                                            <div className='flex-shrink-0'>
                                                <ButtonComp width={54} height={26} title="Verify" callBack={async () => {
                                                    await claimHandle(el)
                                                }}></ButtonComp>
                                            </div>

                                            // <div className='bg-gradient-to-r flex-shrink-0 w-[54px] h-[26px] flex justify-center items-center rounded-[10px] button_shadow text-[#fff] text-sm from-[#00E49F] to-[#54ADFF]' onClick={async (e: any) => {
                                            //     e.stopPropagation();
                                            //     claimHandle(e, el)

                                            // }}>
                                            //     Verify

                                            // </div>

                                        }
                                        {
                                            (claiming == el.code) &&
                                            <>
                                                <Circle className='w-6 h-6 text-white' layerColor="rgba(255,255,255,.2)" color="#fff" defaultRate={100} rate={rate} size="36px" speed={100} text={`${timeCount}s`} strokeWidth={60} />
                                                <CountDown
                                                    className='hidden'
                                                    ref={timeRef}
                                                    onChange={timeChangeHandle}
                                                    time={15 * 1000}
                                                    format="ss"
                                                    autoStart={true}
                                                    onFinish={async () => {

                                                        // await updateClaimState(el)
                                                        // setClaimPointsDialog(true)
                                                        // setPoint(points)
                                                        await getTaskData() //重置任务列表

                                                        await sleep(1000)
                                                        setClaiming("")
                                                        setRate(100)
                                                        isTimeFinish.current = true
                                                        taskCode.current = el.code


                                                    }}
                                                />
                                            </>

                                        }
                                        {
                                            (!el.state && el.claim_state == 0 && taskCode.current == el.code) &&
                                            <div className='flex-shrink-0 '>
                                                <ButtonComp width={54} height={26} title="Claim" callBack={async () => {
                                                    if (isClaim.current) {
                                                        return false
                                                    }
                                                    isClaim.current = true
                                                    // console.log(taskCode.current)
                                                    // console.log(el.code)

                                                    const points = await updateClaimState(el)
                                                    setClaimPointsDialog(true)
                                                    setPoint(points)
                                                }}></ButtonComp>
                                            </div>


                                        }
                                        {
                                            (el.state && el.claim_state == 1) && <div className='flex-shrink-0'> <img className={`w-9 flex-shrink-0 h-9`} src={done} alt="" /></div>
                                        }

                                    </>
                        }

                    </div>
                )}





                <p className="text-[20px] mt-[30px] leading-[24px] font-bold text-[#fff]">Daily</p>
                {
                    Array.isArray(data) && data.filter((el: { type: string }) => el.type == '2').map((el: any, index: number) =>
                        el.code == 'tweet' ?
                            < >
                                {Object.keys(checkInData).length > 0 &&
                                    <div key={`Daily${index}`} className={`taskBackground tabbarIcon mt-[10px] p-[15px] flex items-center justify-between`}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            dispatch(changeTransactionStatus(null))
                                            closePlaySound()
                                            setCheck_in()
                                            dispatch(changeCheckDialog(true))
                                        }}
                                    >
                                        <div className='flex items-center'>
                                            <div className='p-2 rounded-[20px] bg-[#fff] flex justify-center items-center h-10 w-10 flex-shrink-0'>
                                                <img className='w-5 h-5' src={task_check_in} alt="" />
                                            </div>
                                            <div className='text-[#333333] text-[16px] leading-[19px] font-bold ml-[10px]'>
                                                Check-in

                                                <div className='text-[16px] mt-[4px] font-[600]  text-style-3 w-fit leading-[18px]'>
                                                    +{formatNumber(checkInData.is_check_in ? checkInData.check_in_rewards[checkInData.check_in_data - 1].reward : checkInData.check_in_rewards[checkInData.check_in_data].reward)}
                                                    {checkInData.is_check_in ? checkInData.check_in_rewards[checkInData.check_in_data - 1].rewardType == "card" ? ' Card' : checkInData.check_in_rewards[checkInData.check_in_data - 1].rewardType == "lottery" ? ' Draw Card' : '' : checkInData.check_in_rewards[checkInData.check_in_data].rewardType == "card" ? ' Card' : checkInData.check_in_rewards[checkInData.check_in_data].rewardType == "lottery" ? ' Draw Card' : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <img className={`w-9 flex-shrink-0 h-9 ${checkInData.is_check_in && checkInData.is_chain_check_in ? 'hidden' : ''}`} src={right} alt="" />
                                            <img className={`w-9 flex-shrink-0 h-9 ${checkInData.is_check_in && checkInData.is_chain_check_in ? '' : 'hidden'}`} src={done} alt="" />
                                        </div>
                                    </div>} </> :
                            <div key={`Daily${index}`} onClick={() => {
                                if (el.code == "PKArenas") {
                                    navigate("/pk")
                                }
                            }} className="taskBackground mt-[10px] p-[14px] flex items-center justify-between">
                                <div className='flex items-center'>
                                    <div className='p-2  rounded-[10px] bg-[#fff] h-10 w-10 flex-shrink-0'>
                                        <img className='w-6 h-6' src={el.image} alt="" />
                                    </div>
                                    <div className='text-[#333333] text-[16px] leading-[19px] font-bold ml-[10px]'>
                                        {el.name}
                                        {(el.code == 'chats' || el.code == "PKArenas") && <div className=' bg-white mt-[4px] w-[110px] h-1 rounded-[2px]'>
                                            <div style={{ background: " linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)", width: `${Math.floor((el.collected / el.points) * 100)}%` }} className={`h-1 rounded-[2px]`}></div>
                                        </div>}
                                        <div className={`text-[12px] mt-[2px] font-[600] ${el.code == 'chats' ? 'text-[12px] mt-[4px]' : ''} text-style-3 w-fit leading-[13px]`}>{(el.code == 'chats' || el.code == 'PKArenas') ? `${el.collected ? formatNumber(el.collected) : el.collected}/${formatNumber(el.points)}` : `+${formatNumber(el.points)}`}</div>
                                    </div>
                                </div>
                                <div>
                                    {(el.code == 'chats' || el.code == 'PKArenas') ? '' : <div className='w-9 flex-shrink-0 h-9' onClick={(event) => { event.stopPropagation(); closePlaySound() }}> <img className={`w-9 flex-shrink-0 h-9 ${el.state ? 'hidden' : ''}`} src={right} alt="" /></div>}
                                    <div className='w-9 flex-shrink-0 h-9'> <img className={`w-9 flex-shrink-0 h-9 ${el.state ? '' : 'hidden'}`} src={done} alt="" /></div>
                                </div>
                            </div>
                    )
                }
            </div >
            {
                isSoundPlay && <audio ref={AudioRef} src={coinsMusic} id="audio"></audio>
            }
        </div >)
}


export default Task;
