import PromptDialog from "../PromptDialog"
import ButtonComp from "./ButtonComp"
import tonicon from "@/assets/images/settings/tonicon.png"
import usdticon from "@/assets/images/settings/usdticon.png"
import icon from "@/assets/component/starspay/icon.png"
import tableTurnImg from '../../assets/images/shop/table-turn.png'
import flowerImg from "../../assets/images/shop/flower.png"
import { fromNano } from "@ton/core"
import { calculateUsdtAmountSmall, PAYTYPE } from "../../utils/common-helpers"
import Pay from "../../pages/shop/pay"
import { useState } from "react"
import { changeTurntableDialog } from "../../store/module/settins"
import { useDispatch } from "react-redux"
import { getShopPayCount } from "@/api/shop.js"


interface PropType {
    title: string,
    callBack: (val?: string) => void,
    dataList: any[],
    isNeedDialogSuccess?: boolean
    text?: string,
    text_1?: string,
    teleport?: any
}

const payImgType = [
    {
        img: icon,
        name: 'STAR',
        names: 'STARS'
    },

    {
        img: tonicon,
        name: 'TON',
        names: 'TON'
    },
    {
        img: usdticon,
        name: 'USDT',
        names: 'USDT'
    },
]


const PayDialog = ({ title, callBack, dataList, isNeedDialogSuccess = true, text = '', text_1 = '', teleport }: PropType) => {
    const [data, setData] = useState<any>({})
    const [promptDialog, setPromptDialog] = useState(false)
    const dispatch = useDispatch()
    const [isError, setError] = useState(false)
    const payHandle = (item: any) => {
        setData(item)
    }
    const upDataHandle = async (item: any) => {
        if (item.code == 0) {
            // 
            if (isNeedDialogSuccess) {
                setPromptDialog(true)
                await getShopPayCountHandle()
            } else {
                callBack && callBack(item.data)
            }


        } else {
            console.log("shop pay failed")
            setError(true)
        }

    }

    const getShopPayCountHandle = async () => {
        try {
            await getShopPayCount(data.type)
        } catch (e) {
            console.log(e)
        }

    }
    return (
        <>
            <PromptDialog
                className="customClassName"
                isClose={true}
                title={title}
                dialog={true}
                is_Stars={'Stars'}
                price=""
                callback={() => { callBack && callBack('close') }}
                setDialog={() => { callBack && callBack('close') }}
                text={text}
                text_1={text_1}
                teleport={teleport}
            >

                {dataList.map((item: any, i) => {
                    return (
                        <ButtonComp key={i} title={`${item.pay_type == 3 ? fromNano(item.price) : item.pay_type == 4 ? calculateUsdtAmountSmall(item.price / 100) : item.price} ${item.price >= 10 ? payImgType[(item.pay_type - 2)]?.names : payImgType[(item.pay_type - 2)]?.name}`} mt={i != 0 ? 20 : 0} callBack={() => { payHandle(item) }}>



                            <img className="h-9 absolute -left-[10px]" src={payImgType[(item.pay_type - 2)]?.img} alt="" />

                            {
                                item.discount > 0 && <div className="absolute h-9 w-9 -right-[12px] -top-[12px] flex justify-center items-center">
                                    <img className="h-9 w-9 z-[-1] absolute top-0 left-0" src={flowerImg} alt="" />
                                    <p className="z-[10] text-white text-xs">{item.discount}%</p>
                                </div>
                            }



                        </ButtonComp>
                    )

                })}
                <Pay dataObj={data} callBack={async (item: any) => {
                    await upDataHandle(item)
                }}></Pay>


                {isNeedDialogSuccess && <PromptDialog isClose callback={() => { callBack && callBack(), setPromptDialog(false) }} teleport={document.querySelector('body')} title={'Purchase Successfully! 🎉'} dialog={promptDialog} setDialog={() => { callBack && callBack(), setPromptDialog(false) }} text={`You have successfully purchased ${title}.`}>
                    {
                        data.type == 8 &&
                        <>
                            <div className="flex w-full justify-center mb-6">
                                <img className="w-12 h-12" src={tableTurnImg} alt="" />
                                <div className="ml-4">
                                    <p className="text-style-coin">x {parseInt(title)}</p>
                                    <p>card draw</p>
                                </div>
                            </div>
                            <ButtonComp title="Spin" callBack={() => {
                                callBack && callBack()
                                dispatch(changeTurntableDialog(true))
                            }}></ButtonComp>
                        </>

                    }
                </PromptDialog>}
                {
                    isError && <PromptDialog lineBreak={true} teleport={document.querySelector('body')} title={'Attention'} dialog={isError} setDialog={() => { callBack && callBack(), setError(false) }} text={`Network request error. Please check your network and try again.`}>

                    </PromptDialog>
                }

            </PromptDialog>

        </>


    )
}


export default PayDialog