
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import tonImg from "../../assets/images/settings/tonicon.png"
import loadingImg from '@/assets/images/task/loading.png'
import sign_in from '@/assets/images/task/sign-in.png'
import coins from '@/assets/images/task/coins.png'
import shopImg from "../../assets/images/shop.png"
import WebApp from '@twa-dev/sdk'
import done_sign_in from '@/assets/images/task/done-sign-in.png'
import done from '@/assets/images/home/done.png'
import lottery from '@/assets/images/home/lottery.png'
import up from '@/assets/images/home/up.png'
import card from '@/assets/images/home/card.png'
import done_green from '@/assets/images/task/done-green.png'
import PublicDialog from '../../components/PublicDialog'
import { getTask, postCheck_in, getCheck_in, getClaimPoints, chainCheckIn, getUserInfo } from '@/api/url.js'
import wallet from "../../hook/wallet/contentWallet"
import { useCheckInContract } from '@/hook/contract/useCheckInContract';
import { Address } from '@ton/core';
import coin from '@/assets/images/coin.png'
import { formatNumber } from '../../utils'
import { useSelector, useDispatch } from 'react-redux'

import { changeCheckDialog, changeUserInfo, setAddressParams } from '../../store/module/tabbar'
import { changeTransactionStatus } from '../../store/module/tontransaction'
import { changeCheck_in_loading, changeTasks } from '../../store/module/tasks'
import usePlaySound from "@/hook/usePlaySound.tsx"

import BScroll from 'better-scroll'
import TonNetworkError from '../../hook/useTonNetworkError';
import ButtonComp from '../common/ButtonComp';
import RedPacket from '../redPacket/RedPacket';
import './index.less';
import { changeAwardData, changeShowRedPacketStatus } from '../../store/module/lottery';

const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
interface TaskProps {
	getCoinAnimation: Function
}
const CheckIn = ({ getCoinAnimation }: TaskProps) => {
	const navigate = useNavigate()
	const isShowRedPacket = useSelector((state: any) => state.lottery.isShowRedPacket)
	const { useConnectWallet, useGetWalletAddress } = wallet();
	const userInfo = useSelector((state: any) => state.tabbar.userInfo)
	const isSendSuccess = useSelector((state: any) => state.ton.isSendSuccess)
	const address = useGetWalletAddress()
	const closePlaySound = usePlaySound('close')
	const [params] = useSearchParams();
	const [loading, setLoading] = useState(false)
	// const check_in_loading = useSelector((state: any) => state.tasks.check_in_loading)
	const address_params = localStorage.getItem('address_params')
	const dispatch = useDispatch()
	const [data, setData] = useState<any>(useSelector((state: any) => state.tasks.tasksData))
	const dialog = useSelector((state: any) => state.tabbar.checkDialog)

	const [checkInData, setCheckInData] = useState<any>({})
	const [tonNetworkVisible, setTonNetworkVisible] = useState(false)

	const getCheckInData = useCallback(async () => {
		const resGetCheck_in = await getCheck_in(address_params)
		setCheckInData(resGetCheck_in.data)
	}, [dialog])
	useEffect(() => {
		if (params.get('check_in')) {
			dispatch(changeCheckDialog(true))
		}
	}, [])
	const getTaskData = useCallback(async () => {
		const res = await getTask(0, 0)
		if (res.code == '0') {
			setData(res.data.task)
			dispatch(changeTasks(res.data.task))
		}
	}, [])
	useEffect(() => {
		getTaskData()
	}, [])
	const setCheck_in = useCallback(async () => {
		try {
			const { data, code } = await postCheck_in({ init_data: WebApp.initData || localStorage.getItem('init_data') || import.meta.env.VITE_INIT_DATA })
			if (code == '0') {

				const width = window.innerWidth;
				const height = window.innerHeight;
				const resGetCheck_in = await getCheck_in(address_params)
				setUserInfo()
				setCheckInData(resGetCheck_in.data)
				if (resGetCheck_in.data.check_in_rewards[resGetCheck_in.data.check_in_data - 1].rewardType == 'star') {
					getCoinAnimation(width / 2, height / 2);
				} else {
					if (resGetCheck_in.data.check_in_rewards[resGetCheck_in.data.check_in_data - 1].rewardType == 'card') {
						dispatch(changeAwardData({ card: { ...data[0], code: 'card', name: data[0].description, url: data[0].card } }))
					}
					setReadyCheckInData(resGetCheck_in.data.check_in_rewards)
					setCheckRewardsDialog(true)
				}
			}
		} catch {

		}
	}, [])
	useEffect(() => {
		if (Object.keys(checkInData).length === 0) {
			getCheckInData()
		}
	}, [getCheckInData])


	//签到时链接钱包弹窗的显示隐藏
	const [checkInWallet, setCheckInWallet] = useState(false)
	//已经签到的提示语弹窗
	const [cardArr, setCardArr] = useState<any>([])

	const [readyCheck, setReadyCheckIn] = useState(false)
	const [walletReadyCheck, setWalletReadyCheck] = useState(false)
	const [checkRewardsDialog, setCheckRewardsDialog] = useState(false)
	const [readyCheckInData, setReadyCheckInData] = useState<any>([])
	//链上交互
	const { checkIn } = useCheckInContract()
	useEffect(() => {
		if (isSendSuccess == "1") {
			setTimeout(async () => {
				await checkInHandler()
			}, 2000)

		} else if (isSendSuccess == "-1") {
			setTimeout(async () => {
				setLoading(false)
			}, 1000)

		}
	}, [isSendSuccess])

	const checkInHandle = async () => {
		// if (checkInData.is_chain_check_in) {
		//     setReadyCheckIn(true);
		//     return false;
		// }
		if (loading) {
			return false;
		}
		if (!address) {
			setCheckInWallet(true)
			dispatch(changeCheckDialog(false))
			return
		}

		setLoading(true)
		const { code } = await chainCheckIn({ check_in_addr: Address.parse(address).toString() })
		if (code == "0") {
			await checkIn()
		} else {
			setWalletReadyCheck(true)
			setLoading(false)
		}

	}
	//签到函数
	const checkInHandler = async () => {
		setLoading(false);
		const address_params = Address.parse(address);
		dispatch(setAddressParams(address_params.toString()))

		try {
			const res = await chainCheckIn({ check_in_addr: address_params.toString(), is_ok: true })
			const resGetCheck_in = await getCheck_in(address_params)
			if (res.code == '0') {
				if (resGetCheck_in.data?.chain_check_in_data != '4' && resGetCheck_in.data?.chain_check_in_data != '7') {
					dispatch(changeCheck_in_loading({
						loading: true,
						msg: '',
						point: res.data.toString()
						// point: (Math.floor(Number(checkInData.check_in_rewards[checkInData.check_in_data - 1]) * 5 * (1 + (userInfo.ponits_boost / 1000)))).toString()
					}))
				} else {
					if (resGetCheck_in.data?.chain_check_in_data == '4') {
						dispatch(changeAwardData({ card: { ...res.data[0], code: 'card', name: res.data[0].description, url: res.data[0].card } }))
						setCardArr(res.data)
						setCardConut(1)
					}
					setReadyCheckInData(resGetCheck_in.data.chain_check_in_rewards)
					setCheckRewardsDialog(true)
				}
				getCheckInData()
				setUserInfo()
			}

		} catch (e) {
		}

	}


	//钱包相关
	const [isConnect, setIsConnect] = useState(false)
	//链接钱包
	const connectWallet = async (el?: any) => {
		if (address) {
			return false
		}
		useConnectWallet(el, callback);
	}


	useEffect(() => {
		setIsConnect(false);
		if (address) {
			//  dispatch(changeCheckDialog(true))
			setCheckInWallet(false)
		}
	}, [address])

	//钱包已签到弹窗
	const walletReadyCheckIn = () => {
		return (
			<div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
				<div className='text-center w-full'>
					<span className='block text-sm text-[#333]'>This wallet have already checked in today.
					</span>
					<span className='text-sm text-[#333]'>Please try to use another wallet.</span>
				</div>
				<ButtonComp title="OK" callBack={() => {
					setWalletReadyCheck(false)
				}}></ButtonComp>

			</div>
		)
	}
	//用户钱包已签到弹窗
	const readyCheckIn = () => {
		return (
			<div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
				<div className='text-center w-full'>
					<span className='block text-sm text-[#333]'>You have already checked in today.
					</span>
					<span className='text-sm text-[#333]'> Please try again tomorrow.</span>
				</div>


				<ButtonComp title="OK" callBack={() => {
					setReadyCheckIn(false)
				}}></ButtonComp>
				{/* <div className='bg-gradient-to-r button_shadow text-[#fff] from-[#00E49F] to-[#54ADFF] w-[174px] h-[36px] rounded-[10px]' onClick={(event) => { event.stopPropagation(); setReadyCheckIn(false) }}>
                    OK
                </div> */}
			</div>
		)
	}
	//签到链接钱包的弹窗
	const indexDay = useMemo(() => {
		if (Object.keys(checkInData).length > 0) {
			return checkInData.is_chain_check_in ? checkInData.chain_check_in_data : checkInData.check_in_data
		}

	}, [checkInData])
	const checkRewards = () => {
		return (
			<div className='flex flex-col items-center px-[14px] justify-around mx-auto py-11 pt-10 '>
				{Array.isArray(readyCheckInData) && readyCheckInData.length > 0 && <>
					<p className='text-[20px] leading-6 text-[#333333] text-center font-bold'>{checkInData.is_chain_check_in ? 'Check-in on TON Rewards' : 'Check-in Rewards'}</p>
					<div className='mt-[20px] mb-[10px] flex justify-center items-center'>
						<img
							src={readyCheckInData[indexDay - 1]?.rewardType == 'card' ? card : lottery}
							className={`${checkInData?.check_in_rewards[indexDay - 1]?.rewardType == 'card' ? 'w-10 mr-[10px]' : 'w-[60px] mr-[10px]'}`}
							alt="" />
						<div className='py-1'>
							<div className=' flex justify-center items-center'>
								<p className=' text-style-6 mr-1 !text-[20px] !leading-[37px]'>X</p>
								<span className='text-style-6 !text-[30px] !leading-[37px]'>
									{readyCheckInData[indexDay - 1]?.reward}
								</span>
							</div>
							{readyCheckInData[indexDay - 1].rewardType == 'lottery' && <p className='text-[12px] leading-[15px] font-semibold text-[#333333]'>Draw Cards</p>}
						</div>
					</div>

					<p className='mt-[14px] text-[14px] text-center text-[#333333] leading-[18px] font-semibold'>
						{readyCheckInData[indexDay - 1].rewardType == 'lottery' ?
							'You can receive rewards in the form of $STAR and Cards.'
							:
							'You will randomly receive a Nature ,Bronze, or Silver Card.'}
					</p>
					<ButtonComp width={174} mt={30} title='OK' callBack={() => {
						if (readyCheckInData[indexDay - 1].rewardType == 'card') {
							dispatch(changeShowRedPacketStatus(true))
						}
						setCheckRewardsDialog(false)
					}}>
					</ButtonComp>
				</>}
			</div>
		)
	}

	const connect_wallet_button = () => {
		return (
			<div className='flex flex-col items-center justify-around mx-auto h-[216px] py-5'>
				<p className='text-sm text-[#333]'>Please connect your TON wallet.</p>
				<ButtonComp callBack={() => { connectWallet() }} title="Connect"></ButtonComp>
			</div>
		)
	}

	//ton网络出错弹窗
	const tonNetworkHandle = () => {
		setTonNetworkVisible(true)
	}
	const callback = async (el: any) => {
		await getTaskData() //重置任务列表
		await getClaimPoints({ code: el.code }) //领取奖励
	}


	const setUserInfo = useCallback(async () => {
		const UserInfo = await getUserInfo(address_params)
		if (UserInfo.code == "0") {
			dispatch(changeUserInfo(UserInfo.data))
		}
	}, [])
	const [cardConut, setCardConut] = useState(0)
	useEffect(() => {
		if (cardConut == 1 && isShowRedPacket == false) {
			setCardConut(cardConut + 1)
			dispatch(changeAwardData({ card: { ...cardArr[1], code: 'card', name: cardArr[1].description, url: cardArr[1].card } }))
			dispatch(changeShowRedPacketStatus(true))
		}
	}, [isShowRedPacket])

	const dialogContent = () => {
		return (
			<div className=' px-[14px] text-center'>
				<div className='text-center w-full pt-[42px] font-bold leading-6 text-[20px]'>Daily Check-in Rewards</div>
				<div className='flex  flex-wrap justify-center gap-[6px] gap-y-[20px] mb-[30px] mt-5'>
					{checkInData.check_in_rewards?.map((el: any, index: number) => {
						return (
							<div>
								<div key={`Check-in${index}`} className={`rounded-[10px]  ${index == 6 ? 'w-[158px]  h-[124px]' : ' w-[76px]  h-[124px]'} flex flex-col items-center text-center text-[12px] leading-[15px]`}>
									<div style={{}} className={`w-full pt-[6px] h-[69px] flex flex-col items-center text-center pb-[3px] bg-[#fff] ${checkInData.check_in_data > index ? 'opacity-50 rounded-t-[10px]' : 'topBorder '}`}>
										Day {index + 1}
										{el.rewardType == 'star' ?
											<>
												<img className={`w-[21px] h-[14px] my-[7px] ${checkInData.check_in_data > index ? 'hidden' : ''}`} src={coins} alt="" />
												<img className={`w-[14px] h-[14px] my-[5px] ${checkInData.check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
											</> :
											el.rewardType == 'card' ?
												<>
													<div className={`text-center mt-[4px]  text-[12px] flex items-center gap-1 ${checkInData.check_in_data > index ? 'hidden' : ''} leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[16px] h-6' src={card} alt="" /> x1</div>
													<img className={`w-[14px] h-[14px] my-[5px] ${checkInData.check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
												</>
												:
												<>
													<div className={`text-center text-[12px] flex items-center gap-1 ${checkInData.check_in_data > index ? 'hidden' : ''} leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[30px] h-[30px]' src={lottery} alt="" /> x1</div>
													<img className={`w-[14px] h-[14px] my-[5px] ${checkInData.check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
												</>
										}
										<span className={`text-center text-[12px] leading-[15px] ${checkInData.check_in_data > index ? '' : 'text-style-6'}`}>
											{el.rewardType == 'star'
												? `+${formatNumber(el.reward)}` : el.rewardType == "card" ? 'Card' : 'Draw Cards'
											}
										</span>
									</div>
									<div key={`chain-Check-in${index}`} className={`bg-[#fff] relative ${checkInData.chain_check_in_data > index ? 'opacity-50 rounded-b-[10px]' : 'bottomBorder'} ${index == 6 ? 'w-[158px]  h-[55px]' : ' w-[76px]  h-[55px]'} flex flex-col items-center py-[6px] text-center text-[12px] leading-[15px]`}>
										{checkInData?.chain_check_in_rewards[index]?.rewardType == 'star' ?
											<>
												<img className={`w-[21px] h-[14px] my-[7px] ${checkInData.chain_check_in_data > index ? 'hidden' : ''}`} src={coins} alt="" />
												<img className={`w-[14px] h-[14px] my-[5px] ${checkInData.chain_check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
											</> :
											checkInData?.chain_check_in_rewards[index]?.rewardType == 'card' ?
												<>
													<div className={`text-center  text-[12px] flex items-center gap-1 ${checkInData.chain_check_in_data > index ? 'hidden' : ''} leading-[15px] ${checkInData.chain_check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[16px] h-6' src={card} alt="" /> x{checkInData?.chain_check_in_rewards[index].reward}</div>
													<img className={`w-[14px] h-[14px] my-[5px] ${checkInData.chain_check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
												</>
												:
												<>
													<div className={`text-center text-[12px] flex items-center gap-1 ${checkInData.chain_check_in_data > index ? 'hidden' : ''} leading-[15px] ${checkInData.chain_check_in_data > index ? '' : 'text-style-6'}`}> <img className='w-[30px] h-[30px]' src={lottery} alt="" /> x{checkInData?.chain_check_in_rewards[index].reward}</div>
													<img className={`w-[14px] h-[14px] my-[5px] ${checkInData.chain_check_in_data > index ? '' : 'hidden'}`} src={done_green} alt="" />
												</>
										}

										<span className={`text-center text-[12px] leading-[15px] ${checkInData.chain_check_in_data > index ? '' : 'text-style-6'}`}>
											{checkInData?.chain_check_in_rewards[index]?.rewardType == 'star'
												? `+${formatNumber(checkInData?.chain_check_in_rewards[index]?.reward)}` : checkInData?.chain_check_in_rewards[index]?.rewardType == "card" ? 'Card' : 'Draw Cards'
											}
										</span>
										<img className='w-3 h-3 absolute top-[6px] right-0' src={tonImg} alt="" />
										{!(checkInData.chain_check_in_data > index) &&
											<>
												<div style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)', }} className=' w-[3px] h-[3px] z-20 absolute -top-[2px] rounded-full -left-[2px]'></div>
												<div style={{ background: 'linear-gradient( 90deg, #00E49F 0%, #54ADFF 100%)', }} className=' w-[3px] h-[3px] z-20 absolute -top-[2px] rounded-full -right-[2px]'></div>
											</>
										}
									</div >
								</div>
							</div>
						)
					})}
					{/* <div className='bg-gradient-to-r mt-[10px] w-full text-[14px] px-5 leading-[18px flex items-center justify-between text-[#333333] relative h-[60px] rounded-[10px] from-[#00E49F] to-[#54ADFF]'>
                        {checkInData?.is_chain_check_in &&
                            <div style={{
                                width: ' 24px',
                                height: ' 24px',
                                background: 'linear-gradient( 125deg, #EF4444 0%, #E88585 100%)',
                                borderRadius: '0px 10px 0px 10px '
                            }} className=' absolute flex justify-center items-center right-0 top-0'>
                                <img className=' w-[18px] ' src={done} alt="" />
                            </div>
                        }
                        <p>Today of TON Check-in</p>
                        <div className='flex items-center gap-1'>
                            {Array.isArray(checkInData?.check_in_rewards) &&
                                <>
                                    {checkInData?.chain_check_in_rewards[checkInData?.is_chain_check_in ? checkInData?.chain_check_in_data - 1 : checkInData?.chain_check_in_data]?.rewardType == 'star' ?
                                        <div className='flex items-center gap-1'>
                                            <img className=' w-[31px]' src={coins} alt="" />
                                            <span className='text-style-3 !text-[14px] !leading-[18px] font-bold'>
                                                +{formatNumber(checkInData?.chain_check_in_rewards[checkInData?.is_chain_check_in ? checkInData?.chain_check_in_data - 1 : checkInData?.chain_check_in_data]?.reward)}
                                            </span>
                                        </div>
                                        :
                                        <div className='flex items-center gap-1'>
                                            <img className=' h-[30px]' src={checkInData?.chain_check_in_rewards[checkInData?.is_chain_check_in ? checkInData?.chain_check_in_data - 1 : checkInData?.chain_check_in_data]?.rewardType == 'lottery' ? lottery : card} alt="" />
                                            <span className='text-style-3 !text-[14px] !leading-[18px] font-bold'>
                                                x{checkInData?.chain_check_in_rewards[checkInData?.is_chain_check_in ? checkInData?.chain_check_in_data - 1 : checkInData?.chain_check_in_data]?.reward}
                                            </span>
                                        </div>}
                                </>}
                        </div>
                    </div> */}
				</div >

				<ButtonComp callBack={async () => {
					dispatch(changeTransactionStatus(null))
					await checkInHandle()
				}} title={!loading ? 'Check-in on TON' : <img className='w-5 h-5 animate-spin' src={loadingImg} />}>
					{!loading
						&&
						<img className=' absolute -top-[13px] w-[27px] right-0' src={up} alt="" />
					}
				</ButtonComp>
			</div >
		)
	}
	const teleport = useRef(null)
	return (
		<>

			{/* ton network dialog and anytime cancel*/}
			{
				tonNetworkVisible && <TonNetworkError closeHandle={() => { setTonNetworkVisible(false) }}></TonNetworkError>
			}
			<PublicDialog dialog={dialog} setDialog={() => {
				dispatch(changeCheckDialog(false))
			}} closeOnClickOverlay={false} content={dialogContent()} customClassName="customClassName"></PublicDialog>
			{/* 签到链接钱包的弹窗 */}
			<PublicDialog width={"290px"} isPadding={false} dialog={checkInWallet} setDialog={setCheckInWallet} content={connect_wallet_button()} customClassName='customClassName'></PublicDialog>

			{/* 该钱包已经签到弹窗提示语 */}
			<PublicDialog width={"320px"} isPadding={false} dialog={walletReadyCheck} setDialog={setWalletReadyCheck} content={walletReadyCheckIn()}></PublicDialog>

			{/* 该用户已经签到弹窗提示语 */}
			<PublicDialog width={"320px"} isPadding={false} dialog={readyCheck} setDialog={setReadyCheckIn} content={readyCheckIn()}></PublicDialog>
			<PublicDialog width={"290px"} isPadding={false} dialog={checkRewardsDialog} setDialog={setCheckRewardsDialog} content={checkRewards()}></PublicDialog>
			{
				isShowRedPacket && <RedPacket></RedPacket>
			}
			<img ref={teleport} className={`${checkInData.is_check_in ? 'hidden' : ''} fixed z-[800] w-[52px] right-[16px] top-[110px]`} onClick={(event) => {
				event.stopPropagation();
				setCheck_in()
				dispatch(changeCheckDialog(true))
				// setCheckRewardsDialog(true)
			}} src={sign_in} alt="" />
			<img onClick={(event) => {
				event.stopPropagation();
				// setCheckRewardsDialog(true)
				dispatch(changeCheckDialog(true))
			}} className={`${checkInData.is_check_in ? '' : 'hidden'}  fixed w-[52px] z-[800] right-[16px] top-[110px]`} src={done_sign_in} alt="" />
			{/* shop */}
			<img onClick={(event) => {
				event.stopPropagation();
				navigate("/shop")
			}} className={`fixed w-[52px] z-[500] left-[16px] top-[110px]`} src={shopImg} alt="" />
		</ >)
}


export default CheckIn;
