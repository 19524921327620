import { useCallback, useEffect, useRef, useState } from "react"
import coins from '@/assets/images/task/coins.png'
import left_arrow from '@/assets/images/inviting/left-arrow.svg'
import ranks_bg from '@/assets/images/ranks/ranks_bg.png'
import tips_logo from '@/assets/images/ranks/tips_logo.svg'
import top_bg_2 from '@/assets/images/ranks/top_bg_2.png'
import top_bg_1 from '@/assets/images/ranks/top_bg_1.png'
import top_bg_3 from '@/assets/images/ranks/top_bg_3.png'
import top_1 from '@/assets/images/ranks/top_1.png'
import top_2 from '@/assets/images/ranks/top_2.png'
import top_3 from '@/assets/images/ranks/top_3.png'
import star from '@/assets/images/ranks/star.png'

import checkin from '@/assets/images/ranks/checkin.png'
import quarterly_tips_bg from '@/assets/images/ranks/quarterly_tips_bg.png'
import daily_tips_bg from '@/assets/images/ranks/daily_tips_bg.png'
import weekly_tips_bg from '@/assets/images/ranks/weekly_tips_bg.png'
import ClipPathBorderRadius from "../../components/ClipPathBorderRadius"
import { useNavigate, useSearchParams } from "react-router-dom"
import { formatIdString, formatNameString, FormattingNumber } from "../../utils"
import { getRanks } from '@/api/url.js'
import BScroll from 'better-scroll'
import { useDispatch, useSelector } from "react-redux"
import { Popup, Popover } from "react-vant"
import nft from "@/assets/images/nfted.png"
import quarterly from '@/assets/images/ranks/quarterly.png'
import daily from '@/assets/images/ranks/daily.png'
import weekly from '@/assets/images/ranks/weekly.png'
import { changeHomePage } from "../../store/module/tabbar"

new BScroll('.role-box', {
  pullUpLoad: true,
  scrollbar: true,
  pullDownRefresh: true
  // and so on
})
const ranks = () => {
  const [searchParams] = useSearchParams()
  const type: any = searchParams.get("type") as string;
  const [buttonActive, setButtonActive] = useState(type || 0)
  const dispatch = useDispatch()
  const [tipsPopup, setTipsPopup] = useState(false)
  const [ranksList, setRanksList] = useState<Array<any>>([])
  const flag = useSelector((state: any) => state.tabbar.value);
  const [myRanks, setMyRanks] = useState<any>({
    name: '',
    id: '',
    level: '',
    points: '',
    rank: '',
    badge: [],
  })
  const getData = useCallback(async () => {
    try {
      const { code, data } = await getRanks(buttonActive)
      if (code == '0') {
        setRanksList(data.ranks)
        setMyRanks(data.my)
      } else {
        setRanksList([])
      }
    }
    catch (error) {
      setMyRanks({
        name: '',
        id: '',
        level: '',
        points: '',
        rank: '',
        badge: [],
      })
      setRanksList([])
    }
  }, [buttonActive])
  useEffect(() => {
    getData()
  }, [buttonActive])

  useEffect(() => {
    document.addEventListener('touchstart', touchStartHandle);
    return () => {
      document.removeEventListener('touchstart', touchStartHandle);
      window.scrollTo(0, 0);
    }
  })

  const touchStartHandle = () => {
    window.scrollTo(0, 1);
    ensureDocumentIsScrollable();
  }

  const ensureDocumentIsScrollable = () => {
    const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
    if (!isScrollable) {
      document.documentElement.style.setProperty(
        "height",
        "calc(100vh + 1px)",
        "important"
      );
    }
  }

  const ranksDom = useRef<any>(null)

  const openHomePage = (id: any) => {
    if (id) {
      dispatch(changeHomePage(
        {
          showHomePage: true,
          id: id,
        }
      ))
    }
  }
  return (
    <div ref={ranksDom} className="w-[100vw] h-[100vh] ranksPopover  overflow-hidden relative px-4">
      <div className="h-[100vh] w-[100vw] fixed top-0 left-0 -z-10" style={{ background: 'linear-gradient( 179deg, rgba(87,255,61,0.4) 0%, rgba(84,173,255,0) 100%)' }}></div>
      <img className="absolute top-[-20px] z-[-1] left-[-32px]" src={ranks_bg} alt="" />
      <img onClick={() => { setTipsPopup(true) }} className=" z-30 fixed top-[53px] w-5 right-[16px]" src={tips_logo} alt="" />
      {/* <img onClick={() => {
        const oldRoute = localStorage.getItem('oldRoute') || '/character'
        navigate(oldRoute)
      }
      } className="w-6 absolute top-[17px] left-4" src={left_arrow} alt="" /> */}

      <div className="grid grid-cols-3 gap-2 mt-2">
        <ClipPathBorderRadius setButtonActive={() => setButtonActive(0)} buttonActive={buttonActive} borderRadius={15} height={31} width={115} flag={0} text={'Daily $STAR'}></ClipPathBorderRadius>
        <ClipPathBorderRadius setButtonActive={() => setButtonActive(2)} buttonActive={buttonActive} borderRadius={15} height={31} width={125} flag={2} text={'Weekly $STAR'}></ClipPathBorderRadius>
        <ClipPathBorderRadius setButtonActive={() => setButtonActive(1)} buttonActive={buttonActive} borderRadius={15} height={31} width={135} flag={1} text={`Quarterly $STAR`} ></ClipPathBorderRadius>
      </div>

      <div className=" flex mt-[16px] justify-between z-10 px-[10px]">
        <div onClick={() => {
          openHomePage(ranksList[1].id)
        }} className=" flex items-center flex-col">
          <img className="mt-10 w-[40px]" src={top_2} alt="" />
          <p className=" text-[#FFFFFF] text-[14px] font-semibold max-w-[90px] ellipsis leading-[18px]">{ranksList.length > 1 ? ranksList[1]?.name.trim() ? formatNameString(ranksList[1]?.name) : formatIdString(ranksList[1]?.id.toString()) : 'None'}</p>
          <div className="flex h-[14px] items-center justify-center gap-1 ">
            {ranksList[1]?.badge?.includes('nft') && <img className=" h-[14px] " src={nft} alt="" />}
            {ranksList[1]?.badge?.includes('daily') && <img className=" h-[14px] " src={daily} alt="" />}
            {ranksList[1]?.badge?.includes('weekly') && <img className=" h-[14px] " src={weekly} alt="" />}
            {ranksList[1]?.badge?.includes('quarterly') && <img className=" h-[14px] " src={quarterly} alt="" />}
            {ranksList[1]?.badge?.includes('checkin') && <img className=" h-[14px] " src={checkin} alt="" />}
          </div>
          <p className=" text-[#A6A6A6] text-[14px] font-semibold leading-[18px]">Lv {ranksList.length > 1 ? ranksList[1]?.level : '0'}</p>
          <p className="flex items-center justify-center mt-[10px] gap-1 relative text-style-3 textStroke text-[16px] leading-[19px] font-semibold" data-content={ranksList.length > 1 ? FormattingNumber(ranksList[1]?.points.toString()) : '0'}>{ranksList.length > 1 ? FormattingNumber(ranksList[1]?.points.toString()) : '0'} <img className="w-[12px]" src={coins} alt="" /></p>
          <img className="w-[89px] -z-[9] -mt-[23px]" src={top_bg_2} alt="" />
        </div>
        <div onClick={() => {
          openHomePage(ranksList[0].id)
        }} className=" flex items-center relative flex-col gap-[3px]">
          <img className=" w-[30px]" src={top_1} alt="" />
          <p className=" text-[#FFFFFF] text-[14px] font-semibold max-w-[90px] ellipsis leading-[18px]">{ranksList.length > 0 ? ranksList[0]?.name.trim() ? formatNameString(ranksList[0]?.name) : formatIdString(ranksList[0]?.id.toString()) : 'None'}</p>
          <div className="flex h-[14px] items-center justify-center gap-1 ">
            {ranksList[0]?.badge?.includes('nft') && <img className=" h-[14px] " src={nft} alt="" />}
            {ranksList[0]?.badge?.includes('daily') && <img className=" h-[14px] " src={daily} alt="" />}
            {ranksList[0]?.badge?.includes('weekly') && <img className=" h-[14px] " src={weekly} alt="" />}
            {ranksList[0]?.badge?.includes('quarterly') && <img className=" h-[14px] " src={quarterly} alt="" />}
            {ranksList[0]?.badge?.includes('checkin') && <img className=" h-[14px] " src={checkin} alt="" />}
          </div>
          <p className=" text-[#A6A6A6] text-[14px] font-semibold leading-[18px]">Lv {ranksList.length > 0 ? ranksList[0]?.level : '0'}</p>
          <p className="flex items-center justify-center mt-[10px] gap-1 relative text-style-3 textStroke text-[16px] leading-[19px] font-semibold" data-content={ranksList.length > 0 ? FormattingNumber(ranksList[0]?.points.toString()) : '0'}> {ranksList.length > 0 ? FormattingNumber(ranksList[0]?.points.toString()) : '0'} <img className="w-[16px]" src={coins} alt="" /></p>
          <img className="w-[123px] -z-[9] -mt-[29px]" src={top_bg_1} alt="" />
        </div>
        <div onClick={() => {
          openHomePage(ranksList[2].id)
        }} className=" flex  items-center flex-col">
          <img className=" mt-11 w-[41px]" src={top_3} alt="" />
          <p className=" text-[#FFFFFF] text-[14px] font-semibold max-w-[90px] ellipsis leading-[18px]">{ranksList.length > 2 ? ranksList[2]?.name.trim() ? formatNameString(ranksList[2]?.name) : formatIdString(ranksList[2]?.id.toString()) : 'None'}</p>
          <div className="flex h-[14px] items-center justify-center gap-1 ">
            {ranksList[2]?.badge?.includes('nft') && <img className=" h-[14px] " src={nft} alt="" />}
            {ranksList[2]?.badge?.includes('daily') && <img className=" h-[14px] " src={daily} alt="" />}
            {ranksList[2]?.badge?.includes('weekly') && <img className=" h-[14px] " src={weekly} alt="" />}
            {ranksList[2]?.badge?.includes('quarterly') && <img className=" h-[14px] " src={quarterly} alt="" />}
            {ranksList[2]?.badge?.includes('checkin') && <img className=" h-[14px] " src={checkin} alt="" />}
          </div>
          <p className=" text-[#A6A6A6] text-[14px] font-semibold leading-[18px]">Lv {ranksList.length > 2 ? ranksList[2]?.level : '0'}</p>
          <p className="flex items-center justify-center gap-1 mt-[10px] relative text-style-3 textStroke text-[16px] leading-[19px] font-semibold" data-content={ranksList.length > 2 ? FormattingNumber(ranksList[2]?.points.toString()) : '0'}>{ranksList.length > 2 ? FormattingNumber(ranksList[2]?.points.toString()) : '0'} <img className="w-[12px]" src={coins} alt="" /></p>
          <img className="w-[89px] -z-[9] -mt-[23px]" src={top_bg_3} alt="" />
        </div>
      </div>
      <div style={{ background: 'rgba(222,245,224,0.3)', border: ' 1px solid rgba(87,255,61,0.5)' }} className="!backdrop-blur-[10px] h-[calc(100vh_-_200px)] -mt-[94px] rounded-[16px] bg-white/10  w-full">
        <div className="w-full rounded-[16px]  h-[calc(100vh_-_390px)] overflow-auto">
          <div className="w-full rounded-[16px] flex gap-5 items-center flex-col  p-5 py-3 ">
            {ranksList.length > 3 && ranksList.slice(3).map((el: any, index: any) =>
              <div onClick={() => {
                dispatch(changeHomePage(
                  {
                    showHomePage: true,
                    id: el.id,
                  }
                ))
              }} key={`ranks ${index}`} className="flex items-center justify-between w-full">
                <div className="w-[8%] text-[14px] text-left leading-[18px] font-semibold text-white">{index + 4}</div>
                <div className="text-[14px] w-[25%] text-center leading-[18px] font-semibold text-white">{el.name.trim() ? formatNameString(el.name) : formatIdString(el?.id.toString())}</div>
                <div className="w-[30%] flex gap-1 items-center">
                  {el.badge?.includes('nft') && <img className=" h-[14px] " src={nft} alt="" />}
                  {el.badge?.includes('daily') && <img className=" h-[14px] " src={daily} alt="" />}
                  {el.badge?.includes('weekly') && <img className=" h-[14px] " src={weekly} alt="" />}
                  {el.badge?.includes('quarterly') && <img className=" h-[14px] " src={quarterly} alt="" />}
                  {el.badge?.includes('checkin') && <img className=" h-[14px] " src={checkin} alt="" />}
                </div>
                <div className="text-[14px] w-[12%] text-center leading-[18px] font-semibold text-white">Lv {el.level}</div>
                <div className="text-[16px] w-[25%] text-center leading-[19px] flex justify-end items-center font-semibold text-style-6 gap-1">{FormattingNumber(el.points.toString())} <img className="w-[22px]" src={coins} alt="" /></div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`fixed ${flag ? 'bottom-[115px] w-[calc(100vw_-_32px)] left-[16px] rounded-[10px] h-[40px] p-[11px] px-[10px]' : 'bottom-0 rounded-[30px_30px_0_0] w-[100vw] h-[70px] p-[26px] px-[16px] left-[0%]'}  z-40 left-0 flex transition-all items-center`}
        style={{ background: 'linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)' }}
      >
        <p className="text-[#01825D] w-[20%] text-[14px] leading-[18px] font-semibold">{myRanks?.name.trim() ? formatNameString(myRanks?.name) : formatIdString(myRanks?.id.toString())}</p>
        <div className="flex items-center gap-[2px] w-[25%]">
          {myRanks.badge?.includes('nft') && <img className=" h-[14px] " src={nft} alt="" />}
          {myRanks.badge?.includes('daily') && <img className=" h-[14px] " src={daily} alt="" />}
          {myRanks.badge?.includes('weekly') && <img className=" h-[14px] " src={weekly} alt="" />}
          {myRanks.badge?.includes('quarterly') && <img className=" h-[14px] " src={quarterly} alt="" />}
          {myRanks.badge?.includes('checkin') && <img className=" h-[14px] " src={checkin} alt="" />}
        </div>
        <p className="text-[#01825D] w-[15%] text-[14px] text-nowrap whitespace-nowrap leading-[18px] font-semibold">Lv {myRanks?.level}</p>
        <p className="text-[14px] text-style-6 leading-[18px] w-[20%] font-semibold flex items-center justify-center gap-1">{FormattingNumber(myRanks?.points.toString())} <img className="w-[22px]" src={coins} alt="" /></p>
        <Popover
          placement={'top-end'}
          theme='dark'
          teleport={ranksDom.current}
          reference={<p className="text-[#fff] ml-2 text-[14px] leading-[18px] w-[20%] font-semibold flex items-center text-right text-nowrap whitespace-nowrap">No. {myRanks?.rank > 999 ? '999+' : myRanks?.rank}</p>}
        >
          <div style={{ textShadow: '0px 2px 4px rgba(0,0,0,0.5)' }} className='text-[14px] p-[10px] leading-[18px] text-[#ffffff]'>No. {myRanks?.rank}</div>
        </Popover>

      </div>
      <Popup
        style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0)' }}
        position='right'
        overlay={false}
        visible={tipsPopup}>
        <div className="w-full h-full bg-black px-4">
          <div className="flex items-center justify-between h-11 mb-[30px] text-[#fff] text-[16px] font-bold">
            <img onClick={() => {
              setTipsPopup(false)
            }} className="w-6" src={left_arrow} alt="" />
            Rules
            <img className="w-6 opacity-0" src={left_arrow} alt="" />
          </div>
          <div style={{ backgroundImage: `url(${daily_tips_bg})`, backgroundSize: '100% 100%' }} className="py-[27px] bg-cover pr-[11px] mb-5 pl-[7px] flex text-[#385D3E] text-[14px] leading-[18px] font-semibold">
            <img className="w-[14px] h-[13px] mt-[3px]" src={star} alt="" />
            Daily $STAR are the total earned each day. Buying or selling cards does not affect your ranking.
          </div>
          <div style={{ backgroundImage: `url(${weekly_tips_bg})`, backgroundSize: '100% 100%' }} className="py-[27px] bg-cover pr-[11px] mb-5 pl-[7px] flex text-[#645428] text-[14px] leading-[18px] font-semibold">
            <img className="w-[14px] h-[13px] mt-[3px]" src={star} alt="" />
            Weekly $STAR are the total earned each week. Buying or selling cards does not affect your ranking.
          </div>
          <div style={{ backgroundImage: `url(${quarterly_tips_bg})`, backgroundSize: '100% 100%' }} className="py-[27px]  bg-cover pr-[11px] mb-5 pl-[7px] flex text-[#355A67] text-[14px] leading-[18px] font-semibold">
            <img className="w-[14px] h-[13px] mt-[3px]" src={star} alt="" />
            Quarterly $STAR represent the total earned each quarter. Selling cards affects your ranking.
          </div>
        </div>
      </Popup >
    </div>
  )
}
export default ranks
