import { Popup } from "react-vant";
import { useState, useCallback, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper'
SwiperCore.use([Pagination, Autoplay, Navigation])
import 'swiper/swiper-bundle.css'
import { setBackground, getBackground, getCharacterInfo, getUserInfo } from '@/api/url.js'
import CardItem from "../../components/CardItem";
import activeImg from "../../assets/images/selectActive.png"
import coinsImg from "../../assets/images/coinBox.png"
import { useSelector, useDispatch } from 'react-redux';
import { setCurrCharacterInfo } from '../../store/module/progress';
import { changeUserInfo } from '../../store/module/tabbar'
import PublicDialog from "../../components/PublicDialog";
import loadingImg from '@/assets/images/task/loading.png'
import usePlaySound from "@/hook/usePlaySound.tsx"
import ButtonComp from "../../components/common/ButtonComp";

const pageSize = 10;

const Mine = ({ id }: { id: any }) => {
    const userInfo = useSelector((state: any) => state.tabbar.userInfo)
    const [loading, setLoading] = useState(false)
    const isActive = useRef<any>(null)
    const [isActiveId, setIsActiveId] = useState("")
    const [currBgId, setCurrBgId] = useState("")
    const [successBg, setSuccessBg] = useState(false)
    const [insufficientCoinsDialog, setInsufficientCoins] = useState(false)
    const address_params = localStorage.getItem('address_params')
    const [confirmBuyPopup, setConfirmBuyPopup] = useState(false);
    const [cardsData, setCardsData] = useState<any>([])
    // const pageNum = useRef(1);
    const closePlaySound = usePlaySound('close')
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const init = async () => {
        // await getCardsData(1);
    }
    const getCardsData = useCallback(async (page: number) => {
        const { code, data: { my, bg, count } } = await getBackground(id, page, pageSize);
        if (code == 0) {
            setCardsData((prevData: any) => [...prevData, ...bg]);
            setTotal(count);
            setIsActiveId(my.id)
            setCurrBgId(my.id)
            isActive.current = my;

        }
    }, [])

    useEffect(() => {
        init();
    }, [])
    useEffect((): any => {

        getCardsData(currentPage);


    }, [currentPage]);


    //更新用户信息（金币）
    const setUserInfo = useCallback(async () => {
        const UserInfo = await getUserInfo(address_params)
              if(UserInfo.code=="0"){
      dispatch(changeUserInfo(UserInfo.data))

      }
    }, [])

    //设置图片
    const selectBackgroundItem = (item: any) => {
        closePlaySound()
        setIsActiveId(item.id);
        isActive.current = item;
    }
    const setBackgroundHandle = async () => {
        closePlaySound()
        if (userInfo.points < 2000) {
            setConfirmBuyPopup(false)
            setInsufficientCoins(true)
            return false;
        }
        setLoading(true);
        try {
            const { code } = await setBackground(id, isActiveId);
            if (code == 0) {
                setCurrBgId(isActive.current.id)
                await setUserInfo();
                setConfirmBuyPopup(false);
                setSuccessBg(true);
                await dataUpHandle();
            }
            setLoading(false);
        } catch (e) {
            console.log("背景图片更换失败" + e)
            setLoading(false);
        }

    }

    //更新数据
    const dataUpHandle = async () => {
        const { code, data } = await getCharacterInfo(id);
        if (code == "0") {
            dispatch(setCurrCharacterInfo(data))
            window.localStorage.setItem("currCharacterInfo", JSON.stringify(data));

        }
    }

    //设置成功以后都弹窗
    const setBgSuccessComponent = () => {
        return (
            <div className=' flex justify-center flex-col items-center pt-[62px]'>
                <p className='text-[14px] text-[#333333] font-semibold leading-[18px] mb-11'>Setting successful</p>
                {/* <div className='bg-gradient-to-r button_shadow text-[#fff] from-[#00E49F] to-[#54ADFF] w-[174px] h-[36px] rounded-[10px]' onClick={() => { closePlaySound(), setSuccessBg(false) }}>
                    OK
                </div> */}
                <ButtonComp title='OK' callBack={() => { setSuccessBg(false) }}></ButtonComp>

            </div>
        )
    }
    //swiper end handler
    const swiperEndHandle = () => {
        if (!total) return false;
        if (currentPage * pageSize < total) {
            setCurrentPage(currentPage + 1);
        }

    }

    //金币不足的弹窗
    const insufficientCoins = () => {
        return (
            <div className=' flex justify-center flex-col items-center pt-[62px]'>
                <p className='text-[14px] text-[#333333] font-semibold leading-[18px] mb-11'>Insufficient $STAR</p>
                {/* <div className='bg-gradient-to-r button_shadow text-[#fff] from-[#00E49F] to-[#54ADFF] w-[174px] h-[36px] rounded-[10px]' onClick={() => { closePlaySound(), setInsufficientCoins(false) }}>
                    OK
                </div> */}
                <ButtonComp title='OK' callBack={() => { setInsufficientCoins(false) }}></ButtonComp>
            </div>
        )
    }

    return (
        <div className="absolute px-4 w-full box-border bottom-0 left-0 h-[300px] bg-gradient-to-t from-[#050505] to-[#fff][0]">
            <div className="text-sm text-white">Set Card as Background</div>
            <div className="card-list w-full h-[172px] py-5">

                <Swiper
                    loop={false}
                    initialSlide={0}
                    speed={700}
                    observer={true}
                    observeParents={true}
                    slidesPerView={3.5}
                    autoplay={{
                        delay: 2500, // 自动滑动的时间间隔（毫秒）
                        disableOnInteraction: false, // 用户交互后是否禁用自动滑动
                    }}
                    onReachEnd={() => { swiperEndHandle() }}
                >
                    {
                        cardsData.map((item: any) => {
                            return (
                                <SwiperSlide
                                    key={item.id}
                                    className="flex justify-between items-center"
                                >

                                    <div className="text-white w-[92px] h-[132px] rounded-[10px] overflow-hidden relative" onClick={() => { selectBackgroundItem(item) }}>
                                        <CardItem height={132} width={92} mine={1} isNo={false} image={item.image} level={item.is_official ? 6 : item.level + 1} ></CardItem>
                                        {item.id == isActiveId && <div className="isActive absolute top-[6px] right-[6px]">
                                            <img className="w-3 h-3" src={activeImg} alt="" />
                                        </div>}

                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>



            </div>
            <div className="set flex flex-col items-center justify-center">
                <ButtonComp title='Set' callBack={() => { setConfirmBuyPopup(true) }} disabled={currBgId != isActiveId ? false : true}></ButtonComp>
                {/* 
                {(currBgId != isActiveId) ? <div onClick={() => { closePlaySound(), setConfirmBuyPopup(true) }} className="button rounded-xl flex items-center justify-center text-white w-[174px] h-[36px] bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">
                    Set
                </div> : <div className="button rounded-xl flex items-center justify-center text-white w-[174px] h-[36px] bg-[#a6a6a6]">
                    Set
                </div>} */}




            </div>
            <div className="cost flex justify-center items-center relative mt-1">
                <span className="text-[#A6a6a6] z-[2]">Cost: </span>
                <span className="text-style-6 mx-1 z-[2]">20000</span>
                <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    
                    <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="02-talk-更换背景mine" transform="translate(-217.000000, -757.000000)" fillRule="nonzero">
                            <g id="编组备份" transform="translate(217.000000, 757.000000)">
                                <path d="M0,5 C0,7.76666667 2.23333333,10 5,10 C7.76666667,10 10,7.76666667 10,5 C10,2.23333333 7.76666667,0 5,0 C2.23333333,0 0,2.23333333 0,5 Z" id="路径" fill="#FBE945"></path>
                                <path d="M1.25,5.01018051 C1.25,6.35076661 1.97052401,7.59326103 3.11681223,8.24720547 C4.26310044,8.90114991 5.70414847,8.93384713 6.88318777,8.24720547 C8.06222707,7.56056381 8.75,6.35076661 8.75,5.01018051 C8.75,2.95025553 7.07969432,1.25 4.98362446,1.25 C2.88755459,1.25 1.25,2.95025553 1.25,5.01018051 Z" id="路径" fill="#FBB11B"></path>
                                <path d="M8.75,5.23058252 C8.75,4.47208738 8.50609756,3.74393204 8.04878049,3.16747572 C7.5,2.74271845 6.73780488,2.5 6.00609756,2.5 C4.05487805,2.5 2.5,4.0473301 2.5,5.98907767 C2.5,6.74757281 2.74390244,7.47572815 3.20121952,8.05218446 C3.75,8.47694175 4.51219512,8.75 5.27439024,8.75 C7.19512195,8.71966019 8.75,7.17233009 8.75,5.23058252 Z" id="路径" fill="#FDC72F"></path>
                                <polyline id="路径" fill="#FBB11B" points="5 2.5 5.80128205 4.10256411 7.5 4.42307692 6.31410257 5.73717949 6.50641026 7.5 5 6.73076923 3.42948718 7.5 3.68589743 5.73717949 2.5 4.42307692 4.16666666 4.10256411 5 2.5"></polyline>
                                <polygon id="路径" fill="#FBE945" points="5.86538462 4.07051282 7.5 4.42307692 6.31410257 5.73717949 6.50641026 7.5 5 6.69871795 3.49358974 7.5 3.68589743 5.73717949 2.5 4.42307692 4.13461538 4.07051282 5 2.5"></polygon>
                            </g>
                        </g>
                    </g>
                </svg>
                <div className="abc w-[150px] h-[18px] absolute z-[1]">
                    <img src={coinsImg} alt="" />
                </div>
            </div>


            <Popup
                style={{ width: '100%', height: '320px', borderRadius: '30px 30px 0 0' }}
                position='bottom'
                onClose={() => { setConfirmBuyPopup(false) }}
                visible={confirmBuyPopup}>
                <div className='w-full flex flex-col items-center rounded-[30px_30px_0_0] px-[46px] py-10 h-full absolute bottom-0' style={{ backgroundImage: `linear-gradient( 135deg, #CFFFAF 0%, #FFFFFF 100%)`, }}>
                    <div className="text-base text-[#333] text-center">Set Card as Background</div>
                    <div className="img my-4 flex justify-center items-center h-[108px] w-[73px] relative">
                        <CardItem rounded={10} height={108} width={73} mine={1} isNo={false} image={isActive.current && isActive.current.image} level={isActive.current && isActive.current.is_official ? 6 : isActive.current && isActive.current.level + 1} ></CardItem>
                    </div>
                    <p className='w-full flex justify-center items-center mb-5'>
                        <span className="text-base text-[#333]">Cost: </span>
                        <svg className="mx-1 mb-1" width="20px" height="20px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            
                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="02-talk-更换背景mine" transform="translate(-217.000000, -757.000000)" fillRule="nonzero">
                                    <g id="编组备份" transform="translate(217.000000, 757.000000)">
                                        <path d="M0,5 C0,7.76666667 2.23333333,10 5,10 C7.76666667,10 10,7.76666667 10,5 C10,2.23333333 7.76666667,0 5,0 C2.23333333,0 0,2.23333333 0,5 Z" id="路径" fill="#FBE945"></path>
                                        <path d="M1.25,5.01018051 C1.25,6.35076661 1.97052401,7.59326103 3.11681223,8.24720547 C4.26310044,8.90114991 5.70414847,8.93384713 6.88318777,8.24720547 C8.06222707,7.56056381 8.75,6.35076661 8.75,5.01018051 C8.75,2.95025553 7.07969432,1.25 4.98362446,1.25 C2.88755459,1.25 1.25,2.95025553 1.25,5.01018051 Z" id="路径" fill="#FBB11B"></path>
                                        <path d="M8.75,5.23058252 C8.75,4.47208738 8.50609756,3.74393204 8.04878049,3.16747572 C7.5,2.74271845 6.73780488,2.5 6.00609756,2.5 C4.05487805,2.5 2.5,4.0473301 2.5,5.98907767 C2.5,6.74757281 2.74390244,7.47572815 3.20121952,8.05218446 C3.75,8.47694175 4.51219512,8.75 5.27439024,8.75 C7.19512195,8.71966019 8.75,7.17233009 8.75,5.23058252 Z" id="路径" fill="#FDC72F"></path>
                                        <polyline id="路径" fill="#FBB11B" points="5 2.5 5.80128205 4.10256411 7.5 4.42307692 6.31410257 5.73717949 6.50641026 7.5 5 6.73076923 3.42948718 7.5 3.68589743 5.73717949 2.5 4.42307692 4.16666666 4.10256411 5 2.5"></polyline>
                                        <polygon id="路径" fill="#FBE945" points="5.86538462 4.07051282 7.5 4.42307692 6.31410257 5.73717949 6.50641026 7.5 5 6.69871795 3.49358974 7.5 3.68589743 5.73717949 2.5 4.42307692 4.13461538 4.07051282 5 2.5"></polygon>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span className="text-base text-style-6 ">20000</span>
                    </p>


                    {/* <div className='bg-gradient-to-r button_shadow text-[#fff] from-[#00E49F] to-[#54ADFF] w-[200px] h-[36px] rounded-[10px]' onClick={() => { setBackgroundHandle() }}
                    >
                        Set
                        {loading && <img className='w-4 h-4 animate-spin ml-2' src={loadingImg} />}
                    </div> */}
                    <ButtonComp title='Set' callBack={() => { setBackgroundHandle() }} isLoading={loading}></ButtonComp>

                </div>
            </Popup >
            <PublicDialog dialog={successBg} width={'290'} setDialog={setSuccessBg} content={setBgSuccessComponent()}></PublicDialog>
            <PublicDialog dialog={insufficientCoinsDialog} width={'290'} setDialog={setInsufficientCoins} content={insufficientCoins()}></PublicDialog>

        </div>
    )
}

export default Mine;