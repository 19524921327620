import { useCallback, useEffect, useState } from "react"
import WebApp from '@twa-dev/sdk'
import gift from '@/assets/images/inviting/gift.png'
import invite from '@/assets/images/inviting/invite.png'
import earn from '@/assets/images/inviting/earn.png'
import inviting_bg from '@/assets/images/inviting/inviting_bg.png'
import rules_bg_4 from '@/assets/images/inviting/rules_bg_4.png'
import rules_bg_2 from '@/assets/images/inviting/rules_bg_2.png'
import rules_bg_3 from '@/assets/images/inviting/rules_bg_3.png'
import { rewards_count, getRewards } from '@/api/url.js'
import coin from '@/assets/images/coin.png'
import copy_Completed from '@/assets/images/tabbar/copy_Completed.png'
import copy from '@/assets/images/tabbar/copy.png'
import { formatNumber } from "../../utils"
import ButtonComp from "../../components/common/ButtonComp"
import { useSelector } from "react-redux"
import InvitingPopup from "./invitingPopup"

const Inviting = () => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [rewardsList, setRewardsList] = useState([])
  const [isCopy, setIsCopy] = useState(false)
  const [invitation_code, setInvitation_code] = useState(useSelector((state: any) => state.tabbar.inviteData.invitation_code))
  // const closePlaySound = usePlaySound('close')
  const [rewardsData, setRewardsData] = useState<any>({
    count: 0,
    invitation_code: "",
    premium_reward: '',
    standard_reward: '',
    premium: [
      { count: 0, people: 0 },
      { count: 0, people: 0 }
    ],
    standard: [
      { count: 0, people: 0 },
      { count: 0, people: 0 }
    ]
  })
  const getRewardsData = useCallback(async () => {
    try {
      const res = await rewards_count()
      if (res.code == 0) {
        setRewardsData(res.data)
      }
      const resRewards = await getRewards(1, 9999)
      if (resRewards.code == 0) {
        setRewardsList(resRewards.data.data)
      }
    } catch (e) {
      console.log("🚀 ~ getRewardsData ~ e:", e)
    }

  }, [])
  useEffect(() => {
    getRewardsData()
  }, [getRewardsData])
  // ------------------------ 滑动 -----------------------
  useEffect(() => {
    document.addEventListener('touchstart', touchStartHandle);
    return () => {
      document.removeEventListener('touchstart', touchStartHandle);
      // window.scrollTo(0, 0);
    }

  })

  const touchStartHandle = () => {
    window.scrollTo(0, 1);
    ensureDocumentIsScrollable();
  }

  const ensureDocumentIsScrollable = () => {
    const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
    if (!isScrollable) {
      document.documentElement.style.setProperty(
        "height",
        "calc(100vh + 0.5px)",
        "important"
      );
    }
  }
  // ------------------------ 滑动 -----------------------
  const Invitation_code = async () => {
    if (!loading) {
      setLoading(true)
      WebApp.openTelegramLink(`https://t.me/share/url?text=${encodeURI('\n🚀 Join to get 5000 $STAR and a 20% referral bonus!\n🎁 Talk with AI to claim your free Airdrop and NFTs!')}&url=https://t.me/TheStarAIBot/StarAI?startapp=${invitation_code}`)
      setLoading(false)
    }
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`
        https://t.me/TheStarAIBot/StarAI?startapp=${invitation_code}
        
🚀 Join to get 5000 $STAR and a 20% referral bonus!
🎁 Talk with AI to claim your free Airdrop and NFTs!`);
      setIsCopy(true)
    } catch (err) {
    }
    // setTimeout(() => {
    //   setIsCopy(false)
    // }, 2000)
  };

  return (<div className="px-4 pt-[10px] relative pb-[236px] h-[100vh] overflow-y-auto">
    {/* <img src={left_arrow} className="fixed w-6 h-6 top-4 left-4 z-10" onClick={() => { backPlaySound(), navigate('/tasks') }} alt="" /> */}
    <img className="w-[197px] z-[-1] fixed top-[-88px] left-0" src={inviting_bg} alt="" />
    <img className="w-[232px] z-[-1] fixed bottom-0 right-0" src={rules_bg_4} alt="" />
    <img className="w-[175px] z-[-1] fixed bottom-0 left-0" src={rules_bg_3} alt="" />
    <img className="w-[213px] z-[-1] fixed top-[141px] right-0" src={rules_bg_2} alt="" />
    {/* <CoinProgressBar padding={24}></CoinProgressBar> */}
    <p className="font-bold text-[20px] text-[#fff] leading-[24px]">Rules</p>
    <div style={{ backgroundSize: '100% 100%' }} className="mt-2 bg-[url(@/assets/images/inviting/rules_bg.png)] bg-no-repeat bg-cover p-[22px] pb-9">
      <div className="flex  items-center">
        <img className="w-[50px] h-[50px]" src={gift} alt="" />
        <p className="font-bold text-[14px] ml-[10px] text-[#333] leading-[18px]">You and your invite both get <span className="text-style-3 font-bold text-[14px] leading-[18px]">{formatNumber(rewardsData.standard_reward)}</span> $STAR.</p>
      </div>
      <div className="flex my-[10px] items-center">
        <img className="w-[50px] h-[50px]" src={earn} alt="" />
        <p className="font-bold text-[14px] ml-[10px] text-[#333] leading-[18px]">Invite Telegram Premium both get <span className="text-style-3 font-bold text-[14px] leading-[18px]" >{formatNumber(rewardsData.premium_reward)}</span> $STAR.</p>
      </div>
      <div className="flex  items-center">
        <img className="w-[50px] h-[50px]" src={invite} alt="" />
        <p className="font-bold text-[14px] ml-[10px] text-[#333] leading-[18px]">Earn 20% $STAR from direct referrals and 10% from secondary referrals.</p>
      </div>
    </div>
    <>
      <div className="flex justify-between mt-6 items-center pr-[10px]">
        <div className="flex gap-1 items-center" onClick={() => {
          if (rewardsList.length > 0) {
            setVisible(true)
          }
        }}>
          <p className="font-bold text-[20px] text-[#fff] leading-[24px]">Stats</p>
          {rewardsList.length > 0 && <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="邀请页修改" transform="translate(-60.000000, -341.000000)" fillRule="nonzero">
                <g id="right备份-8" transform="translate(60.000000, 341.000000)">
                  <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="20" height="20"></rect>
                  <polygon id="路径" fill="#FFFFFF" points="11.8705547 10.0028117 6.66666667 4.88088203 7.67651664 3.88888889 13.8888889 10.0032723 7.67604839 16.1111111 6.66762267 15.1181775"></polygon>
                </g>
              </g>
            </g>
          </svg>}
        </div>
        <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] mr-1 h-[14px]" src={coin} alt="" /> + {formatNumber(rewardsData?.count) || 0}</p>
      </div>
      <div className=" bg-[#333333] rounded-[10px] mt-[10px] p-[10px]">
        <p className="text-[16px] leading-[19px] text-[#fff] font-bold">Premium</p>
        <div className="flex justify-between mt-[10px] items-center">
          <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Direct Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.premium[0].people) || 0}</span> {rewardsData.premium[0].people > 1 ? "friends" : 'friend'}</p>
          <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.premium[0].count) || 0}</p>
        </div>
        <div className="flex justify-between mt-[10px] items-center">
          <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Secondary Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.premium[1].people) || 0}</span> {rewardsData.premium[1].people > 1 ? "friends" : 'friend'}</p>
          <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.premium[1].count) || 0}</p>
        </div>
      </div>
      <div className=" bg-[#333333] rounded-[10px] mt-[10px] p-[10px]">
        <p className="text-[16px] leading-[19px] text-[#fff] font-bold">Standard</p>
        <div className="flex justify-between mt-[10px] items-center">
          <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Direct Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.standard[0].people) || 0}</span> {rewardsData.standard[0].people > 1 ? "friends" : 'friend'}</p>
          <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.standard[0].count) || 0}</p>
        </div>
        <div className="flex justify-between mt-[10px] items-center">
          <p className=" font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">Secondary Referrals: <span className="font-semibold text-[14px] text-[#fff] leading-[16px]" >{formatNumber(rewardsData.standard[1].people) || 0}</span> {rewardsData.standard[1].people > 1 ? "friends" : 'friend'}</p>
          <p className="text-[14px] text-style-6 flex items-center font-bold leading-[18px]"><img className="w-[14px] h-[14px] mr-1" src={coin} alt="" /> + {formatNumber(rewardsData.standard[1].count) || 0}</p>
        </div>
      </div>
    </>
    <div style={{ background: 'linear-gradient( 180deg, rgba(5,9,9,0) 0%, rgba(5,9,9,0.82) 24%, #050909 100%)' }}
      className=" fixed bottom-[108px] left-0 w-[100vw] pt-[10px] h-[128px]">
      <ButtonComp width="174px" isLoading={loading} title='Invite' callBack={() => {
        Invitation_code()
      }}></ButtonComp>
      <div className="mt-[10px] flex justify-between items-center px-4">
        <div className="flex gap-1 items-center">
          <p className="text-[16px] leading-[19px] text-[#fff] font-bold">My invite link:</p>
          <p className="font-semibold text-[14px] text-[#a6a6a6] leading-[16px]">
            https://t.me/…startapp={invitation_code}
          </p>
        </div>
        <div onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          handleCopy()
        }} className={`${isCopy ? '' : 'pt-[6px]'} items-center from-[#00E49F] bg-gradient-to-r rounded-[10px] flex justify-center button_shadow to-[#54ADFF] flex-shrink-0 w-9 h-9`}>
          <img className={` ${isCopy ? 'w-[20px]' : 'w-[28px]'}`} src={isCopy ? copy_Completed : copy} alt="" />
        </div>
      </div>
    </div>
    <InvitingPopup
      title={'Invite'}
      visible={visible}
      rewardsData={rewardsData}
      rewardsList={rewardsList}
      setVisible={setVisible}
      setLoading={setLoading}
      Invitation_code={Invitation_code}
      loading={loading} isCopy={isCopy}
      setIsCopy={setIsCopy}
      handleCopy={handleCopy}>
    </InvitingPopup>
  </div>
  )
}

export default Inviting
