import { useNavigate } from "react-router-dom";
import RefinedLoading_bg from "@/assets/images/refining/RefinedLoading.jpg"
import coins from '@/assets/images/task/coins.png'
import refiningFailure from '@/assets/images/refining/refiningFailure.png'
import CardItem from "./CardItem";
import { Popup } from "react-vant";
import { formatNumber } from "../utils";
import Back from "./common/BackComp";
import ButtonComp from "./common/ButtonComp";

interface LotteryCardDialogProps {
  img: string;
  name: string;
  level: number;
  points?: string;
  title: string;
  close: Function;
  power?: any
  property?: any
}

function LotteryCardDialog({ power = 0, property = '', img, name, level, points, close, title }: LotteryCardDialogProps) {
  const navigate = useNavigate();
  return (
    <Popup
      style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0)' }}
      position='right'
      overlay={false}
      visible={true}
    >
      <div style={{overflowY:'auto'}} className={`px-4 h-[100vh] RefiningFailure flex overflow-hidden items-center flex-col pb-[24px] pt-[86px]`}>
        <div className="fixed left-0 top-0 z-[1200] h-11 w-full text-[16px] justify-between px-4 font-bold leading-[19px] text-white flex items-center">
          <Back title={title} callback={() => { close() }}></Back>
          {/* 
          <svg onClick={() => { close() }} className="mr-2" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="10-cards-搜索4" transform="translate(-16.000000, -57.000000)" fillRule="nonzero">
                <g id="编组-66" transform="translate(16.000000, 54.237154)">
                  <g id="left-arrow备份-2" transform="translate(0.000000, 2.762846)">
                    <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="24" height="24"></rect>
                    <polygon id="路径" fill="#FFFFFF" points="10.9382812 20.0811328 4.06971094 13.2125625 2.85714844 12 4.06914844 10.788 10.9382812 3.91886719 12.1502812 5.13086719 6.13945312 11.1428672 21.1451719 11.1428672 21.1451719 12.8571328 6.13942969 12.8571328 12.1508672 18.8685937"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          {title}
          <div className="w-6"></div> */}
        </div>
        <div className="w-full flex justify-center items-center relative">
          <div className="card relative z-20">
            <div className="content content_1">
              <div className="back">
                <div className="back-content !gap-0">
                  <CardItem big={true} power={power} property={property} image={img} text={name} level={level + 1}></CardItem>
                </div>
              </div>
              <div className="front">

                <div className="img">
                  <div className="circle">
                  </div>
                  <div className="circle" id="right">
                  </div>
                  <div className="circle" id="bottom">
                  </div>
                </div>
                <div className="front-content">
                  <div className="card mx-auto">
                    <div className="bg">
                      <img className=" rounded-[24px]" src={RefinedLoading_bg} alt="" />
                    </div>
                    <div className="blob"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className=" absolute refiningAnimation_1 w-[100vw] bottom-4 z-10" src={refiningFailure} alt="" />
        </div>
        <div className="flex justify-center flex-col items-center">
          <div className="flex justify-center my-[42px] gap-[10px] items-center">
            {
              points && <>
                <img className="w-12" src={coins} alt="" />
                <p className="text-style-6 !text-[24px] !leading-[29px] font-semibold">+{formatNumber(Number(points))}</p>
              </>
            }
          </div>
          <ButtonComp title="OK" callBack={() => { close() }} mt={60}></ButtonComp>
        </div>
      </div>
    </Popup>
  )
}
export default LotteryCardDialog