import coins from "../../assets/images/redpacket/coins.png"
import { formatNumber } from "../../utils"
interface PropType {
    count: string | number;
    type?: string;
    direction?: string;
}
const Coins = ({ count, type = "+", direction = "row" }: PropType) => {
    return (
        <>
            {
                direction == 'row' &&
                <div className="w-[200px] h-[150px] bg-gradient-to-r flex flex-col justify-center items-center">
                    <div className="mb-10 font-medium text-style-6 !text-3xl">{type}{formatNumber(Number(count))}</div>
                    <img src={coins} alt="" />

                </div>
            }

            {
                direction == 'col' &&

                <div className="flex justify-center items-center">
                    <img className="w-12 mr-4" src={coins} alt="" />
                    <div className="font-medium text-style-6 !text-3xl">{type}{count ? formatNumber(Number(count)) : 0}</div>

                </div>

            }

        </>

    )
}


export default Coins