import WebApp from '@twa-dev/sdk'
import "./index.less"
import { useEffect, useRef, useState } from 'react';
import { Tabs, Popup, Field, NumberKeyboard, Loading } from 'react-vant';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getSaleCards, getCards } from "../../api/url.js"
import { changeActive, changeCardAttributesIsActive, changeCardEnhancementLevelIsActive } from '../../store/module/search';
import loading from '@/assets/images/cards/loading.png'
import { List } from 'react-vant';
import usePlaySound from "@/hook/usePlaySound.tsx"


import Sale from "../cards/Sale";
import { formatNumber } from "../../utils/index.js";
import Main from "../cards/Main.js";
import Back from "../../components/common/BackComp.js";
import ButtonComp from "../../components/common/ButtonComp.js";
// import NumberKeyBoard from "../../components/NumberKeyBoard.js";
let tabsList: any = [];
const saleTabsList = [
    {
        "id": "-1",
        "name": "All"
    },
    {
        "id": "5",
        "name": "Diamond"
    },
    {
        "id": "4",
        "name": "Gold"
    },
    {
        "id": "3",
        "name": "Silver"
    },
    {
        "id": "2",
        "name": "Bronze"
    },
    {
        "id": "1",
        "name": "Nature"
    },
    {
        "id": "0",
        "name": "StarAI"
    }
]
const priceList = [
    {
        id: 1,
        price: "0 - 50k",
        priceList: [0, 50000]

    },
    {
        id: 2,
        price: "50k - 100k",
        priceList: [50000, 100000]

    },
    {
        id: 3,
        price: "100k - 1M",
        priceList: [100000, 1000000]

    }
]

const mainTabsList = [
    {
        "id": "-1",
        "name": "All"
    },
    {
        "id": "7",
        "name": "Selling&Display"
    },
    {
        "id": "6",
        "name": "NFT"
    },
    {
        "id": "5",
        "name": "Diamond"
    },
    {
        "id": "4",
        "name": "Gold"
    },
    {
        "id": "3",
        "name": "Silver"
    },
    {
        "id": "2",
        "name": "Bronze"
    },
    {
        "id": "1",
        "name": "Nature"
    },
    {
        "id": "0",
        "name": "StarAI"
    },

]

const miningTabsList = [
    {
        "id": "-1",
        "name": "All"
    },
    {
        "id": "6",
        "name": "NFT"
    },
    {
        "id": "5",
        "name": "Diamond"
    },
    {
        "id": "4",
        "name": "Gold"
    },
    {
        "id": "3",
        "name": "Silver"
    },
    {
        "id": "2",
        "name": "Bronze"
    },
]

const cardAttributesList = [
    {
        id: "electric",
        name: "Electric"
    },
    {
        id: "water",
        name: "Water"
    },
    {
        id: "fire",
        name: "Fire"
    },
    {
        id: "grass",
        name: "Grass"
    },
    {
        id: "ground",
        name: "Ground"
    },
]

const enhancementLevelList = [
    {
        id: 0,
        name: "Level 0"
    },
    {
        id: 1,
        name: "Level 1"
    },
    {
        id: 2,
        name: "Level 2"
    },
    {
        id: 3,
        name: "Level 3"
    },
    {
        id: 4,
        name: "Level 4"
    },
    {
        id: 5,
        name: "Level 5"
    },
]
const pageSize = 10
var initPriceList: any = [];

interface SearchProps {
    callback?: any
    type?: string
    cardArray?: Array<any>
}
const Search = ({ callback, type, cardArray }: SearchProps) => {
    const navigate = useNavigate()
    const isActive = useSelector((state: any) => state.search.isActive)
    const cardAttributesIsActive = useSelector((state: any) => state.search.cardAttributesIsActive)
    const cardEnhancementLevelIsActive = useSelector((state: any) => state.search.cardEnhancementLevelIsActive)
    const dispatch = useDispatch()
    const closePlaySound = usePlaySound('close')
    const [value, setValue] = useState('')
    const [isDown, setIsDown] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const popupRef = useRef(null)
    const [isActivePrice, setIsActivePrice] = useState<any>(Number(localStorage.getItem('isActivePrice')) || -1)
    const [isActivePower, setIsActivePower] = useState<any>(Number(localStorage.getItem('isActivePower')) || -1)
    const [startPrice, setStartPrice] = useState(localStorage.getItem('isActivePrice') != "-1" ? localStorage.getItem('startPrice') : "")
    const [startPower, setStartPower] = useState(localStorage.getItem('isActivePower') != "-1" ? localStorage.getItem('startPower') : "")
    const [endPrice, setEndPrice] = useState(localStorage.getItem('isActivePrice') != "-1" ? localStorage.getItem('endPrice') : "")
    const [endPower, setEndPower] = useState(localStorage.getItem('isActivePower') != "-1" ? localStorage.getItem('endPower') : "")
    const isInitLoading = useRef(true)
    const currPage = useRef(1)
    const total = useRef(0);
    const [saleCardsData, setSaleCardsData] = useState<any>([])
    const isError = useRef(false)
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [visibleEndPower, setVisibleEndPower] = useState(false);
    const [visibleStartPower, setVisibleStartPower] = useState(false);
    const [finished, setFinished] = useState(false);
    const listRef = useRef(null)
    const [params] = useSearchParams();
    const ID: string | null = type !== 'mining' ? params.get("id") : '1'
    useEffect(() => {
        if (ID == "1") {
            tabsList = type == 'mining' ? miningTabsList : mainTabsList

        } else if (ID == "2") {
            tabsList = saleTabsList
        } else if (ID == "3") {
            tabsList = mainTabsList.filter((item: any) => item.id != 7)
        }
    }, [])


    const searchHandle = (e: any) => {
        // if (value.trim() === '') {
        //     return
        // }
        setValue(e.target.value)
    }

    //清除输入框
    const clearHandle = () => {
        setFinished(false)
        setSaleCardsData([])
        setValue(() => "")
        closePlaySound()
        // setIsLoading(false)
        currPage.current = 1
        getDataList("clear")

    }

    const toggleHandle = () => {
        setIsDown(!isDown)
        setStartPrice(localStorage.getItem('startPrice'))
        setEndPrice(localStorage.getItem('endPrice'))
        setStartPower(localStorage.getItem('startPower'))
        setEndPower(localStorage.getItem('endPower'))
    }

    //search
    const searchClickHandle = () => {
        setFinished(false)
        closePlaySound()
        currPage.current = 1
        setSaleCardsData([])
        getDataList()

    }
    //change tabs
    const changeTabHandle = async (name: any) => {
        dispatch(changeActive(name))
        setFinished(false)
    }

    //clickTabHandle
    const clickTabHandle = () => {
        isInitLoading.current = true;
        return false
    }

    // ok
    const okHandle = () => {
        console.log()
        if (isActivePrice != -1) {
            localStorage.setItem('startPrice', '');
            localStorage.setItem('endPrice', '');
        }
        validatePrices()
        setFinished(false)
        if (isActivePrice < 0 && isError.current) {
            return false;
        }
        setIsDown(true)
        currPage.current = 1
        setSaleCardsData([])
        getDataList()
    }


    //reset
    const resetHandle = () => {
        if (isActive == -1) {
            // return false;
        }
        setFinished(false)
        setSaleCardsData([])
        setStartPrice("")
        setEndPrice("")
        setStartPower("")
        setEndPower("")
        dispatch(changeActive("-1"))
        dispatch(changeCardAttributesIsActive(""))
        dispatch(changeCardEnhancementLevelIsActive(-1))
        setIsActivePrice(-1)
        initPriceList = []
        // setIsError(false)
        isError.current = false;
        // setIsDown(true)
        currPage.current = 1
        // if (isActive == 0) {
        getDataList()
        // }

    }

    useEffect(() => {
        // setIsInitLoading(true)
        isInitLoading.current = true;
        setIsLoading(false)
        // initPriceList = []
        // // setValue("")
        // setIsActivePrice(-1)
        // setStartPrice("")
        // setEndPrice("")
        currPage.current = 1
        setSaleCardsData([])
        getDataList()
    }, [isActive])

    const validatePrices = () => {
        if (isActivePrice < 0) {
            if (Number(startPrice) && Number(endPrice) && Number(endPrice) < Number(startPrice)) {
                isError.current = true;
            } else {
                isError.current = false
                initPriceList = [Number(startPrice), Number(endPrice)]
            }
            if (Number(startPrice) && !Number(endPrice)) {
                initPriceList = [Number(startPrice)]
            } else if (!Number(startPrice) && Number(endPrice)) {
                initPriceList = [0, Number(endPrice)]
            }

        }

    };

    const endPriceBlur1 = () => {
        setIsActivePrice(-1)
        localStorage.setItem('isActivePrice', "-1")
        setVisible1(false)
        validatePrices()

    }

    const onLoad = async () => {
        if (finished || isInitLoading.current) return;

        currPage.current++
        await getDataList()
    }



    useEffect(() => {
        initPriceList = priceList.find((item: any) => item.id === isActivePrice)?.priceList

    }, [isActivePrice])

    const getDataList = async (val?: string) => {
        const userId = window.localStorage.getItem("userId") || "";
        try {
            setIsLoading(true)
            let res = null;
            localStorage.setItem('isActivePrice', isActivePrice)
            localStorage.setItem('level', isActive)
            if (ID == "2") {
                if (Array.isArray(initPriceList) && initPriceList.length > 1) {
                    if (isActivePrice == -1) {
                        localStorage.setItem('startPrice', initPriceList[0] || '')
                        localStorage.setItem('endPrice', initPriceList[1] || '')
                    }

                }
                res = await getSaleCards(currPage.current, pageSize, type ? '' : value, isActive, initPriceList, 0, cardAttributesIsActive, 0)
            } else if (ID == "1") {
                res = await getCards(userId, currPage.current, pageSize, isActive, type ? '' : value, '', cardEnhancementLevelIsActive, cardAttributesIsActive, 0, type == 'mining' ? true : false)
            } else {
                res = await getSaleCards(currPage.current, pageSize, type ? '' : value, isActive, initPriceList, 0, cardAttributesIsActive, 2)
            }
            const { code, data: { data, count } } = res
            if (code === '0') {
                setSaleCardsData((prevData: any) => [...prevData, ...data]);
                total.current = count;
                setIsLoading(false)
                isInitLoading.current = false
                if (data.length == 0) {
                    setFinished(true)
                }
            } else {
                setFinished(true)
                setIsLoading(false)
                total.current = 0
            }

        } catch (e) {
            console.log("🚀 ~ getDataList ~ e:", e)
            setIsLoading(false)
            setFinished(true)
            total.current = 0
        }

        if (currPage.current * pageSize >= total.current) {
            setFinished(true)
            // return false;
        }
    }

    const changeTagHandle = (item: any) => {
        closePlaySound()
        // setFinished(false)
        if (isActive == item.id) {
            dispatch(changeActive("-1"))
        } else {
            dispatch(changeActive(item.id))
        }
    }
    const changeAttributesTagHandle = (item: any) => {
        closePlaySound()
        // setFinished(false)
        if (cardAttributesIsActive == item.id) {
            dispatch(changeCardAttributesIsActive(""))
        } else {
            dispatch(changeCardAttributesIsActive(item.id))
        }

    }
    const changeEnhancementTagHandle = (item: any) => {
        closePlaySound()
        // setFinished(false)
        if (cardEnhancementLevelIsActive == item.id) {
            dispatch(changeCardEnhancementLevelIsActive(-1))
        } else {
            dispatch(changeCardEnhancementLevelIsActive(item.id))
        }

    }


    const changePriceHandle = (item: any) => {
        closePlaySound()
        if (isActivePrice == item.id) {
            setIsActivePrice(-1)
        } else {
            setIsActivePrice(item.id)
        }

    }

    const updateHandle = () => {
        currPage.current = 1
        setSaleCardsData([])
        getDataList()
    }



    useEffect(() => {
        document.addEventListener('touchmove', touchMoveHandle, false);
        return () => {
            document.removeEventListener('touchmove', touchMoveHandle, false);
        }
    })

    const touchMoveHandle = () => {
        window.scrollTo(0, 1);
        ensureDocumentIsScrollable();
    }

    const ensureDocumentIsScrollable = () => {
        const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
        if (!isScrollable) {
            document.documentElement.style.setProperty(
                "height",
                "calc(100vh + 0.5px)",
                "important"
            );
        }
    }
    return (
        <div className='search-boxh-full w-full box-border overflow-hidden'>
            {type !== 'mining' ? <div className={`h-[44px] flex justify-center fixed w-full z-[9] pt-5 px-4 box-border`}>
                <Back callback={() => { navigate(`/cards?id=${ID}`) }}>
                    <div className="flex-1 h-[44px] relative mr-2">
                        {/* search icon */}
                        <svg className="absolute left-2 top-[14px]" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="10-cards-搜索4" transform="translate(-60.000000, -61.000000)" fill="#A6A6A6" fillRule="nonzero">
                                    <g id="编组-66" transform="translate(16.000000, 54.237154)">
                                        <g id="编组备份" transform="translate(44.000000, 6.762846)">
                                            <path d="M15.8162606,14.916384 L12.8433128,11.9434362 C13.9040342,10.679772 14.5437767,9.05077441 14.5437767,7.27184289 C14.5437767,3.255722 11.2880547,0 7.27193382,0 C3.255722,0 0,3.25563108 0,7.27175197 C0,11.2878729 3.255722,14.5435949 7.27184289,14.5435949 C9.05077441,14.5435949 10.679772,13.9039433 11.9434362,12.8432218 L14.916384,15.8161696 C15.1652699,16.0622097 15.5661334,16.0610673 15.813613,15.8136127 C16.0610926,15.5661581 16.0622755,15.1652948 15.8162606,14.916384 L15.8162606,14.916384 Z M1.27257478,7.27175197 C1.27257478,3.96374857 3.96383949,1.27248386 7.27184289,1.27248386 C10.5798463,1.27248386 13.271111,3.96374857 13.271111,7.27175197 C13.271111,10.5797554 10.5798463,13.2710201 7.27184289,13.2710201 C3.96383949,13.2710201 1.27257478,10.5797554 1.27257478,7.27175197 Z" id="形状"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        {/* clear icon */}

                        <svg onClick={() => { clearHandle() }} className={`absolute right-2 top-[14px] ${value.length ? "" : "hidden"}`} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="10-cards-搜索4" transform="translate(-276.000000, -61.000000)">
                                    <g id="编组-12备份-2" transform="translate(276.000000, 61.000000)">
                                        <circle id="椭圆形备份-2" fillOpacity="0.804359703" fill="#7F7F7F" cx="8" cy="8" r="8"></circle>
                                        <g id="close备份-2" transform="translate(5.000000, 5.000000)" fillRule="nonzero">
                                            <rect id="矩形" opacity="0" x="0" y="0" width="7" height="7"></rect>
                                            <polygon id="路径" fill="#FFFFFF" points="5.35544156 1.55353843 3.4105707 3.49999664 5.35560345 5.44630031 5.00596784 5.79310345 3.06258094 3.84827796 1.11903216 5.79310345 0.769396552 5.44630031 2.71426741 3.49999664 0.769396552 1.55353171 1.11903216 1.20689655 3.06241906 3.15156078 5.00580595 1.20689655"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <input type="text" placeholder='Search' value={value} onInput={(e) => { searchHandle(e) }} className='rounded-lg text-sm w-full h-full bg-[#333333] placeholder-[#a6a6a6] text-white outline-none border-none px-8' />
                    </div>
                    <ButtonComp title='Search' width={60} height={28} callBack={() => {
                        searchClickHandle()
                    }}>
                    </ButtonComp>

                </Back>
                {/* <svg onClick={() => { backPlaySound(); navigate(`/cards?id=${ID}`) }} className="mr-2" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    
                    <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="10-cards-搜索4" transform="translate(-16.000000, -57.000000)" fillRule="nonzero">
                            <g id="编组-66" transform="translate(16.000000, 54.237154)">
                                <g id="left-arrow备份-2" transform="translate(0.000000, 2.762846)">
                                    <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="24" height="24"></rect>
                                    <polygon id="路径" fill="#FFFFFF" points="10.9382812 20.0811328 4.06971094 13.2125625 2.85714844 12 4.06914844 10.788 10.9382812 3.91886719 12.1502812 5.13086719 6.13945312 11.1428672 21.1451719 11.1428672 21.1451719 12.8571328 6.13942969 12.8571328 12.1508672 18.8685937"></polygon>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg> */}

            </div>
                :
                <Back position={true} title='Select Cards' callback={() => { callback() }}></Back>}
            <div className="content-box h-[calc(100%_-_46px)] overflow-auto">
                <div className={`tabs ${type == 'mining' ? 'mt-[44px]' : 'mt-16'} h-10 w-full flex items-center box-border fixed`} ref={popupRef}>
                    {type != 'mining' && <div className={`${!isDown ? 'hidden' : ''} icon-down absolute right-6  top-2`} onClick={(e) => { e.stopPropagation(); toggleHandle() }}>
                        <svg className="z-[999999px]" width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                            <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="10-cards-搜索" transform="translate(-338.000000, -100.000000)" fillRule="nonzero">
                                    <g id="right备份-2" transform="translate(351.000000, 113.000000) rotate(-270.000000) translate(-351.000000, -113.000000) translate(338.000000, 100.000000)">
                                        <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="26" height="26"></rect>
                                        <polygon id="路径" fill="#FFFFFF" points="15.4317211 13.0036552 8.66666667 6.34514664 9.97947163 5.05555556 18.0555556 13.004254 9.97886291 20.9444444 8.66790947 19.6536308"></polygon>
                                    </g>
                                </g>
                            </g>
                        </svg>

                    </div>}
                    <div className={`tabs-list h-full ${type == 'mining' ? 'w-[calc(100%_-_16px)]' : "w-[calc(100%_-_56px)]"} `}>
                        <Tabs className="w-full h-10 -z-[1px]" beforeChange={() => !isLoading} onClickTab={(e) => { clickTabHandle() }} onChange={(name) => { changeTabHandle(name) }} active={isActive} sticky align="start" swipeable={true} background="#050a0a" color="#fff">
                            {tabsList.filter((item: any) => cardAttributesIsActive ? item.name !== "StarAI" && item.name !== "Nature" : item.name).map((item: any) => (
                                <Tabs.TabPane key={item.id} name={item.id} title={`${item.name}`}>
                                    {
                                        saleCardsData.length > 0 && <div className={`w-[100%] h-[calc(100vh_-_120px)] py-4 box-border overflow-auto text-white `} >
                                            <List ref={listRef} finished={finished} loading={isLoading} immediateCheck={false} onLoad={onLoad} loadingText="Loading...">
                                                {
                                                    saleCardsData && saleCardsData.length > 0 ? (ID == "2" || ID == "3") ? <Sale saleCardsData={saleCardsData} setSaleCardsData={setSaleCardsData} isPadding={false} callback={() => { okHandle() }}></Sale> : <Main callback={callback} cardArray={cardArray} cardsData={saleCardsData} isPadding={false} updateHandle={() => { updateHandle() }}></Main> : null
                                                }
                                            </List>
                                        </div>
                                    }
                                    {
                                        !isLoading && !saleCardsData.length &&
                                        <div className={'w-[100%] h-[calc(100vh_-_120px)] py-4 flex flex-col items-center'}>
                                            <img className="w-[225px] mt-10" src={loading}></img>
                                            <p className='text-[14px] text-[#FFF] mt-[25px] leading-[18px] font-bold'>Not Found</p>

                                        </div>

                                    }
                                    {
                                        isLoading && !saleCardsData.length &&
                                        <div className={'w-[100%]  py-4 flex flex-col items-center'}>
                                            <Loading type="spinner" size="20" color="#00E49F">
                                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]">Loading...</span>
                                            </Loading>
                                        </div>

                                    }
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </div>
                </div>
            </div>





            <Popup className={`${!isDown ? '' : 'hidden'} tabs mt-2 w-full flex items-center box-border`} visible={!isDown} overlay={true} teleport={popupRef.current} position="right" style={{ zIndex: '999', paddingBottom: "30px", width: "100%", background: '#000', marginTop: "66px" }}>
                <div className="w-full h-full px-4 box-border">
                    {/* card level */}
                    <div className="flex justify-between mt-5 items-center" >
                        <div className="title text-white" style={{ fontSize: '16px' }}>Card Level</div>
                        <div className="icon-down" onClick={() => { closePlaySound(); okHandle() }}>
                            <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="10-cards-搜索3" transform="translate(-338.000000, -100.000000)" fillRule="nonzero">
                                        <g id="right备份-2" transform="translate(351.000000, 113.000000) scale(1, -1) rotate(-270.000000) translate(-351.000000, -113.000000) translate(338.000000, 100.000000)">
                                            <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="26" height="26"></rect>
                                            <polygon id="路径" fill="#FFFFFF" points="15.4317211 13.0036552 8.66666667 6.34514664 9.97947163 5.05555556 18.0555556 13.004254 9.97886291 20.9444444 8.66790947 19.6536308"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="card-level flex flex-wrap gap-[6px] mt-[10px] ">
                        {
                            tabsList.map(((item: any) => {
                                return (

                                    <div onClick={() => { changeTagHandle(item) }} key={item.id} className={`${item.id == "-1" ? 'hidden' : ''}  ${cardAttributesIsActive ? Number(item.id) > 1 ? '' : ' hidden ' : ''} ${isActive == item.id ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-shadow-lg' : ''} w-[32%] mb-2 h-9 rounded flex justify-center items-center bg-[#333] text-white text-sm`}>
                                        {item.name}
                                    </div>
                                )
                            }))
                        }
                    </div>
                    <div className="flex justify-between mt-5 items-center" >
                        <div className="title text-white" style={{ fontSize: '16px' }}>Card Attributes</div>
                    </div>
                    <div className="card-level flex flex-wrap gap-[6px] mt-[10px] ">
                        {
                            cardAttributesList.map(((item: any) => {
                                return (
                                    <div
                                        onClick={() => { changeAttributesTagHandle(item) }}
                                        key={item.id}
                                        className={`${item.id ? '' : ''}  
                                    ${cardAttributesIsActive == item.id ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-shadow-lg' : ''} w-[32%] mb-2 h-9 rounded flex justify-center items-center bg-[#333] text-white text-sm`}>
                                        {item.name}
                                    </div>
                                )
                            }))
                        }
                    </div>
                    {ID == '1' &&
                        <>
                            <div className="flex justify-between mt-5 items-center" >
                                <div className="title text-white" style={{ fontSize: '16px' }}>Enhancement Level</div>
                            </div>
                            <div className="card-level flex flex-wrap gap-[6px] mt-[10px] ">
                                {
                                    enhancementLevelList.map(((item: any) => {
                                        return (
                                            <div
                                                onClick={() => { changeEnhancementTagHandle(item) }}
                                                key={item.id}
                                                className={`${item.id ? '' : ''}  
                                    ${cardEnhancementLevelIsActive == item.id ? 'bg-gradient-to-r from-[#00E49F] to-[#54ADFF] text-shadow-lg' : ''} w-[32%] mb-2 h-9 rounded flex justify-center items-center bg-[#333] text-white text-sm`}>
                                                {item.name}
                                            </div>
                                        )
                                    }))
                                }
                            </div>
                        </>}
                    {/* Power setting */}
                    {/* <div className={`${ID == '1' ? 'hidden' : ''} price mt-7`}>
                        <div className="title text-white" style={{ fontSize: '16px' }}>Power</div>
                        <div className="price-setting flex flex-wrap justify-between mt-4 ">
                            {
                                powerList.map((item => {
                                    return (
                                        <div onClick={() => {
                                            let arr = item.price.split('-')
                                            setStartPower(arr[0]);
                                            setEndPower(arr[1]);
                                            localStorage.setItem('isActivePower', String(item.id))
                                            setIsActivePower(item.id)

                                        }} key={item.id} className={`${isActivePower == item.id ? 'activePrice bg-gradient-to-r from-[#CFFFAF] to-[#FFFFFF]' : ''} w-[32%] h-9 rounded flex mb-2 justify-center items-center bg-[#333]`}>
                                            <span className="text-sm text-style-6 ">{item.price}</span>
                                        </div>
                                    )
                                }))
                            }

                            <div className="custom-price flex w-full justify-between items-center">

                                <Field
                                    readOnly={WebApp.platform === "macos" || WebApp.platform === "tdesktop" ? false : true}
                                    value={formatNumber(Number(startPower)).toString() == '0' ? '' : formatNumber(Number(startPower)).toString() || ''}
                                    placeholder="Lowest Power"
                                    onFocus={() => {
                                        setVisibleStartPower(true);
                                    }}
                                    onBlur={() => {
                                        endPowerBlur1()
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
                                            setVisibleStartPower(true);
                                        }
                                        setIsActivePower(-1)
                                    }}
                                    onChange={(e: any) => {
                                        if (WebApp.platform === "macos" || WebApp.platform === "tdesktop") {
                                            const newValue = e.replace(/,/g, "");
                                            const numericRegex = /^[0-9]*$/;
                                            const newValueIsNumeric = numericRegex.test(newValue);
                                            if (newValueIsNumeric || newValue === '') {
                                                if (newValue >= 1000000) {
                                                    setStartPower('1000000')
                                                } else {
                                                    setStartPower(newValue);
                                                }
                                            }
                                        }
                                    }}
                                />
                                <NumberKeyboard
                                    value={startPower}
                                    onBlur={() => setVisibleStartPower(false)}
                                    visible={visibleStartPower}
                                    maxlength={7}
                                    onChange={(e) => {
                                        if (Number(e) > 999) {
                                            setStartPower('1000')
                                        } else {
                                            setStartPower(e)
                                        }
                                    }}
                                />

                                <span className="mx-1 text-white">-</span>


                                <Field
                                    readOnly={(WebApp.platform === "macos" || WebApp.platform === "tdesktop") ? false : true}
                                    // value={formatNumber(Number(endPrice)).toString()}
                                    value={formatNumber(Number(endPower)).toString() == '0' ? '' : formatNumber(Number(endPower)).toString() || ''}

                                    onFocus={() => {
                                        setVisibleEndPower(true);
                                    }}
                                    placeholder="Highest Power"
                                    onBlur={() => {
                                        endPowerBlur1()
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
                                            setVisibleEndPower(true);
                                        }
                                        setIsActivePrice(-1)
                                    }}
                                    onChange={(e: any) => {
                                        if ((WebApp.platform === "macos" || WebApp.platform === "tdesktop")) {
                                            const newValue = e.replace(/,/g, "");
                                            const numericRegex = /^[0-9]*$/;
                                            const newValueIsNumeric = numericRegex.test(newValue);
                                            if (newValueIsNumeric || newValue === '') {
                                                if (newValue >= 1000) {
                                                    setEndPower('1000')
                                                } else {
                                                    setEndPower(newValue);
                                                }
                                            }
                                        }
                                    }} />
                                <NumberKeyboard
                                    value={endPower}
                                    visible={visibleEndPower}
                                    onBlur={() => setVisibleEndPower(false)}
                                    maxlength={7}
                                    onChange={(e) => {
                                        if (Number(e) > 999) {
                                            setEndPower('1000')
                                        } else {
                                            setEndPower(e)
                                        }
                                    }}
                                />

                            </div>

                            {
                                isError.current && <span className="text-red-600">
                                    Incorrect number
                                </span>
                            }

                        </div>
                    </div> */}

                    {/* price setting */}
                    <div className={`${ID == '1' || ID == '3' ? 'hidden' : ''} price mt-5`}>
                        <div className="title text-white" style={{ fontSize: '16px' }}>Price</div>
                        <div className="price-setting flex flex-wrap justify-between mt-[10px] ">
                            {
                                priceList.map((item => {
                                    return (
                                        <div onClick={() => {
                                            localStorage.setItem('startPrice', '');
                                            localStorage.setItem('endPrice', '');
                                            setStartPrice("");
                                            setEndPrice("");
                                            changePriceHandle(item);
                                            localStorage.setItem('isActivePrice', String(item.id))
                                        }} key={item.id} className={`${isActivePrice == item.id ? 'activePrice bg-gradient-to-r from-[#CFFFAF] to-[#FFFFFF]' : ''} w-[32%] h-9 rounded flex mb-2 justify-center items-center bg-[#333]`}>
                                            <span className="text-sm text-style-6 ">{item.price}</span>
                                        </div>
                                    )
                                }))
                            }

                            <div className="custom-price flex w-full justify-between items-center">

                                <Field readOnly={WebApp.platform === "macos" || WebApp.platform === "tdesktop" ? false : true}
                                    value={formatNumber(Number(startPrice)).toString()}
                                    onFocus={() => {
                                        setVisible(false);
                                    }}
                                    onBlur={() => {
                                        endPriceBlur1()
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
                                            setVisible1(true);
                                        }
                                        setIsActivePrice(-1)
                                    }}
                                    onChange={(e: any) => {
                                        if (WebApp.platform === "macos" || WebApp.platform === "tdesktop") {
                                            const newValue = e.replace(/,/g, "");
                                            const numericRegex = /^[0-9]*$/;
                                            const newValueIsNumeric = numericRegex.test(newValue);
                                            if (newValueIsNumeric || newValue === '') {
                                                if (newValue >= 1000000) {
                                                    setStartPrice('1000000')
                                                } else {
                                                    setStartPrice(newValue);
                                                }
                                            }
                                        }
                                    }}
                                />
                                <NumberKeyboard
                                    value={startPrice}
                                    visible={visible1}
                                    onBlur={() => setVisible1(false)}
                                    maxlength={7}
                                    onChange={(e) => {
                                        if (Number(e) > 999999) {
                                            setStartPrice('1000000')
                                        } else {
                                            setStartPrice(e)
                                        }
                                    }}
                                />

                                <span className="mx-1 text-white">-</span>
                                <Field readOnly={(WebApp.platform === "macos" || WebApp.platform === "tdesktop") ? false : true} value={formatNumber(Number(endPrice)).toString()}
                                    onFocus={() => {
                                        setVisible1(false);
                                    }}
                                    onBlur={() => {
                                        endPriceBlur1()
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
                                            setVisible(true);
                                        }
                                        setIsActivePrice(-1)
                                    }}
                                    onChange={(e: any) => {
                                        if ((WebApp.platform === "macos" || WebApp.platform === "tdesktop")) {
                                            const newValue = e.replace(/,/g, "");
                                            const numericRegex = /^[0-9]*$/;
                                            const newValueIsNumeric = numericRegex.test(newValue);
                                            if (newValueIsNumeric || newValue === '') {
                                                if (newValue >= 1000000) {
                                                    setEndPrice('1000000')
                                                } else {
                                                    setEndPrice(newValue);
                                                }
                                            }
                                        }
                                    }} />
                                <NumberKeyboard
                                    value={endPrice}
                                    visible={visible}
                                    onBlur={() => setVisible(false)}
                                    maxlength={7}
                                    onChange={(e) => {
                                        if (Number(e) > 999999) {
                                            setEndPrice('1000000')
                                        } else {
                                            setEndPrice(e)
                                        }
                                    }}
                                />



                            </div>

                            {
                                isError.current && <span className="text-red-600">
                                    Incorrect number
                                </span>
                            }

                        </div>
                    </div>

                    {/* button */}
                    <div className="btn flex justify-center items-center mt-7">
                        <ButtonComp title='OK' width={160} callBack={() => {
                            setVisible1(false);
                            setVisible(false);
                            okHandle()
                        }}>

                        </ButtonComp>

                        <ButtonComp title='Reset' width={160} callBack={() => {
                            setVisible1(false);
                            setVisible(false);
                            resetHandle()
                        }}>

                        </ButtonComp>

                    </div>
                </div>
            </Popup >





        </div >
    )
}


export default Search;