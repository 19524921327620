import { createSlice } from "@reduxjs/toolkit";


interface CardState {
  continueEnhanceCard: any
  battleCardData: any
  shopConfigList: any[]
  card_report: any
}


const initialState: CardState = {
  continueEnhanceCard: {},
  battleCardData: {},
  shopConfigList: [],
  card_report: {}
}
const CardStore = createSlice({
  name: 'card',
  initialState,
  reducers: {
    changeContinueEnhanceCard(state, action) {
      state.continueEnhanceCard = action.payload
    },
    changeBattleCardData(state, action) {
      state.battleCardData = action.payload
    },
    changeShopConfigList(state, action) {
      state.shopConfigList = action.payload
    },
    changeCardReport(state, action) {
      state.card_report = action.payload
    }
  }
})

export const { changeContinueEnhanceCard, changeBattleCardData, changeShopConfigList, changeCardReport } = CardStore.actions


export default CardStore.reducer