import { useEffect, useState } from 'react';
import { CheckIn } from '@/contracts/tact_CheckIn.ts';
import { useTonClient } from './useTonClient';
import { useAsyncInitialize } from './useAsyncInitialize';
import { useTonConnect } from './useTonConnect';
import { Address, OpenedContract, toNano } from '@ton/core';

export function useCheckInContract() {
  const client = useTonClient();
  // console.log(client)
  const { sender } = useTonConnect();



  const checkInContract = useAsyncInitialize(async () => {
    if (!client) return;
    const contract = CheckIn.fromAddress(
      Address.parse('EQCxr1o-x7cEFb3vALiYMOW7QPuAoGHMtw1Yab5m6HrnuIuZ') // replace with your address from tutorial 2 step 8
    );
    return client.open(contract) as OpenedContract<CheckIn>;
  }, [client]);
  return {
    address: checkInContract?.address.toString(),
    checkIn: async () => {
      return await checkInContract?.send(sender,
        {
          value: toNano('0.008'),
        },
        "check-in"
      );
    },
    getTotal: async () => {
      return await checkInContract?.getTotalCheckIn()
    }
  };
}