import lV_1 from "@/assets/images/levelList/2-5.png";
import lV_2 from "@/assets/images/levelList/6-10.png";
import lV_3 from "@/assets/images/levelList/11-15.png";
import lV_4 from "@/assets/images/levelList/16-20.png";
import lV_5 from "@/assets/images/levelList/21-25.png";
import lV_6 from "@/assets/images/levelList/26-30.png";
import lV_7 from "@/assets/images/levelList/31-35.png";
import lV_8 from "@/assets/images/levelList/36-40.png";
import lV_9 from "@/assets/images/levelList/41-45.png";
import lV_10 from "@/assets/images/levelList/46-50.png";
export function getLevelImg(level: string) {
  let value = lV_1;
  switch (Math.floor(+level / 5)) {
    case 0:
      value = lV_1;
      break;
    case 1:
      value = lV_2;
      break;
    case 2:
      value = lV_3;
      break;
    case 3:
      value = lV_4;
        break;
    case 4:
      value = lV_5;
        break;
    case 5:
      value = lV_6;
        break;
    case 6:
      value = lV_7;
        break;
    case 7:
      value = lV_8;
        break;
    case 8:
      value = lV_9;
        break;
    case 9:
      value = lV_10;
        break;
  }
  return value;
}