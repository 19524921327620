import tabbar from '@/assets/mp3/tabbar.mp3';
import close from '@/assets/mp3/close.mp3';
import back from '@/assets/mp3/back.mp3';
import coins from '@/assets/mp3/coins.mp3';
import bronze from "@/assets/images/deck/bronze.png"
import diamond from "@/assets/images/deck/diamond.png"
import gold from "@/assets/images/deck/gold.png"
import hero from "@/assets/images/deck/hero.png"
import nft from "@/assets/images/deck/nft.png"
import silver from "@/assets/images/deck/silver.png"

import water from '@/assets/images/attribute/water.png'
import electric from '@/assets/images/attribute/electric.png'
import grass from '@/assets/images/attribute/grass.png'
import ground from '@/assets/images/attribute/ground.png'
import fire from '@/assets/images/attribute/fire.png'


export function formatNumber(num: number | string) {

  if (typeof num !== 'number' || isNaN(num)) {
    return num
  } else {
    if (num === 0) {
      return 0
    } else {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
  // 将数字转换为字符串，并使用正则表达式添加逗号
}
export function convertNumber(num: string) {
  if (typeof num !== 'string' || isNaN(Number(num))) {
    return num
  }
  let str = num;
  while (str.length < 3) {
    str = '0' + str;
  }
  return str;
}

export function b64Decode(str: any) {
  // 将Base64编码的字符串转换为Unicode字符串
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}



export function FormattingNumber(val: any) {
  if (typeof val !== 'string' || isNaN(Number(val))) {
    return val
  }
  let num = Number(val);
  if (num >= 1000000) {
    return Math.floor((num / 1000000) * 10) / 10 + 'm';
  } else if (num >= 1000) {
    return Math.floor((num / 1000) * 10) / 10 + 'k';
  } else {
    return num;
  }
}

export function FormattingTwoNumber(val: any) {
  // if (typeof val !== 'string' || isNaN(Number(val))) {
  //   return val
  // }
  let num = Number(val);
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + 'b';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + 'm';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + 'k';
  } else {
    return num;
  }
}

export function formatIdString(str: string, startChars = 4, endChars = 4) {
  if (typeof str !== 'string') return str;
  const start = str.toString().slice(0, startChars);
  const end = str.slice(-endChars);
  return `${start}...${end}`;
}
export function formatNameString(str: string) {
  if (typeof str !== 'string') return str;
  if (str.length < 10) return str;
  const startChars = 4;
  const endChars = 4;
  const start = str.toString().slice(0, startChars);
  const end = str.slice(-endChars);
  return `${start}...${end}`;
}

// export function button_shadow() {
//   const buttons = document.querySelectorAll('.button_shadow')
//   buttons.forEach((btn: any) => {
//     btn.addEventListener('touchstart', function (e: any) {
//       var touch = e.touches[0];
//       // 获取触点相对于页面的位置
//       let x = touch.pageX - btn.offsetLeft;
//       let y = touch.pageY - btn.offsetTop;
//       let ripples = document.createElement('span');
//       ripples.style.left = x + 'px';
//       ripples.style.top = y + 'px';
//       btn.appendChild(ripples);
//       setTimeout(() => {
//         ripples.remove()
//       }, 1000)
//     })
//   })
// }


export function PlaySound(type: string) {
  function file() {
    switch (type) {
      case 'tabbar':
        return tabbar;
      case 'close':
        return close;
      case `back`:
        return back;
      case `coins`:
        return coins;
      default:
        return close;
    }
  }
  const audio = new Audio(file());
  audio.play()
    .then(() => {
    })
    .catch((error) => {
      console.error('无法播放音频:', error);
    });
}

export function HighlightPercentages(text: string) {
  const highlightedText = text.replace(/<([^>]+)>/g, "<span style='background: linear-gradient(48deg, #005FC4 0%, #D43BA6 100%);background-clip: text;color: transparent;font-weight:800;font-size: 14px;line-height:18px;'>$1</span>");
  return highlightedText;
}

export function getQueryString(name: string) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  console.log("🚀 ~ getQueryString ~ window.location:", window.location)

  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return '';
}


export function attributeImage(attribute: string) {
  switch (attribute) {
    case 'water':
      return water
    case 'electric':
      return electric
    case 'grass':
      return grass
    case 'ground':
      return ground
    case 'fire':
      return fire
    default:
  }
}

export function levelName(level: number) {
  switch (level) {
    case 8:
      return 'Hero'
    case 6:
      return 'NFT'
    case 5:
      return 'Diamond'
    case 4:
      return 'Gold'
    case 3:
      return 'Silver'
    case 2:
      return 'Bronze'

  }
}

export function levelImage(level: string) {
  switch (level) {
    case 'Bronze':
      return bronze
    case 'Diamond':
      return diamond
    case 'Gold':
      return gold
    case 'Hero':
      return hero
    case 'NFT':
      return nft
    case 'Silver':
      return silver
  }
}

export function attributeName(attribute: number) {
  switch (attribute) {
    case 0:
      return 'water'
    case 1:
      return 'electric'
    case 2:
      return 'grass'
    case 3:
      return 'ground'
    case 4:
      return 'fire'
    default:
  }
}