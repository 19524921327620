import { Dialog } from "react-vant"
import coins from '@/assets/images/task/coins.png'
import { useEffect, useRef, useState, } from "react"
import usePlaySound from "@/hook/usePlaySound.tsx"
import { formatNumber } from "../utils"
import ButtonComp from "./common/ButtonComp"
interface GetCoinAnimationDialogProps {
  dialog: boolean
  setDialog: (val: boolean) => void
  closeOnClickOverlay?: boolean
  getCoinAnimation: Function
  title?: string
  point?: string
}
const GetCoinAnimationDialog = ({ dialog, title = 'Task Rewards', setDialog, point = '1000', getCoinAnimation }: GetCoinAnimationDialogProps) => {
  const [show, setShow] = useState(true)
  const coinsPlaySound = usePlaySound('coins')
  const coinsRef = useRef<any>(null)
  function getCoordinate() {
    getCoinAnimation(coinsRef.current.getBoundingClientRect().x, coinsRef.current.getBoundingClientRect().y)
    coinsPlaySound()
    setShow(false)
    setTimeout(() => {
      setDialog(false)
      setShow(true)
    }, 250)
  }
  const dialogRef = useRef<any>(null)
  var mo = function (e: any) { e.preventDefault(); };
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      className='PublicDialog overflow-visible'
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={true}
      onClose={() => { setDialog(false) }}
      width={'100%'}
    >
      <div ref={dialogRef} className="w-[100%] h-[100vh] flex items-center justify-center">
        <div style={{ backgroundSize: "100% 100%" }} className="w-full  overflow-visible !bg-[length:100%_100%] relative bg-[url(@/assets/images/dialog_bg.png)] pb-[50px]">
          <div className='text-center'>
            <div className='text-center w-full pt-[42px] font-bold leading-6 text-[20px]'>{title}</div>
            <div className='flex justify-center items-center my-10'>
              {show && <img className={`w-[65px] mr-[10px] h-[43px] my-[7px]`} ref={coinsRef} src={coins} alt="" />}
              <p className='text-style-6 font-bold !text-[30px] !leading-[37px]'>+{formatNumber(Number(point))}</p>
            </div>

            <ButtonComp title="OK" callBack={() => {
              getCoordinate()
            }}></ButtonComp>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
export default GetCoinAnimationDialog
