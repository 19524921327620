import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface BattleState {

    selectionList: any[],
    times: number
    battleInfo: any
    arenaNavActive: number
    nftDeck: any

}




const initialState: BattleState = {
    selectionList: [],
    times: 1,
    battleInfo: {},
    arenaNavActive: 0,
    nftDeck:[]
}
const BattleStore = createSlice({
    name: 'battle',
    initialState,
    reducers: {
        changeSelectionList(state, { payload }) {

            if (Object.prototype.toString.call(payload) === '[object Object]') {
                state.selectionList.push(payload)
            } else if (Object.prototype.toString.call(payload) === '[object Boolean]') {
                state.selectionList = []
            } else if (Object.prototype.toString.call(payload) === '[object Number]') {
                state.selectionList = state.selectionList.filter((item) => item.id != payload)
            }

        },
        removeSelectionList(state, action) {
            state.selectionList = state.selectionList.filter((item) => item.id !== action.payload)
        },
        changeTimes(state, action) {
            state.times = action.payload
        },
        changeBattleInfo(state, action) {
            state.battleInfo = action.payload
        },
        changeArenaNavActive(state, action) {
            state.arenaNavActive = action.payload
        },
        changeNftDeck(state, action) {

            state.nftDeck = action.payload
            // console.log(state.nftDeck)
        }
    }
})

export const { changeSelectionList, removeSelectionList, changeTimes, changeBattleInfo, changeArenaNavActive, changeNftDeck } = BattleStore.actions

export default BattleStore.reducer