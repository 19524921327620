
import downloadImg from "../../assets/images/shop/download.png"

import progressRight from "@/assets/images/refining/progressRight.png"

import starGreenImg from "../../assets/images/rules/star.png"
import starCardImg from "../../assets/images/cards/starCard.png"
import downloadIconImg from "../../assets/images/shop/download-icon.png"
import BuyComp from '../../components/common/BuyComp.js';

import flowerImg from "../../assets/images/shop/flower.png"

interface PropType {
    dataList: any[]
}
const Refining = ({ dataList }: PropType) => {
    return (

        <>
            <div style={{ backgroundSize: '100% 100%' }} className="bg-[url(@/assets/images/shop/bg-2.png)] flex justify-center flex-col items-center  px-4 py-8 bg-cover bg-no-repeat h-[280px] w-full">
                <div className='w-[80%] flex justify-around items-center'>

                    <img className='h-[90px]' src={starCardImg} alt="" />

                    <div className='text-center '>
                        <p className='text-white text-sm'>Image Download</p>
                        <div className="w-[94px] mt-1 overflow-hidden flex">

                            <img className="w-[94px] translate-x-[-94px] progressRight_1 " src={progressRight} alt="" />
                            <img className="w-[94px] translate-x-[-94px] progressRight_2 " src={progressRight} alt="" />
                        </div>
                    </div>
                    <div className='flex items-center mt-3'>

                        <img className='w-[90px] h-[80px]' src={downloadImg} alt="" />
                    </div>

                </div>
                <div className='flex items-center'>
                    <img className='w-4 h-4 mr-2' src={downloadIconImg} alt="" />
                    <p className='text-white text-xs'>(Chances)</p>
                </div>
                <div className="text flex-1 text-left mt-3">
                    <p className='text-sm text-shadow-lg text-white flex items-start'><img className='w-3 h-3 mr-2 mt-1' src={starGreenImg} alt="" />You can download your favorite AI images through payment.</p>
                    <p className='text-sm text-shadow-lg text-white flex items-start'><img className='w-3 h-3 mr-2 mt-1' src={starGreenImg} alt="" />Below, you can purchase multiple download opportunities at once.</p>
                    <p className='text-sm text-shadow-lg text-white flex items-start'><img className='w-3 h-3 mr-2 mt-1' src={starGreenImg} alt="" />After a successful purchase, the number of chances you have will be displayed in the functional area.</p>
                </div>

            </div>
            <div className='grid grid-cols-1 gap-4 mt-4'>
                {
                    dataList.map((item: any, index) => {
                        return (
                            <div key={index}>
                                {
                                    item.length > 0 && <div key={index} className='relative w-full flex items-center justify-around px-4 py-2 h-[90px] rounded-[20px] special-class'>

                                        <div className={`${item[0].discount <= 0 ? 'hidden' : ''} w-8 absolute -top-1 -left-0 flex justify-center items-center`}>
                                            <img className='w-8' src={flowerImg} alt="" />
                                            <span className='absolute text-xs text-white'>{item[0].discount}%</span>
                                        </div>
                                        <div className='flex flex-1 items-center justify-center'>
                                            <p className='text-sm text-white font-[900]'>Includes<span className='text-transparent bg-clip-text bg-gradient-to-r from-[#00E49F] to-[#54ADFF]'> {item[0].count} Chances</span> for Image Download</p>
                                        </div>
                                        <BuyComp title="Image Download Chances" info={item}></BuyComp>
                                    </div>
                                }
                            </div>

                        )
                    })
                }
            </div>
        </>


    )
}


export default Refining;