
import { useCallback, useEffect, useRef, useState } from 'react'

import { calculateUsdtAmountSmall, PAYTYPE } from '../../utils/common-helpers.js';
import { fromNano } from '@ton/core';
import TonTransfer from '../../components/connectOkWallet/transform.js';
import { miningWaitForTransaction, waitForTransaction } from '../../hook/WaitForTransaction.js';
import { changeTransactionBoc, changeUsdtTransferType } from '../../store/module/lottery.js';
import { useDispatch, useSelector } from 'react-redux';
import { useTonAddress } from '@tonconnect/ui-react';
import UsdtTransfer from "@/components/connectOkWallet/USDT_transform"
import ConnectWallet from '../../components/common/ConnectWallet.js';
import PromptDialog from '../../components/PromptDialog.js';
import { Toast } from 'react-vant';
import WebApp from '@twa-dev/sdk';


interface PropType {
    dataObj: any,
    callBack: (value?: any) => void
    type?: string
}
const Pay = ({ dataObj, callBack, type = '' }: PropType) => {
    const { shopTransformHandle, shopStarPayHandle, miningTransformHandle, miningShopStarPayHandle } = TonTransfer()
    const dispatch = useDispatch()
    const { UsdtTransformHandle, UsdtMiningTransformHandle } = UsdtTransfer()
    const address = useTonAddress()
    const [isShowConnectWallet, setShowConnectWallet] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const errorText = useRef("")

    useEffect(() => {
        if (dataObj.id) {
            payHandle(dataObj)
        }
    }, [dataObj])


    const payHandle = async (item: any) => {
        if (item.pay_type != 2) {
            if (!address) {
                setShowConnectWallet(true)
                return false
            }
        }
        switch (item.pay_type) {
            case 3:
                await tonPayHandle(item)
                break
            case 4:
                await usdtTransferHandle(item)
                break
            case 2:
                await starPayHandle(item)
                break

        }
    }


    //pay for ton
    const tonPayHandle = async (item: any) => {
        let res: any = null
        if (type == 'mining') {
            res = await miningTransformHandle(fromNano(item.price), item.id)
        } else {
            res = await shopTransformHandle(fromNano(item.price), item.id, item.title)
        }
        // if (res?.code == '0') {
        callBack && callBack(res)
        // } else {
        //     errorText.current = "Network Error."
        //     setNetworkError(true)
        // }
    }

    //pay for usdt
    const { UsdtTransferOrderId, transactionBoc, usdtTransferType } = useSelector((state: any) => state.lottery)
    const orderId = useRef("")
    useEffect(() => {
        orderId.current = UsdtTransferOrderId
        if (transactionBoc && usdtTransferType === 'shop') {
        console.log("🚀 ~ useEffect ~ transactionBoc:", transactionBoc)

            transferHandle(orderId.current, transactionBoc)
        }
        if (transactionBoc && usdtTransferType === 'mining') {
            miningTransferHandle(orderId.current, transactionBoc, dataObj.id)
        }
    }, [UsdtTransferOrderId, transactionBoc])

    const transferHandle = async (orderId: string, boc: string) => {
        try {
            const url = '/api/v1/miniapp/shop/pay_check'
            const data = await waitForTransaction(orderId, boc, url);
            dispatch(changeTransactionBoc(""))
            if (data?.code == "0") {
                callBack && callBack(data)
                // refreshCount(data.data)
            } else if (data?.code == "65551") {
                errorText.current = "Transaction failed, please try again."
                setNetworkError(true)
            } else if (data?.code == "65539") {
                errorText.current = "Blockchain network congested.Please wait for 5 minutes."
                setNetworkError(true)
            }
        } catch (err) {
            errorText.current = "Network Error."
            setNetworkError(true)
        }
    }

    const miningTransferHandle = async (orderId: string, boc: string, id: string) => {
        try {
            const url = '/api/v1/miniapp/mining/accelerator_pay_check'
            const data = await miningWaitForTransaction(orderId, boc, url, id);
            dispatch(changeTransactionBoc(""))
            if (data?.code == "0") {
                callBack && callBack(data)
                // refreshCount(data.data)
            } else if (data?.code == "65551") {
                errorText.current = "Transaction failed, please try again."
                setNetworkError(true)
            } else if (data?.code == "65539") {
                errorText.current = "Blockchain network congested.Please wait for 5 minutes."
                setNetworkError(true)
            }
        } catch (err) {
            errorText.current = "Network Error."
            setNetworkError(true)
        }

    }

    const usdtTransferHandle = async (item: any) => {
        const price = calculateUsdtAmountSmall(item.price / 100)
        if (type == 'mining') {
            UsdtMiningTransformHandle(price, '', item.id)
            dispatch(changeUsdtTransferType("mining"))
            dispatch(changeTransactionBoc(""))
        } else {
            console.log(item)
            UsdtTransformHandle(price, item.title, '', item.id)
            dispatch(changeUsdtTransferType("shop"))
            dispatch(changeTransactionBoc(""))
        }

    }


    //pay for star
    const starPayHandle = async (item: any) => {
        let res: any = null
        if (type == 'mining') {
            res = await miningShopStarPayHandle(item)
        } else {
            res = await shopStarPayHandle(item)
        }
        // if (res?.code == '0') {
        callBack && callBack(res)
        // } else {
        //     errorText.current = "Network Error."
        //     setNetworkError(true)
        // }
    }

    return (

        <>
            {/* <CardPresentDialog data={[]} callBack={(url: any) => { }}></CardPresentDialog> */}

            {
                isShowConnectWallet && <ConnectWallet callback={() => { setShowConnectWallet(false) }}></ConnectWallet>
            }
            {
                <PromptDialog isClose={true} title={'Attention'} dialog={networkError} setDialog={setNetworkError} text={errorText.current}></PromptDialog>
            }
        </>


    )
}


export default Pay;