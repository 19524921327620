import { createSlice } from "@reduxjs/toolkit";


interface ProgressState {
  settings: {
    music: boolean
    vibration: boolean
    sound: boolean
    nsfw: boolean
    star_nsfw: boolean
    character_nsfw: boolean
    connect_wallet: boolean
  },
  isShowIntroductory: boolean
  isShowNewUserGiftDialog: boolean,
  turntableDialog: boolean,
  miningPoolConfig: any
}




const initialState: ProgressState = {
  settings: {
    music: false,
    sound: true,
    vibration: true,
    nsfw: false,
    star_nsfw: false,
    character_nsfw: false,
    connect_wallet: false,

  },
  isShowIntroductory: false,
  isShowNewUserGiftDialog: false,
  turntableDialog: false,
  miningPoolConfig: {
    is_sai_clicked: true
  }
}
const settingsStore = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    changeSettings(state, action) {
      state.settings = action.payload;
    },
    changeShowIntroductory(state, action) {
      state.isShowIntroductory = action.payload;
    },
    changeShowNewUserGiftDialog(state, action) {
      state.isShowNewUserGiftDialog = action.payload;
    },
    changeTurntableDialog(state, action) {
      state.turntableDialog = action.payload;
    },
    changeMiningPoolConfig(state, action) {

      state.miningPoolConfig = { ...action.payload }

    }



  }
})

export const { changeMiningPoolConfig, changeTurntableDialog, changeSettings, changeShowIntroductory, changeShowNewUserGiftDialog } = settingsStore.actions

export default settingsStore.reducer