import { NumberKeyboard } from "react-vant"
import coin from '@/assets/images/coin.png'
import WebApp from '@twa-dev/sdk'
import { formatNumber } from "../../utils"
import { useState } from "react"

interface DigitalInputCompProps {
  valueSlider: string
  setValueSlider: Function
  height?: number
  background: string
  max?: number
  coinHeight?: number

}
const DigitalInputComp = ({ valueSlider, setValueSlider, height = 36, background, max = 1000000 ,coinHeight=24 }: DigitalInputCompProps) => {
  const [state, setState] = useState(false);
  return (
    <>
      <div style={{ height: `${height}px` }} className=" w-full flex items-center justify-center relative">
        <img src={coin} style={{height:coinHeight}} className='h-[24px] absolute right-[10px]' alt="" />
        <input
          min="0" max={max}
          style={{ background: background }}
          value={formatNumber(Number(valueSlider))}
          placeholder={`1-${formatNumber(max)}`}
          onClick={() => {
            if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
              setState(true)
            }
          }}
          onChange={(e: any) => {
            if (WebApp.platform === "macos" || WebApp.platform === "tdesktop") {
              const newValue = e.target.value.replace(/,/g, "");
              const numericRegex = /^[0-9]*$/;
              const newValueIsNumeric = numericRegex.test(newValue);
              if (newValueIsNumeric || newValue === '') {
                if (newValue >= max) {
                  setValueSlider(max.toString())
                } else {
                  setValueSlider(newValue);
                }
              }
            }
          }}
          onFocus={(e: any) => {
            e.preventDefault()
            if (WebApp.platform !== "macos" && WebApp.platform !== "tdesktop") {
              e.target.blur()
            }
          }}
          className={`publishInput tracking-[1.5px] text-[18px] leading-[16px] w-[100%] h-full rounded-[10px] p-2 px-[10xp] font-bold`}
        />
      </div>
      <NumberKeyboard
        visible={state}
        value={valueSlider}
        className='z-[999999]'
        maxlength={7}
        onBlur={() => setState(false)}
        onChange={v => {
          if (Number(v) >= max) {
            setValueSlider(max.toString())
          } else {
            setValueSlider(v)
          }
        }}
      />
    </>
  )
}
export default DigitalInputComp