import { useEffect, useRef } from 'react';
import AsyncScript from '@/utils/AsyncScript';

const DynamicScript = () => {
  const serviceDOM = useRef<any>(null)

  useEffect(() => {
    AsyncScript.load({
      name: 'openAdJs',
      version: new Date().valueOf(),
      url: '//alpha.openad.network/www/delivery/asyncjs.php',
      noCache: true,
      id: '6fced4eee9927b57847cf8dce447ceac',
    });
    return () => {
      AsyncScript.remove({ id: '6fced4eee9927b57847cf8dce447ceac', script: 'reviveAsync' });

    }
  }, [])
  return (
    <>
      <div ref={serviceDOM}>
        <ins data-revive-zoneid="16" data-revive-id="6fced4eee9927b57847cf8dce447ceac"></ins>
      </div>
    </>
  );
};

export default DynamicScript;