import { Dialog } from "react-vant"
import coins from '@/assets/images/task/coins.png'
import { useEffect, useRef, useState, } from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeCheck_in_loading } from "../store/module/tasks"
import usePlaySound from "@/hook/usePlaySound.tsx"
import { formatNumber } from "../utils"
import ButtonComp from "./common/ButtonComp"
interface GetCoinAnimationDialogProps {
  dialog: boolean
  setDialog: Function
  closeOnClickOverlay?: boolean
  getCoinAnimation: Function
  title?: string
  point?: string
}
const LoadingDialog = ({ dialog, title = 'Task Rewards', point = '1000', getCoinAnimation }: GetCoinAnimationDialogProps) => {
  const [show, setShow] = useState(true)
  const dispatch = useDispatch()
  const closePlaySound = usePlaySound('close')
  const check_in_loading = useSelector((state: any) => state.tasks.check_in_loading)
  const coinsRef = useRef<any>(null)
  const dialogRef = useRef<any>(null)
  function getCoordinate() {
    Number(point) && getCoinAnimation(coinsRef.current.getBoundingClientRect().x, coinsRef.current.getBoundingClientRect().y)
    setShow(false)
    setTimeout(() => {
      closeDialog()
      setShow(true)
    }, 250)
  }
  function closeDialog() {
    const newLoading = { ...check_in_loading }
    newLoading.loading = false
    dispatch(changeCheck_in_loading(newLoading))
  }
  useEffect(() => {

  }, [dialogRef.current])
  return (
    <Dialog
      className='PublicDialog overflow-visible'
      visible={dialog}
      showConfirmButton={false}
      showCancelButton={false}
      closeOnClickOverlay={true}
      onClose={() => { closeDialog() }}
      width={'100%'}
    >
      <div ref={dialogRef} className="w-[100%] h-[100vh] flex items-center justify-center">
        <div style={{ backgroundSize: "100% 100%" }} className="w-full overflow-visible relative !bg-[length:100%_100%] bg-[url(@/assets/images/dialog_bg.png)] pb-[50px]">
          <div className='text-center'>
            <div className='text-center w-full pt-[42px] font-bold leading-6 text-[20px]'>{title}</div>
            <div className='flex justify-center items-center my-10'>
              {show && <img className={`w-[65px] mr-[10px] h-[43px] my-[7px]`} ref={coinsRef} src={coins} alt="" />}
              <p className='text-style-1 font-bold !text-[30px] text-style-6 !leading-[37px]'>+{formatNumber(Number(point)) || 0}</p>
            </div>
            <div className='relative w-[174px] h-[36px] mx-auto'>
              <ButtonComp title="OK" callBack={() => {
                getCoordinate()
                closePlaySound()
              }}></ButtonComp>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
export default LoadingDialog
