import WebApp from '@twa-dev/sdk'

WebApp.ready();
WebApp.enableClosingConfirmation()

export default function () {
    try {
        const initHeight = WebApp.viewportHeight;
        // document.getElementsByTagName('body')[0].style.height = height  +'px';
        const userInfo: any = decodeURIComponent(WebApp.initData);
        const userPart = userInfo.match(/user=(.*?)&/)[1] || "";
        var chatInstanceMatch = userInfo.match(/chat_instance=(\d+)/) || "";
        var userFields;
        try {
            userFields = JSON.parse(userPart);
        } catch (e) {
            console.error("Error parsing JSON:", e);
        }

        WebApp.onEvent("viewportChanged",(e)=>{
            if(e.isStateStable){
                if(WebApp.platform == 'ios' && WebApp.isExpanded ){
                    if( initHeight > WebApp.viewportHeight ){
                        const keyboardHeight = initHeight - WebApp.viewportHeight;
                        if( keyboardHeight > 100 ){
                            localStorage.setItem("keyboardHeight",keyboardHeight.toString());
                        }
                    }
                }
                // document.getElementsByTagName('body')[0].style.height = height  +'px';
                // window.localStorage.setItem("viewportHeight", height.toString())
            }
            
        })

    }
    catch (e) {

    }

    return {
        userId: userFields && userFields["id"],
        userName: userFields && userFields["username"],
        firstName: userFields && userFields["first_name"],
        lastName: userFields && userFields["last_name"],
        chatInstance: chatInstanceMatch && chatInstanceMatch[1]
    }
    // 使用JSON.parse来解析user字段的值


}