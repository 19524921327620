import request from '@/utils/axios-req'



//最近聊过

export const getCharacterChatList = (params) => {
    const { page, limit, id } = params
    return request({
        url: `/api/v1/miniapp/character/${id}/chatted?page=${page}&limit=${limit}`,
        method: 'get',
    })
}


//聊过置顶
export const pinCharacterChatList = (params) => {
    const { id, is_pin } = params
    return request({
        url: `/api/v1/miniapp/character/${id}/chatted?is_pin=${is_pin}`,
        method: 'put',
    })
}


//删除
export const delCharacterChatList = (id) => {
    return request({
        url: `/api/v1/miniapp/character/${id}/chatted`,
        method: 'delete',
    })
}