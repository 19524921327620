import { Popup } from "react-vant"
import CardItem from "../CardItem"
import bg from "../../assets/images/talk/bg.png"
import addImg from '../../assets/images/pk/add-icon.png'
import delImg from '../../assets/images/pk/delete.png'
import ButtonComp from '../common/ButtonComp';
import { useRef } from "react";
import { convertNumber } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectionList } from "../../store/module/battle"


interface PropsType {
    createHandle: () => void
}
const mySelection = ({ createHandle }: PropsType) => {
    const dispatch = useDispatch()
    const { selectionList, times } = useSelector((state: any) => state.battle)
    const delHandle = (el: any) => {
        if (selectionList.map((item: any) => item.id).includes(el.id)) {
            dispatch(changeSelectionList(el.id))
        }
    }
    return (
        <div className='w-full rounded-t-xl text-center px-2 pt-[28px] h-[150px] absolute bottom-0' style={{ backgroundImage: `linear-gradient( 135deg, #00E49F 0%, #54ADFF 100%)` }}>
            <div className="text-white rounded-tl-xl rounded-br-xl absolute top-0 left-0 py-1 px-2 text-xs bg-gradient-to-bl to-[#ef4444] from-[#e88585]">
                My Selection
            </div>
            <div className="h-[90px] w-full flex justify-center items-center overflow-hidden">
                <div className="w-[300px] h-[90px] flex flex-nowrap overflow-x-scroll overflow-y-hidden">
                    {
                        selectionList.length > 0 && selectionList.map((el: any) => {
                            return (
                                <div className="relative h-[90px] w-[60px] flex-shrink-0 mr-2" key={el.id} onClick={() => { delHandle(el) }}>
                                    <CardItem is_enhance={true} height={90} width={60} rounded={10} power={el.power} property={el.property} enhanceLevel={el.enhance_level} mint_state={el.mint_state} is_nft={el.is_nft} id={el.id} price={el.price} like={el.like_count} view={el.view_count} state={el.state} mine={1} no={`No. ${convertNumber(el.seq)}`} image={el.image} level={el.is_official ? 6 : el.level + 1} text={""}>
                                        <div className="absolute w-full h-full flex justify-center items-center top-0 left-0 z-[996]">
                                            <img className="h-7" src={delImg} alt="" />
                                        </div>
                                    </CardItem>
                                </div>
                            )
                        })
                    }
                    {
                        (times - selectionList.length) > 0 && Array((times - selectionList.length)).fill(1).map((el, index) => {
                            return (
                                <div className="relative rounded-[10px] flex justify-center items-center h-[90px] w-[60px] flex-shrink-0 bg-[#fff] mr-2" key={index}>
                                    <img className="w-8" src={addImg} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="btn w-[90px]">
                    <ButtonComp width={80} disabled={selectionList.length < times ? true : false} title="Create" callBack={createHandle} ></ButtonComp>
                </div>

            </div>
            <div className="text-white text-sm">The Bet card will have its enhancement level reset after losing.</div>



        </div>

    )
}


export default mySelection